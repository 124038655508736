import React, { Fragment, FC } from 'react'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { SponsorImage } from 'scenes/Events/Sponsors/SponsorImage'
import 'swiper/css'
import 'swiper/css/pagination'
import styles from './EventsCarousel.module.scss'
import { SponsorProps } from '../../../store/api/types/Event.types'

interface SponsorsCarouselProps {
  sponsors: SponsorProps[]
  name: string
}

const ClientsAndSponsorsEventsCarousel: FC<SponsorsCarouselProps> = ({
  sponsors,
  name,
}) => {
  const SwiperButtonPrev = () => {
    const swiper = useSwiper()
    return (
      <ArrowBackIos
        className={styles.arrow}
        onClick={() => swiper.slidePrev()}
      />
    )
  }

  const SwiperButtonNext = () => {
    const swiper = useSwiper()
    return (
      <ArrowForwardIos
        className={styles.arrow}
        onClick={() => swiper.slideNext()}
      />
    )
  }

  if (!sponsors.length) {
    return <Fragment />
  }

  return (
    <div className={styles.component}>
      <Swiper slidesPerView={'auto'} className={styles.swiperSponsors}>
        <h1 className={styles.header}>
          <SwiperButtonPrev />
          {name}
          <SwiperButtonNext />
        </h1>
        {sponsors.map((sponsor) => {
          return (
            <SwiperSlide
              className={styles.swiperSponsorsSlide}
              key={sponsor.imageUrl}
            >
              <SponsorImage imageUrl={sponsor.imageUrl} onClick={() => {}} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default ClientsAndSponsorsEventsCarousel
