import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'

import { toggleMenu } from 'store/app/actions'
import { isMenuOpened, isScrollEnabled } from 'store/app/selectors'
import { toggleShoppingCart } from 'store/shop/actions'
import { getShoppingCart } from 'store/shop/selectors'

import BadgeButton from 'components/BadgeButton'
import ContextMenu, { ContextMenuOption } from 'components/ContextMenu'
import IconButton from 'components/IconButton'
import Link from 'components/Link'
import Logo from 'components/Logo'
import SwitchProfile from 'components/SwitchProfile'

import Profiles, { Profile } from 'data/Profile'

import { useGetMyProfileQuery } from 'store/api'

import { handlePageScroll } from 'utils/dom'

import AccountLinks from './AccountLinks'
import Navigation from './Navigation'
import { useMyRole } from 'hooks/useMyRole'

import './header.css'

const Header: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const checkoutProducts = useSelector(getShoppingCart)

  const menuOpened = useSelector(isMenuOpened)

  const scrollEnabled = useSelector(isScrollEnabled)

  handlePageScroll(scrollEnabled)

  const { data: profile } = useGetMyProfileQuery()
  const { isAdmin: isAdminLogged } = useMyRole()
  const [loggedProfile, setLoggedProfile] = useState<Profile | null>()
  useEffect(() => {
    profile
      ? setLoggedProfile(Profiles.ChangeToLegacyProfile(profile))
      : setLoggedProfile(null)
  }, [profile])

  const menuOptions: ContextMenuOption[] = [
    {
      label: 'Log In',
      onClick: () => navigate('/login'),
    },
    {
      label: 'Sign Up',
      onClick: () => navigate('/signup'),
    },
  ]

  const handleShopCartClick = () => {
    dispatch(toggleShoppingCart())
  }

  return (
    <>
      <header
        className="header flex items-center justify-center flex-row-reverse-l bg-white z-5"
        style={{ borderBottom: '2px solid var(--dark-surface)' }}
      >
        {!profile && <AccountLinks />}
        <IconButton
          name={menuOpened ? 'close' : 'menu'}
          className="menu-icon left-1"
          label="menu"
          onClick={() => dispatch(toggleMenu())}
        />
        <Navigation collapsed={!menuOpened}></Navigation>
        <Link to="/" label="home">
          <figure>
            <Logo />
          </figure>
        </Link>
        {!isAdminLogged && (
          <BadgeButton
            iconName="shopping_cart"
            className={cn(
              'shopping-cart-icon z-4',
              profile && 'shopping-cart-icon--authenticated',
            )}
            badgeContent={checkoutProducts.length}
            onClick={handleShopCartClick}
          />
        )}
        {loggedProfile ? (
          <SwitchProfile
            profile={loggedProfile}
            className="profile-icon z-4 right-1"
          />
        ) : (
          <div className="header__login-avatar">
            <ContextMenu icon="person" options={menuOptions} />
          </div>
        )}
      </header>
    </>
  )
}

export default Header
