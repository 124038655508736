import React, { FC, useRef, useCallback, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'

import { OutlinedContainer } from 'components/OutlineContainer'
import { Conditional } from 'components/Conditional'

import { MediaItem } from './MediaItem'
import { FileUploaderItem } from './FileUploaderItem'

import styles from './RecordMedias.module.scss'

interface RecordMediasProps {
  name?: string
  value?: string[]
  onChange(event: any): void
  accept?: string
  maxCount?: number
}

export const RecordMedias: FC<RecordMediasProps> = ({
  name,
  value = [],
  onChange,
  accept = 'image/*,video/*',
  maxCount,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [files, setFiles] = useState<File[]>([])

  const onAddIconClick = useCallback(() => {
    fileInputRef?.current?.click()
  }, [])

  const onFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesFromInput = event?.currentTarget?.files || []
    if (filesFromInput.length) {
      setFiles([...files, ...Array.from(filesFromInput)])
    }
  }

  const onUploadComplete = useCallback(
    (file: File, url: string) => {
      setFiles((files) => files.filter((fileItem) => fileItem !== file))
      const event = { target: { value: [...value, url] } }
      onChange(event)
    },
    [onChange, value],
  )

  const onDelete = useCallback(
    (url) => {
      const event = { target: { value: value.filter((item) => item !== url) } }
      onChange(event)
    },
    [onChange, value],
  )

  const shouldRenderAddItem =
    !maxCount || value.length + files.length < maxCount

  return (
    <OutlinedContainer label="Videos & Pictures">
      <input
        type="file"
        id="myFile"
        name={name}
        accept={accept}
        ref={fileInputRef}
        onChange={onFileSelect}
        hidden
        multiple
      />
      <div className={styles.medias}>
        {value.map((media) => (
          <MediaItem key={media} src={media} onDelete={onDelete} />
        ))}
        {files.map((file) => (
          <FileUploaderItem
            key={file.size}
            file={file}
            onUploadComplete={onUploadComplete}
          />
        ))}
        <Conditional shouldRender={Boolean(onChange) && shouldRenderAddItem}>
          <div className={styles.addNewItem} onClick={onAddIconClick}>
            <AddIcon className={styles.icon} />
          </div>
        </Conditional>
      </div>
    </OutlinedContainer>
  )
}
