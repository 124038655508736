import React, { useState, useEffect, useCallback } from 'react'
import { useCookies } from 'react-cookie'
import { DialogContent, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

import InputText from '../InputText'
import Button from '../Button'
import Conditional from '../Conditional'
import ShadowModal from '../ShadowModal'
import { LocationModal } from '../LocationModal'

import {
  useGetIPLocationQuery,
  useGetLocationsForRecordsQuery,
  useSubscribeToNewsletterMutation,
} from 'store/api'

import styles from './NewsletterSignUp.module.scss'

interface NewsLetterEnterEmailProps {
  email: string
  onChangeEmail(email: string): void
  onClose(): void
  onContinue(): void
  errorMessage?: string
}

export const NewsLetterEnterEmail = ({
  email,
  onChangeEmail,
  onClose,
  onContinue,
  errorMessage,
}: NewsLetterEnterEmailProps) => (
  <>
    <DialogContent className={styles.dialogContent}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        className={styles.closeBtn}
      >
        <Close />
      </IconButton>
      <p className={styles.header}>Get our monthly newsletter</p>
      <p className={styles.text}>
        Ready, Set, Get Inspired!
        <br />
        Don't miss our monthly challenges, fun activity ideas, Record Setter
        Spotlight, and exclusive deals.
      </p>
      <div className="flex flex-row w-100 items-center">
        <InputText
          label="Email"
          value={email}
          className={styles.emailInput}
          onChange={(e) => onChangeEmail(e)}
          error={!!errorMessage}
          helperText={errorMessage}
        />

        <Button className={styles.button} onClick={onContinue}>
          Subscribe
        </Button>
      </div>
      <p className={styles.bottomText}>
        We do not sell or share your information for marketing purposes, ever.
        You may opt out at any time.{' '}
        <a
          href={'/terms-of-service'}
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 'bold', textDecoration: 'underline' }}
        >
          Terms and Conditions
        </a>
        {' and '}
        <a
          href={`/privacy-policy`}
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 'bold', textDecoration: 'underline' }}
        >
          Privacy Policy
        </a>
      </p>
    </DialogContent>
  </>
)

interface InTouchProps {
  onClose(): void
}

export const InTouch = ({ onClose }: InTouchProps) => (
  <>
    <DialogContent className={styles.dialogContent}>
      <p className={styles.header}>Thank you for subscribing!</p>
      <p className={styles.text}>
        Don't forget to register or an account and add your child in order to
        submit record attempts on Kids World Records
      </p>
      <div>
        <Button className={styles.button} onClick={onClose}>
          Close
        </Button>
      </div>
      <p className={styles.bottomText}>
        We do not sell or share your information for marketing purposes, ever.
        You may opt out at any time.{' '}
        <a
          href={'/terms-of-service'}
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 'bold', textDecoration: 'underline' }}
        >
          Terms and Conditions
        </a>
        {' and '}
        <a
          href={`/privacy-policy`}
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 'bold', textDecoration: 'underline' }}
        >
          Privacy Policy
        </a>
      </p>
    </DialogContent>
  </>
)

export const NewsletterSignUp = () => {
  const [subscribeToNewsletter] = useSubscribeToNewsletterMutation()
  const [cookies, setCookie] = useCookies([`newsletter`, `location`])
  const [isOpen, setIsOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [step, setStep] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)
  const [location, setLocation] = useState('')

  const { data } = useGetIPLocationQuery()
  const { data: locations } = useGetLocationsForRecordsQuery()

  useEffect(() => {
    if (data && locations) {
      const timeoutId = setTimeout(() => {
        const locationCookie = cookies.location

        const locationsByCountry = locations.filter(
          (location) => location.country === data.country_code,
        )
        const locationByCity = locationsByCountry.find(
          (location) => location.city === data?.city,
        )

        if (!locationByCity) {
          setLocation(data.city)
        }
        if (!locationsByCountry.length) {
          setLocation(data.country_name)
        }

        if (!locationCookie && !locationByCity) {
          setCookie('location', 'true', {
            maxAge: 7 * 24 * 60 * 60,
          })
          setIsLocationModalOpen(true)
        }
      }, 5000)

      return () => clearTimeout(timeoutId)
    }
  }, [cookies, data, locations, setCookie])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const newsletterCookie = cookies.newsletter
      if (!isLocationModalOpen && !newsletterCookie) {
        setCookie(`newsletter`, 'true', {
          maxAge: 7 * 24 * 60 * 60,
        })
        setIsOpen(true)
      }
    }, 20000)

    return () => clearTimeout(timeoutId)
  }, [isLocationModalOpen, cookies, setCookie])

  const _onClose = useCallback(() => {
    setIsOpen(false)

    setTimeout(() => {
      setErrorMessage('')
      setEmail('')
      setStep(0)
    }, 500)
  }, [])

  useEffect(() => {
    if (step === 1) {
      const timeoutId = setTimeout(() => _onClose(), 2000)
      return () => clearTimeout(timeoutId)
    }
  }, [step, _onClose])

  return (
    <>
      <LocationModal
        isOpen={isLocationModalOpen}
        location={location}
        onClose={() => setIsLocationModalOpen(false)}
      />
      <ShadowModal
        className={styles.modal}
        isModalOpen={isOpen}
        noSize
        withShadow
        onCancel={_onClose}
      >
        <Conditional shouldRender={step === 0}>
          <NewsLetterEnterEmail
            email={email}
            onClose={_onClose}
            errorMessage={errorMessage}
            onChangeEmail={(email) => setEmail(email)}
            onContinue={async () => {
              if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
                setErrorMessage(
                  'We didn’t quite get that, please check your email.',
                )
              else {
                try {
                  await subscribeToNewsletter({
                    email: email.toLowerCase().trim(),
                  }).unwrap()
                  setErrorMessage('')
                  setStep(1)
                } catch (e) {
                  setErrorMessage('This email is already subscribed')
                }
              }
            }}
          />
        </Conditional>
        <Conditional shouldRender={step === 1}>
          <InTouch onClose={_onClose} />
        </Conditional>
      </ShadowModal>
    </>
  )
}
