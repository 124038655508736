import React, { useCallback, useState } from 'react'
import Title from 'components/Title'
import { Page } from 'components/Page'
import { ControlPanel } from './components/ControlPanel'
import { DropDownValue } from './components/CategorySelect'
import { QuizCard } from './QuizCard'
import styles from './Quiz.module.scss'
import { useGetQuizzesQuery } from '../../store/api'

export const Quizzes = () => {
  const { data: quizzes } = useGetQuizzesQuery()
  const [, setFilter] = useState({
    searchString: '',
    category: 'ALL_QUIZZES',
  })
  const [defaultCategory] = useState('ALL_QUIZZES')
  return (
    <Page className={styles.page}>
      <Title className={styles.title}>Quizzes</Title>
      <p className={styles.description}>
        Break an instant Kids World Record right here on our site!
      </p>
      <p>
        Test your knowledge of Flags, Science, General Trivia, Nature, Disney
        World Capitals/Money/dialing codes and more. If you get the most
        correct, or if you're the fastest with most correct answers, you become
        the next Kids World Record Holder!
      </p>
      <ControlPanel
        onSearch={useCallback((filter) => {
          setFilter(filter)
        }, [])}
        defaultValue={defaultCategory as DropDownValue}
      />
      <div className={styles.list}>
        {quizzes?.map((quiz) => (
          <QuizCard
            key={quiz.id}
            id={quiz.id}
            title={quiz.title}
            description={quiz.shortDescription}
            image={quiz.previewImageUrl}
            sponsorLogo={quiz.sponsorLogo}
          />
        ))}
      </div>
    </Page>
  )
}
