import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useGetEventQuery, useUpdateEventMutation } from 'store/api'
import { CampaignForm } from 'scenes/Events/CampaignForm'
import { FormError, UpdateEvent } from '../CampaignForm/types'
import { uploadEventImages } from '../Events.utils'
import { getErrorsUpdate } from '../CreateEvent/createEvent.validator'

export const EditEvent = () => {
  const { id = '' } = useParams<{ id: string }>()
  const { data: event, isLoading } = useGetEventQuery(id, { skip: !id })
  const [updateEvent, { isLoading: isUpdating }] = useUpdateEventMutation()
  const [uploading, setUploading] = useState(false)
  const [errors, setErrors] = useState<FormError | undefined>({})

  const onUpdateEvent = async (eventToUpdate: UpdateEvent) => {
    setUploading(true)
    const rawErrors = getErrorsUpdate(eventToUpdate)
    setErrors(rawErrors)
    if (!rawErrors && !isLoading && id) {
      try {
        const { medias, finishedPageMedias, comingSoonPageMedias } =
          await uploadEventImages(
            id,
            eventToUpdate.medias,
            eventToUpdate.comingSoonPageMedias,
            eventToUpdate?.finishedPageMedias,
          )

        const updatedMedias = medias.length ? medias : event?.medias || []
        const updatedComingSoonPageMedias = comingSoonPageMedias.length
          ? comingSoonPageMedias
          : event?.comingSoonPageMedias || []
        const updatedFinishedPageMedias = finishedPageMedias.length
          ? finishedPageMedias
          : event?.finishedPageMedias || []

        updateEvent({
          ...eventToUpdate,
          medias: updatedMedias,
          comingSoonPageMedias: updatedComingSoonPageMedias,
          finishedPageMedias: updatedFinishedPageMedias,
          id,
        })
      } catch (e) {
        console.log(e)
      }
    }
    setUploading(false)
  }

  return (
    <CampaignForm
      event={event}
      isLoading={isLoading || isUpdating || uploading}
      onSubmit={onUpdateEvent}
      errors={errors}
    />
  )
}
