import React, { ReactElement } from 'react'
import Dialog from '@mui/material/Dialog'
import Button from 'components/Button'

import styles from './BreakRecordModal.module.scss'
// import InputText from 'components/InputText'
// import { useOneRecordApiHook } from 'hooks/useOneRecordApi.hook'
// import Conditional from 'components/Conditional'
// import { sortLinksImagesFirst } from 'utils/url'

interface BreakRecordModalProps {
  isOpen?: boolean
  onCancel?(): void
  onConfirm?(): void
  disabled?: boolean
}

function BreakRecordModal({
  isOpen = false,
  onCancel,
  onConfirm,
  disabled,
}: BreakRecordModalProps): ReactElement {
  return (
    <Dialog open={isOpen} fullWidth onClose={onCancel}>
      <div className={styles.main}>
        <div className="nunito-bold neo-red f4">Mark record as broken</div>
        <div className="nunito-regular f5 pt3">{`Are you sure?`}</div>
        <div className="flex flex-row justify-end pt5">
          <Button
            ghost
            theme="secondary"
            onClick={onCancel}
            disabled={disabled}
          >
            Cancel
          </Button>
          <Button theme="secondary" onClick={onConfirm} disabled={disabled}>
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  )

  // todo: REPLACE WITH NEXT RECORD ID AFTER FIX BREAK RECORD FLOW
  //   return (
  //     <Dialog open={isOpen} fullWidth onClose={onCancel}>
  //       <div className={styles.main}>
  //         <div className="nunito-bold neo-red f4">Mark record as broken</div>
  //         <div className="nunito-regular f5 pt3">{`Please insert record Id which break this record`}</div>
  //         <InputText label="Record Id" value={recordId} onChange={setRecordId} />
  //         <Button
  //           theme="secondary"
  //           onClick={() => doRequest(recordId)}
  //           disabled={!recordId || loading}
  //         >
  //           Find
  //         </Button>
  //         <Conditional shouldRender={Boolean(data)}>
  //           <div className={styles.record}>
  //             <img
  //               alt="Record img"
  //               src={sortLinksImagesFirst(data?.imageLinks || [])[0]}
  //             />
  //             <div className={styles.info}>
  //               <div className={styles.title}>{data?.title}</div>
  //               <div className={styles.description}>{data?.title}</div>
  //               <div className={styles.date}>Broken on: {data?.brokenOn}</div>
  //             </div>
  //           </div>
  //         </Conditional>
  //         <div className="flex flex-row justify-end pt5">
  //           <Button ghost theme="secondary" onClick={onCancel}>
  //             Cancel
  //           </Button>
  //           <Button
  //             theme="secondary"
  //             onClick={() => onConfirm && onConfirm(recordId)}
  //             disabled={!data || loading}
  //           >
  //             Confirm
  //           </Button>
  //         </div>
  //       </div>
  //     </Dialog>
  //   )
}

export default BreakRecordModal
