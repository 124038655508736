import React from 'react'
import Title from '../../components/Title'
import BorderedContainer from '../../components/BorderedContainer'
import styles from './Ambassador.module.scss'

export const AmbassadorList = () => {
  return (
    <BorderedContainer>
      <Title>Ambassador List</Title>
      <p className={styles.description}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>
    </BorderedContainer>
  )
}
