import React from 'react'
import Button from 'components/Button'

export interface Question {
  description: string
  subDescription: string
  alternatives: string[]
  possibleAnswers: string[]
  rightAnswerIndex: number
}

interface QuestionCardProps {
  question: Question
  shownQuestionIndex: number
  questionIndex: number
  onClick: () => void
  onRightAnswer: () => void
}

const QuestionCard: React.FC<QuestionCardProps> = ({
  question,
  questionIndex,
  shownQuestionIndex,
  onClick,
  onRightAnswer,
}) => {
  return (
    <>
      {questionIndex === shownQuestionIndex && (
        <>
          <h3 className="f4 normal ma0 dark-gray">
            <b>{question.description}</b>
            {question.subDescription}
          </h3>
          <ul className="list pl0 pb3">
            {question.alternatives.map((alternative: string, index) => (
              <li key={index} className="pt2 pb2 pl0">
                {alternative}
              </li>
            ))}
          </ul>
          <ul className="list flex flex-column pt1 pl0 w-100">
            {question.possibleAnswers.map((possibleAnswer: string, index) => (
              <li className="pb3" key={index}>
                <Button
                  onClick={
                    question.rightAnswerIndex === index
                      ? onRightAnswer
                      : onClick
                  }
                  theme="secondary"
                  className="w-100 mw6"
                  ghost
                  outline
                >
                  {possibleAnswer}
                </Button>
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  )
}

export default QuestionCard
