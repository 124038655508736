import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { EditPin } from 'scenes/Pin'
import { Pin } from 'scenes/Pin/EditPin'

// import Pins, { Pin } from 'typings/Pin'
import { useEditParentPinMutation } from 'store/api'

function EditPinProfile(): ReactElement {
  const [editPin] = useEditParentPinMutation()
  const navigate = useNavigate()

  const handleSubmit = async (number: Pin) => {
    await editPin({
      pin: `${number.pin[0]}${number.pin[1]}${number.pin[2]}${number.pin[3]}`,
    }).unwrap()
    navigate(-1)
  }

  return <EditPin handleSubmit={handleSubmit} />
}

export default EditPinProfile
