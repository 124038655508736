import React, { FC, Fragment, useMemo, useState } from 'react'
import cn from 'classnames'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import Conditional from 'components/Conditional'
import Loader from 'components/Loader'

import { useGetScoreboardDataByQuizIdQuery } from 'store/api'
import { SortType } from 'store/api/types/Quiz.types'

import styles from './Scoreboard.module.scss'

const HEADER_TITLES = ['Name', 'Division', 'Location', 'Score']

type ScoreboardProps = {
  quizId: string
}

export const Scoreboard: FC<ScoreboardProps> = ({ quizId }) => {
  const { data, isFetching } = useGetScoreboardDataByQuizIdQuery(quizId)

  const rawStyle = {
    backgroundColor: data?.themeColor,
  }
  const headerStyle = {
    color: data?.themeColor,
  }

  const [sort, setSort] = useState<SortType>({
    name: 'Score',
    order: 'desc',
  })

  const onSortChange = (title: string) => {
    title === sort.name
      ? setSort({
          name: sort.name,
          order: sort.order === 'desc' ? 'asc' : 'desc',
        })
      : setSort({ name: title, order: 'desc' })
  }

  const sortedScores = useMemo(() => {
    const scores = (data?.records || []).slice()

    const sortedArray = scores.sort((a, b) => {
      if (sort.name === 'Name')
        return a.recordHolderName
          .toLowerCase()
          .localeCompare(b.recordHolderName.toLowerCase())
      if (sort.name === 'Division')
        return a.division.toLowerCase().localeCompare(b.division.toLowerCase())
      if (sort.name === 'Location')
        return a.location.toLowerCase().localeCompare(b.location.toLowerCase())
      if (sort.name === 'Score')
        return a.score.toLowerCase().localeCompare(b.score.toLowerCase())
      return 0
    })

    return sort.order === 'desc' ? sortedArray.reverse() : sortedArray
  }, [data, sort])

  return (
    <Fragment>
      <Conditional shouldRender={isFetching}>
        <Loader className={styles.loader} active />
      </Conditional>
      <Conditional shouldRender={!isFetching}>
        <div className={styles.title} style={headerStyle}>
          Most {data?.quizTitle} Identified
        </div>
        <TableContainer>
          <Table className={styles.table}>
            <TableHead className={styles.tableHead}>
              <TableRow>
                {HEADER_TITLES.map((headItem) => (
                  <TableCell className={styles.cell} key={headItem}>
                    <TableSortLabel
                      IconComponent={ArrowDropDownIcon}
                      active={sort.name === headItem}
                      direction={sort.order}
                      onClick={() => onSortChange(headItem)}
                    >
                      {headItem}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedScores.map((record, index) => (
                <TableRow
                  style={index % 2 === 0 ? rawStyle : undefined}
                  className={styles.tableRow}
                  key={index + record.id}
                >
                  <TableCell className={cn(styles.cell, styles.bold)}>
                    {record.recordHolderName}
                  </TableCell>
                  <TableCell className={styles.cell}>
                    {record.division}
                  </TableCell>
                  <TableCell className={styles.cell}>
                    {record.location}
                  </TableCell>
                  <TableCell className={cn(styles.cell, styles.bold)}>
                    {record.score}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Conditional>
    </Fragment>
  )
}
