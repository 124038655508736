import React, { FC, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay, Pagination } from 'swiper/modules'

import Button from 'components/Button'
import { Input } from 'components/Inputs'

import { isEmailValid } from 'utils/validators'
import {
  useLetsTalkMutation,
  useGetPublicEventsQuery,
  useGetPublicSponsorsQuery,
} from 'store/api'
import { Event } from 'store/api/types/Event.types'

import ClientsAndSponsorsEventsCarousel from './EventsCarousel/ClientsAndSporsorsCarousel'
import EventsCarousel from './EventsCarousel'
import { ThankYouModal } from './Modal'

import styles from './GroupEvents.module.scss'
import { PageWrapper } from 'components/PageWrapper'
import LinkButton from 'components/LinkButton'

const title = 'Create Memorable World Record Events With Kids World Records'
const description =
  "We provide support with ideation, social and marketing and the digital platform for unforgettable brand marketing, a unique product launches, creating digital buzz, or rally a community around an important cause. We're all about crafting share-worthy moments that'll make an impact. Let's have a conversation about creating your unique, record-breaking experience."
const eventDescriptionHeader =
  'Kids World Records Events are engaging and versatile'
// const eventDescriptionImage =
//   'https://storage.googleapis.com/staging.refreshing-park-265118.appspot.com/GLGK-NPPC-N9LX-JFXJ-3howtobreakarecord.png'

const descriptionLoremLong =
  'Events are often fun, but predictable. Contact us to hear how we can add excitement and press coverage for your event.'
const descriprtionSponsorship =
  'By sponsoring Kids World Records events, you gain exposure to kids, parents and press. Contact us to learn more about sponsoring an event.'

const consultingTitle = 'Talk to us about your audience and goals'
const consultingDescription =
  'Let us help you create an eye-catching world record and an unforgettable event.'

const learnMoreDescription =
  'We’re in the creating fun business. Contact us for a casual conversation about how we might help you.'

interface EventBoxProps {
  image: string
  title: string
  description: string
}

const eventDescriptionMock: EventBoxProps[] = [
  {
    image: '/assets/event-image-A.png',
    title: 'In-person Events',
    description:
      'In-person events bring the energy for record-breaking success. We help you ideate, plan and manage record breaking in-person events',
  },
  {
    image: '/assets/event-image-B.png',
    title: 'Virtual Events',
    description:
      'Virtual events are a great way to scale digitally. We support brands through ideation, digital asset production, social media strategy and execution.',
  },
  {
    image: '/assets/event-image-C.png',
    title: 'Hybrid Events',
    description:
      'Hybrid events combines the energy of in-person events with the scale of digital. We can help plan and manage all aspect of hybrid campaigns.',
  },
]

const testimonialSlides: TestimonialSlideProps[] = [
  {
    title: 'Testimonials',
    description:
      'We’re excited to support kids exploring science in a way that has reach and align with our brand. I hope one day I’ll be interviewing one of these amazing kids for a job.',
    photo: '/assets/сustomer.png',
    name: 'Aleem Mohummed',
    position: 'COO, Lasso Informatics',
  },
]

interface TestimonialSlideProps {
  title: string
  description: string
  photo: string
  name: string
  position: string
}

const TestimonialSlide: FC<TestimonialSlideProps> = ({
  title,
  description,
  photo,
  name,
  position,
}) => {
  return (
    <div className={styles.slide}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.card}>
        <div className={styles.description}>{description}</div>
        <div className={styles.userBox}>
          <img className={styles.image} src={photo} alt={'testimonialPhoto'} />
          <div className={styles.userDescriptionBox}>
            <div className={styles.name}>{name}</div>
            <div className={styles.title}>{position}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const EventBox: FC<EventBoxProps> = ({ image, title, description }) => {
  return (
    <div className={styles.eventBox}>
      <img src={image} alt={'eventImage'} className={styles.imageEventBox} />
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  )
}

const GroupEvents: FC = () => {
  const ref = useRef()
  const { data } = useGetPublicEventsQuery()
  const { data: sponsors = [] } = useGetPublicSponsorsQuery()
  const [errorMessage, setErrorMessage] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [sendEmail, { isLoading: isSending }] = useLetsTalkMutation()

  const [pastEvents, currentEvents, upcomingEvents] = useMemo(() => {
    const past: Event[] = []
    const current: Event[] = []
    const upcoming: Event[] = []

    if (data && data.length) {
      data.forEach((event) => {
        const isCurrentEvent =
          new Date(event.startDate) <= new Date() &&
          new Date() < new Date(event.endDate)
        const isPastEvent = new Date() >= new Date(event.endDate)
        const isUpcomingEvent = new Date() <= new Date(event.startDate)
        if (isCurrentEvent) current.push(event)
        if (isPastEvent) past.push(event)
        if (isUpcomingEvent) upcoming.push(event)
      })
    }

    return [past, current, upcoming]
  }, [data])

  const onContinue = async () => {
    // @ts-ignore
    const email = ref.current?.value
    if (isEmailValid(email)) {
      setErrorMessage('')
      await sendEmail(email)
      setIsOpenModal(true)
      // @ts-ignore
      ref.current.value = ''
    } else {
      setErrorMessage('Email is not valid')
      console.log('errorMessage', errorMessage)
    }
  }

  const onConsulting = () => {
    document.getElementById('scroller')?.scrollIntoView({ behavior: 'smooth' })
  }

  const pagination = {
    clickable: true,
  }

  return (
    <PageWrapper>
      <div className={styles.page}>
        <ThankYouModal
          isOpenModal={isOpenModal}
          onClose={() => setIsOpenModal(false)}
        />
        <div id="scroller" className={styles.main}>
          <div className={styles.text}>
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.description}>
              {description}
              <br />
              <div className={styles.buttonBoxLinkToList}>
                <LinkButton
                  to="/available-and-unavailable-group-records"
                  className={styles.linkButtonToList}
                >
                  Find camp and school records
                </LinkButton>
              </div>
              For personal record attempts,{' '}
              <a
                href={`https://www.kidsworldrecords.com/signup`}
                target="_blank"
                rel="noreferrer"
                style={{ fontWeight: 'bold' }}
              >
                please apply via your profile account.
              </a>
            </p>
            <div className={styles.emailInput}>
              <Input
                inputRef={ref}
                className={styles.inputField}
                type="email"
                name="email"
                label="Email"
              />
              <Button
                className={styles.button}
                disabled={isSending}
                onClick={onContinue}
              >
                LET’S TALK
              </Button>
            </div>
          </div>

          <div className={styles.carousel}>
            <video controls className={classNames(styles.iframe)}>
              <source src="/assets/events-promo.mp4" type="video/mp4" />
            </video>
          </div>
        </div>

        <EventsCarousel events={upcomingEvents} name={'Upcoming Events'} />
        <EventsCarousel events={currentEvents} name={'Current Events'} />
        <EventsCarousel events={pastEvents} name={'Past Events'} />

        <div className={styles.eventDescription}>
          <h1 className={styles.header}>{eventDescriptionHeader}</h1>
          <div className={styles.eventDescriptionBox}>
            {eventDescriptionMock.map((event) => (
              <EventBox
                image={event.image}
                title={event.title}
                description={event.description}
                key={event.image}
              />
            ))}
          </div>
        </div>

        <div className={styles.eventConsulting}>
          <img
            className={styles.eventConsultingImage}
            src="/assets/chicken-dance.png"
            alt={'eventImage'}
          />
          <div className={styles.eventConsultingTextBox}>
            <h1 className={styles.title}>{consultingTitle}</h1>
            <div className={styles.description}>{consultingDescription}</div>
            <div className={styles.buttonBox}>
              <Button className={styles.button} onClick={onConsulting}>
                LET’S TALK
              </Button>
            </div>
          </div>
        </div>

        <ClientsAndSponsorsEventsCarousel
          sponsors={sponsors}
          name={'Clients & Sponsors'}
        />

        <div className={styles.eventBenefits}>
          <img
            className={styles.eventBenefitsImage}
            src="/assets/quiz.png"
            alt={'eventImage'}
          />
          <div className={styles.bottomBox}>
            <div className={styles.benefitBox}>
              <h1 className={styles.title}>
                Build better events with Kids World Records
              </h1>
              <div className={styles.description}>{descriptionLoremLong}</div>
            </div>
            <div className={styles.benefitBox}>
              <h1 className={styles.title}>Become a sponsor</h1>
              <div className={styles.description}>
                {descriprtionSponsorship}
              </div>
            </div>
          </div>
        </div>

        <Swiper
          slidesPerView={1}
          autoplay={true}
          className={styles.testimonialsSwiper}
          pagination={pagination}
          modules={[Autoplay, Pagination]}
          style={{
            '--swiper-pagination-color': '#FF5252',
            '--swiper-pagination-bullet-inactive-color': '#FFF',
            '--swiper-pagination-bullet-inactive-opacity': '1',
          }}
        >
          {testimonialSlides.map((slide: TestimonialSlideProps) => {
            return (
              <SwiperSlide className={styles.swiperSlide} key={slide.name}>
                <TestimonialSlide
                  name={slide.name}
                  description={slide.description}
                  photo={slide.photo}
                  title={slide.title}
                  position={slide.position}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>

        <div className={styles.learnMore}>
          <h1 className={styles.title}>{'Learn more'}</h1>
          <p className={styles.description}>{learnMoreDescription}</p>
          <div className={styles.emailInput}>
            <Input
              className={styles.inputField}
              type="email"
              name="email"
              label="Email"
            />
            <Button className={styles.button} onClick={onContinue}>
              Contact Us
            </Button>
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}

export default GroupEvents
