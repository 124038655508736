import React from 'react'
import { IconButton } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import ShadowModal from 'components/ShadowModal'
import LinkButton from 'components/LinkButton'
import Button from 'components/Button'
import Conditional from 'components/Conditional'
import styles from '../Quiz/Quiz.module.scss'
import { Link } from 'react-router-dom'

interface CompleteAttemptModalProps {
  isOpenModal: boolean
  recordId?: string
  onClose: () => void
}

export const CompleteAttemptModal: React.FC<CompleteAttemptModalProps> = ({
  isOpenModal,
  recordId,
  onClose,
}) => {
  return (
    <ShadowModal
      className={styles.modal}
      isModalOpen={isOpenModal}
      onCancel={onClose}
      withShadow
      noSize
    >
      <Conditional shouldRender={!recordId}>
        <div className={styles.modalContainer}>
          <div className={styles.header}>
            Well done, but you did not set a Kids World Record.
          </div>
          <div className={styles.modalText}>
            Immediately restart the quiz to try again, or go back to the quiz
            page to attempt a different one.
          </div>
          <div className={styles.buttonBox}>
            <Button onClick={onClose}>Try again</Button>
            <LinkButton to="/quizzes">Try another quiz</LinkButton>
          </div>
        </div>
      </Conditional>
      <Conditional shouldRender={!!recordId}>
        <div className={styles.modalContainer}>
          <div className={styles.header}>You set a new Kids World Record!</div>
          <div className={styles.modalText}>
            You’re now part of the Kids World Record family along with kids from
            all over the world. To see your record{' '}
            <Link
              to={`/my-records/${recordId}`}
              target="_blank"
              rel="noreferrer"
              style={{
                color: 'red',
                textDecoration: 'underline',
                display: 'inline-block',
              }}
            >
              click here.
            </Link>
          </div>
          <div className={styles.buttonBox}>
            <LinkButton to="/shop">
              Shop Certificate and Exclusive Gear
            </LinkButton>
          </div>
          <div className={styles.share}>
            Share
            <div className={styles.shareButtons}>
              <IconButton aria-label="instagram" component="span">
                <InstagramIcon />
              </IconButton>
              <IconButton aria-label="facebook" component="span">
                <FacebookIcon />
              </IconButton>
              <IconButton aria-label="x" component="span">
                <img src="/assets/x.svg" alt="x" />
              </IconButton>
            </div>
          </div>
        </div>
      </Conditional>
    </ShadowModal>
  )
}
