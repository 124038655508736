import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router'

import { useForm } from 'react-hook-form'
import BorderedContainer from 'components/BorderedContainer'
import ChildForm from 'components/ChildProfileForm'
import ImageDecoration, {
  ImageDecorationType,
} from 'components/ImageDecoration'
import { Page } from 'components/Page'

import { ValidChildProfileFormData } from 'typings/ChildProfileFormData'

import dayjs from 'dayjs'
import { joiResolver } from '@hookform/resolvers/joi'
import { ADD_CHILD_VALIDATOR } from './AddChild.utils'
import { useCreateChildMutation } from '../../store/api'
import { ErrorAgeModal } from './ErrorAgeModal'
import { useMyRole } from 'hooks/useMyRole'

const DEFAULT_VALUE = {
  dateOfBirth: dayjs().add(-4, 'years').toDate(),
  firstName: '',
  lastName: '',
}

const AddChild: React.FC = () => {
  const navigate = useNavigate()
  const [trigger] = useCreateChildMutation()
  const { isAuthenticated, isParent } = useMyRole()
  const [showErrorAgeModal, setShowErrorAgeModal] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ValidChildProfileFormData>({
    defaultValues: {
      ...DEFAULT_VALUE,
    },
    resolver: joiResolver(ADD_CHILD_VALIDATOR),
    mode: 'all',
  })

  const onSubmit = (data: ValidChildProfileFormData) => {
    const dateOfBirth = dayjs(
      new Date(data.dateOfBirth).setUTCHours(0, 0, 0, 0),
    )
    const dateCurrent = dayjs(
      new Date().getTime() +
        (data.location?.timezoneInMiliseconds
          ? data.location?.timezoneInMiliseconds
          : 0),
    )
    const age = dateCurrent.diff(dateOfBirth, 'year')
    if (age < 4) {
      setShowErrorAgeModal(true)
    } else {
      trigger(data)
        .unwrap()
        .then(() => navigate('/profile'))
    }
  }

  if (isAuthenticated && !isParent) {
    return <Navigate to="/" />
  }

  return (
    <Page>
      {!isAuthenticated && <Navigate to="/signup" />}
      {isAuthenticated && (
        <>
          <BorderedContainer>
            <ChildForm
              control={control}
              errors={errors}
              handleSubmit={handleSubmit(onSubmit)}
              setValue={setValue}
              title="Add Child"
            />
          </BorderedContainer>
          <ImageDecoration
            type={ImageDecorationType.GIRL_RUNNING}
            position="left"
          />
        </>
      )}
      <ErrorAgeModal
        showErrorAgeModal={showErrorAgeModal}
        onCancel={() => setShowErrorAgeModal(false)}
      />
    </Page>
  )
}

export default AddChild
