import React, { useEffect, useState } from 'react'

import BorderedContainer from 'components/BorderedContainer'
import Button from 'components/Button'
import RecordSubmissionTable from 'components/RecordSubmissionTable'
import Title from 'components/Title'
import { Screen } from 'components/Screen'
import { PageWrapper } from 'components/PageWrapper'

import { useSubmissions } from 'hooks/useSubmissionApi.hook'
import { SortType } from 'store/api/types/Record.types'

import { AddNewSubmission } from './components/AddNewSubmission'
import { SearchBar } from './components/SearchBar'

import styles from './RecordSubmissions.module.scss'
import { ExportRecords } from './components/ExportRecords'
import { CONFIG } from 'config'

const all = 'All'
const approved = 'Approved'
const inReview = 'In Review'
const rejected = 'Rejected'

type AllowedTabs = 'All' | 'Approved' | 'In Review' | 'Rejected'

const getStatus = (tab: AllowedTabs): string | undefined => {
  switch (tab) {
    case 'All':
      return undefined
    case 'Approved':
      return 'APPROVED'
    case 'In Review':
      return 'IN_REVIEW'
    case 'Rejected':
      return 'REJECTED'
  }
}

export const RecordSubmissions: React.FC = () => {
  const [displayedTab, setDisplayedTab] = useState<AllowedTabs>(inReview)
  const [text, setSearchText] = useState<string>('')
  const [sort, setSort] = useState<SortType>({
    name: 'Submitted On',
    order: 'desc',
  })

  const [filter, setFilter] = useState<{
    search: string
    sort: SortType
    status: string
  }>({
    search: '',
    sort,
    status: 'IN_REVIEW',
  })
  const { submissions, counts, onNext, isLoading, hasNextPage } =
    useSubmissions(filter)

  useEffect(() => {
    const newStatus = getStatus(displayedTab) || ''
    setFilter((prev) => ({
      search: text,
      sort:
        prev.search === text && prev.status === newStatus
          ? sort
          : {
              name: 'Submitted On',
              order: 'desc',
            },
      status: newStatus,
    }))
  }, [displayedTab, text, sort])

  const handleTheme = (tabName: string) =>
    displayedTab === tabName ? 'primary' : 'secondary'

  const handleSortOrder = (title: string) => {
    title === sort.name
      ? setSort({
          name: sort.name,
          order: sort.order === 'desc' ? 'asc' : 'desc',
        })
      : setSort({ name: title, order: 'asc' })
  }

  return (
    <PageWrapper>
      <Screen className={styles.screen}>
        <BorderedContainer>
          <Title>Records</Title>
          <div className={styles.buttons}>
            <Button
              fullWidth
              theme={handleTheme(all)}
              onClick={() => setDisplayedTab(all)}
            >
              All ({counts?.totalCount})
            </Button>
            <Button
              fullWidth
              theme={handleTheme(inReview)}
              onClick={() => setDisplayedTab(inReview)}
            >
              In review ({counts?.inReview})
            </Button>
            <Button
              fullWidth
              theme={handleTheme(approved)}
              onClick={() => setDisplayedTab(approved)}
            >
              Approved ({counts?.approved})
            </Button>
            <Button
              fullWidth
              theme={handleTheme(rejected)}
              onClick={() => setDisplayedTab(rejected)}
            >
              Rejected ({counts?.rejected})
            </Button>
          </div>
          <SearchBar
            onSearchSubmit={setSearchText}
            isSearching={isLoading}
            setIsSearching={() => {}}
          />
          <RecordSubmissionTable
            sort={sort}
            getMore={onNext}
            onSortChange={handleSortOrder}
            hasNextPage={hasNextPage}
            loadingMore={isLoading}
            submissions={submissions}
            refreshRows={() => null}
          />
        </BorderedContainer>
        {CONFIG.FEATURE_741 && <ExportRecords />}
        <AddNewSubmission />
      </Screen>
    </PageWrapper>
  )
}
