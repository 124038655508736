import React, { FC } from 'react'
import { IconButton } from '@mui/material'
import { Delete, ArrowDownward, ArrowUpward } from '@mui/icons-material'
import cn from 'classnames'

import BorderedContainer from 'components/BorderedContainer'
import Conditional from 'components/Conditional'
import Button from 'components/Button'
import { MediaType } from 'store/api/types/MainPageHeaderContent'

import styles from './AccordionImage.module.scss'

type AccordionImageProps = {
  src?: string
  alt: string
  className?: string
  type: MediaType
  onImageSelect?(): void
  onDelete?(): void
  onLink?(): void
  onUp?(): void
  onDown?(): void
}

export const AccordionImage: FC<AccordionImageProps> = ({
  src,
  alt,
  type,
  className,
  onDown,
  onUp,
  onDelete,
  onImageSelect,
}) => {
  const isImageAvailable = Boolean(src)
  return (
    <div className={cn(styles.wrapper, className)}>
      <Conditional shouldRender={isImageAvailable}>
        {type === MediaType.IMAGE ? (
          <img className={styles.image} src={src} alt={alt} />
        ) : (
          <video
            autoPlay
            loop={true}
            muted
            playsInline
            style={{ width: '100%' }}
          >
            <source src={src} type="video/mp4" />
          </video>
        )}
      </Conditional>
      <Conditional shouldRender={!isImageAvailable}>
        <BorderedContainer>
          <Button onClick={onImageSelect}>Select Image</Button>
        </BorderedContainer>
      </Conditional>
      <div className={styles.buttons}>
        <Conditional shouldRender={Boolean(onUp)}>
          <IconButton className={styles.button} onClick={onUp}>
            <ArrowUpward />
          </IconButton>
        </Conditional>
        <Conditional shouldRender={Boolean(onDown)}>
          <IconButton className={styles.button} onClick={onDown}>
            <ArrowDownward />
          </IconButton>
        </Conditional>
        <Conditional shouldRender={Boolean(onDelete)}>
          <IconButton className={styles.button} onClick={onDelete}>
            <Delete />
          </IconButton>
        </Conditional>
      </div>
    </div>
  )
}
