import React, { useCallback, useMemo } from 'react'
import { TextFieldVariants } from '@mui/material/TextField'
import { InputAdornment } from '@mui/material'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import AccessTime from '@mui/icons-material/AccessTime'
import dayjs, { Dayjs } from 'dayjs'
import cn from 'classnames'

import styles from './Input.module.scss'

interface DatePickerProps {
  label: string
  name?: string
  defaultValue?: Date
  errorText?: string
  disableFuture?: boolean
  ampm?: boolean
  onChange?: (e: Date | null) => void
}

export const TimePicker = ({
  label,
  name,
  defaultValue,
  errorText,
  ampm,
  disableFuture = false,
  onChange,
}: DatePickerProps) => {
  const slotProps = useMemo(
    () => ({
      textField: {
        variant: 'filled' as TextFieldVariants,
        name: name,
        error: !!errorText,
        helperText: errorText,
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <AccessTime />
            </InputAdornment>
          ),
        },
      },
    }),
    [name, errorText],
  )

  const onChangeHandler = useCallback(
    (date: Dayjs | null) => {
      if (onChange) {
        onChange(date ? date?.toDate() : null)
      }
    },
    [onChange],
  )

  return (
    <MobileTimePicker
      className={cn(styles.input, styles.picker)}
      ampm={ampm}
      label={label}
      disableFuture={disableFuture}
      defaultValue={defaultValue ? dayjs(defaultValue) : undefined}
      slotProps={slotProps}
      onChange={onChangeHandler}
    />
  )
}
