import React from 'react'
import BorderedContainer from 'components/BorderedContainer'
import Title from 'components/Title'
import { Page } from 'components/Page'
import { Link } from 'react-router-dom'

export const FAQV1 = () => {
  return (
    <Page>
      <BorderedContainer>
        <Title className="pb3 pb4-l">FAQ</Title>
        <h4>Who can participate in Kids World Records?</h4>
        Kids World Records makes room for kids of all ages and talents to shine.
        <ul>
          <li>
            Individual record submissions are grouped and adjudicated according
            to age range (4-6, 7-9, 10-12 and 13-16) and gender (boy, girl,
            gender X)
          </li>
          <li>
            Group record submissions are grouped and adjudicated according
            to the number of participants: Small (2-5), Medium (6-15), Large
            (16-30) and Gigantic (31+)
          </li>
        </ul>
        <h4>What does it take to get a Kids World Record?</h4>
        The key tenants of a Kids World Record is that it is adequately
        challenging, safe and measurable by either:
        <ul>
          <li>Quantity: Most Consecutive / Most Ever</li>
          <li>Size: Smallest / Largest / Longest / Highest</li>
          <li>Time: Fastest / Endurance</li>
        </ul>
        While we love a challenge, Kids World Record attempts{' '}
        <strong>should never be dangerous or harmful.</strong> Participants
        should always be supervised by an adult while performing an
        age-appropriate activity, and attire should be appropriate for the
        activity.
        <br />
        <br />
        <strong>Youngest Records</strong>
        <br />
        <br />
        We want to celebrate kids at every age and stage so{' '}
        <strong>
          we do not adjudicate Kids World Records attempts based on them being
          the youngest.
        </strong>{' '}
        Instead, we use a divisional model that groups participants based on age
        range and gender. Therefore,{' '}
        <strong>each record attempt must be measurable and repeatable</strong>{' '}
        across the various age and gender category. For example, a record
        attempt for youngest computer programmer will not be accepted. If you
        would like to submit a Kids World Record attempt,{' '}
        <strong>
          consider how the attempt could be quantified and measured.
        </strong>{' '}
        For instance, does the record participant do a specific task in a
        measured time frame?
        <br />
        <br />
        <strong>Memorization records</strong>
        <br />
        <br />
        Whether recitation or visual identification, memorization records must
        be the most of a specific category. For example, "the most dinosaur
        types recited from memory" or "the most flags identified" would qualify.
        Adjudicators must be able to clearly hear the record attempt so
        “fastest” memorization records will no longer be accepted. For example,
        "50 United States recited in 30 seconds" would NOT be accepted.
        <br />
        <br />
        <strong>Recognizing, identifying, recounting records.</strong>
        <br />
        <br />
        For records involving memorizing items such as cars, cartoon characters,
        dinosaurs, numbers, names etc.{' '}
        <strong>the following must be provided with the submission.</strong>
        <br />
        <ul>
          <li>
            Video with the entire attempt showing the screen and the child
          </li>
          <li>
            A timer such a smart phone must be visible in the video at all
            times.
          </li>
          <li>
            A list with all images and written name of each item being
            identified in English. The list must be in the same order as the
            items identified in the video.
          </li>
        </ul>
        <br />
        All record attempts require video and images for verification! For
        complete rules, please visit:{' '}
        <Link
          to={'/how-to-break-a-record'}
          style={{ color: 'blue', textDecoration: 'underline' }}
        >
          How to break a record.
        </Link>
        <h4>How do I set a new Kids World Record?</h4>
        Use your imagination – we’d love to see what creative ideas you come up
        with! You can also use our{' '}
        <Link
          to={'/record-ideas'}
          style={{ color: 'blue', textDecoration: 'underline' }}
        >
          World Record Idea Generator{' '}
        </Link>{' '}
        for inspiration.
        <h4>How do I break an existing Kids World Record?</h4>
        Check out the{' '}
        <Link to={'/'} style={{ color: 'blue', textDecoration: 'underline' }}>
          Kids Word Records
        </Link>{' '}
        website for existing records in your age and gender group. If you spot
        one you’d like to break, click “Break This Record” to start the
        submission process.
        <h4>What evidence do I need of my Kids World Record attempt?</h4>
        In order for a Kids World Record attempt to be verified and potentially
        make it into the Kids World Records book we require high-resolution
        images and video.
        <h4>Videos should:</h4>
        <ul>
          <li>Show the Kids World Record attempt in its entirety</li>
          <li>
            <strong>Video must not be edited</strong> or speed up in any way
            records and <strong>must show full attempt.</strong> Altered videos
            will be rejected. Videos on our site are edited to ~15 seconds for
            easy viewing
          </li>
          <li>
            Record attempts that are timed should show a timer in the video
            frame. You can use any smart phone or timer
          </li>
          <li>
            Capture the entire figure of the child doing the Kids World Record
            attempt. The participant should not go off-screen or be cut off.
          </li>
          <li>
            Do not move the camera around, focus it on the record breaker and
            hold it still. It the record is long place the camera in a fixed
            position
          </li>
          <li>
            Make sure the attire worn is activity-appropriate and avoid attire
            that overexposes the minor or may be viewed as inappropriate,
            including not wearing a shirt.
          </li>
        </ul>
        <h4>Photos should include the child:</h4>
        <ul>
          <li>Posing with any equipment or props used</li>
          <li>Doing the activity</li>
          <li>
            If it is not possible to provide a photo while doing the activity
            then alternatively the photo should be on a uniform background like
            a wall
          </li>
          <li>
            Make sure the attire worn is activity-appropriate and avoid attire
            that overexposes the minor or may be viewed as inappropriate,
            including not wearing a shirt.
          </li>
        </ul>
        <h4>All visuals should:</h4>
        <ul>
          <li>Clear show the child</li>
          <li>Be high-resolution</li>
          <li>Not use any filters</li>
          <li>
            Be steady, in focus and well lit. We highly recommend using a tripod
          </li>
          <li>Have a simple and uncluttered background</li>
        </ul>
        <h4>How do I submit an individual Kids World Record attempt?</h4>
        Make sure the Kids World Record attempt is properly documented with
        photos and video! Next, create a parent account{' '}
        <Link
          to={'/signup'}
          style={{ color: 'blue', textDecoration: 'underline' }}
        >
          here
        </Link>{' '}
        and then add the child(ren) to the account. From there, submit the Kids
        World Record attempt from the child’s profile.
        <h4>How do I submit a group Kids World Record attempt?</h4>
        For small groups, create an account for one of the participants and
        include the names of the other participants in the submission. For
        larger groups or special group events, go to{' '}
        <Link to={'/'} style={{ color: 'blue', textDecoration: 'underline' }}>
          Kids World Records Events
        </Link>{' '}
        .<h4>What happens after I submit a Kids World Record attempt?</h4>
        Upon submission you will receive a confirmation email. The Kids World
        Record attempt will then go into a queue for review, which can take 2-4
        weeks. If your attempt is approved you’ll be eligible to purchase
        certificates and merchandise in the online shop.
        <h4>How do I order printed certificates, medals and the book?</h4>
        Anyone can order the Kids World Record book, but only Kids World Record
        holders are eligible to purchase exclusive products like medals,
        certificates and pins. To order, log in to the parental account and go
        to our{' '}
        <Link
          to={'/shop'}
          style={{ color: 'blue', textDecoration: 'underline' }}
        >
          shop
        </Link>
      </BorderedContainer>
    </Page>
  )
}
