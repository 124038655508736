import React from 'react'
import styles from './SignUpInfo.module.scss'

const steps = [
  {
    image: '/assets/recordSubmission/registration-sign-up.png',
    description: 'A parent or legal guardian completes account registration',
  },
  {
    image: '/assets/recordSubmission/registration-provide-id.png',
    description: "Verify that you are an adult (It's completely free)",
  },
  {
    image: '/assets/recordSubmission/registration-add-child.png',
    description: 'Add your kid age 4-16 years old to your parental account',
  },
  {
    image: '/assets/recordSubmission/registration-evidence.png',
    description:
      'All set, you can now submit world record attempts with evidence',
  },
]

export const SignUpInfo = () => {
  return (
    <div className={styles.info}>
      {steps.map((step) => (
        <div className={styles.container} key={step.image}>
          <img className={styles.image} src={step.image} alt={'stepImage'} />
          <div className={styles.description}>{step.description}</div>
        </div>
      ))}
    </div>
  )
}
