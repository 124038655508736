import React from 'react'
import cn from 'classnames'

import styles from './ShieldLevel.module.scss'

interface ShieldProps {
  level: number
  className?: string
}

export const ShieldLevel: React.FC<ShieldProps> = ({ level, className }) => {
  return (
    <div className={cn(styles.shield, className)}>
      <img
        src={`/assets/shields/${level}-level-shield.svg`}
        alt="shield level"
      />
      <div className={styles.level}>L{level}</div>
    </div>
  )
}
