import { Option } from 'components/Select'

export type Category =
  | 'collections'
  | 'fastest'
  | 'highest'
  | 'largest'
  | 'longest'
  | 'marathon'
  | 'endurance'
  | 'most'
  | 'other'
  | 'broken'
  | 'smallest'

export type CategoryOption =
  | 'NEWEST'
  | 'MOST'
  | 'SMALLEST'
  | 'LARGEST'
  | 'LONGEST'
  | 'HIGHEST'
  | 'FASTEST'
  | 'MARATHON'
  | 'COLLECTIONS'
  | 'BROKEN'
  | 'OTHER'

const getCategoryOptionLabel = (category: Category): string =>
  `${category[0].toUpperCase()}${category.slice(1)}`

const getCategoryOptions = (
  categoryOptions: Category[] = [
    'most',
    'smallest',
    'largest',
    'longest',
    'highest',
    'fastest',
    'endurance',
    'collections',
    'broken',
    'other',
  ],
): Option<Category>[] => {
  return categoryOptions.map<Option<Category>>((option) => ({
    label: getCategoryOptionLabel(option),
    value: option,
  }))
}

export const getCategoryBasedOnValue = (category: string): Category => {
  switch (category) {
    case 'most':
      return 'most'
    case 'smallest':
      return 'smallest'
    case 'largest':
      return 'largest'
    case 'longest':
      return 'longest'
    case 'highest':
      return 'highest'
    case 'fastest':
      return 'fastest'
    case 'endurance':
      return 'marathon'
    case 'collections':
      return 'collections'
    case 'broken':
      return 'broken'
    default:
      return 'other'
  }
}

const exp = {
  getCategoryOptions,
  getCategoryBasedOnValue,
}

export default exp
