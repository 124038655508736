import React from 'react'

import cn from 'classnames'

const ImageWithPlaceHolder: React.FC<{
  src: string
  alt: string
  ariaHidden?: boolean
  className?: string
}> = ({ src, alt, ariaHidden, className }) => {
  //const [image, setImage] = useState<string | undefined>()

  // useEffect(() => {
  //   const img = new Image()
  //   img.src = `${src}?size=100`
  //   img.onload = () => {
  //     setImage(img.src)
  //   }
  //   return () => {
  //     img.onload = null
  //   }
  // }, [src])

  const placeHolderClassName = cn(className, 'animation-gradient-sliding')
  const imageClassName = cn(className, 'animation-reveal')

  return src ? (
    <img
      src={src}
      aria-hidden={ariaHidden}
      alt={alt}
      className={imageClassName}
    />
  ) : (
    <div className={placeHolderClassName} aria-hidden="true" />
  )
}

export default ImageWithPlaceHolder
