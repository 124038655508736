import React, { FC, memo, useCallback, useRef } from 'react'

import { useVisibleCheck } from 'hooks/useVisibleCheck'

interface InViewComponentProps {
  onInView(): void
}

const InViewComponent: FC<InViewComponentProps> = ({ onInView }) => {
  const ref = useRef(null)
  useVisibleCheck(
    ref,
    useCallback(() => {
      onInView()
    }, [onInView]),
  )
  return <div ref={ref} />
}

export const InView = memo(InViewComponent)
