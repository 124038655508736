import React from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react'

import Conditional from 'components/Conditional'

import styles from './ContentInput.module.scss'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
interface ContentInputProps {
  initialValue: string
  errorText?: string
  onChange: (value: any) => void
}

class MyUploadAdapter {
  private loader: any

  constructor(loader: any) {
    this.loader = loader
  }

  upload() {
    return this.loader.file.then(
      (file: File) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve({ default: reader.result as string })
          }
          reader.onerror = (error) => {
            reject(error)
          }
          reader.readAsDataURL(file)
        }),
    )
  }

  abort() {
    // Abort the upload process if needed.
  }
}

function MyCustomUploadAdapterPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return new MyUploadAdapter(loader)
  }
}

export const ContentInput: React.FC<ContentInputProps> = ({
  initialValue,
  errorText,
  onChange,
}) => {
  return (
    <div className={styles.CKadminEdit}>
      <div className={styles.document_editor_toolbar}></div>
      <CKEditor
        editor={DecoupledEditor}
        config={{
          extraPlugins: [MyCustomUploadAdapterPlugin],
          mediaEmbed: {
            previewsInData: true,
            providers: [
              {
                name: 'customProvider',
                url: /^(https:\/\/storage\.googleapis\.com\/(?:kwr-record-images-videos-prod|staging\.refreshing-park-265118\.appspot\.com)\/.*\.(mp4|webm|ogg|mkv))$/,
                html: (match) => {
                  const url = match[0]
                  const extension = url.split('.').pop()
                  let mimeType = ''

                  switch (extension) {
                    case 'mp4':
                      mimeType = 'video/mp4'
                      break
                    case 'webm':
                      mimeType = 'video/webm'
                      break
                    case 'ogg':
                      mimeType = 'video/ogg'
                      break
                    case 'mkv':
                      mimeType = 'video/x-matroska'
                      break
                    default:
                      mimeType = 'video/mp4'
                  }
                  return (
                    `<video controls  style="width: 100%;">` +
                    `<source src="${url}" type="${mimeType}">` +
                    `Your browser does not support the video tag.` +
                    `</video>`
                  )
                },
              },
            ],
          },
          link: {
            addTargetToExternalLinks: true,
          },
          toolbar: [
            'undo',
            'redo',
            'heading',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'imageUpload',
            'insertTable',
            'mediaEmbed',
            'fontColor',
            'fontBackgroundColor',
            'blockQuote',
            'outdent',
            'indent',
          ],
          heading: {
            options: [
              { model: 'paragraph', title: 'Paragraph', class: '' },
              // {
              //   model: 'heading1',
              //   view: 'h1',
              //   title: 'Heading 1',
              //   class: '',
              // },
              {
                model: 'heading2',
                view: 'h2',
                title: 'Heading 2',
                class: '',
              },
            ],
          },
        }}
        data={initialValue}
        onReady={(editor) => {
          const toolbarContainer = document.querySelector(
            `.${styles.document_editor_toolbar}`,
          )
          if (toolbarContainer && editor.ui.view.toolbar.element) {
            toolbarContainer.appendChild(editor.ui.view.toolbar.element)
          }
        }}
        onChange={(event, editor) => {
          onChange(editor.getData())
        }}
      />
      <Conditional shouldRender={!!errorText}>
        <div className={styles.errorText}>{errorText}</div>
      </Conditional>
    </div>
  )
}
