import React, { useState } from 'react'
import { Autocomplete } from '@mui/material'
import { Input } from './Input'

interface AutoCompleteSelectInputProps {
  label?: string
  disabled?: boolean
  onChange(value: string): void
  items: { value: string | number; label: string }[]
}

export const AutoCompleteSelectInput: React.FC<
  AutoCompleteSelectInputProps
> = ({ label, disabled, items, onChange }) => {
  const [inputValue, setInputValue] = useState('')

  return (
    <Autocomplete
      onChange={(event, newValue) => {
        onChange(newValue ? `${newValue.value}` : '')
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      options={items}
      disabled={disabled}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <Input {...params} label={label} key="SelectAutocomplete" />
      )}
    />
  )
}
