import React, { useState, createContext, useCallback } from 'react'

import Profiles, { Profile } from 'data/Profile'

import './splash.css'
import ProfileAvatar from 'components/ProfileAvatar'

interface SplashContextProps {
  showSplash?: (profile: Profile) => void
}

export const SplashContext = createContext<SplashContextProps>({})

const Splash: React.FC = ({ children }) => {
  const [profile, setProfile] = useState<Profile | null>(null)

  const showSplash = useCallback((profile: Profile) => {
    setProfile(profile)
    setTimeout(() => setProfile(null), 3000)
  }, [])

  return (
    <SplashContext.Provider value={{ showSplash: showSplash }}>
      {profile ? <Avatar profile={profile} /> : children}
    </SplashContext.Provider>
  )
}

const Avatar: React.FC<{ profile: Profile }> = ({ profile }) => (
  <div className="splash-avatar flex flex-column items-center justify-center vh-100">
    <ProfileAvatar type={Profiles.getAvatar(profile)} size="xlarge" />
    <div className="f3 nunito-bold neo-red pt3">
      {Profiles.getFullName(profile)}
    </div>
  </div>
)

export default Splash
