import React, { FC, Fragment } from 'react'
import Button from '../../../components/Button'

interface WitnessesStepProps {
  onBack(): void
}

export const WitnessesStep: FC<WitnessesStepProps> = ({ onBack }) => {
  return (
    <Fragment>
      <Button onClick={onBack} className="w-100 w-40-l outline ghost mt4">
        Back
      </Button>
    </Fragment>
  )
}
