import React, { FC, useEffect, useState, useCallback } from 'react'
import { v4 } from 'uuid'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

import { Input } from 'components/Inputs'
import { Conditional } from 'components/Conditional'

import styles from './BreakRecord.module.scss'
import Button from '../../../components/Button'

interface PicturedPersonsProps {
  defaultValue?: {
    name: string
    email: string
  }[]
  errors?: { id: string; name: string; email: string }[]
  onPersonListChanged?(actualIds: string[]): void
}

export const PicturedPersons: FC<PicturedPersonsProps> = ({
  defaultValue,
  errors,
  onPersonListChanged,
}) => {
  const [peopleIds, setPeopleIds] = useState<string[]>([])

  useEffect(() => {
    if (defaultValue?.length) {
      setPeopleIds(defaultValue.map(() => v4()))
    } else {
      setPeopleIds([v4()])
    }
  }, [defaultValue])

  useEffect(() => {
    if (onPersonListChanged) {
      onPersonListChanged(peopleIds)
    }
  }, [onPersonListChanged, peopleIds])

  const removeId = useCallback((id: string) => {
    setPeopleIds((ids) => ids.filter((item) => item !== id))
  }, [])

  return (
    <div className={styles.picturedPeople}>
      {peopleIds.map((id, index) => (
        <div key={id} className={styles.peopleRow}>
          <Input
            label="Name"
            name={`name-${id}`}
            errorText={errors?.find((i) => i.id === id)?.name}
          />
          <Input
            label="Email"
            name={`email-${id}`}
            errorText={errors?.find((i) => i.id === id)?.email}
          />
          <Conditional shouldRender={index !== 0}>
            <IconButton className={styles.icon} onClick={() => removeId(id)}>
              <Close />
            </IconButton>
          </Conditional>
          <Conditional shouldRender={index === 0}>
            <div className={styles.empty} />
          </Conditional>
        </div>
      ))}
      <Button
        ghost
        className="mw5-l"
        onClick={() => setPeopleIds([...peopleIds, v4()])}
      >
        Add another person
      </Button>
    </div>
  )
}
