import React from 'react'

import Title from 'components/Title'
import LinkButton from 'components/LinkButton'
import BorderedContainer from 'components/BorderedContainer'
import { Page } from 'components/Page'

const RequestSubmitted: React.FC = () => (
  <Page>
    <BorderedContainer>
      <div className="flex flex-column items-center h-100 mv4">
        <Title className="pb4 tc">
          Your request was successfully submitted!
        </Title>
        <p className="nunito-regular f6 ma0">
          Now, we'll analyze your request and give you a feedback as soon as
          possible!
        </p>
        <div className="flex justify-center">
          <img
            style={{ maxWidth: 300 }}
            src="/assets/high-5.png"
            alt="A high five celebrating the account validation request successfully submitted."
          />
        </div>
        <div className="flex flex-column items-center w-100 mb4 mt5">
          <LinkButton theme="secondary" className="w-100 w5-ns w5-l" to="/">
            Browse Records
          </LinkButton>
        </div>
      </div>
    </BorderedContainer>
  </Page>
)

export default RequestSubmitted
