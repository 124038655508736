export enum DraftType {
  RECORD = 'RECORD',
  EVENT = 'EVENT',
  EVENT_PARTICIPANT = 'EVENT_PARTICIPANT',
}

type UUID = string

export type DraftProps = {
  id: UUID
  type: DraftType
  expiresAt: Date
  ownerId: UUID
}

export type PartialEvent = {
  id: string
  name: string
  slug: string
}

export type Sponsor = {
  id: string
  name: string
  imageUrl: string
  siteUrl: string
}

export type EventForParticipation = {
  links: {
    name: string
    url: string
  }[]
  id: string
  title: string
  target: number
  description: string
  mediaList: string[]
  eventStartDate: Date
  eventEndDate: Date
  isMediaUploadRequired: boolean
  sponsors: Sponsor[]
}

export type EventForParticipationParams = {
  id: string
  preview: boolean
  token?: string
}

export enum EventTemplate {
  TEMPLATE_1 = 'TEMPLATE_1',
}

export type Event = PartialEvent & {
  template: EventTemplate
  description: string
  medias: string[]
  target: number
  partnerName: string
  startDate: string
  endDate: string
  links: {
    name: string
    url: string
  }[]
  sponsors: Sponsor[]
  isParticipantDeleted: boolean
  isMediaUploadRequired: boolean
  comingSoonSplashPageTitle: string
  comingSoonSplashPageDescription: string
  comingSoonPageMedias: string[]
  finishedSplashPageTitle: string
  finishedSplashPageDescription: string
  finishedPageMedias: string[]
  certificateTitle: string
  certificateDescription: string
  approvedAmount: number
  rejectedAmount: number
  inReviewAmount: number
  isCertificateSent: boolean
  isPrivate: boolean
  isHidden: boolean
  whitelistedEmails: string[]
  whitelistEnabled: boolean
  token: string
}

export enum HowDidYouFoundUs {
  RECOMMENDED_BY_A_FRIEND = 'RECOMMENDED_BY_A_FRIEND',
  SEARCH_ENGIN = 'SEARCH_ENGIN',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  YOUTUBE = 'YOUTUBE',
  INTERNET_ADVERTISING = 'INTERNET_ADVERTISING',
  READ_AN_ARTICLE = 'READ_AN_ARTICLE',
}

export type CreateParentProps = {
  firstName: string
  lastName: string
  email: string
  pin: string
  dateOfBirth: Date
  howDidYouFoundUs: HowDidYouFoundUs
}

export type UpdateEvent = Omit<
  Event,
  | 'isParticipantDeleted'
  | 'approvedAmount'
  | 'rejectedAmount'
  | 'inReviewAmount'
  | 'slug'
  | 'sponsors'
  | 'isCertificateSent'
  | 'isPrivate'
  | 'isHidden'
  | 'token'
> & {
  sponsors: string[]
}

export type Participant = {
  id: string
  name: string
  parentFirstName: string
  parentLastName: string
  parentEmail: string
  kidsFirstName: string
  kidsLastName: string
  kidsBirthDate: Date
  instagram: string
  status: 'approved' | 'rejected' | undefined
  certificate: string
}

export enum EventParticipantStatus {
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type UpdateParticipantStatus = {
  id: string
  eventId: string
  status: EventParticipantStatus
  reviewComment?: string | undefined
}

export type EventParticipant = {
  id: string
  parentFirstName: string
  parentLastName: string
  kidsFirstName: string
  kidsLastName: string
  status: EventParticipantStatus
  reviewComment: string
  kidsDateOfBirth: Date
  instagramId: string
  email: string
  isEmailSent: boolean
  createdAt: Date
  medias: string[]
  eventId: string
  certificate?: string
  eventTitle: string
}

export type ApplyEvent = Omit<
  EventParticipant,
  'reviewComment' | 'isEmailSent' | 'createdAt' | 'status' | 'eventTitle'
>

export type SponsorProps = {
  id: string
  name: string
  siteUrl: string
  imageUrl: string
}
