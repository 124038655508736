import React, { FC, memo, useMemo, useCallback } from 'react'
import { Select, MenuItem, SelectChangeEvent } from '@mui/material'

import styles from './CampaignForm.module.scss'
import { useGetSponsorsQuery } from 'store/api'
import { Sponsor } from 'store/api/types/Event.types'

interface SponsorItemProps {
  name: string
  imageUrl: string
}

const SponsorItemComponent: FC<SponsorItemProps> = ({ name, imageUrl }) => {
  return (
    <div className={styles.sponsorSelectItem}>
      <img className={styles.img} src={imageUrl} alt={name} />
      <span>{name}</span>
    </div>
  )
}

const SponsorItem = memo(SponsorItemComponent)

interface SponsorSelectProps {
  selectedSponsorsIds?: string[]
  onSelect(sponsor: Sponsor): void
}

export const SponsorSelectComponent: FC<SponsorSelectProps> = ({
  selectedSponsorsIds = [],
  onSelect,
}) => {
  const { data: sponsors = [] } = useGetSponsorsQuery()

  const sponsorToDisplay = useMemo(
    () =>
      sponsors.filter(
        (sponsor) => !selectedSponsorsIds.find((item) => item === sponsor.id),
      ),
    [selectedSponsorsIds, sponsors],
  )

  const onChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const item = sponsors.find((item) => item.id === event.target.value)

      if (item) {
        onSelect(item)
      }
    },
    [onSelect, sponsors],
  )

  return (
    <Select onChange={onChange}>
      {sponsorToDisplay.map((sponsor) => (
        <MenuItem key={sponsor.id} value={sponsor.id}>
          <SponsorItem name={sponsor.name} imageUrl={sponsor.imageUrl} />
        </MenuItem>
      ))}
    </Select>
  )
}

export const SponsorSelect = memo(SponsorSelectComponent)
