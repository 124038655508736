import React, { useState } from 'react'
import { AboutProgram } from './AboutProgram'
import { AmbassadorList } from './AmbassadorList'
import Button from '../../components/Button'
import Conditional from '../../components/Conditional'
import { Page } from 'components/Page'
import styles from './Ambassador.module.scss'

export const AmbassadorProgram = () => {
  const [isAboutProgramPage, setIsAboutProgramPage] = useState(true)

  return (
    <Page>
      <div className={styles.buttons}>
        <Button
          className={styles.btn}
          theme="secondary"
          ghost={!isAboutProgramPage}
          onClick={() => setIsAboutProgramPage(true)}
        >
          About Program
        </Button>
        {/*<Button*/}
        {/*  className={styles.btn}*/}
        {/*  theme="secondary"*/}
        {/*  ghost={isAboutProgramPage}*/}
        {/*  onClick={() => setIsAboutProgramPage(false)}*/}
        {/*>*/}
        {/*  Ambassador List*/}
        {/*</Button>*/}
      </div>
      <Conditional shouldRender={isAboutProgramPage}>
        <AboutProgram />
      </Conditional>
      <Conditional shouldRender={!isAboutProgramPage}>
        <AmbassadorList />
      </Conditional>
    </Page>
  )
}
