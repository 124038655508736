import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'

import styles from './Editor.module.scss'

interface EditorProps {
  disabled?: boolean
  header?: string
  data?: string
  onChange: (e: any, editor: DecoupledEditor) => void
  errorText?: string
}

export const Editor: React.FC<EditorProps> = ({
  disabled,
  header,
  onChange,
  data,
  errorText,
}) => {
  const nameToolBar = `document_editor_toolbar_campain_form_${header?.replaceAll(' ', '_').replaceAll(':', '')}`

  return (
    <div className={styles.editor}>
      {header && <h4 className={errorText && styles.errorColor}>{header}</h4>}
      <div className={nameToolBar}></div>
      <CKEditor
        disabled={disabled}
        /*@ts-ignore */
        editor={DecoupledEditor}
        onReady={(editor) => {
          const toolbarContainer = document.querySelector(`.${nameToolBar}`)
          if (toolbarContainer && editor.ui.view.toolbar.element) {
            toolbarContainer.appendChild(editor.ui.view.toolbar.element)
          }
        }}
        data={data}
        /*@ts-ignore */
        onChange={onChange}
        config={{
          removePlugins: ['FontColor', 'FontBackgroundColor'],
          link: {
            addTargetToExternalLinks: true,
          },
          toolbar: [
            'undo',
            'redo',
            '|',
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'blockQuote',
            'uploadImage',
            'insertTable',
            'mediaEmbed',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent',
          ],
          heading: {
            options: [
              { model: 'paragraph', title: 'Paragraph', class: '' },
              {
                model: 'heading1',
                view: 'h1',
                title: 'Heading 1',
                class: '',
              },
              {
                model: 'heading2',
                view: 'h2',
                title: 'Heading 2',
                class: '',
              },
            ],
          },
        }}
      />
      <p className={styles.error}>{errorText}</p>
    </div>
  )
}
