// @ts-nocheck

import React from 'react'
import cn from 'classnames'
import TextField from '@mui/material/TextField'
import { FilledInputProps } from '@mui/material/FilledInput'
import MenuItem from '@mui/material/MenuItem'

import './select.css'

type Identifiable<T = unknown> = {
  id: string
} & T

export type OptionValue = Identifiable | string | number

export type Option<T extends OptionValue> = {
  label: string
  value: T
}

export interface SelectProps<T extends OptionValue> {
  className?: string
  defaultValue?: T
  disabled?: boolean
  error?: boolean
  helperText?: string
  items: Option<T>[]
  label?: string
  name?: string
  onChange?: (value: T) => void
  fullWidth?: boolean
  value?: T | null
}

const Select = <T extends any>({
  className = '',
  defaultValue,
  disabled = false,
  error,
  helperText,
  items,
  label,
  name,
  onChange,
  fullWidth,
  value = null,
}: SelectProps<T>): JSX.Element => (
  <TextField
    fullWidth={fullWidth}
    className={cn('select', className)}
    disabled={disabled}
    error={error}
    helperText={helperText}
    InputProps={
      {
        disableUnderline: true,
      } as Partial<FilledInputProps>
    }
    defaultValue={defaultValue}
    label={label}
    name={name}
    onChange={(event) =>
      onChange && event.target.value && onChange(event.target.value as any as T)
    }
    select
    value={value || ''}
    variant="filled"
  >
    {items.map((item) => (
      <MenuItem
        className="select-item"
        key={item.value['id'] || item.value}
        value={item.value}
      >
        {item.label}
      </MenuItem>
    ))}
  </TextField>
)

export default Select
