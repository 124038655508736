import React from 'react'
import Checkbox from '@mui/material/Checkbox'

import styles from './LabeledCheckBox.module.scss'

interface LabeledCheckBoxProps {
  label: string
  checked?: boolean
  disabled?: boolean
  onClick(): void
}

export const LabeledCheckBox = ({
  label,
  onClick,
  checked,
  disabled = false,
}: LabeledCheckBoxProps) => {
  return (
    <div className={styles.main}>
      <Checkbox
        checked={checked}
        disabled={disabled}
        color="primary"
        onClick={onClick}
      />
      <h1>{label}</h1>
    </div>
  )
}
