import Joi from 'joi'

export const ADD_CHILD_VALIDATOR = Joi.object({
  dateOfBirth: Joi.date().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .allow(''),
  firstName: Joi.string().label('first name').required(),
  gender: Joi.string().required(),
  lastName: Joi.string().label('last name').required(),
  location: Joi.object({
    country: Joi.string().label('country').required(),
    city: Joi.string().label('city').required(),
    state: Joi.string(),
    timezoneInMiliseconds: Joi.number().required(),
  }).required(),
})
