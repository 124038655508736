import React, { FC } from 'react'
import { ArrowForwardIos } from '@mui/icons-material'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'

import ImageWithPlaceHolder from 'components/ImageWithPlaceholder'
import Link from 'components/Link'

import { useGetQuizRecordsForScoreBoardQuery } from 'store/api'
import { AttemptType } from 'store/api/types/Quiz.types'

import styles from './QuizRecord.module.scss'

export const QuizRecord: FC = () => {
  const { data: records = [] } = useGetQuizRecordsForScoreBoardQuery()

  const SwiperButtonNext = () => {
    const swiper = useSwiper()
    return (
      <ArrowForwardIos
        className={styles.arrow}
        onClick={() => swiper.slideNext()}
      />
    )
  }

  return (
    <Swiper slidesPerView={1}>
      {records.map((record) => (
        <SwiperSlide key={record.id} className={styles.swiper_slide}>
          <div className={styles.container}>
            <ImageWithPlaceHolder
              src={record.previewImageUrl}
              alt="record image"
              className={styles.image}
            />
            <div className={styles.record}>
              <h2 className={styles.record_title}>
                {record.type === AttemptType.AMOUNT ? 'Most' : 'Fastest'}{' '}
                {record.quizTitle} Identified
              </h2>
              <div className={styles.information}>
                <div className={styles.description}>
                  <p className={styles.name}>{record.recordHolderName}</p>
                  <p className={styles.record_info}>{record.division}</p>
                  <p className={styles.record_info}>{record.location}</p>
                  <p className={styles.record_info}>Score: {record.score}</p>
                </div>
                <Link className={styles.link} to={`/quiz/${record.quizId}`}>
                  Take the Quiz &gt;
                </Link>
              </div>
            </div>
          </div>
          <SwiperButtonNext />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
