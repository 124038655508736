import React, { useState, useEffect } from 'react'

import IconButton from 'components/IconButton'
import { ImageOrVideo } from 'components/ImageOrVideo'

import './image-gallery.css'

interface Props {
  alt: string
  imageLinks: string[]
  initialIndex?: number
  onChangeIndex?: (index: number) => void
}

const ImageGallery: React.FC<Props> = ({
  alt,
  imageLinks,
  initialIndex = 0,
  onChangeIndex,
}) => {
  const [imageIndex, setImageIndex] = useState(initialIndex)

  useEffect(() => {
    setImageIndex(initialIndex)
  }, [initialIndex])

  const lastImageIndex = imageLinks.length - 1

  const updateImageIndex = (index: number) => {
    const newIndex =
      index >= 0 ? (index <= lastImageIndex ? index : 0) : lastImageIndex

    setImageIndex(newIndex)
    onChangeIndex && onChangeIndex(newIndex)
  }

  return (
    <>
      <section className="relative">
        {imageLinks.length > 1 && (
          <div className="image-gallery__arrow-back absolute z-999 left-0">
            <IconButton
              iconClassName="image-gallery__arrow-icon image-gallery__arrow-icon--back"
              name="arrow_forward"
              onClick={() => updateImageIndex(imageIndex - 1)}
            />
          </div>
        )}
        <ImageOrVideo
          src={imageLinks[imageIndex]}
          alt={alt}
          className="image-gallery__media"
          autoPlay
          controls
        />
        {imageLinks.length > 1 && (
          <div className="image-gallery__arrow-forward absolute z-999 right-0">
            <IconButton
              iconClassName="image-gallery__arrow-icon"
              name="arrow_forward"
              onClick={() => updateImageIndex(imageIndex + 1)}
            />
          </div>
        )}
      </section>
    </>
  )
}

export default ImageGallery
