import React from 'react'
import IconButton from '@mui/material/IconButton'
import styles from '../RecordSubmissions.module.scss'
import useToggle from 'hooks/useToggle'
import { ExportRecordsModal } from 'components/ShadowModal/ExportRecordsModal'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'

export const ExportRecords = () => {
  const [isExportRecordsModalOpen, setIsExportRecordstModalOpen] = useToggle()

  return (
    <div className={styles.exportButton}>
      <IconButton
        aria-label="export records"
        component="span"
        onClick={() => setIsExportRecordstModalOpen()}
        className={styles.iconButton}
      >
        <ArrowCircleDownIcon className={styles.downloadIcon} />
      </IconButton>
      <ExportRecordsModal
        isOpen={isExportRecordsModalOpen}
        onCancel={setIsExportRecordstModalOpen}
      />
    </div>
  )
}
