import React from 'react'

import { useDispatch } from 'react-redux'
import { showToaster } from 'store/app'
import { formatDate } from 'utils/datetime'

import { useLazyGetEventsParticipantsFileQuery } from 'store/api'
import Button from 'components/Button'

export type ExportEventPartisipantXlsxFileButtonProps = {
  className: string
  eventId: string
}

export const ExportEventPartisipantXlsxFileButton: React.FC<
  ExportEventPartisipantXlsxFileButtonProps
> = ({ className, eventId }) => {
  const [trigger, { isLoading: isLoadingDownloadFile }] =
    useLazyGetEventsParticipantsFileQuery()
  const dispatch = useDispatch()

  const ClickDownloadHandler = () => {
    try {
      trigger(eventId)
        .unwrap()
        .then(
          (fileData) => {
            let blob = new Blob([fileData])
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `KWR_events_participants_${formatDate(new Date())}.xlsx`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
          },
          (e) => {
            dispatch(
              showToaster({
                message: `There are no participants that matches your filters`,
                color: 'red',
              }),
            )
          },
        )
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Button
      className={className}
      onClick={ClickDownloadHandler}
      disabled={isLoadingDownloadFile}
    >
      Export Participants Results
    </Button>
  )
}
