import React, { FC, useCallback, useState } from 'react'
import cn from 'classnames'

import styles from './RecordCard.module.scss'

interface RecordImageProps {
  src: string
  className?: string
}

export const RecordImage: FC<RecordImageProps> = ({ src, className }) => {
  const [displayedSrc, setDisplayedSrc] = useState(src)

  const onError = useCallback(() => {
    setDisplayedSrc('/assets/video-thumb.png')
  }, [])

  return (
    <img
      alt="recordImage"
      className={cn(styles.recordImage, className)}
      src={displayedSrc}
      onError={onError}
    />
  )
}
