import * as Yup from 'yup'

const holderName = Yup.string().required('Card holder name is required')
const number = Yup.string().required('Card number is required')
const validThru = Yup.string().required('Valid thru is required')

const Schema = Yup.object().shape({
  holderName,
  number,
  validThru,
})

export default Schema
