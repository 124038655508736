import React, { FC, ReactNode } from 'react'
import cn from 'classnames'

import styles from './Screen.module.scss'

interface ScreenProps {
  className?: string
  children: ReactNode
}

export const Screen: FC<ScreenProps> = ({ className, children }) => {
  return <div className={cn(styles.screen, className)}>{children}</div>
}
