import React from 'react'
import MuiSwitch from '@mui/material/Switch'
import cn from 'classnames'

import './switch.css'

interface SwitchProps {
  onClick: () => void
  theme?: 'primary' | 'secondary' | 'tertiary'
}

const Switch: React.FC<SwitchProps> = ({ onClick, theme }) => (
  <MuiSwitch
    className={cn('switch', `switch--${theme}`)}
    onClick={onClick}
    color={theme === 'tertiary' ? 'default' : theme}
  />
)

export default Switch
