import React, { ReactElement } from 'react'

import styles from './LoadingTextRow.module.scss'

function LoadingInfoRow({
  className,
  loading,
  title,
  onClick,
}: any): ReactElement {
  return (
    <div
      className={`${styles.body} ${
        loading ? 'animation-body' : ''
      } ${className}`}
      onClick={title && onClick}
    >
      <span
        className={`indigo-blue nunito-extra-bold text tc ${
          loading ? 'loading' : ''
        }`}
      >
        {loading ? 'Loading...' : title}
      </span>
    </div>
  )
}

export default LoadingInfoRow
