import React from 'react'

import './bordered-container.css'

const BorderedContainer: React.FC<{ style?: any }> = ({
  children,
  style,
}: any) => (
  <div className={`bordered-container bg-white`} style={style}>
    {children}
  </div>
)

export default BorderedContainer
