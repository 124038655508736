import schema from 'validation/recordSubmissionRejectSchema'

export interface RecordSubmissionRejectFormData {
  submissionId: string
  reason: string | null
}

export interface ValidRecordSubmissionRejectFormData {
  submissionId: string
  reason: string
}

const isValid = (
  data: RecordSubmissionRejectFormData,
): data is ValidRecordSubmissionRejectFormData => schema.isValidSync(data)

const exp = {
  isValid,
}

export default exp
