import Joi from 'joi'
import dayjs from 'dayjs'

export const SIGN_UP_VALIDATOR = Joi.object({
  firstName: Joi.string().required().messages({
    'string.empty': `This field cannot be empty`,
    'any.required': `This is a required field`,
  }),
  lastName: Joi.string().required().messages({
    'string.empty': `This field cannot be empty`,
    'any.required': `This is a required field`,
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.empty': `This field cannot be empty`,
      'any.required': `This is a required field`,
      'string.email': `Enter valid email`,
    }),
  dateOfBirth: Joi.date().required().messages({
    'any.required': `This is a required field`,
    'date.base': `Enter valid date`,
  }),
  howDidYouFoundUs: Joi.string().required().messages({
    'any.required': `This is a required field`,
  }),
  isTeacher: Joi.boolean(),
})

export const buildDefaultValue = () => {
  return {
    dateOfBirth: dayjs().add(-25, 'years').toDate().toISOString(),
    isTeacher: false,
  }
}
