import React, { ReactNode } from 'react'

import { OutlinedContainer } from 'components/OutlineContainer'
import { Input } from 'components/Inputs'

import styles from './RecordFunFacts.module.scss'

export interface RecordFunFactsProps {
  errorMessage?: string
  children: ReactNode
  disabled?: boolean
  value?: string | undefined
  onChange?(val: any): void
}
export const RecordFunFacts = ({
  errorMessage,
  disabled,
  value,
  onChange,
  children,
}: RecordFunFactsProps) => {
  return (
    <OutlinedContainer
      label="Your Fun Fact(s)"
      errorMessage={errorMessage}
      className={styles.outlined}
    >
      <div className="openSans-regular dark-gray pt2 pb2">{children}</div>
      <Input
        name="funFacts"
        value={value}
        placeholder="Enter your fun fact(s)"
        minRows={4}
        multiline
        errorText={errorMessage}
        disabled={disabled}
        onChange={(e) => {
          onChange && onChange(e.target.value ? e.target.value : '')
        }}
      />
    </OutlinedContainer>
  )
}
