import React from 'react'
import cn from 'classnames'

import './shadow-modal.css'

const ShadowModal: React.FC<{
  isModalOpen: boolean
  isLarge?: boolean
  noSize?: boolean
  withShadow?: boolean
  className?: string
  onCancel?: () => void
}> = ({
  children,
  isModalOpen,
  isLarge = false,
  noSize = false,
  withShadow = false,
  className,
  onCancel,
}) => {
  return (
    <>
      {isModalOpen && (
        <>
          <div
            className={cn(
              'shadow-modal fixed flex flex-column br3 z-999 pa4',
              className,
              isLarge && 'large',
              noSize && 'noSize',
              withShadow && 'shadow',
            )}
          >
            {children}
          </div>
          <div
            className={cn('fixed z-5 left-0 top-0 w-100 h-100 bg-black-20')}
            onClick={(e) => {
              e.preventDefault()
              onCancel && onCancel()
            }}
          />
        </>
      )}
    </>
  )
}

export default ShadowModal
