import React from 'react'
import { Interweave } from 'interweave'
import cn from 'classnames'

import styles from './ParticipantsInEvent.module.scss'
import Title from '../../../components/Title'
import { ImageOrVideo } from '../../../components/ImageOrVideo'

interface ClosedEventProps {
  image: string[]
  title: string
  description: string
}

export const ClosedEvent = ({
  image,
  title,
  description,
}: ClosedEventProps) => {
  return (
    <div className={styles.container}>
      <ImageOrVideo
        className={styles.imageOrVideo}
        alt="event image"
        src={image[0] || ''}
      />
      <div className={cn(styles.columnBox, styles.padding)}>
        <Title className={styles.title}>{title}</Title>
        <Interweave content={description} />
      </div>
    </div>
  )
}
