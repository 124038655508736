import React from 'react'

import Button from 'components/Button'
import SubmitButton from 'components/SubmitButton'

import RecordSubmissionFormDatas, {
  RecordWitnessesFormData,
  ValidRecordWitnessesFormData,
} from 'typings/RecordSubmissionFormData'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { WITNESSES_VALIDATOR } from './Witnesses.utils'
import { FormInput } from 'components/Form/FormInput'

import styles from './Witnesses.module.scss'

interface WitnessesProps {
  loading?: boolean
  onBack: () => void
  onSubmit: (data: ValidRecordWitnessesFormData) => void
}
const Witnesses: React.FC<WitnessesProps> = ({ onSubmit, onBack, loading }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RecordWitnessesFormData>({
    resolver: joiResolver(WITNESSES_VALIDATOR),
    mode: 'onSubmit',
  })
  const submitHandler = (data: RecordWitnessesFormData) => {
    if (RecordSubmissionFormDatas.isValidWitnesses(data)) {
      onSubmit(data)
    }
  }
  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <p className={styles.text}>
        If you have witnesses to your record that are not your parents please
        add their emails here.
      </p>
      <FormInput
        control={control}
        errorText={errors.witness1Email?.message}
        label="Witness 1 e-mail address"
        name="witness1Email"
        type="email"
      />
      <FormInput
        control={control}
        errorText={errors.witness2Email?.message}
        label="Witness 2 e-mail address"
        name="witness2Email"
        type="email"
      />

      <div className={styles.submitButtonDiv}>
        <SubmitButton className={styles.submitButton} disabled={loading}>
          Submit your record attempt
        </SubmitButton>
      </div>
      <div className={styles.backButtonDiv}>
        <Button
          onClick={onBack}
          className={styles.backButton}
          ghost
          outline
          disabled={loading}
        >
          Back
        </Button>
      </div>
    </form>
  )
}
export default Witnesses
