import React from 'react'
import dayjs from 'dayjs'
import { Navigate, useNavigate } from 'react-router'

import { ChildProfile } from 'data/ChildProfile'

import BorderedContainer from 'components/BorderedContainer'
import ChildProfileCard from 'components/ChildProfileCard'
import ContextMenu, { ContextMenuOption } from 'components/ContextMenu'
import EmptySection from 'components/EmptySection'
import ImageDecoration, {
  ImageDecorationType,
} from 'components/ImageDecoration'
import LinkButton from 'components/LinkButton'
import LogoutModal from 'components/ShadowModal/LogoutModal'
import SubTitle from 'components/SubTitle'
import Title from 'components/Title'
import { Page } from 'components/Page'

import useToggle from 'hooks/useToggle'
import { useLogout } from 'hooks/useLogout.hook'

import { formatDate } from 'utils/datetime'
import {
  useGetMyProfileQuery,
  useLegacyChildProfilesQuery,
} from '../../store/api'
import { useMyRole } from 'hooks/useMyRole'

const childrenProfiles = (
  childProfiles: ChildProfile[],
): JSX.Element | JSX.Element[] =>
  !childProfiles.length ? (
    <>
      <p className="dark-gray pb4">
        You must register your child before you can submit a Kids World Record
        attempt. If approved, only your child's first name, age bracket, city
        and country will be displayed.
      </p>
      <EmptySection
        className="pt2"
        message="There are no children registered. You must add a child to submit their record attempt."
      />
    </>
  ) : (
    childProfiles.map((child) => (
      <ChildProfileCard key={child.id} className="mt2" profile={child} />
    ))
  )

const Profile: React.FC = () => {
  const navigate = useNavigate()

  const { data: loggedProfile, isLoading } = useGetMyProfileQuery()
  const { isParent, isLoading: isFetching } = useMyRole()
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useToggle(false)

  const { handleLogout } = useLogout()

  const { data: allChildProfiles = [] } = useLegacyChildProfilesQuery()

  const menuOptions: ContextMenuOption[] = [
    {
      label: 'Edit Profile',
      onClick: () => navigate('/edit-profile'),
    },
    {
      label: 'Log Out',
      onClick: setIsLogOutModalOpen,
    },
  ]

  if (isLoading) {
    return <></>
  }

  if (!isFetching && !isParent) {
    return <Navigate to="/" />
  }

  return (
    <Page>
      <BorderedContainer>
        <div className="flex justify-between pb4">
          <Title className="pa0">My Profile</Title>
          <ContextMenu options={menuOptions} />
        </div>
        <div className="flex flex-column">
          <div className="pb4 flex flex-row justify-between">
            <span className="dark-gray">Name</span>
            <span className="indigo-blue">
              {loggedProfile?.firstName} {loggedProfile?.lastName}
            </span>
          </div>
          <div className="pb4 flex flex-row justify-between">
            <span className="dark-gray">Email</span>
            <span className="indigo-blue">{loggedProfile?.email}</span>
          </div>
          <div className="pb4 flex flex-row justify-between">
            <span className="dark-gray">Date of Birth (YYYY-MM-DD)</span>
            <span className="indigo-blue">
              {formatDate(dayjs(loggedProfile?.dateOfBirth).toDate())}
            </span>
          </div>
          <div className="pb4 flex flex-row justify-between">
            <span className="dark-gray">PIN</span>
            <LinkButton ghost theme="secondary" to="/edit-pin">
              Change PIN
            </LinkButton>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="flex justify-between items-center mt3 mb2">
          <SubTitle>Registered children</SubTitle>
          <LinkButton to="/add-child" className="w-100 w-700-ns">
            Add a child
          </LinkButton>
        </div>
        {isParent && childrenProfiles(allChildProfiles)}
      </BorderedContainer>
      <LogoutModal
        isOpen={isLogOutModalOpen}
        onCancel={setIsLogOutModalOpen}
        onConfirm={handleLogout}
      />
      <ImageDecoration
        type={ImageDecorationType.GIRL_RUNNING}
      ></ImageDecoration>
    </Page>
  )
}

export default Profile
