import React from 'react'

import Avatar, { AvatarProps } from 'components/Avatar'

import { ProfileAvatarType } from 'data/Profile'

const avatars: { type: ProfileAvatarType; src: string }[] = [
  {
    type: 'boy',
    src: '/assets/avatars/kid-lion.svg',
  },
  {
    type: 'girl',
    src: '/assets/avatars/kid-giraffe.svg',
  },
  {
    type: 'gender_x',
    src: '/assets/avatars/kid-beaver.svg',
  },
  {
    type: 'parent',
    src: '/assets/avatars/parent-hippo.svg',
  },
  {
    type: 'admin',
    src: '/assets/avatars/admin.svg',
  },
]

const getAvatar = (type: ProfileAvatarType): string | undefined =>
  avatars.find((avatar) => avatar.type === type)?.src

interface ProfileAvatarProps extends AvatarProps {
  type: ProfileAvatarType
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({ type, ...rest }) => (
  <Avatar src={getAvatar(type)} {...rest} />
)
export default ProfileAvatar
