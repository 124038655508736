import React from 'react'
import cn from 'classnames'
import MuiAvatar from '@mui/material/Avatar'

import './avatar.css'

export interface AvatarProps {
  className?: string
  size?: 'small' | 'medium' | 'large' | 'xlarge'
  src?: string
}

const Avatar: React.FC<AvatarProps> = ({
  className,
  size = 'medium',
  src,
  children,
}) => (
  <MuiAvatar src={src} className={cn('avatar', `avatar--${size}`, className)}>
    {children}
  </MuiAvatar>
)

export default Avatar
