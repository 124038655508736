// @ts-nocheck
import React from 'react'
import { Link } from 'react-router-dom'

export type Page = '/' | '/records' | '/record-ideas' | '/signup' | string

const Link_: React.FC<{
  className?: string
  label?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  style?: any
  target?: string
  to: Page
}> = ({ className, children, onClick, label, style, target, to }) => (
  <Link
    style={style}
    className={className}
    to={to}
    onClick={onClick}
    aria-label={label}
    target={target}
  >
    {children}
  </Link>
)

export default Link_
