import React, { FC } from 'react'
import { DialogContent, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

import LinkButton from '../LinkButton'
import ShadowModal from '../ShadowModal'
import styles from './LocationModal.module.scss'

type LocationModalProps = {
  isOpen: boolean
  location: string
  onClose: () => void
}

export const LocationModal: FC<LocationModalProps> = ({
  isOpen,
  location,
  onClose,
}) => {
  return (
    <ShadowModal
      isModalOpen={isOpen}
      noSize
      withShadow
      onCancel={onClose}
      className={styles.modal}
    >
      <DialogContent className={styles.dialogContent}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={styles.closeBtn}
        >
          <Close />
        </IconButton>
        <p className={styles.header}>
          We're looking for record holders from {location}
        </p>
        <p className={styles.text}>
          Please register and submit a record to be the first to represent{' '}
          {location}
        </p>
        <LinkButton to={`/signup`} className={styles.button} onClick={onClose}>
          Register
        </LinkButton>
      </DialogContent>
    </ShadowModal>
  )
}
