import React, { FC } from 'react'
import { useResolvedPath, Outlet } from 'react-router'
import { Link, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import SpeedIcon from '@mui/icons-material/Speed'
import GroupIcon from '@mui/icons-material/Group'
import ContactsIcon from '@mui/icons-material/Contacts'

import styles from './Events.module.scss'
import { PageWrapper } from 'components/PageWrapper'

const LIST_ITEMS = [
  {
    label: 'Dashboard',
    Icon: SpeedIcon,
    to: '',
  },
  {
    label: 'View Participants',
    Icon: GroupIcon,
    to: 'participants',
  },
  {
    label: 'View Inquiries',
    Icon: GroupIcon,
    to: 'inquiries',
  },
  {
    label: 'Sponsors',
    Icon: ContactsIcon,
    to: 'sponsors',
  },
]
export const Events: FC = () => {
  const { pathname: path } = useResolvedPath('')
  const { pathname } = useLocation()

  return (
    <PageWrapper>
      <div className={styles.page}>
        <List className={styles.sidebar}>
          {LIST_ITEMS.map(({ label, Icon, to }) => (
            <Link key={label} to={`${path}${to ? '/' + to : ''}`}>
              <ListItemButton
                selected={pathname === `${path}${to ? '/' + to : ''}`}
                className={styles.listItem}
              >
                <ListItemIcon>
                  <Icon
                    className={cn(
                      styles.icon,
                      pathname === `${path}${to ? '/' + to : ''}` &&
                        styles.selected,
                    )}
                  />
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItemButton>
            </Link>
          ))}
        </List>
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </PageWrapper>
  )
}
