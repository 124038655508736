import React, {
  ChangeEvent,
  KeyboardEvent,
  FC,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from 'react'

import { Input } from '../Input'

import styles from './PinInput.module.scss'

export type PinInputProps = {
  value?: string[]
  onChange?: (pin: string[]) => void
  errorText?: string
  isMasked?: boolean
  isFocused?: boolean
}

export const PinInput: FC<PinInputProps> = ({
  value = [],
  onChange,
  errorText,
  isMasked,
  isFocused = false,
}) => {
  const firstInput = useRef<HTMLInputElement>(null)
  const secondInput = useRef<HTMLInputElement>(null)
  const thirdInput = useRef<HTMLInputElement>(null)
  const fourthInput = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (isFocused) {
      firstInput.current?.focus()
    }
  }, [isFocused])
  const inputRefs = useMemo(
    () => [firstInput, secondInput, thirdInput, fourthInput],
    [],
  )

  const onChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newPin = [...value]
      const index = parseInt(event.target.name.split('-')[0])
      newPin[index] = event.target.value
      onChange && onChange(newPin)

      if (event.target.value && index < inputRefs.length - 1) {
        inputRefs[index + 1].current?.focus()
      }
    },
    [onChange, value, inputRefs],
  )

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const currentInput = event.target as HTMLInputElement
      const index = parseInt(currentInput.name.split('-')[0])

      if (event.key === 'Backspace' && currentInput.value === '') {
        inputRefs[index - 1].current?.focus()
      }
    },
    [inputRefs],
  )

  return (
    <div className={styles.pin}>
      <Input
        name="0-pinInput"
        value={value[0]}
        onChange={onChangeInput}
        className={styles.pinInput}
        inputRef={firstInput}
        errorText={errorText}
        type={isMasked ? 'password' : ''}
        maxLength={1}
        hiddenLabel
      />
      <Input
        name="1-pinInput"
        value={value[1]}
        onChange={onChangeInput}
        onKeyDown={onKeyDown}
        className={styles.pinInput}
        inputRef={secondInput}
        errorText={errorText && ' '}
        type={isMasked ? 'password' : ''}
        maxLength={1}
        hiddenLabel
      />
      <Input
        name="2-pinInput"
        value={value[2]}
        onChange={onChangeInput}
        onKeyDown={onKeyDown}
        className={styles.pinInput}
        inputRef={thirdInput}
        errorText={errorText && ' '}
        type={isMasked ? 'password' : ''}
        maxLength={1}
        hiddenLabel
      />
      <Input
        name="3-pinInput"
        value={value[3]}
        onChange={onChangeInput}
        onKeyDown={onKeyDown}
        className={styles.pinInput}
        inputRef={fourthInput}
        errorText={errorText && ' '}
        type={isMasked ? 'password' : ''}
        maxLength={1}
        hiddenLabel
      />
    </div>
  )
}
