import React from 'react'

import Button from 'components/Button'
import LinkButton from 'components/LinkButton'
import SubTitle from 'components/SubTitle'
import Link from 'components/Link'

import './rules.css'
import styles from './Rules.module.scss'

const steps = [
  {
    image: '/assets/recordSubmission/registration-evidence.png',
    description: (
      <>
        <strong>1. Prove it!</strong> Make sure you properly record the attempt
        so you have the necessary evidence to prove you broke the record.
      </>
    ),
  },
  {
    image: '/assets/recordSubmission/break-record-1.png',
    description: (
      <>
        <strong>2. Submit it!</strong> Have your parent or legal guardian help
        you complete the submission.
      </>
    ),
  },
  {
    image: '/assets/recordSubmission/break-record-2.png',
    description: (
      <>
        <strong>3. Sit back,</strong> and relax! But be patient, it can take up
        to 4 weeks for Kids World Records to review your application.
      </>
    ),
  },
  {
    image: '/assets/recordSubmission/break-record-3.png',
    description: (
      <>
        <strong>4. You've got mail!</strong> After careful review we will let
        you know if your record is approved.
      </>
    ),
  },
  {
    image: '/assets/recordSubmission/break-record-4.png',
    description: (
      <>
        <strong>5. Visit our online store</strong> to buy the book, official
        certificates, medals and more!
      </>
    ),
  },
]

interface RulesProps {
  isRecordLoading?: boolean
  shouldPay?: boolean
  onSubmit: () => void
  title?: string
  ideaTitle?: string
}

const Rules: React.FC<RulesProps> = ({
  onSubmit,
  title,
  ideaTitle,
  shouldPay = false,
}) => {
  return (
    <>
      {(ideaTitle || title) && (
        <SubTitle className="neo-red f3-m f3-l">{ideaTitle || title}</SubTitle>
      )}
      <div className={styles.header}>
        Before you attempt and submit a Kids World Record make sure you
        understand the process and rules.
      </div>
      <div className={styles.info}>
        {steps.map((step) => (
          <div className={styles.container}>
            <img className={styles.image} src={step.image} alt={'stepImage'} />
            <div className={styles.description}>{step.description}</div>
          </div>
        ))}
      </div>
      <div className={styles.text}>
        <Link
          to={`https://www.kidsworldrecords.com/how-to-break-a-record`}
          style={{ textDecoration: 'underline', fontWeight: 'bold' }}
        >
          Click here
        </Link>{' '}
        to see videos about how to attempt a Kids World Record.
        <br />
        <br />
        <br />
        We're excited to see what you've come up with!
      </div>
      <div className="flex h-100 mv3 justify-center">
        <div
          className="flex flex-column justify-between w-100 mw5-m mw5-l"
          style={{ height: '100px' }}
        >
          <Button className="w-100" onClick={onSubmit}>
            I agree
          </Button>
          <LinkButton to="/" ghost className="w-100">
            I don't agree
          </LinkButton>
        </div>
      </div>
    </>
  )
}

export default Rules
