import React, { FC } from 'react'

import { Controller } from 'react-hook-form'

import { RecordMedias } from 'components/RecordMedias'

interface FormRecordMediasProps {
  name: string
  control: any
  maxCount?: number
  accept?: string
}

export const FormRecordMedias: FC<FormRecordMediasProps> = ({
  name,
  control,
  maxCount,
  accept,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <RecordMedias
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          maxCount={maxCount}
          accept={accept}
        />
      )}
    />
  )
}
