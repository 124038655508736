import React from 'react'
import ShadowModal from 'components/ShadowModal'
import Button from 'components/Button'
import Conditional from 'components/Conditional'
import { Child } from 'store/api/types/Child.types'
import styles from '../Quiz/Quiz.module.scss'

interface StartModalProps {
  isOpenModal: boolean
  child?: Child
  isParent: boolean
  onClose: () => void
  onPlay: () => void
  isLoading: boolean
  type: 'amount' | 'speed'
}

export const StartModal: React.FC<StartModalProps> = ({
  isOpenModal,
  child,
  isParent,
  onClose,
  onPlay,
  isLoading,
  type,
}) => {
  const ageBracket = child
    ? child?.ageGap.split('-').map((i) => parseInt(i))
    : []
  const isGrownChild = ageBracket[0] > 16
  return (
    <ShadowModal
      className={styles.modal}
      isModalOpen={isOpenModal}
      onCancel={onClose}
      withShadow
      noSize
    >
      <div className={styles.modalContainer}>
        <Conditional shouldRender={Boolean(child) && !isGrownChild}>
          <div className={styles.startHeader}>
            Ready to set a Kids World Record?
          </div>
        </Conditional>
        <Conditional shouldRender={!child || isGrownChild}>
          <div className={styles.startHeader}>
            Ready to test your knowledge?
          </div>
        </Conditional>
        <div className={styles.modalText}>
          {child && !isGrownChild && (
            <span>
              <strong>
                You are competing in the {child.gender}s, {child.ageGap} year
                old Division. <br />
              </strong>
              <Conditional shouldRender={type === 'speed'}>
                If you finish this quiz faster than anyone else, you become a
                Kids World Record holder!
              </Conditional>
              <Conditional shouldRender={type === 'amount'}>
                If you get the most correct answers to this quiz, you become a
                Kids World Record holder!
              </Conditional>
              <br />
              <br />
              If someone got all the answers to this quiz right before, you
              can't set a new record, but you can still have fun playing!
            </span>
          )}
          <Conditional shouldRender={!child}>
            For your child to participate in a Kids World Record attempt for
            this quiz, please switch to their account.
          </Conditional>
          <Conditional shouldRender={isGrownChild}>
            You’re all grown up, only kids between 4-16 can submit record
            attempts. But don’t worry, you will always be a member of our family
            of extraordinary individuals.
          </Conditional>
        </div>
        <div className={styles.buttonBox}>
          <Button
            className={
              child && !isGrownChild ? styles.nextBtn : styles.startBtn
            }
            onClick={onPlay}
            disabled={isLoading}
          >
            {child && !isGrownChild ? 'Next' : 'Start Quiz'}
          </Button>
        </div>
      </div>
    </ShadowModal>
  )
}
