export interface Pin {
  firstDigit: string | null
  secondDigit: string | null
  thirdDigit: string | null
  fourthDigit: string | null
}

const formatPin = (pin: Pin): string =>
  `${pin?.firstDigit}${pin?.secondDigit}${pin?.thirdDigit}${pin?.fourthDigit}`

const exp = {
  formatPin,
}

export default exp
