import { Category } from 'store/api/types/Record.types'
import { capitalizeFirstLetter } from './strings'

export type SelectItems = {
  value: string
  label: string
}

export const getItemsForSelect = (category: typeof Category): SelectItems[] => {
  return Object.values(category).map((item) => ({
    value: item,
    label: capitalizeFirstLetter(item),
  }))
}
