import React, { FC } from 'react'
import { Checkbox } from 'components/Inputs'

import styles from './BreakRecord.module.scss'

interface PicturedPersonCheckBoxProp {
  name?: string
  value?: boolean
  onClick?(): void
}

export const PicturedPersonCheckBox: FC<PicturedPersonCheckBoxProp> = ({
  name,
  value,
  onClick,
}) => {
  return (
    <div className={styles.picturedPersonCheckbox}>
      <Checkbox
        name={name}
        checked={value || false}
        onClick={onClick}
        label="Is there anyone else pictured in the images or video you provided?"
      />
      <div className={styles.description}>
        Please ensure you have permission to post pictures of everyone in the
        video and pictures that are submitted with the attempt. If anyone is
        pictured please also provide their name and e-mail.
      </div>
    </div>
  )
}
