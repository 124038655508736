import { ObjectSchema } from 'joi'

export const isEmailValid = (email: string): boolean =>
  /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  )

export const buildValidator =
  <T, R>(schema: ObjectSchema) =>
  (data: T): R | null => {
    const validationResult = schema
      .options({ abortEarly: false })
      .validate(data)

    if (!validationResult.error) {
      return null
    }

    return validationResult.error.details.reduce((object, item) => {
      return {
        ...object,
        [item.context?.key || '']: item.message,
      }
    }, {}) as R
  }
