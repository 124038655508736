import React from 'react'

import Icon from 'components/Icon'
import LinkButton from 'components/LinkButton'

interface BackButtonProps {
  backUrl: string
}

const BackButton: React.FC<BackButtonProps> = ({ children, backUrl }) => (
  <LinkButton
    startIcon={<Icon name="arrow_left" />}
    className="pr3"
    ghost
    size={'small'}
    theme={'tertiary'}
    to={backUrl}
  >
    {children}
  </LinkButton>
)

export default BackButton
