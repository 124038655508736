import React from 'react'
import cn from 'classnames'
import Button from '../Button'
import Conditional from '../Conditional'

const EmptySection: React.FC<{
  message: string
  className?: string
  onSearchTheEntireSite?(): void
}> = ({ message, className, onSearchTheEntireSite }) => (
  <section
    className={cn(
      'f6 ph3 pv4 ba br3 border--dark-surface bg-surface dark-gray',
      className,
    )}
  >
    {message}
    <Conditional shouldRender={Boolean(onSearchTheEntireSite)}>
      <div style={{ marginTop: '10px' }}>
        <Button onClick={onSearchTheEntireSite}>
          Please click here to search the entire site.
        </Button>
      </div>
    </Conditional>
  </section>
)

export default EmptySection
