import React from 'react'
import cn from 'classnames'
import { ShieldLevel } from '../ShieldLevel'
import styles from './SelectedShield.module.scss'

interface SelectedShieldProps {
  level: number
  className?: string
}

export const SelectedShield: React.FC<SelectedShieldProps> = ({
  level,
  className,
}) => {
  return (
    <div className={cn(styles.main, className)}>
      <img
        className={styles.badge}
        src={`/assets/sharp-badge.svg`}
        alt="shield level"
      />
      <div className={styles.star_shield}>
        <img
          className={styles.star}
          src={`/assets/star.svg`}
          alt="shield level"
        />
        <ShieldLevel className={styles.shield_level} level={level} />
      </div>
    </div>
  )
}
