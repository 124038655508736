import React, { FC } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay, Pagination } from 'swiper/modules'
import { useGetRecordHoldersInfoQuery } from 'store/api'
import styles from '../Quiz/Quiz.module.scss'

interface QuizRecordSlideProps {
  description: string
}

const QuizRecordSlide: FC<QuizRecordSlideProps> = ({ description }) => {
  return (
    <div className={styles.slide}>
      <h1 className={styles.title}>Current Record and record Holder</h1>
      <div className={styles.description}>{description}</div>
    </div>
  )
}

interface QuizRecordOwnersProps {
  quizId: string
}

export const QuizRecordOwners: FC<QuizRecordOwnersProps> = ({ quizId }) => {
  const { data } = useGetRecordHoldersInfoQuery(quizId)
  return (
    <Swiper
      slidesPerView={1}
      autoplay={true}
      className={styles.recordsSwiper}
      pagination={{ clickable: true }}
      modules={[Autoplay, Pagination]}
      style={{
        '--swiper-pagination-color': '#FF5252',
        '--swiper-pagination-bullet-inactive-color': '#FFF',
        '--swiper-pagination-bullet-inactive-opacity': '1',
      }}
    >
      {data?.mostRecordInfo && (
        <SwiperSlide className={styles.swiperSlide}>
          <QuizRecordSlide description={data.mostRecordInfo} />
        </SwiperSlide>
      )}
      {data?.speedRecordInfo && (
        <SwiperSlide className={styles.swiperSlide}>
          <QuizRecordSlide description={data.speedRecordInfo} />
        </SwiperSlide>
      )}
    </Swiper>
  )
}
