import React, { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { useLazyGetRecordByIdQuery } from 'store/api'

import BorderedContainer from 'components/BorderedContainer'
import Title from 'components/Title'
import Stepper from 'components/Stepper'
import Conditional from 'components/Conditional'

import { RuleStep } from './RuleStep'
import { RecordDetailsStep } from './RecordDetailsStep'
import { WitnessesStep } from './WitnessesStep'

const STEPS = ['Rules', 'Details', 'Witnesses']

// interface NewRecord {
//   idea?: string
//   category?: string
//   description?: string
//   previousRecordId?: string
//   templatedDescription?: {
//     description: string
//     measurement: string
//   }
//   funFacts?: string
//   brokenOn?: Date
//   location?: {
//     country?: string
//     state?: string
//     city?: string
//   }
//   mediaLinks: string[]
//   picturedPerson?: {
//     name?: string
//     email?: string
//   }[]
// }

export const BreakRecordSteps: FC = () => {
  const { recordId } = useParams<{ recordId: string }>()
  const [getRecord, { data: recordToBreak }] = useLazyGetRecordByIdQuery()
  const [step, setStep] = useState(0)
  // const [newRecord, setNewRecord] = useState<NewRecord>({ mediaLinks: [] })

  const search = window.location.search
  const params = new URLSearchParams(search)
  const idea = params.get('idea') || undefined

  const ideaToBreak = idea || recordToBreak?.idea?.title || undefined

  useEffect(() => {
    if (recordId && !idea) {
      getRecord(recordId)
    }
  }, [getRecord, idea, recordId])

  const nextStep = useCallback(() => setStep((step) => step + 1), [])
  const prevStep = useCallback(() => setStep((step) => step - 1), [])

  return (
    <BorderedContainer>
      <Title>Submit a Record</Title>
      <Stepper activeStep={step} steps={STEPS} />
      <Conditional shouldRender={step === 0}>
        <RuleStep onSubmit={nextStep} />
      </Conditional>
      <Conditional shouldRender={step === 1}>
        <RecordDetailsStep
          idea={ideaToBreak}
          category={recordToBreak?.category?.toUpperCase() as any}
          onNext={nextStep}
        />
      </Conditional>
      <Conditional shouldRender={step === 2}>
        <WitnessesStep onBack={prevStep} />
      </Conditional>
    </BorderedContainer>
  )
}
