import React, { useRef } from 'react'
import { Navigate } from 'react-router'
import { IconButton } from '@mui/material'
import {
  useGetAmbassadoredRecordsQuery,
  useGetProfileQuery,
} from '../../store/api'
import { AccountRole } from '../../store/api/types/Auth.types'
import Title from '../../components/Title'
import BorderedContainer from '../../components/BorderedContainer'
import { Page } from '../../components/Page'
import Loader from '../../components/Loader'
import Conditional from '../../components/Conditional'
import { LevelTable } from './LevelTable'
import { RecordBreakerList } from './RecordBreakerList'
import styles from './AmbassadorProfile.module.scss'

export const AmbassadorProfile = () => {
  const { data, isLoading: isProfileLoading } = useGetProfileQuery()
  const { data: records, isLoading } = useGetAmbassadoredRecordsQuery()

  const isChild = data && data?.type === AccountRole.CHILD
  const isAmbassador = data?.profile.isAmbassador

  const textRef = useRef<HTMLSpanElement | null>(null)

  const handleCopyId = async () => {
    if (textRef.current) {
      await navigator.clipboard.writeText(textRef.current.innerText)
    }
  }

  return (
    <Page>
      <Conditional shouldRender={!isProfileLoading && !isLoading}>
        {(!isChild && <Navigate to={'/'} />) ||
          (!isAmbassador && <Navigate to={'/ambassador-program'} />)}
        <BorderedContainer>
          <Title className={styles.title}>
            Ambassador ID: #
            <span ref={textRef}>{data?.profile.ambassadorCode}</span>
            <IconButton aria-label="copy" onClick={handleCopyId}>
              <img
                className={styles.copy}
                src="/assets/copy.svg"
                alt="copy icon"
              />
            </IconButton>
          </Title>
          <LevelTable currentLevel={data?.profile.ambassadorLevel || 0} />
          <RecordBreakerList records={records} />
        </BorderedContainer>
      </Conditional>
      <Conditional shouldRender={isProfileLoading || isLoading}>
        <Loader
          active
          className="z-999"
          style={{
            top: '50%',
            left: 'calc(50% - 30px)',
          }}
        />
      </Conditional>
    </Page>
  )
}
