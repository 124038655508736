import React from 'react'
import ShadowModal from 'components/ShadowModal'
import LinkButton from 'components/LinkButton'
import Conditional from 'components/Conditional'
import Link from 'components/Link'
import styles from '../Ambassador.module.scss'

interface ModalProps {
  isOpen: boolean
  isJoined: boolean
  isChild: boolean
  onClose: () => void
}

export const JoinTheProgramModal: React.FC<ModalProps> = ({
  isOpen,
  isJoined,
  isChild,
  onClose,
}) => {
  return (
    <ShadowModal
      isModalOpen={isOpen}
      className={styles.modal}
      onCancel={onClose}
      withShadow
      noSize
    >
      <Conditional shouldRender={!isJoined && isChild}>
        <div className={styles.modalContainer}>
          <div className={styles.header}>
            Sorry you need to be a Kids World Record Holder to join!
          </div>
          <div className={styles.modalText}>
            Click here to see how you could get a Kids World Record or change to
            a different profile.
          </div>
          <LinkButton to="/FAQ" outline>
            Break a record
          </LinkButton>
        </div>
      </Conditional>
      <Conditional shouldRender={isJoined}>
        <div className={styles.modalContainer}>
          <div className={styles.header}>
            Thank you for joining our Ambassador Program
          </div>
          <div className={styles.modalText}>
            Please{' '}
            <Link
              to={`/ambassador`}
              style={{ textDecoration: 'underline', color: 'red' }}
            >
              click here
            </Link>{' '}
            to review your status in the Ambassador Program and read about the
            what you get and how to advance to the next level.
          </div>
          <LinkButton to="/ambassador" outline>
            Take me to my profile
          </LinkButton>
        </div>
      </Conditional>
      <Conditional shouldRender={!isChild}>
        <div className={styles.modalContainer}>
          <div className={styles.header}>
            You need an account to join the Ambassador Program
          </div>
          <div className={styles.modalText}>
            Use the button below to create an account. You'll be taken back to
            this page when you're done to join the Ambassador Program.
            <br />
            <br />
            <strong>
              <a
                href={`/login`}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'underline', color: 'red' }}
              >
                Click here
              </a>{' '}
              to log in if you already have an account.
            </strong>
          </div>
          <LinkButton to="/signup" outline>
            Create an account
          </LinkButton>
        </div>
      </Conditional>
    </ShadowModal>
  )
}
