import React from 'react'
import ShadowModal from 'components/ShadowModal'
import Button from 'components/Button'
import styles from '../Quiz/Quiz.module.scss'

interface RestartModalProps {
  isOpenModal: boolean
  onClose: () => void
  onRestart: () => void
  isLoading: boolean
}

export const RestartModal: React.FC<RestartModalProps> = ({
  isOpenModal,
  onClose,
  onRestart,
  isLoading,
}) => {
  return (
    <ShadowModal
      className={styles.modal}
      isModalOpen={isOpenModal}
      onCancel={onClose}
      withShadow
      noSize
    >
      <div className={styles.modalContainer}>
        <div className={styles.header}>Are you sure you want to restart?</div>
        <div className={styles.modalText}>
          Restarting this quiz will make you lose your current progress, and you
          will have to start over.
        </div>
        <div className={styles.buttonBox}>
          <Button onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <button
            className={styles.underlinedLinkButton}
            onClick={onRestart}
            disabled={isLoading}
          >
            Yes, I want to restart
          </button>
        </div>
      </div>
    </ShadowModal>
  )
}
