import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import { Toast } from 'typings/Toast'

import { useDispatch } from 'react-redux'
import { hideToaster } from 'store/app/actions'

import './toaster.css'

const formatMessage = (message?: string): string | undefined =>
  message && !message.endsWith('.') ? `${message}.` : message

interface ToasterProps {
  toast: Toast | null
}

const Toaster: React.FC<ToasterProps> = ({ toast }) => {
  const dispatch = useDispatch()
  const [colorToast, setColorToast] = useState('')
  useEffect(() => {
    if (toast) {
      setColorToast(toast.color)
      const timeoutToaster = setTimeout(() => {
        dispatch(hideToaster({ color: toast.color, message: toast.message }))
      }, 5000)
      return () => clearTimeout(timeoutToaster)
    }
  }, [dispatch, toast])
  return (
    <div
      className={cn(
        'left-0 right-0 pa3 f5 white nunito-regular z-4 toaster',
        `bg-pure-${colorToast}`,
        toast?.active && 'toaster--active',
      )}
    >
      {formatMessage(toast?.message)}
    </div>
  )
}

export default Toaster
