import React from 'react'

import BorderedContainer from 'components/BorderedContainer'
import LinkButton from 'components/LinkButton'
import Title from 'components/Title'
import { SignUpInfo } from 'components/SignUpInfo'

const BreakRecordInfo: React.FC = () => (
  <BorderedContainer>
    <Title>Submit a Record</Title>
    <h4>
      Have a great Kids World Record attempt to submit? Great! It only takes a
      few minutes to get set up!
    </h4>
    <SignUpInfo />
    <p>We’re excited to see what you’ve come up with!</p>
    <div className="flex h-100 mv3 mv5-ns justify-center">
      <div
        className="flex flex-column justify-between w-100 mw5-m mw5-l"
        style={{ height: '120px' }}
      >
        <LinkButton to="/login" className="w-100">
          Login
        </LinkButton>
        <LinkButton to="/signup" ghost outline className="w-100">
          Sign Up
        </LinkButton>
      </div>
    </div>
  </BorderedContainer>
)

export default BreakRecordInfo
