import React from 'react'

import Conditional from 'components/Conditional'

import IconButton from 'components/IconButton'
import { useMyRole } from 'hooks/useMyRole'

interface AdminCKEditorPencilProps {
  onClick: () => void
}

export const AdminCKEditorPencil: React.FC<AdminCKEditorPencilProps> = ({
  onClick,
}) => {
  const { isAdmin: isAdminLogged } = useMyRole()

  return (
    <Conditional shouldRender={isAdminLogged}>
      <IconButton
        name="edit"
        type="button"
        onClick={onClick}
        label="Edit content"
      />
    </Conditional>
  )
}
