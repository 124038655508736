import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router'
import cn from 'classnames'

import { CONFIG } from '../../config'

import Divider from '@mui/material/Divider'
import MuiIconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import MuiMenu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'

import {
  useGetLegacyRelativeAccountsQuery,
  useSwitchProfileMutation,
} from 'store/api'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import ProfileAvatar from 'components/ProfileAvatar'
import LogoutModal from 'components/ShadowModal/LogoutModal'
import { SplashContext } from 'components/Splash'

import Profiles, { Profile, ProfileAvatarType } from 'data/Profile'

import useToggle from 'hooks/useToggle'
import { EnterYourPin } from 'components/EnterYourPin'
import { useLogout } from '../../hooks/useLogout.hook'

import './switch-profile.css'

interface ProfileWithAvatar {
  id: string
  active: boolean
  isParent: boolean
  name: string
  avatar: ProfileAvatarType
}

interface ProfileMenuItemProps {
  profile: ProfileWithAvatar
  onClick: (id: string) => void
}

const ProfileMenuItem: React.FC<ProfileMenuItemProps> = ({
  profile,
  onClick,
}) => (
  <MuiMenuItem
    className="switch-profile__menu-item"
    onClick={() => onClick(profile.id)}
  >
    <div className="pv3 ph4 ma0 flex items-center justify-start nunito-regular">
      <ListItemIcon className="switch-profile__menu-item-icon mr2 mw-2">
        {profile.isParent ? <Icon name="lock" size="small" /> : <></>}
      </ListItemIcon>
      <ListItemText
        className="dark-gray switch-profile__menu-item-text"
        primary={profile.name}
      />
      <ListItemAvatar className="ml2">
        <ProfileAvatar type={profile.avatar} />
      </ListItemAvatar>
    </div>
  </MuiMenuItem>
)

const LinkMenuItem: React.FC<{ label: string; link: string }> = ({
  label,
  link,
}) => {
  const navigate = useNavigate()

  return (
    <MuiMenuItem
      onClick={() => {
        navigate(link)
      }}
      className="switch-profile__menu-item"
    >
      <div className="pv3 ph4 ma0 flex items-center justify-start nunito-regular">
        <ListItemText className="mr2 mw-2" primary="" />
        <ListItemText className="indigo-blue" primary={label} />
        <ListItemText primary="" />
      </div>
    </MuiMenuItem>
  )
}

const AdminLogoutMenuItem: React.FC<{ handleLogout: () => void }> = ({
  handleLogout,
}) => (
  <MuiMenuItem onClick={handleLogout} className="switch-profile__menu-item">
    <div className="pv3 ph4 ma0 flex items-center justify-start nunito-regular">
      <ListItemText className="mr2 mw-2" primary="" />
      <ListItemText className="indigo-blue" primary="Log Out" />
      <ListItemText primary="" />
    </div>
  </MuiMenuItem>
)

const SubmissionsMenuItem: React.FC = (props: any) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/admin/submission/')
  }

  return (
    <MuiMenuItem onClick={handleClick} className="switch-profile__menu-item">
      <div className="pv3 ph4 ma0 flex items-center justify-start nunito-regular">
        <ListItemText className="mr2 mw-2" primary="" key={'sub-m-i-1'} />
        <ListItemText
          className="indigo-blue"
          primary="Submissions"
          key={'sub-m-i-2'}
        />
        <ListItemText primary="" key={'sub-m-i-3'} />
      </div>
    </MuiMenuItem>
  )
}

const AmbassadorTableMenuItem: React.FC = (props: any) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/admin/ambassador-table')
  }

  return (
    <MuiMenuItem onClick={handleClick} className="switch-profile__menu-item">
      <div className="pv3 ph4 ma0 flex items-center justify-start nunito-regular">
        <ListItemText className="mr2 mw-2" primary="" key={'am-t-m-1'} />
        <ListItemText
          className="indigo-blue"
          primary="Ambassadors"
          key={'am-t-m-2'}
        />
        <ListItemText primary="" key={'am-t-m-3'} />
      </div>
    </MuiMenuItem>
  )
}

const PaidCountriesMenuItem: React.FC = (props: any) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/admin/paid-countries')
  }

  return (
    <MuiMenuItem onClick={handleClick} className="switch-profile__menu-item">
      <div className="pv3 ph4 ma0 flex items-center justify-start nunito-regular">
        <ListItemText className="mr2 mw-2" primary="" key={'p-c-p-1'} />
        <ListItemText
          className="indigo-blue"
          primary="Paid Coutries"
          key={'p-c-p-2'}
        />
        <ListItemText primary="" key={'p-c-p-3'} />
      </div>
    </MuiMenuItem>
  )
}

const UsersManagmentMenuItem: React.FC = (props: any) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/admin/users-managment')
  }

  return (
    <MuiMenuItem onClick={handleClick} className="switch-profile__menu-item">
      <div className="pv3 ph4 ma0 flex items-center justify-start nunito-regular">
        <ListItemText className="mr2 mw-2" primary="" key={'u-m-p-1'} />
        <ListItemText
          className="indigo-blue"
          primary="Users Managment"
          key={'u-m-p-2'}
        />
        <ListItemText primary="" key={'u-m-p-3'} />
      </div>
    </MuiMenuItem>
  )
}
const LoginToUserMenuItem: React.FC = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/admin/login-to-user/')
  }

  return (
    <MuiMenuItem onClick={handleClick} className="switch-profile__menu-item">
      <div className="pv3 ph4 ma0 flex items-center justify-start nunito-regular">
        <ListItemText className="mr2 mw-2" primary="" key={'l1'} />
        <ListItemText
          className="indigo-blue"
          primary="Login To User"
          key={'l2'}
        />
        <ListItemText primary="" key={'l3'} />
      </div>
    </MuiMenuItem>
  )
}

const ToEditMainPage = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/admin/editMainPage')
  }

  return (
    <MuiMenuItem onClick={handleClick} className="switch-profile__menu-item">
      <div className="pv3 ph4 ma0 flex items-center justify-start nunito-regular">
        <ListItemText className="mr2 mw-2" primary="" key={'l1'} />
        <ListItemText className="indigo-blue" primary="To Editing" key={'l2'} />
        <ListItemText primary="" key={'l3'} />
      </div>
    </MuiMenuItem>
  )
}

const ToEventsPage = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/admin/events')
  }

  return (
    <MuiMenuItem onClick={handleClick} className="switch-profile__menu-item">
      <div className="pv3 ph4 ma0 flex items-center justify-start nunito-regular">
        <ListItemText className="mr2 mw-2" primary="" key={'l1'} />
        <ListItemText className="indigo-blue" primary="Events" key={'l2'} />
        <ListItemText primary="" key={'l3'} />
      </div>
    </MuiMenuItem>
  )
}

const IdeaManagerMenuItem: React.FC = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/admin/idea-manager')
  }

  return (
    <MuiMenuItem onClick={handleClick} className="switch-profile__menu-item">
      <div className="pv3 ph4 ma0 flex items-center justify-start nunito-regular">
        <ListItemText className="mr2 mw-2" primary="" />
        <ListItemText className="indigo-blue" primary="Manage idea" />
        <ListItemText primary="" />
      </div>
    </MuiMenuItem>
  )
}

const renderItems = (
  profile: Profile,
  relativeProfiles: Profile[],
  onSelect: (profile: Profile) => void,
): JSX.Element[] => {
  const parentProfile = relativeProfiles.find((profile) =>
    Profiles.isParentProfile(profile),
  )
  const childrenProfiles = relativeProfiles.filter((profile) =>
    Profiles.isChildProfile(profile),
  )
  const relatives: JSX.Element[] = childrenProfiles.map(
    (relativeProfile: Profile) => (
      <ProfileMenuItem
        onClick={() => {
          onSelect(relativeProfile)
        }}
        key={relativeProfile.id}
        profile={{
          active: false,
          avatar: Profiles.getAvatar(relativeProfile),
          id: relativeProfile.id,
          isParent: Profiles.isParentProfile(relativeProfile),
          name: relativeProfile.firstName,
        }}
      />
    ),
  )

  const myRecords = (
    <LinkMenuItem
      key={`my-records-${profile.id}`}
      label="My Records"
      link="/my-records"
    />
  )

  const extraItems = Profiles.isParentProfile(profile)
    ? [
        <LinkMenuItem
          key={`view-profile-${profile.id}`}
          label="My Profile"
          link="/profile"
        />,
        <LinkMenuItem
          key={`add-child-${profile.id}`}
          label="Add your child"
          link="/add-child"
        />,
      ]
    : [
        <Conditional shouldRender={Boolean(parentProfile)}>
          {parentProfile && (
            <ProfileMenuItem
              onClick={() => {
                onSelect(parentProfile)
              }}
              key={parentProfile.id}
              profile={{
                active: false,
                avatar: 'parent',
                id: parentProfile.id,
                isParent: true,
                name: parentProfile.firstName,
              }}
            />
          )}
        </Conditional>,
        <Conditional shouldRender={CONFIG.FEATURE_324}>
          <LinkMenuItem
            key={`ambassador-profile-${profile.id}`}
            label="Ambassador Profile"
            link="/ambassador"
          />
        </Conditional>,
      ]

  const items = [myRecords, ...extraItems, ...relatives]

  return items.reduce<JSX.Element[]>(
    (result, item, index) =>
      index === items.length - 1
        ? result.concat(item)
        : result.concat(
            item,
            <Divider
              key={`divider-${item.key}`}
              className="switch-profile__menu-divider"
            />,
          ),
    [],
  )
}

const renderAdminItems = (
  profile: Profile,
  handleLogout: () => void,
): JSX.Element[] => [
  <SubmissionsMenuItem key={`submissions-${profile.id}`} />,
  <Divider key={`d1-${profile.id}`} className="switch-profile__menu-divider" />,
  ...(CONFIG.FEATURE_328
    ? [
        <AmbassadorTableMenuItem key={`/ambassador-table-${profile.id}`} />,
        <Divider
          key={`d6-${profile.id}`}
          className="switch-profile__menu-divider"
        />,
      ]
    : []),
  <PaidCountriesMenuItem key={`/paid-countries-${profile.id}`} />,
  <Divider key={`d7-${profile.id}`} className="switch-profile__menu-divider" />,
  <LoginToUserMenuItem key={`login-to-user-${profile.id}`} />,
  <Divider key={`d2-${profile.id}`} className="switch-profile__menu-divider" />,
  <ToEditMainPage key={`to-edit-main-page-${profile.id}`} />,
  <Divider key={`d3-${profile.id}`} className="switch-profile__menu-divider" />,
  <ToEventsPage key={`to-events-page-${profile.id}`} />,
  <Divider key={`d4-${profile.id}`} className="switch-profile__menu-divider" />,
  <IdeaManagerMenuItem key={`idea-manager-${profile.id}`} />,
  <Divider key={`d5-${profile.id}`} className="switch-profile__menu-divider" />,
  ...(CONFIG.FEATURE_1054
    ? [
        <UsersManagmentMenuItem key={`/users-managment-${profile.id}`} />,
        <Divider
          key={`d8-${profile.id}`}
          className="switch-profile__menu-divider"
        />,
      ]
    : []),
  <AdminLogoutMenuItem
    key={`log-out-${profile.id}`}
    handleLogout={handleLogout}
  />,
]

const AvatarButton: React.FC<{
  profile: Profile
  className?: string
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}> = ({ className, onClick, profile }) => (
  <MuiIconButton
    id="avatarButton"
    className={cn('switch-profile', className)}
    onClick={onClick}
  >
    <ProfileAvatar size="small" type={Profiles.getAvatar(profile)} />
  </MuiIconButton>
)

interface ProfileMenuProps {
  profile: Profile
  anchorEl: HTMLElement | null
  onClose: () => void
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({
  profile,
  anchorEl,
  onClose,
}) => {
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const { showSplash } = useContext(SplashContext)

  const { handleLogout } = useLogout()

  const { data: relativeAccounts = [] } = useGetLegacyRelativeAccountsQuery()
  const [switchProfile] = useSwitchProfileMutation()

  const parentPermissionConceded = Profiles.isParentProfile(profile)

  const handleSelect = (profile: Profile): void => {
    setSelectedProfile(profile)

    Profiles.isChildProfile(profile)
      ? onSelectedProfile(profile)
      : setIsOpen(true)
  }

  const onSelectedProfile = useCallback(
    (profile: Profile) => {
      switchProfile(profile.id)
      showSplash && showSplash(profile)
      onClose()
    },
    [onClose, showSplash, switchProfile],
  )

  useEffect(() => {
    parentPermissionConceded &&
      selectedProfile &&
      onSelectedProfile(selectedProfile)
  }, [onSelectedProfile, parentPermissionConceded, selectedProfile])

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useToggle()

  return (
    <>
      <MuiMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className="switch-profile__menu"
        elevation={0}
        // getContentAnchorEl={null}
        keepMounted
        onClose={onClose}
        onClick={onClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {Profiles.isAdminProfile(profile)
          ? renderAdminItems(profile, setIsLogoutModalOpen)
          : renderItems(profile, relativeAccounts, handleSelect)}
      </MuiMenu>
      {Profiles.isAdminProfile(profile) && (
        <LogoutModal
          isOpen={isLogoutModalOpen}
          onConfirm={handleLogout}
          onCancel={setIsLogoutModalOpen}
        />
      )}
      <EnterYourPin
        open={isOpen}
        selectedProfile={selectedProfile}
        onCancel={() => setIsOpen(false)}
      />
    </>
  )
}

interface SwitchProfileProps {
  className?: string
  profile: Profile
}

const SwitchProfile: React.FC<SwitchProfileProps> = ({
  className,
  profile,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!profile) return null

  return (
    <>
      <AvatarButton
        profile={profile}
        className={className}
        onClick={handleClick}
      />
      <ProfileMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        profile={profile}
      />
    </>
  )
}

export default SwitchProfile
