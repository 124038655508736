export interface Location {
  id?: string
  city: string
  country: string
  state?: string
  timezoneInMiliseconds?: number
}

export type LocationParams = Omit<Location, 'id' | 'timezoneInMiliseconds'>

const isAddressComplete = ({
  city,
  country,
  state,
}: Location | LocationParams) => city && country && state

const getFullAddress = (
  location: Location | LocationParams | null | undefined,
) => {
  return location && isAddressComplete(location)
    ? `${location.city} - ${location.state === 'N/A' ? '' : location.state}, ${
        location.country
      }`
    : ''
}

const getLocationParamsFromLocation = (
  location: Location | undefined,
): (LocationParams & { timezoneInMiliseconds?: number }) | undefined => {
  if (!location) {
    return undefined
  }

  const { city, state, country, timezoneInMiliseconds } = location
  return { city, state, country, timezoneInMiliseconds }
}

const exp = {
  getFullAddress,
  getLocationParamsFromLocation,
}

export default exp
