import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { ChildRecordSelect } from './ChildRecordSelect'

type FormRecordSelectProps = {
  name: string
  control: any
  childrenApprovedRecords: {
    childName: string
    records: {
      id: string
      title: string
    }[]
  }[]
}

export const FormRecordSelect: FC<FormRecordSelectProps> = ({
  name,
  control,
  childrenApprovedRecords,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <ChildRecordSelect
          value={field.value}
          onChange={(childName: string, submissionId: any) =>
            field.onChange({
              target: { value: { childName, submissionId } },
            })
          }
          childrenApprovedRecords={childrenApprovedRecords}
        />
      )}
    />
  )
}
