import Joi from 'joi'

export interface ContactUsFormData {
  name: string
  subject: string
  email: string
  text: string
}

export const CONTACT_VALIDATOR = Joi.object({
  name: Joi.string().required().messages({
    'string.empty': `This field cannot be empty`,
    'any.required': `This is a required field`,
  }),
  subject: Joi.string().required().messages({
    'string.empty': `This field cannot be empty`,
    'any.required': `This is a required field`,
  }),
  email: Joi.string()
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      'string.empty': `This field cannot be empty`,
      'any.required': `This is a required field`,
      'string.email': `Enter valid email`,
    }),
  text: Joi.string().required().messages({
    'string.empty': `This field cannot be empty`,
    'any.required': `This is a required field`,
  }),
})
