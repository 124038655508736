import React from 'react'
import { useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import styles from '../RecordSubmissions.module.scss'

export const AddNewSubmission = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.plusButton}>
      <IconButton
        aria-label="add submission"
        component="span"
        onClick={() => navigate(`/admin/create-submission`)}
        className={styles.iconButton}
      >
        <AddIcon className={styles.addIcon} />
      </IconButton>
    </div>
  )
}
