import React, { useState } from 'react'
import cn from 'classnames'
import { isImageFile } from 'utils/url'

import './thumbnail.css'

interface ThumbnailProps {
  alt?: string
  isSelected: boolean
  onClick: () => void
  src: string
}

const Thumbnail: React.FC<ThumbnailProps> = ({
  alt,
  isSelected,
  onClick,
  src,
}) => {
  const [displayedSrc, setDisplayedSrc] = useState(src)

  return (
    <div
      className={cn(
        'thumbnail__media',
        isSelected && 'thumbnail__media--selected',
      )}
    >
      {isImageFile(src) ? (
        <img
          alt={alt}
          className="thumbnail__image pointer"
          onClick={onClick}
          src={displayedSrc}
          onError={() => setDisplayedSrc('/assets/video-thumb.png')}
        />
      ) : (
        <img
          alt={alt}
          className="thumbnail__image pointer"
          onClick={onClick}
          src={'/assets/video-thumb.png'}
        />
      )}
    </div>
  )
}

export default Thumbnail
