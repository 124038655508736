import * as Yup from 'yup'

import { requiredLocation } from './requiredFields'

import { isImageFile, imageFileFormats } from 'utils/url'

const brokenOn = Yup.date().required('Date of record broken is required')
const category = Yup.string().nullable().required('Record Category is required')
const description = Yup.string().required('Record Description is required')
const title = Yup.string().required('Record Name is required')

const imageLinks = Yup.array()
  .of(
    Yup.string()
      .test('format test', 'At least one image is required', function () {
        this.parent.sort((file: string) => (file && isImageFile(file) ? -1 : 1))

        if (this.path === 'imageLinks[0]') {
          return new RegExp(imageFileFormats.join('|')).test(
            String(this.parent[0].toLowerCase()),
          )
        }

        return true
      })
      .required('At least one image is required'),
  )
  .required()

export const recordDetails = {
  brokenOn,
  category,
  description,
  imageLinks,
  location: requiredLocation,
  title,
}

const Schema = Yup.object().shape(recordDetails)

export default Schema
