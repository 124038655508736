import React, { FC, useState } from 'react'

import { IconButton } from '@mui/material'
import { Delete, Preview } from '@mui/icons-material'

import Conditional from '../Conditional'

import styles from './RecordMedias.module.scss'

interface MediaItemProps {
  src: string
  alt?: string
  onDelete?(src: string): void
  onPreview?(src: string): void
}

export const MediaItem: FC<MediaItemProps> = ({
  src,
  alt,
  onDelete,
  onPreview,
}) => {
  const [isOverlay, setIsOverlay] = useState(false)

  return (
    <div
      className={styles.mediaItem}
      onMouseEnter={() => setIsOverlay(true)}
      onMouseLeave={() => setIsOverlay(false)}
    >
      <img
        alt={alt}
        src={src}
        onError={(event) => {
          //@ts-ignore
          event.target.src = '/assets/video-thumb.png'
          // @ts-ignore
          event.onerror = null
        }}
      />
      <Conditional shouldRender={isOverlay}>
        <div className={styles.overlay}>
          <Conditional shouldRender={Boolean(onDelete)}>
            <IconButton size="large" onClick={() => onDelete && onDelete(src)}>
              <Delete fontSize="large" />
            </IconButton>
          </Conditional>
          <Conditional shouldRender={Boolean(onPreview)}>
            <IconButton
              size="large"
              onClick={() => onPreview && onPreview(src)}
            >
              <Preview fontSize="large" />
            </IconButton>
          </Conditional>
        </div>
      </Conditional>
    </div>
  )
}
