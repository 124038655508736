const GENDERS = {
  male: 'Boy',
  female: 'Girl',
  gender_x: 'Gender X',
}

export type AvailableGenders = 'male' | 'female' | 'gender_x'

export const getGenderLabel = (gender: AvailableGenders): string => {
  return GENDERS[gender] || GENDERS.gender_x
}
