import React, { FC } from 'react'

import ImageWithPlaceHolder from 'components/ImageWithPlaceholder'
import Link from 'components/Link'

import { Recommendation } from 'store/api/types/Recommendation.types'

import styles from '../RecommendationArea.module.scss'

type RecommendationViewProps = {
  recommendation: Recommendation
}

function isExternalLink(url: string) {
  const currentDomain = window.location.hostname
  const linkDomain = new URL(url, window.location.href).hostname
  return linkDomain !== currentDomain
}

export const RecommendationView: FC<RecommendationViewProps> = ({
  recommendation,
}) => {
  const isLinkExternal = isExternalLink(recommendation.link)
  return (
    <div className={styles.container}>
      <div className={styles.information}>
        <p className={styles.title}>{recommendation.title}</p>
        <p className={styles.description}>{recommendation.description}</p>
        <div className={styles.didYouKnow}>
          Did you know?
          <p className={styles.description}>{recommendation.didYouKnow}</p>
        </div>

        {isLinkExternal ? (
          <a
            href={recommendation.link}
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {recommendation.urlText}
          </a>
        ) : (
          <Link to={recommendation.link} className={styles.link}>
            {recommendation.urlText}
          </Link>
        )}
      </div>
      <ImageWithPlaceHolder
        src={recommendation.imageUrl}
        alt="recommendation image"
        className={styles.image}
      />
    </div>
  )
}
