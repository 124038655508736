import React, { FC, memo, useEffect, useState } from 'react'
import axios from 'axios'
import { CircularProgress } from '@mui/material'

import styles from './RecordMedias.module.scss'

interface FileUploaderItemProps {
  file: File
  onUploadComplete(file: File, url: string): void
}

const getFileType = (file: File) => file.type.split('/')[1]

const uploadFile = async (
  file: File,
  onUploadProgress?: (percent: number) => void,
): Promise<string> => {
  const format = getFileType(file)
  const { data } = await axios.get<{ url: string }>(
    `/api/media/uploadUrl?format=${format}`,
  )

  await axios.put(`${data.url}`, file, {
    headers: {
      'Content-Type': file.type,
      'content-disposition': 'attachment',
      // 'Cache-Control': 'public, max-age=31536000'
    },
    onUploadProgress: (uploadProgress) => {
      if (onUploadProgress) {
        const percent =
          (uploadProgress.loaded * 100) / (uploadProgress?.total || 1)
        onUploadProgress(percent)
      }
    },
  })

  return data.url.split('?')[0] || ''
}

const FileUploaderItemComponent: FC<FileUploaderItemProps> = ({
  file,
  onUploadComplete,
}) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    uploadFile(file, setProgress).then((url) => onUploadComplete(file, url))
  }, [file, onUploadComplete])

  return (
    <div className={styles.mediaItem}>
      <img alt="file-uploader" src="/assets/video-thumb.png" />
      <div className={styles.overlay}>
        <CircularProgress variant="determinate" value={progress} size={120} />
        <span className={styles.progress}>{progress.toFixed(0)}%</span>
      </div>
    </div>
  )
}

export const FileUploaderItem = memo(FileUploaderItemComponent)
