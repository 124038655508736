import React, { FC } from 'react'

import { Controller } from 'react-hook-form'

import { CheckboxV2 } from 'components/Inputs/Checkbox'

interface FormInputProps {
  name: string
  label?: string
  icon?: React.ReactNode
  control: any
  className?: string
}

export const FormCheckbox: FC<FormInputProps> = ({
  name,
  label,
  icon,
  control,
  className,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <CheckboxV2
            key={field.value}
            label={label}
            icon={icon}
            checked={field.value}
            onChange={field.onChange}
            name={field.name}
            className={className}
          />
        )
      }}
    />
  )
}
