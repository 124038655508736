import React, { FC } from 'react'
import FacebookIcon from '@mui/icons-material/Facebook'
import PinterestIcon from '@mui/icons-material/Pinterest'
import { IconButton } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import SubTitle from 'components/SubTitle'

import styles from './RecordDetails.module.scss'

type ShareLinkProps = {
  imageUrl: string
}

export const ShareLink: FC<ShareLinkProps> = ({ imageUrl }) => {
  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(window.location.href)
  }

  return (
    <div>
      <SubTitle className="neo-red pt3 pt4-l">Spread the word!</SubTitle>
      <p className={styles.text}>
        Love this record? Go ahead, share with friends—they'll thank you for it.
      </p>
      <div className={styles.links}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.facebook.com/sharer.php?u=${window.location.href}`}
          data-pin-custom="true"
        >
          <FacebookIcon className={styles.facebookIcon} />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.pinterest.com/pin/create/button/?url=${window.location.href}&media=${imageUrl}&description=kwr`}
          data-pin-custom="true"
        >
          <PinterestIcon className={styles.pinterestIcon} />
        </a>
        <div className={styles.text}>Or copy link</div>
        <div className={styles.shareLink}>
          https://www.kidsworldrecords.com
          <IconButton aria-label="copy" onClick={handleCopyLink}>
            <ContentCopyIcon className={styles.copy} />
          </IconButton>
        </div>
      </div>
    </div>
  )
}
