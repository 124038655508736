import React from 'react'
import { IMaskMixin } from 'react-imask'

import { FormInput, FormInputProps } from 'components/Form/FormInput'

interface IMaskProps {
  lazy?: boolean
  mask: string
  onAccept?: (v: string) => void
  unmask?: 'typed' | boolean
}

interface MaskedInputFieldProps {
  setValue?: any
}

// const getCreditCardNumberPattern = (
//   cardIssuer: CardIssuer | undefined,
// ): string => {
//   switch (cardIssuer) {
//     case 'amex':
//       return '0000 000000 00000'
//     case 'dinersclub':
//       return '0000 000000 0000'
//     case 'mastercard':
//     case 'elo':
//       return '0000 0000 0000 0000'
//     case 'visa':
//     case 'jbc':
//     case 'discover':
//       return '0000 0000 0000 0000 000'
//     default:
//       return '0000 0000 0000 0000'
//   }
// }

export type CardIssuer =
  | 'amex'
  | 'mastercard'
  | 'visa'
  | 'dinersclub'
  | 'discover'
  | 'elo'
  | 'jbc'

interface CardNumberFieldProps {
  cardIssuer?: CardIssuer
}

export const NewCardNumberField: React.FC<
  CardNumberFieldProps & MaskedInputFieldProps & FormInputProps
> = ({ cardIssuer, ...props }) => {
  return <FormInput {...props} />
}

export const NewMaskedInput: React.FC<IMaskProps & FormInputProps> =
  IMaskMixin<FormInputProps>(({ ...props }) => {
    return <FormInput {...props} />
  })

export const NewValidThruField: React.FC<FormInputProps> = (props) => (
  <NewMaskedInput {...props} mask="00/00" />
)
