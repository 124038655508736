import Joi from 'joi'
import { GenderV2 } from 'store/api/types/Gender.types'
import { Category } from 'store/api/types/Record.types'

export const GENDER_LABEL = {
  [GenderV2.MALE]: 'Boy',
  [GenderV2.FEMALE]: 'Girl',
  [GenderV2.GENDER_X]: 'Gender X',
}

export const GENDER_OPTIONS = [
  {
    label: GENDER_LABEL[GenderV2.FEMALE],
    value: GenderV2.FEMALE,
  },
  {
    label: GENDER_LABEL[GenderV2.MALE],
    value: GenderV2.MALE,
  },
  {
    label: GENDER_LABEL[GenderV2.GENDER_X],
    value: GenderV2.GENDER_X,
  },
]

export const CATEGORY_OPTIONS = [
  {
    label: 'Most',
    value: Category.MOST,
  },
  {
    label: 'Smallest',
    value: Category.SMALLEST,
  },
  {
    label: 'Largest',
    value: Category.LARGEST,
  },
  {
    label: 'Longest',
    value: Category.LONGEST,
  },
  {
    label: 'Highest',
    value: Category.HIGHEST,
  },
  {
    label: 'Fastest',
    value: Category.FASTEST,
  },
  {
    label: 'Endurance',
    value: Category.ENDURANCE,
  },
  {
    label: 'Collections',
    value: Category.COLLECTIONS,
  },
  {
    label: 'Other',
    value: Category.OTHER,
  },
]

export const SUBMISSION_UPDATE_VALIDATOR = Joi.object({
  idea: Joi.string().required().label('Should not be empty'),
  category: Joi.string()
    .required()
    .valid(...Object.values(Category)),
  previousRecordId: Joi.string().uuid().allow(null, '').required(),
  description: Joi.string().required().required(),
  notes: Joi.string().allow(null, '').required(),
  requireParentalSupervision: Joi.boolean().required(),
  requireTimingDevice: Joi.boolean().required(),
  noNewRecord: Joi.boolean().required(),
  mediaLinks: Joi.array().items(Joi.string()).min(1).required(),
  isFirstRecord: Joi.boolean().required(),
})

export const buildDefaultValue = (submission: any): any => {
  if (!submission) {
    return undefined
  }

  return {
    idea: submission.idea,
    category: submission.category,
    description: submission.description,
    notes: submission.notes,
    previousRecordId: submission.previousRecordId,
    requireParentalSupervision: submission.requireParentalSupervision,
    requireTimingDevice: submission.requireTimingDevice,
    noNewRecord: submission.noNewRecord,
    mediaLinks: submission.mediaLinks,
    isFirstRecord: submission.isFirstRecord,
  }
}
