import React from 'react'

import ImageWithPlaceHolder from 'components/ImageWithPlaceholder'

import { isImageFile } from 'utils/url'

interface ImageOrVideoProps {
  alt: string
  className?: string
  autoPlay?: boolean
  loop?: boolean
  controls?: boolean
  src: string
}

export const ImageOrVideo: React.FC<ImageOrVideoProps> = ({
  alt,
  className,
  src,
  autoPlay,
  loop,
  controls,
}) => (
  <>
    {isImageFile(src) ? (
      <ImageWithPlaceHolder
        src={src}
        alt={alt}
        className={className}
        ariaHidden={true}
      />
    ) : (
      <video
        style={{ width: '100%' }}
        className={className}
        autoPlay={autoPlay}
        muted={autoPlay}
        controls={controls}
        loop={loop}
      >
        <source src={src} />
      </video>
    )}
  </>
)
