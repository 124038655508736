import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Navigate, useNavigate } from 'react-router'

import { getSignUpProfile, isLoading } from 'store/signup/selectors'
import { updateSignUpProfile } from 'store/signup/actions'

import BorderedContainer from 'components/BorderedContainer'
import ImageDecoration, {
  ImageDecorationType,
} from 'components/ImageDecoration'
import Loader from 'components/Loader'
import Title from 'components/Title'

import SignUpProfiles from 'typings/SignUpProfile'
import { PageWrapper } from 'components/PageWrapper'
import { useForm, FieldErrors } from 'react-hook-form'
import { FormPinInput } from 'components/Form/FormPinInput'
import { PIN_VALIDATOR } from 'scenes/Quizz/components/ParentPermissionModal/PinInput.utils'
import { joiResolver } from '@hookform/resolvers/joi'
import SubmitButton from 'components/SubmitButton'

import styles from './ChooseAPin.module.scss'

const getErrorMessage = (errors: FieldErrors<{ pin: string[] }>) =>
  errors?.pin &&
  (errors.pin[0]?.message ||
    errors.pin[1]?.message ||
    errors.pin[2]?.message ||
    errors.pin[3]?.message)

export const ChooseAPin: React.FC = () => {
  const user = useSelector(getSignUpProfile)
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ pin: string[] }>({
    resolver: joiResolver(PIN_VALIDATOR),
    mode: 'all',
  })

  const onSubmit = (body: { pin: string[] }) => {
    dispatch(
      updateSignUpProfile({
        ...user,
        pin: {
          firstDigit: body.pin[0],
          secondDigit: body.pin[1],
          thirdDigit: body.pin[2],
          fourthDigit: body.pin[3],
        },
      }),
    )
    navigate('/verify-age')
  }

  const loading = useSelector(isLoading)

  return (
    <PageWrapper>
      <div className={styles.page}>
        <BorderedContainer>
          <Loader active={loading} className={styles.loader} />
          {!SignUpProfiles.isValid(user) && <Navigate to="/signup" />}
          <Title>Choose a PIN</Title>
          <span className={styles.textInfo}>
            To activate parental controls, please choose a four-digit PIN. You
            will need the PIN later if you need to change your account settings.
          </span>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.enter_your_pin}>
              <div className={styles.pinInput}>
                <FormPinInput
                  name="pin"
                  control={control}
                  errorText={getErrorMessage(errors)}
                  isFocused={true}
                />
              </div>
              <div className={styles.buttonsDiv}>
                <SubmitButton className={styles.buttonChoice}>
                  Confirm
                </SubmitButton>
              </div>
            </div>
          </form>
        </BorderedContainer>
        <ImageDecoration
          type={ImageDecorationType.BOY_JUMPING_ROPE}
        ></ImageDecoration>
      </div>
    </PageWrapper>
  )
}
