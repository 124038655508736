import React from 'react'
import styles from './GroupEvents.module.scss'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import ShadowModal from '../../components/ShadowModal'

interface ThankYouModalProps {
  isOpenModal: boolean
  onClose: () => void
}

export const ThankYouModal: React.FC<ThankYouModalProps> = ({
  isOpenModal,
  onClose,
}) => {
  return (
    <ShadowModal
      isModalOpen={isOpenModal}
      className={styles.modal}
      onCancel={onClose}
      withShadow
      noSize
    >
      <IconButton
        className={styles.closeBtn}
        aria-label="close"
        onClick={onClose}
      >
        <Close />
      </IconButton>
      <div className={styles.modalContainer}>
        <div className={styles.header}>Thank you!</div>
        <div>
          We will be in touch shortly to learn more about what your are looking
          to do.
        </div>
      </div>
    </ShadowModal>
  )
}
