import React from 'react'
import styles from './Footer.module.scss'

const links = [
  {
    icon: '/assets/socialLinks/youtube.png',
    link: 'https://www.youtube.com/@kidsworldrecords',
  },
  {
    icon: '/assets/socialLinks/facebook.png',
    link: 'https://www.facebook.com/kidsworldrecords/',
  },
  {
    icon: '/assets/socialLinks/tiktok.png',
    link: 'https://www.tiktok.com/@kidsworldrecords',
  },
  {
    icon: '/assets/socialLinks/instagram.png',
    link: 'https://www.instagram.com/kidsworldrecords/',
  },
]

export const SocialLinks = () => {
  return (
    <div className={styles.socialLinks}>
      {links.map((item) => (
        <a key={item.link} href={item.link} target="_blank" rel="noreferrer">
          <img
            className={styles.icon}
            src={item.icon}
            alt="social network icon"
          />
        </a>
      ))}
    </div>
  )
}
