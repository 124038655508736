import React, { FC, memo } from 'react'
import BreakRecordInfo from '../BreakRecordInfo'
import { RestrictComponent } from './RestrictComponent'
import { BreakRecordSteps } from './BreakRecordSteps'
import { useMyRole } from 'hooks/useMyRole'

const BreakRecordBodyComponent: FC = () => {
  const { isAuthenticated, isChild: isChildProfile } = useMyRole()

  if (!isAuthenticated) {
    return <BreakRecordInfo />
  }

  if (isAuthenticated && !isChildProfile) {
    return <RestrictComponent />
  }

  return <BreakRecordSteps />
}

export const BreakRecordBody = memo(BreakRecordBodyComponent)
