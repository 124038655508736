import React, { FC, useRef, useCallback } from 'react'
import AddIcon from '@mui/icons-material/Add'

import { OutlinedContainer } from 'components/OutlineContainer'
import { Conditional } from 'components/Conditional'

import { MediaItem } from './MediaItem'

import styles from './RecordMedias.module.scss'

interface RecordMediasProps {
  medias: string[]
  onAddNewMedia?(files: File[]): void
  name?: string
  accept?: string
  disableSelect?: boolean
}

export const RecordMedias: FC<RecordMediasProps> = ({
  name,
  medias,
  onAddNewMedia,
  accept = 'image/*,video/*',
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const onAddIconClick = useCallback(() => {
    fileInputRef?.current?.click()
  }, [])

  const onFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesFromInput = event?.currentTarget?.files || []
    if (onAddNewMedia) {
      onAddNewMedia(Array.from(filesFromInput))
    }
  }

  return (
    <OutlinedContainer label="Videos & Pictures">
      <input
        type="file"
        id="myFile"
        name={name}
        accept={accept}
        ref={fileInputRef}
        onChange={onFileSelect}
        hidden
        multiple
      />
      <div className={styles.medias}>
        {medias.map((media) => (
          <MediaItem key={media} src={media} />
        ))}
        <Conditional shouldRender={Boolean(onAddNewMedia)}>
          <div className={styles.addNewItem} onClick={onAddIconClick}>
            <AddIcon className={styles.icon} />
          </div>
        </Conditional>
      </div>
    </OutlinedContainer>
  )
}
