import React, { ReactNode } from 'react'
import cn from 'classnames'
import styles from './Page.module.scss'
import { PageWrapper } from 'components/PageWrapper'

interface PageProps {
  children: ReactNode
  className?: string
}

export const Page: React.FC<PageProps> = ({ children, className }) => {
  return (
    <PageWrapper>
      <div className={cn(styles.page, className)}>{children}</div>
    </PageWrapper>
  )
}
