import React from 'react'

import MuiCheckBox from '@mui/material/Checkbox'

interface CheckBoxProps {
  theme?: 'primary' | 'secondary'
  onChange: () => void
}

const CheckBox: React.FC<CheckBoxProps> = ({ theme = 'primary', onChange }) => (
  <MuiCheckBox color={theme} onChange={onChange} />
)

export default CheckBox
