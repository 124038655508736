import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import Title from 'components/Title'
import SubmitButton from 'components/SubmitButton'
import BorderedContainer from 'components/BorderedContainer'
import { Page } from 'components/Page'
import ShadowModal from 'components/ShadowModal'
import { FormInput } from 'components/Form/FormInput'

import { ContactUsForm } from 'store/api/types/Email.types'
import { useSendContactUsEmailMutation } from 'store/api'
import { CONTACT_US_VALIDATOR } from './Educators.utils'

const addContact = (text: string, name: string, email: string): string =>
  `${text} \nfrom ${name} - ${email}`

const Educators = () => {
  const [sendContactUsEmail, { isLoading }] = useSendContactUsEmailMutation()
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ContactUsForm>({
    resolver: joiResolver(CONTACT_US_VALIDATOR),
    mode: 'all',
  })

  const onSubmit = async (data: ContactUsForm) => {
    await sendContactUsEmail({
      body: addContact(data.text, data.name, data.email),
      from: data.email,
      subject: `[Educators] ${data.subject}`,
    })
    reset()
    setShowModal(true)
  }

  return (
    <Page>
      <BorderedContainer>
        <Title>Break School, Association and Club records</Title>
        <p>
          Calling all educators! If you are looking to set or break a Kids Word
          Record with a group of <strong>awesome kids</strong>, we'd love to
          head from you!
        </p>
        <p style={{ lineHeight: 1.5 }}>
          Set up is <strong>easy and free</strong> making it the perfect
          opportunity for{' '}
          <strong> fundraising, team building science and math</strong> and{' '}
          <strong>raising awareness </strong>
          for your crew! Open to all kids between the{' '}
          <strong>ages of 4 to 16</strong>, Kids word Records accommodates{' '}
          <strong>groups of every size</strong> and uses a divisional model to{' '}
          <strong>celebrate kids’ unique achievements </strong>
          at every age and stage. Check out what a Group Record Event page looks
          like:{' '}
          <a
            href={`/events`}
            style={{ color: 'blue', textDecoration: 'underline' }}
          >
            Kids World Records Events
          </a>
        </p>
        <div className="f4 dark-gray nunito-regular">
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-row">
                <div className="mr2 flex-auto">
                  <FormInput
                    name="name"
                    label="Name"
                    control={control}
                    errorText={errors.name?.message}
                  />
                </div>
                <div className="flex-auto">
                  <FormInput
                    name="email"
                    label="E-mail"
                    control={control}
                    errorText={errors.email?.message}
                  />
                </div>
              </div>
              <FormInput
                name="subject"
                label="Subject"
                control={control}
                errorText={errors.subject?.message}
              />
              <FormInput
                name="text"
                label="Enter Your Text Here"
                control={control}
                errorText={errors.text?.message}
                multiline
                minRows={4}
              />
              <div className="flex h-100 mv3 justify-center items-center">
                <SubmitButton disabled={isLoading}>SEND E-MAIL</SubmitButton>
              </div>
            </form>
          </>
        </div>
        <ShadowModal isModalOpen={showModal}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <h3 style={{ textAlign: 'center' }}>
              Your contact form successfully sent
            </h3>
            <SubmitButton
              onClick={() => {
                navigate('/', { replace: true })
              }}
            >
              OK
            </SubmitButton>
          </div>
        </ShadowModal>
      </BorderedContainer>
    </Page>
  )
}

export default Educators
