import React, { FC } from 'react'

import styles from './ImagesPreview.module.scss'

type ImagesPreviewProps = {
  images: string[]
}

export const ImagesPreview: FC<ImagesPreviewProps> = ({ images }) => {
  return (
    <div className={styles.imageList}>
      {images.map((image) => (
        <img key={image} src={image} alt={image} />
      ))}
    </div>
  )
}
