import React from 'react'
import cn from 'classnames'

import './image-decoration.css'

export enum ImageDecorationType {
  GIRL_RUNNING = '/assets/girl-running.png',
  BOY_JUMPING_ROPE = '/assets/boy-jumping-rope.png',
}

const ImageDecoration: React.FC<{
  type: ImageDecorationType
  position?: 'left' | 'right'
}> = ({ type, position = 'right' }) => {
  const className = cn(
    'dn db-l absolute bottom-0 animation-reveal',
    'image-decoration',
    `image-decoration--${position}`,
  )

  return <img src={type} aria-hidden={true} className={className} alt="" />
}

export default ImageDecoration
