import { Location } from 'data/Location'

export const buildLocationValue = (location?: Location): string => {
  const state =
    !location?.state || location?.state === 'N/A' ? '' : ` ${location.state}`
  return location ? `${location.city}${state} ${location.country}` : ''
}

type Entity = {
  firstName: string
  lastName: string
}

export const buildFullName = (entity?: Entity | null): string =>
  entity ? `${entity?.firstName} ${entity?.lastName}` : ''
