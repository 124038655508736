import React from 'react'
import cn from 'classnames'

import { FilledInputProps } from '@mui/material/FilledInput'
import TextField from '@mui/material/TextField'

import './input-text.css'

export interface InputTextProps {
  className?: string
  shrink?: boolean
  defaultValue?: string | null
  disabled?: boolean
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  error?: boolean
  helperText?: string
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
  inputRef?: any
  label?: string
  placeholder?: string
  maxLength?: number
  multiline?: boolean
  name?: string
  rows?: number
  rowsMax?: number
  variant?: 'filled' | 'standard'
  value?: string | null
  type?: string
  onChange?: (value: string) => void
  onFocus?: (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void
  onBlur?: (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const InputText: React.FC<InputTextProps> = ({
  className,
  defaultValue,
  disabled,
  startAdornment,
  endAdornment,
  error,
  helperText,
  inputMode,
  inputRef,
  label,
  maxLength,
  multiline,
  name,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  onKeyUp,
  rows,
  rowsMax,
  type,
  shrink,
  variant = 'filled',
  ...rest
}) => {
  console.warn('This component has been deprecated')
  return (
    <TextField
      {...rest}
      className={cn('input-text', className)}
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      fullWidth
      helperText={helperText}
      label={label}
      name={name}
      variant={variant}
      inputMode={inputMode}
      InputLabelProps={shrink ? { shrink: true } : {}}
      InputProps={
        {
          disableUnderline: true,
          onKeyUp: onKeyUp,
          onKeyDown: onKeyDown,
          startAdornment: startAdornment,
          endAdornment: endAdornment,
          onFocus: onFocus,
          onBlur: onBlur,
        } as Partial<FilledInputProps>
      }
      inputProps={{
        maxLength: maxLength ? maxLength : undefined,
        inputMode: inputMode,
      }}
      inputRef={inputRef}
      onChange={(e) => {
        onChange && onChange(e.target.value ? e.target.value : '')
      }}
      type={type}
      multiline={multiline}
      maxRows={rowsMax}
      rows={rows}
    />
  )
}

export default InputText
