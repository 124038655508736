import React from 'react'
import { Navigate } from 'react-router'
import { useMyRole } from 'hooks/useMyRole'

const DefaultRedirect: React.FC = () => {
  const { isAdmin: isAdminLogged } = useMyRole()

  return (
    <>
      {isAdminLogged ? (
        <Navigate to="/admin/submissions" />
      ) : (
        <Navigate to="/" />
      )}
    </>
  )
}

export default DefaultRedirect
