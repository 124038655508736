import React, { useState } from 'react'
import {
  TableContainer,
  TableSortLabel,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
} from '@mui/material'
import cn from 'classnames'

import { AmbassadorsResponse } from 'store/api/types/Ambassadors.types'
import { useUpdateAmbassadorFulfillMutation } from 'store/api'

import styles from './AmbassadorProgressTable.module.scss'

const HEADER_TITLES = [
  'Parent First Name',
  'Parent Last Name',
  'Email',
  'City',
  'State',
  'Country',
  'Kid First Name',
  'Kid Last Name',
  'Level',
  'Fulfilled',
]

interface AmbassadorProgressTableProps {
  ambassadors: AmbassadorsResponse[]
  dataFetching: boolean
}

function AmbassadorProgressTable({
  ambassadors,
  dataFetching,
}: AmbassadorProgressTableProps) {
  const [updateAmbassadorFulfill, { isLoading }] =
    useUpdateAmbassadorFulfillMutation()
  const [id, setId] = useState<string>('')
  async function ClickToggle(id: string, check: boolean) {
    updateAmbassadorFulfill({ id: id, fulfilled: !check })
    setId(id)
  }
  return (
    <>
      <TableContainer>
        <Table className="table">
          <TableHead>
            <TableRow>
              {HEADER_TITLES.map((headItem) => (
                <TableCell key={headItem} style={{ padding: '0.5rem' }}>
                  <TableSortLabel
                    className={styles.table_label}
                    disabled={!!HEADER_TITLES.find((i) => i === headItem)}
                  >
                    {headItem}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {ambassadors.map((ambassador, index) => (
              <TableRow
                key={index + ambassador.ambassadorId}
                className={
                  index % 2 === 1
                    ? cn(styles.table_row, styles.even_style)
                    : styles.table_row
                }
              >
                <TableCell>{ambassador.parent.firstName}</TableCell>
                <TableCell>{ambassador.parent.lastName}</TableCell>
                <TableCell>{ambassador.parent.email}</TableCell>
                <TableCell>{ambassador.location.city}</TableCell>
                <TableCell>{ambassador.location.state}</TableCell>
                <TableCell>{ambassador.location.country}</TableCell>
                <TableCell>{ambassador.child.firstName}</TableCell>
                <TableCell>{ambassador.child.lastName}</TableCell>
                <TableCell>{ambassador.level}</TableCell>
                <TableCell
                  onClick={() => {
                    if (!ambassador.fulfilled)
                      ClickToggle(ambassador.ambassadorId, ambassador.fulfilled)
                  }}
                  sx={{
                    backgroundColor: ambassador.fulfilled
                      ? 'lightgreen'
                      : index % 2 === 1
                        ? 'lightgray'
                        : 'white',
                  }}
                >
                  {(isLoading || dataFetching) && ambassador.ambassadorId === id
                    ? 'Loading'
                    : ambassador.fulfilled
                      ? 'Done'
                      : 'Fulfill'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default AmbassadorProgressTable
