import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'

import {
  useGetSubmissionByIdQuery,
  useReviewRecordMutation,
  useUpdateRecordPriorityMutation,
  useMarkAsBrokenRecordMutation,
} from 'store/api'
import { RecordStatus, RecordType } from 'store/api/types/Record.types'
import LinkButton from 'components/LinkButton'
import BackButton from 'components/BackButton'
import BorderedContainer from 'components/BorderedContainer'
import Button from 'components/Button'
import { ImageOrVideo } from 'components/ImageOrVideo'
import { Input } from 'components/Inputs'
import RejectReasonSection from 'components/RejectReasonSection'
import ReviewButton from 'components/ReviewButton'
import Conditional from 'components/Conditional'
import ConfirmSubmissionApproval from 'components/ShadowModal/ConfirmSubmissionApprovalModal'
import ConfirmSubmissionReject from 'components/ShadowModal/ConfirmSubmissionRejectModal'
import BreakRecordModal from './components/BreakRecordModal'
import { CheckboxV2 } from 'components/Inputs/Checkbox'
import { TwoColumnsGrid } from '../RecordSubmissionDetailsEdit/components/TwoColumnsGrid'
import { PriorityChange } from 'components/Card/components/Buttons/PriorityChange'
import SubTitle from 'components/SubTitle'
import { Page } from 'components/Page'

import {
  buildFullName,
  buildLocationValue,
} from './RecordSubmissionDetails.utils'
import styles from './RecordSubmissionDetails.module.scss'
import { ValidRecordSubmissionRejectFormData } from '../../typings/RecordSubmissionRejectFormData'
import { GENDER_LABEL } from '../RecordSubmissionDetailsEdit/RecordSubmissionDetailsEdit.utils'
import { GenderV2 } from '../../store/api/types/Gender.types'

type RecordSubmissionDetailParams = {
  id: string
}

export const RecordSubmissionDetails = () => {
  const { id } = useParams<RecordSubmissionDetailParams>()
  const { data: submission, isLoading: isFetching } = useGetSubmissionByIdQuery(
    id || '',
    {
      skip: !id,
    },
  )
  const [breakModal, setBreakModal] = useState(false)
  const [isSubmissionApprovedModalOpen, setIsSubmissionApprovedModalOpen] =
    useState(false)
  const [isRecordRejectBoxOpen, setIsRecordRejectBoxOpen] = useState(false)
  const [reviewRecord, { isLoading }] = useReviewRecordMutation()
  const [updatePriority] = useUpdateRecordPriorityMutation()
  const [markAsBroken, { isLoading: isBreaking }] =
    useMarkAsBrokenRecordMutation()

  const handleSubmissionRejection = async (
    data: ValidRecordSubmissionRejectFormData,
  ) => {
    try {
      await reviewRecord({
        id: data.submissionId,
        status: 'REJECT',
        rejectNotes: data.reason,
      }).unwrap()
      setIsRecordRejectBoxOpen(false)
    } catch (e) {
      console.error(e)
    }
  }

  const handleSubmissionApproval = async (submissionId: string) => {
    try {
      await reviewRecord({
        id: submissionId,
        status: 'APPROVE',
      }).unwrap()
      setIsSubmissionApprovedModalOpen(false)
    } catch (e) {
      console.error(e)
    }
  }

  const handleBreakRecord = async () => {
    try {
      if (submission) {
        await markAsBroken(submission.id).unwrap()
        setBreakModal(false)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onPriorityChange = async () => {
    if (submission) {
      await updatePriority({
        recordId: submission.id,
        priority: submission.priority === 1 ? 0 : 1,
      })
    }
  }

  const handleDownload = useCallback(
    (e: React.BaseSyntheticEvent) => {
      e.preventDefault()
      let srcList = []
      let i = 0
      const length: number = submission?.mediaLinks.length || 0

      setInterval(function () {
        if (length > i) {
          srcList.push(submission?.mediaLinks[i])
          let link = document.createElement('a')
          link.id = `${i}`
          link.download = ''
          link.href = submission?.mediaLinks[i] || ''
          link.click()
          i++
        }
      }, 1500)
    },
    [submission?.mediaLinks],
  )

  const isApproved = submission?.status === RecordStatus.APPROVED
  const isRejected = submission?.status === RecordStatus.REJECTED
  // const isInReview = submission?.status === RecordStatus.IN_REVIEW
  const isBroken = submission?.status === RecordStatus.BROKEN
  const shouldRenderBrokenButton = isApproved || isBroken

  const isDisabled = isApproved || isRejected

  return (
    <Page>
      <BorderedContainer>
        <TwoColumnsGrid>
          <BackButton backUrl="/admin/submissions">Back to Records</BackButton>
          <div className={styles.headButtons}>
            <Conditional shouldRender={Boolean(submission?.ambassadorId)}>
              <WorkspacePremiumIcon className={styles.ambassadorIcon} />
            </Conditional>
            <Conditional
              shouldRender={
                submission?.priority === 0 || submission?.priority === 1
              }
            >
              <PriorityChange
                priority={submission?.priority || 0}
                onChangePriority={onPriorityChange}
              />
            </Conditional>
            <LinkButton
              to={
                submission?.type === RecordType.SINGLE
                  ? `/admin/submissions/${id}/edit`
                  : `/admin/group-submission/${id}/edit`
              }
              disabled={isFetching}
              className={styles.button240}
            >
              Edit
            </LinkButton>
          </div>
        </TwoColumnsGrid>
        <Conditional shouldRender={shouldRenderBrokenButton}>
          <TwoColumnsGrid className={styles.reviewButtons}>
            <div />
            <Button
              onClick={() => setBreakModal(true)}
              disabled={isBroken}
              className={styles.button240}
            >
              {isBroken ? 'Already Broken' : 'Mark as broken'}
            </Button>
          </TwoColumnsGrid>
        </Conditional>
        <TwoColumnsGrid className={styles.reviewButtons}>
          <ReviewButton
            disabled={isDisabled}
            hasTheme={isApproved}
            label={isDisabled && isApproved ? 'APPROVED' : 'APPROVE'}
            name="done"
            theme="secondary"
            onClick={() => setIsSubmissionApprovedModalOpen(true)}
          />
          <ReviewButton
            disabled={isDisabled}
            hasTheme={isRejected}
            label={isDisabled && isRejected ? 'REJECTED' : 'REJECT'}
            name="close"
            theme="primary"
            onClick={() => setIsRecordRejectBoxOpen(true)}
          />
        </TwoColumnsGrid>
        <Conditional shouldRender={Boolean(submission?.previousRecordId)}>
          <TwoColumnsGrid className={styles.brokenRecordRow}>
            <Input
              label="This Record is breaking record #"
              value={submission?.previousRecordId}
              disabled
            />
            <LinkButton
              className={styles.button240}
              to={`/records/${submission?.previousRecordId}`}
              target="_blank"
            >
              Browse Record
            </LinkButton>
          </TwoColumnsGrid>
        </Conditional>
        <Input label="Title" value={submission?.idea} disabled />
        <Input
          label="Category"
          value={submission?.category.toLowerCase()}
          disabled
          className={styles.category}
        />
        <Input
          label="Kid's Name"
          value={buildFullName(submission?.recordOwner)}
          disabled
        />
        <TwoColumnsGrid>
          <Input
            label="Gender"
            disabled
            value={GENDER_LABEL[submission?.gender || GenderV2.GENDER_X] || ''}
          />
          <Input label="Age" disabled value={submission?.ageGap} />
        </TwoColumnsGrid>
        <Input
          label="Record Description"
          value={submission?.description}
          multiline
          disabled
        />
        <Input
          label="Fun Facts"
          value={submission?.funFacts}
          placeholder="No Fun Facts for this record"
          disabled
        />
        <Conditional shouldRender={Boolean(submission?.notes)}>
          <Input
            label="Record Notes"
            value={submission?.notes}
            multiline
            disabled
          />
        </Conditional>
        <Conditional shouldRender={Boolean(submission?.rejectReason)}>
          <RejectReasonSection message={submission?.rejectReason || ''} />
        </Conditional>
        <TwoColumnsGrid>
          <Input
            label="Record Date"
            disabled
            value={submission?.brokenOn.toString()}
          />
          <Input
            label="Record Location"
            disabled
            value={buildLocationValue(submission?.location)}
          />
        </TwoColumnsGrid>
        <TwoColumnsGrid>
          <Input
            label="Parent Name"
            disabled
            value={buildFullName(submission?.parentAccount)}
          />
          <Input
            label="Parent Email"
            disabled
            value={submission?.parentAccount?.email}
          />
        </TwoColumnsGrid>
        <Conditional shouldRender={Boolean(submission?.witnessEmails.length)}>
          <TwoColumnsGrid>
            {submission?.witnessEmails.map((witness) => (
              <Input key={witness} label="Witness" disabled value={witness} />
            ))}
          </TwoColumnsGrid>
        </Conditional>
        <Conditional shouldRender={Boolean(submission)}>
          <CheckboxV2
            checked={submission?.isFirstRecord}
            label="This record is the first of its kind."
            icon={<WorkspacePremiumIcon className={styles.icon} />}
            disabled
          />
          <CheckboxV2
            checked={submission?.requireParentalSupervision}
            label="This record require proof of parental supervision."
            icon={<ReportProblemRoundedIcon className={styles.icon} />}
            disabled
          />
          <CheckboxV2
            checked={submission?.requireTimingDevice}
            label="A timing device must be visible in video submitted."
            icon={<ReportProblemRoundedIcon className={styles.icon} />}
            disabled
          />
          <CheckboxV2
            checked={submission?.noNewRecord}
            label="We are not accepting new submissions for this record."
            icon={<ReportProblemRoundedIcon className={styles.icon} />}
            disabled
          />
        </Conditional>
        {submission?.mediaLinks.map((link) => (
          <ImageOrVideo
            key={link}
            className="record-submission-details__image br3"
            alt=""
            src={link}
            controls
          />
        ))}
        <Button className="w5" ghost outline onClick={handleDownload}>
          Download Files
        </Button>
        <Conditional shouldRender={Boolean(submission?.picturedPersons.length)}>
          <SubTitle className="pt3 pb4">Pictured Persons:</SubTitle>
          {submission?.picturedPersons.map((person) => (
            <TwoColumnsGrid key={`${person.name}-${person.email}`}>
              <Input label="Name" disabled value={person.name} />
              <Input label="E-mail" disabled value={person.email} />
            </TwoColumnsGrid>
          ))}
        </Conditional>
      </BorderedContainer>
      <BreakRecordModal
        isOpen={breakModal}
        onCancel={() => setBreakModal(false)}
        onConfirm={handleBreakRecord}
        disabled={isBreaking}
      />
      {submission && (
        <ConfirmSubmissionReject
          isOpen={isRecordRejectBoxOpen}
          onCancel={() => setIsRecordRejectBoxOpen(false)}
          onConfirm={handleSubmissionRejection}
          submissionTitle={submission.idea}
          submissionId={submission.id}
          disabled={isLoading}
        />
      )}
      {submission && (
        <ConfirmSubmissionApproval
          isOpen={isSubmissionApprovedModalOpen}
          onCancel={() => setIsSubmissionApprovedModalOpen(false)}
          onConfirm={handleSubmissionApproval}
          submissionTitle={submission.idea}
          submissionId={submission.id}
          submissionDescription={submission?.description}
          disabled={isLoading}
        />
      )}
    </Page>
  )
}
