import React, { useEffect, FC } from 'react'
import { useCookies } from 'react-cookie'

import styles from './CookiePolicyPopUp.module.scss'
import useToggle from 'hooks/useToggle'
import Link from 'components/Link'
import IconButton from 'components/IconButton'
import { PopperModal } from 'components/PopperModal'

export const CookiePolicyPopUp: FC = () => {
  const [isPopUpVisible, setIsPopUpVisible] = useToggle()
  const [cookies, setCookie] = useCookies([`cookie_popup`])
  useEffect(() => {
    if (!cookies.cookie_popup) {
      setCookie(`cookie_popup`, 'true', {
        maxAge: 7 * 24 * 60 * 60,
      })
      setIsPopUpVisible()
    }
  }, [cookies.cookie_popup, setCookie, setIsPopUpVisible])

  useEffect(() => {
    if (isPopUpVisible) {
      const timeoutPopUp = setTimeout(() => {
        setIsPopUpVisible()
      }, 10000)
      return () => clearTimeout(timeoutPopUp)
    }
  }, [setIsPopUpVisible, isPopUpVisible])

  const onClose = () => {
    setIsPopUpVisible()
  }

  return (
    <PopperModal isPopUpVisible={isPopUpVisible}>
      <div className={styles.popUpBox}>
        <div className={styles.header}>
          This site uses limited cookies
          <div className={styles.closeBtn}>
            <IconButton onClick={() => onClose()} name="cancel" />
          </div>
        </div>
        <p className={styles.text}>
          We use Google, PayPal and Facebook cookies for technical purposes to
          understand and improve site performance. We do not use advertising
          cookies and we do not sell user data. You can find our Cookie Policy{' '}
          <Link
            onClick={() => onClose()}
            to="/terms-of-service"
            className={styles.link}
          >
            here
          </Link>
          .
        </p>
      </div>
    </PopperModal>
  )
}
