import React from 'react'
import ShadowModal from 'components/ShadowModal'
import LinkButton from 'components/LinkButton'
import Conditional from 'components/Conditional'
import styles from '../Quiz/Quiz.module.scss'

interface AuthorizationModalProps {
  isOpenModal: boolean
  isParent: boolean
  onClose: () => void
  onPlay: () => void
}

export const AuthorizationModal: React.FC<AuthorizationModalProps> = ({
  isOpenModal,
  isParent,
  onClose,
  onPlay,
}) => {
  return (
    <ShadowModal
      className={styles.modal}
      isModalOpen={isOpenModal}
      onCancel={onClose}
      withShadow
      noSize
    >
      <Conditional shouldRender={!isParent}>
        <div className={styles.modalContainer}>
          <div className={styles.header}>Register your attempt</div>
          <div className={styles.modalText}>
            If you don’t already have an account, ask your parent or guardian to
            register you. You can play without registering, but you won’t
            receive a Kids World Record if you win.
          </div>
          <div className={styles.buttonBox}>
            <LinkButton to="/login">Sign in</LinkButton>
            <button className={styles.underlinedLinkButton} onClick={onPlay}>
              I just want to play
            </button>
          </div>
          <div className={styles.bottomText}>
            Registering and getting a Kids World Record is completely free.
          </div>
        </div>
      </Conditional>
      <Conditional shouldRender={isParent}>
        <div className={styles.modalContainer}>
          <div className={styles.header1}>
            Switch to your child’s account to have your record attempt counted.
          </div>
          <div className={styles.modalText}>
            Anyone can play, but you have to be logged into a child account to
            play for a Kids World Record.
          </div>
          <div className={styles.buttonBox}>
            <button className={styles.underlinedLinkButton} onClick={onPlay}>
              I just want to play
            </button>
          </div>
          <div className={styles.bottomText1}>
            Registering and getting a Kids World Record is completely free.
          </div>
        </div>
      </Conditional>
    </ShadowModal>
  )
}
