import React from 'react'
import IconButton from 'components/IconButton'
import styles from './QuantitySelect.module.scss'

export type QuantitySelectProps = {
  value: number
  onChange(quantity: number): void
}

export const QuantitySelect: React.FC<QuantitySelectProps> = ({
  value,
  onChange,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.quantitySelect}>
        <IconButton
          name="remove"
          className={styles.iconRemove}
          disabled={value === 1}
          onClick={() => onChange(value - 1)}
        />
        <input className={styles.quantityCount} value={value} disabled={true} />
        <IconButton
          name="add"
          className={styles.iconAdd}
          onClick={() => onChange(value + 1)}
        />
      </div>
    </div>
  )
}
