import React from 'react'
import MuiButton from '@mui/material/Button'
import cn from 'classnames'

import './button.css'

export interface ButtonProps {
  className?: string
  fullWidth?: boolean
  disabled?: boolean
  href?: string
  target?: string
  endIcon?: React.ReactNode
  ghost?: boolean
  label?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  outline?: boolean
  size?: 'small' | 'medium' | 'large'
  startIcon?: React.ReactNode
  theme?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
  type?: 'button' | 'reset' | 'submit'
}

const Button: React.FC<ButtonProps> = ({
  fullWidth,
  children,
  className,
  disabled = false,
  endIcon,
  ghost = false,
  label,
  href,
  outline = false,
  onClick,
  size = 'large',
  startIcon,
  type,
  theme = 'primary',
}) => (
  <MuiButton
    className={cn(
      'button',
      `button--${theme}`,
      className,
      ghost && 'ghost',
      outline && 'outline',
    )}
    href={href}
    disabled={disabled}
    onClick={onClick}
    variant={ghost ? 'text' : 'contained'}
    type={type}
    size={size}
    endIcon={endIcon}
    startIcon={startIcon}
    aria-label={label}
    fullWidth={fullWidth}
  >
    {children}
  </MuiButton>
)

export default Button
