import React, { FC, FormEvent, useEffect, useState, useMemo } from 'react'
import { Box } from '@mui/material'
import dayjs from 'dayjs'

import { Event, SponsorProps } from 'store/api/types/Event.types'
import { Editor } from 'components/Editor'
import {
  Select,
  Input,
  DatePicker,
  TimePicker,
  FileInput,
  Checkbox,
} from 'components/Inputs'
import Button from 'components/Button'
import { SponsorSelect } from './SponsorSelect'
import { WhitelistedEmails } from './WhitelistedEmails'
import { ImagesPreview } from '../ImagesPreview'
import { FormError, UpdateEvent, WhiteList } from './types'

import { useGetSponsorsQuery } from '../../../store/api'
import { SponsorImage } from '../Sponsors/SponsorImage'
import styles from './CampaignForm.module.scss'

export type CampaignFormProps = {
  event?: Event
  errors?: FormError
  isLoading?: boolean
  onSubmit?(event: UpdateEvent): void
}

const TEMPLATES = [{ label: 'Template 1', value: 'TEMPLATE_1' }]

const combineDateTime = (date: Date, time: Date): string => {
  const hour = dayjs(time).hour()
  const minute = dayjs(time).minute()

  date.setHours(hour, minute)
  return date.toISOString()
}

const getSponsorListByIds = (
  sponsors: SponsorProps[],
  sponsorIds: string[],
): SponsorProps[] => {
  return sponsorIds
    .map((item) => sponsors.find((sponsor) => sponsor.id === item))
    .filter((item) => Boolean(item)) as SponsorProps[]
}

const removeSponsors = (sponsorIds: string[], sponsorId: string): string[] => {
  const filteredSponsors = sponsorIds.filter((item) => item !== sponsorId)
  return filteredSponsors.length === sponsorIds.length
    ? sponsorIds
    : filteredSponsors
}

export const CampaignForm: FC<CampaignFormProps> = ({
  event,
  isLoading,
  onSubmit,
  errors,
}) => {
  const { data: sponsors = [] } = useGetSponsorsQuery()

  const [descriptions, setDescriptions] = useState({
    description: event?.description,
    certificateDescription: event?.certificateDescription,
    comingSoonSplashPageDescription: event?.comingSoonSplashPageDescription,
    finishedSplashPageDescription: event?.finishedSplashPageDescription,
  })

  const [selectedSponsors, setSelectedSponsors] = useState<string[]>([])
  const [whitelist, setWhitelist] = useState<WhiteList>({
    isEnabled: false,
    emails: [],
  })

  const [dates, setDates] = useState({
    startTime: event?.startDate ? new Date(event?.startDate) : new Date(),
    startDate: event?.startDate ? new Date(event?.startDate) : new Date(),
    endTime: event?.endDate ? new Date(event?.endDate) : new Date(),
    endDate: event?.endDate ? new Date(event?.endDate) : new Date(),
  })

  const disabled = isLoading

  useEffect(() => {
    setDates({
      startTime: event?.startDate ? new Date(event?.startDate) : new Date(),
      startDate: event?.startDate ? new Date(event?.startDate) : new Date(),
      endTime: event?.endDate ? new Date(event?.endDate) : new Date(),
      endDate: event?.endDate ? new Date(event?.endDate) : new Date(),
    })
  }, [event])

  useEffect(() => {
    setSelectedSponsors(
      event?.sponsors ? event.sponsors.map((sponsor) => sponsor.id) : [],
    )
  }, [event?.sponsors])

  useEffect(() => {
    setWhitelist({
      isEnabled: !!event?.whitelistEnabled,
      emails: event?.whitelistedEmails || [],
    })
  }, [event?.whitelistedEmails, event?.whitelistEnabled])

  useEffect(() => {
    setDescriptions({
      description: event?.description,
      certificateDescription: event?.certificateDescription,
      comingSoonSplashPageDescription: event?.comingSoonSplashPageDescription,
      finishedSplashPageDescription: event?.finishedSplashPageDescription,
    })
  }, [
    event?.description,
    event?.comingSoonSplashPageDescription,
    event?.finishedSplashPageDescription,
    event?.certificateDescription,
  ])

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const target = e.currentTarget

    const links = []

    if (target.links_0_name.value || target.links_0_link.value) {
      links.push({
        name: target.links_0_name.value,
        url: target.links_0_link.value,
      })
    }

    if (target.links_1_name.value || target.links_1_link.value) {
      links.push({
        name: target.links_1_name.value,
        url: target.links_1_link.value,
      })
    }

    if (target.links_2_name.value || target.links_2_link.value) {
      links.push({
        name: target.links_2_name.value,
        url: target.links_2_link.value,
      })
    }

    if (onSubmit) {
      onSubmit({
        template: target.template.value,
        name: target.eventTitle.value,
        description: descriptions.description || '',
        medias: target.mailGraphic?.files,
        target: target.campaignTarget.value,
        partnerName: target.organisationName.value,
        links,
        isMediaUploadRequired: target.mediaRequired.checked,
        comingSoonSplashPageTitle: target.comingSoonSplashPageTitle.value,
        comingSoonSplashPageDescription:
          descriptions.comingSoonSplashPageDescription || '',
        comingSoonPageMedias: target.comingSoonPageMedias?.files,
        finishedSplashPageTitle: target.finishedSplashPageTitle.value,
        finishedSplashPageDescription:
          descriptions.finishedSplashPageDescription || '',
        finishedPageMedias: target.finishedPageMedias?.files,
        certificateTitle: target.certificateTitle.value,
        certificateDescription: descriptions.certificateDescription || '',
        startDate: combineDateTime(dates.startDate, dates.startTime),
        endDate: combineDateTime(dates.endDate, dates.endTime),
        sponsors: selectedSponsors,
        whitelistEnabled: whitelist.isEnabled,
        whitelistedEmails: whitelist.isEnabled
          ? target.whitelistedEmails.value
              .split('\n')
              .filter((item: string) => item !== '')
          : [],
      })
    }
  }

  const sponsorToDisplay = useMemo(
    () => getSponsorListByIds(sponsors, selectedSponsors),
    [selectedSponsors, sponsors],
  )

  return (
    <form className={styles.container} onSubmit={onFormSubmit}>
      <div className={styles.containerRow}>
        <div className={styles.column}>
          <Select
            name="template"
            label="Select Template"
            defaultValue={event?.template}
            items={TEMPLATES}
            disabled={disabled}
            errorText={errors?.template}
          />
          <FileInput
            name="mailGraphic"
            label="Main Graphic"
            disabled={disabled}
            errorText={errors?.medias}
          />
          <ImagesPreview images={event?.medias || []} />
          <Input
            name="organisationName"
            label="Organisation Name"
            disabled={disabled}
            defaultValue={event?.partnerName}
            errorText={errors?.partnerName}
          />
          <Input
            type="number"
            name="campaignTarget"
            label="Campaign Target"
            defaultValue={event?.target || 0}
            disabled={disabled}
            errorText={errors?.target}
          />
          <Checkbox
            key={'mediaRequired' + event?.isMediaUploadRequired}
            name="mediaRequired"
            label="Photo/Video Upload Required"
            defaultValue={Boolean(event?.isMediaUploadRequired)}
            disabled={disabled}
          />
          <div>
            <Box className={styles.contestLinkBox}>
              <h5>Contest Links:</h5>
              <div className={styles.inputRow}>
                <Input
                  name="links_0_name"
                  className={styles.contestInput}
                  label={'Name'}
                  defaultValue={event?.links[0]?.name}
                  disabled={disabled}
                  errorText={errors?.links && errors?.links[0]?.name}
                />
                <Input
                  name="links_0_link"
                  className={styles.contestInput}
                  label={'Web Link'}
                  defaultValue={event?.links[0]?.url}
                  disabled={disabled}
                  errorText={errors?.links && errors?.links[0]?.url}
                />
              </div>
              <div className={styles.inputRow}>
                <Input
                  name="links_1_name"
                  className={styles.contestInput}
                  label={'Name'}
                  defaultValue={event?.links[1]?.name}
                  disabled={disabled}
                  errorText={errors?.links && errors?.links[1]?.name}
                />
                <Input
                  name="links_1_link"
                  className={styles.contestInput}
                  label={'Web Link'}
                  defaultValue={event?.links[1]?.url}
                  disabled={disabled}
                  errorText={errors?.links && errors?.links[1]?.url}
                />
              </div>
              <div className={styles.inputRow}>
                <Input
                  name="links_2_name"
                  className={styles.contestInput}
                  label={'Name'}
                  defaultValue={event?.links[2]?.name}
                  disabled={disabled}
                  errorText={errors?.links && errors?.links[2]?.name}
                />
                <Input
                  name="links_2_link"
                  className={styles.contestInput}
                  label={'Web Link'}
                  defaultValue={event?.links[2]?.url}
                  disabled={disabled}
                  errorText={errors?.links && errors?.links[2]?.url}
                />
              </div>
            </Box>
          </div>
          <div className={styles.dateTime}>
            <DatePicker
              key={'data start' + dates?.startDate.toString()}
              label={'Campaign start'}
              defaultValue={dates?.startDate}
              onChange={(startDate) =>
                startDate && setDates({ ...dates, startDate })
              }
              errorText={errors?.startDate}
            />
            <TimePicker
              key={'time start' + dates?.startDate.toString()}
              label={'Start time'}
              ampm={false}
              defaultValue={dates?.startTime}
              onChange={(startTime) =>
                startTime && setDates({ ...dates, startTime })
              }
              errorText={errors?.startDate}
            />
          </div>
          <div className={styles.dateTime}>
            <DatePicker
              key={'date end' + dates?.endDate.toString()}
              label={'Campaign end'}
              minDate={new Date()}
              defaultValue={dates?.endDate}
              onChange={(endDate) => endDate && setDates({ ...dates, endDate })}
              errorText={errors?.endDate}
            />
            <TimePicker
              key={'time end' + dates?.endDate.toString()}
              label={'End time'}
              ampm={false}
              defaultValue={dates?.endTime}
              onChange={(endTime) => endTime && setDates({ ...dates, endTime })}
              errorText={errors?.endDate}
            />
          </div>
        </div>
        <div className={styles.column}>
          <Input
            name="eventTitle"
            label="Campaign Title/Name"
            defaultValue={event?.name}
            disabled={disabled}
            errorText={errors?.name}
          />
          <Editor
            header={'Description:'}
            data={event?.description}
            disabled={disabled}
            onChange={(event, editor) => {
              const data = editor.getData()
              setDescriptions({ ...descriptions, description: data })
            }}
            errorText={errors?.description}
          />
          <FileInput
            name="comingSoonPageMedias"
            label="Coming soon Splash page graphic"
            disabled={disabled}
            errorText={errors?.comingSoonPageMedias}
          />
          <ImagesPreview images={event?.comingSoonPageMedias || []} />
          <Input
            name="comingSoonSplashPageTitle"
            label="Coming soon Splash page title"
            defaultValue={event?.comingSoonSplashPageTitle}
            disabled={disabled}
            errorText={errors?.comingSoonSplashPageTitle}
          />
          <Editor
            header={'Coming soon Splash page description:'}
            disabled={disabled}
            data={event?.comingSoonSplashPageDescription}
            onChange={(event, editor) => {
              const data = editor.getData()
              setDescriptions({
                ...descriptions,
                comingSoonSplashPageDescription: data,
              })
            }}
            errorText={errors?.comingSoonSplashPageDescription}
          />
          <div className={styles.eventSponsors}>
            {sponsorToDisplay.map((sponsor) => (
              <SponsorImage
                key={sponsor.id}
                imageUrl={sponsor.imageUrl}
                onDelete={() =>
                  setSelectedSponsors(
                    removeSponsors(selectedSponsors, sponsor.id),
                  )
                }
              />
            ))}
          </div>
          <SponsorSelect
            selectedSponsorsIds={selectedSponsors}
            onSelect={(sponsor) =>
              setSelectedSponsors([...selectedSponsors, sponsor.id])
            }
          />
        </div>
        <div className={styles.column}>
          <Input
            name="certificateTitle"
            label={'Certificate Title'}
            defaultValue={event?.certificateTitle}
            disabled={disabled}
            errorText={errors?.certificateTitle}
          />
          <Editor
            header={'Certificate Description'}
            disabled={disabled}
            data={event?.certificateDescription}
            onChange={(event, editor) => {
              const data = editor.getData()
              setDescriptions({
                ...descriptions,
                certificateDescription: data,
              })
            }}
            errorText={errors?.certificateDescription}
          />
          <FileInput
            name="finishedPageMedias"
            label="Contest finished Splash page graphic"
            disabled={disabled}
            errorText={errors?.finishedPageMedias}
          />
          <ImagesPreview images={event?.finishedPageMedias || []} />
          <Input
            name="finishedSplashPageTitle"
            label="Finished Splash Page Title"
            defaultValue={event?.finishedSplashPageTitle}
            disabled={disabled}
            errorText={errors?.finishedSplashPageTitle}
          />
          <Editor
            header={'Contest finished Splash page description:'}
            disabled={disabled}
            data={event?.finishedSplashPageDescription}
            onChange={(event, editor) => {
              const data = editor.getData()
              setDescriptions({
                ...descriptions,
                finishedSplashPageDescription: data,
              })
            }}
            errorText={errors?.finishedSplashPageDescription}
          />
          <WhitelistedEmails
            whitelist={whitelist}
            onChange={setWhitelist}
            disabled={disabled}
            errorText={errors?.whitelistedEmails}
          />
        </div>
      </div>
      <Button className={styles.submitBtn} type="submit" disabled={disabled}>
        SUBMIT
      </Button>
    </form>
  )
}
