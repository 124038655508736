import React from 'react'
import cn from 'classnames'

import { IconName } from './IconName'

import {
  Add,
  ArrowBackIos,
  ArrowDropDown,
  ArrowDropUp,
  ArrowForwardIos,
  Cancel,
  Close,
  Done,
  Favorite,
  Help,
  KeyboardArrowLeft,
  Lock,
  Menu,
  MoreVert,
  Person,
  Remove,
  Edit,
  Search,
  ShoppingCart,
  SvgIconComponent,
  ViewList,
  ViewModule,
} from '@mui/icons-material'

import './icon.css'

const getIcon = (name: IconName): SvgIconComponent => {
  switch (name) {
    case 'add':
      return Add
    case 'edit':
      return Edit
    case 'arrow_down':
      return ArrowDropDown
    case 'arrow_left':
      return KeyboardArrowLeft
    case 'arrow_up':
      return ArrowDropUp
    case 'arrow_back':
      return ArrowBackIos
    case 'arrow_forward':
      return ArrowForwardIos
    case 'cancel':
      return Cancel
    case 'close':
      return Close
    case 'done':
      return Done
    case 'help':
      return Help
    case 'like':
      return Favorite
    case 'lock':
      return Lock
    case 'menu':
      return Menu
    case 'more':
      return MoreVert
    case 'person':
      return Person
    case 'remove':
      return Remove
    case 'search':
      return Search
    case 'shopping_cart':
      return ShoppingCart
    case 'view_list':
      return ViewList
    case 'view_module':
      return ViewModule
  }
}

interface Props {
  className?: string
  color?: 'white' | 'gray'
  disabled?: boolean
  name: IconName
  onClick?: () => void
  size?: 'small' | 'medium' | 'large'
}

const Icon: React.FC<Props> = ({
  className,
  color = 'gray',
  disabled = false,
  name,
  onClick,
  size = 'medium',
}) => {
  const MuiIcon = getIcon(name)

  return (
    <MuiIcon
      onClick={disabled ? undefined : onClick}
      className={cn('icon', `icon--${size} ${color}`, className)}
    />
  )
}

export default Icon
