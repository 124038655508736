import React, { FC } from 'react'
import { useParams } from 'react-router-dom'

import { useGetProductByIdQuery } from 'store/api'

import Loader from 'components/Loader'
import BackButton from 'components/BackButton'
import ImageWithPlaceHolder from 'components/ImageWithPlaceholder'
import { Page } from 'components/Page'
import EmptySection from 'components/EmptySection'
import Conditional from 'components/Conditional'

import { BuyProductForm } from './components/BuyProductForm'
import { useMyRole } from 'hooks/useMyRole'

import styles from './ProductDetails.module.scss'

type ProductDetailsParams = {
  id: string
}

type ProductDetailsProps = {
  childrenApprovedRecords: {
    childName: string
    records: {
      id: string
      title: string
      url: string
    }[]
  }[]
}

export const ProductDetails: FC<ProductDetailsProps> = ({
  childrenApprovedRecords,
}) => {
  const { id } = useParams<ProductDetailsParams>()
  const { data: product, isLoading: isFetching } = useGetProductByIdQuery(
    `gid://shopify/Product/${id}`,
  )

  const { isChild } = useMyRole()

  const shouldRender = !isFetching
  const showForm = !(
    product?.isCustom &&
    isChild &&
    !childrenApprovedRecords[0]?.records?.length
  )

  return (
    <Page>
      <Loader active={!shouldRender} className={styles.loader} />
      <Conditional shouldRender={shouldRender}>
        <div className={styles.backButton}>
          <BackButton backUrl="/shop">Back to KWR Store home</BackButton>
        </div>
        {product && (
          <div className={styles.main}>
            <ImageWithPlaceHolder
              src={product.previewImageUrl}
              alt={`Image representing the ${product.title}  product`}
              className="product-details__media"
              ariaHidden={true}
            />
            <Conditional shouldRender={!showForm}>
              <EmptySection message="You must break a record to buy this product." />
            </Conditional>
            <Conditional shouldRender={showForm}>
              <BuyProductForm
                product={product}
                childrenApprovedRecords={childrenApprovedRecords}
                isCustomProduct={product.isCustom}
              />
            </Conditional>
          </div>
        )}
      </Conditional>
    </Page>
  )
}
