export interface Product {
  id: string
  title: string
  description: string
  info: string
  price: number
  imageLinks: string[]
  variantIds: string[]
  isCustom: boolean
  variants?: [
    {
      title: string
    },
  ]
}

export interface ProductCheckout {
  product: Product
  childName: string
  submissionTitle: string
  submissionId: string
  submissionUrl: string
  quantity: number
}

export const getVariantTitleInfo = (product: any): string[] => {
  if (!product.variants || !product.variants[0].title.includes(';')) {
    return ['', '']
  }
  return product.variants[0].title.split(';')
}

const getProductTotal = (productCheckout: ProductCheckout): number =>
  productCheckout.product.price * productCheckout.quantity

const getSubTotal = (checkoutProducts: ProductCheckout[]): number =>
  checkoutProducts.reduce<number>((acc, item) => acc + getProductTotal(item), 0)

const exp = {
  getProductTotal,
  getSubTotal,
}

export default exp
