import React, { FC, ReactElement } from 'react'
import cn from 'classnames'
import { CircularProgress } from '@mui/material'
import Conditional from 'components/Conditional'
import styles from '../Quiz.module.scss'

export enum AnswerStatus {
  CORRECT = 'CORRECT',
  WRONG = 'WRONG',
  IN_PROGRESS = 'IN_PROGRESS',
}

interface AnswerProps {
  answerStatus: AnswerStatus
  value: string
  index: number
  onAnswer: (value: string) => void
  disabled: boolean
  selected: boolean
}

const COLORS = {
  [AnswerStatus.CORRECT]: 'correct',
  [AnswerStatus.WRONG]: 'wrong',
  [AnswerStatus.IN_PROGRESS]: 'inProgress',
}

const Answer = ({
  index,
  value,
  onAnswer,
  answerStatus,
  disabled,
  selected,
}: AnswerProps): ReactElement => {
  const colorStyle = styles[COLORS[answerStatus]]

  return (
    <div
      className={cn(styles.answer, colorStyle)}
      onClick={() => !disabled && onAnswer(value)}
    >
      <div className={cn(styles.letter)}>
        {String.fromCharCode('a'.charCodeAt(0) + index)})
      </div>
      <div className={styles.container}>
        {value}
        <Conditional
          shouldRender={selected && answerStatus === AnswerStatus.IN_PROGRESS}
        >
          <CircularProgress size={20} />
        </Conditional>
      </div>
    </div>
  )
}

interface QuestionProps {
  imgUrl: string
  answer: string
  answers: string[]
  onAnswer: (answer: string) => void
  correctAmount: number
  wrongAmount: number
  totalCount?: number
  answerStatus: AnswerStatus
  disabled: boolean
  showImageDescription?: boolean
}

export const Question: FC<QuestionProps> = ({
  imgUrl,
  answer,
  answers,
  answerStatus,
  correctAmount,
  wrongAmount,
  totalCount,
  onAnswer,
  disabled,
  showImageDescription,
}) => {
  return (
    <div className={styles.question}>
      <div className={styles.questionImageDiv}>
        <div className={styles.questionImage}>
          <img src={imgUrl} alt={'questionImage'} />
        </div>
        <Conditional shouldRender={showImageDescription || false}>
          <p>
            <strong>Image Credit:</strong> Artwork by Nobumichi Tamura. Learn
            more about the artist on his{` `}
            <a
              href=" https://en.wikipedia.org/wiki/Nobu_Tamura"
              target="_blank"
              rel="noreferrer"
            >
              Wikipedia page
            </a>
            .
          </p>
        </Conditional>
      </div>
      <div className={styles.answersBlock}>
        <div className={styles.questionCounters1}>
          <div className={styles.counterCorrect}>Correct: {correctAmount}</div>
          <div className={styles.counterWrong}>Wrong: {wrongAmount}</div>
        </div>
        <div className={cn(styles.answers, 'nunito-extra-bold')}>
          {answers.map((value, index) => (
            <Answer
              key={value + index.toString()}
              index={index}
              value={value}
              answerStatus={
                value === answer ? answerStatus : AnswerStatus.IN_PROGRESS
              }
              onAnswer={onAnswer}
              disabled={disabled}
              selected={value === answer}
            />
          ))}
        </div>

        <div className={styles.questionCounters2}>
          <div className={styles.counterCorrect}>Correct: {correctAmount}</div>
          <div className={styles.counterWrong}>Wrong: {wrongAmount}</div>
          {totalCount && (
            <div className={styles.counterRemaining}>
              Remaining: {totalCount - (wrongAmount + correctAmount)}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
