import React from 'react'
import cn from 'classnames'

import Thumbnail from 'components/Thumbnail'

interface ThumbnailsProps {
  alt?: string
  className?: string
  imageLinks: string[]
  onClick?: (index: number) => void
  selectedIndex?: number
}
const Thumbnails: React.FC<ThumbnailsProps> = ({
  alt,
  className,
  imageLinks,
  onClick,
  selectedIndex,
}) => (
  <section className={cn('flex flex-row', className)}>
    {imageLinks.map((imageLink: string, index) => (
      <Thumbnail
        alt={alt}
        isSelected={index === selectedIndex}
        key={imageLink}
        onClick={() => onClick && onClick(index)}
        src={imageLink}
      />
    ))}
  </section>
)

export default Thumbnails
