import { AccountRole, UserProfile } from 'store/api/types/Auth.types'
import { AdminProfile } from './AdminProfile'
import { ChildProfile } from './ChildProfile'
import { ParentProfile } from './ParentProfile'
import { GenderV2 } from 'store/api/types/Gender.types'

export interface Profile {
  __typename: 'ParentProfile' | 'ChildProfile' | 'AdminProfile'
  dateOfBirth: string
  email: string | null
  firstName: string
  id: string
  lastName: string
  tokens: string[]
}

export type ProfileAvatarType = 'parent' | 'boy' | 'girl' | 'gender_x' | 'admin'

export interface ProfileErrors {
  dateOfBirth?: string[]
  email?: string[]
  firstName?: string[]
  lastName?: string[]
  location?: {
    city?: string[]
    country?: string[]
    state?: string[]
  }
  pin?: string[]
}

const getAvatar = (profile: Profile): ProfileAvatarType => {
  if (isChildProfile(profile)) {
    return getChildAvatar(profile)
  } else if (isAdminProfile(profile)) {
    return 'admin'
  } else {
    return 'parent'
  }
}

const getChildAvatar = (profile: ChildProfile): ProfileAvatarType =>
  profile.gender === 'female'
    ? 'girl'
    : profile.gender === 'male'
      ? 'boy'
      : 'gender_x'

const getFullName = (profile?: Profile | null): string =>
  `${profile?.firstName} ${profile?.lastName}`

const isAdminProfile = (profile?: Profile): profile is AdminProfile =>
  profile?.__typename === 'AdminProfile'

const isChildProfile = (profile?: Profile): profile is ChildProfile =>
  profile?.__typename === 'ChildProfile'

const isParentProfile = (profile?: Profile): profile is ParentProfile =>
  profile?.__typename === 'ParentProfile'
const isAdminProfileV2 = (role?: AccountRole) => role === AccountRole.ADMIN
const isChildProfileV2 = (role?: AccountRole) => role === AccountRole.CHILD
const isParentProfileV2 = (role?: AccountRole) => role === AccountRole.PARENT

const ChangeToLegacyProfile = (
  profile: UserProfile | null | undefined,
): (Profile & { gender: GenderV2 }) | null => {
  if (!profile) return null
  let profileType = 'ChildProfile' as Profile['__typename']
  if (profile.role === AccountRole.ADMIN) profileType = 'AdminProfile'
  if (profile.role === AccountRole.PARENT) profileType = 'ParentProfile'

  return {
    __typename: profileType,
    dateOfBirth: profile.dateOfBirth,
    email: profile.email,
    firstName: profile.firstName,
    id: profile.id,
    lastName: profile.lastName,
    tokens: [] as string[],
    gender: profile.gender,
  }
}

const exp = {
  getAvatar,
  getChildAvatar,
  getFullName,
  isAdminProfile,
  isChildProfile,
  isParentProfile,
  isAdminProfileV2,
  isChildProfileV2,
  isParentProfileV2,
  ChangeToLegacyProfile,
}

export default exp
