import React, { FC, useCallback, useRef, useState } from 'react'

import Button from 'components/Button'
import { KwrMediaHeader } from 'components/KwrMediaHeader/KwrMediaHeader'
import BorderedContainer from 'components/BorderedContainer'
import Loader from 'components/Loader'
import Conditional from 'components/Conditional'
import { PageWrapper } from 'components/PageWrapper'
import { useForm, useFieldArray } from 'react-hook-form'
import { FormInput } from 'components/Form/FormInput'
import { FormAccordion } from './components/FormAccordion'

import {
  MainPageHeaderContent,
  MediaItem,
  MediaType,
} from 'store/api/types/MainPageHeaderContent'
import { useGetHeadContentQuery, useUpdateHeadContentMutation } from 'store/api'
import { uploadHeadContentFile } from 'utils/file.utils'

import styles from './EditMainPage.module.scss'

const ACCEPTED_FILE_FORMATS = '.mp4,.png,.jpeg,.gif'

export type EditableMediaItem = MediaItem & {
  file?: File
  fileUrl?: string
}

type EditContentProps = {
  isLoading: boolean
  defaultValue?: MainPageHeaderContent<EditableMediaItem>
  onSave(value: MainPageHeaderContent<EditableMediaItem>): void
}

const DEFAULT_VALUE: MainPageHeaderContent<EditableMediaItem> = {
  title: '',
  description: '',
  medias: [
    {
      time: 0,
      type: MediaType.IMAGE,
      onClickUrl: '',
      isExternal: false,
    },
  ],
}

export const EditContent: FC<EditContentProps> = ({
  isLoading,
  defaultValue = DEFAULT_VALUE,
  onSave,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { control, handleSubmit, setValue, reset, watch } = useForm<
    MainPageHeaderContent<EditableMediaItem>
  >({
    mode: 'all',
    defaultValues: defaultValue,
  })
  const { fields, swap, remove, append } = useFieldArray({
    control,
    name: 'medias',
  })
  const watchedfield = watch()

  const onResetClick = useCallback(() => {
    reset(defaultValue)
  }, [defaultValue, reset])

  const onFileSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event?.currentTarget?.files?.[0]
      const idx = parseInt(event.currentTarget.name)

      if (file) {
        const fileUrl = URL.createObjectURL(file)
        const medias = [...fields]
        medias[idx].file = file
        medias[idx].fileUrl = fileUrl
        medias[idx].type =
          (file.type.split('/')[1] || '') === 'mp4'
            ? MediaType.VIDEO
            : MediaType.IMAGE
        medias[idx].mediaUrl = undefined
        setValue(`medias.${idx}`, medias[idx])
      }
    },
    [fields, setValue],
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <KwrMediaHeader
        title={watchedfield.title}
        description={watchedfield.description}
        medias={watchedfield.medias}
      />
      <input
        ref={inputRef}
        type="file"
        hidden
        accept={ACCEPTED_FILE_FORMATS}
        onChange={onFileSelect}
      />
      <BorderedContainer>
        <form onSubmit={handleSubmit(onSave)}>
          <FormInput label="Title" name="title" control={control} />
          <FormInput label="Description" name="description" control={control} />
          <div className={styles.confirmButtons}>
            <Button
              fullWidth
              type="button"
              onClick={() => {
                append({
                  time: 10,
                  onClickUrl: ' ',
                  type: MediaType.IMAGE,
                  isExternal: false,
                })
              }}
            >
              Add new tile
            </Button>
          </div>
          <FormAccordion
            setValue={setValue}
            isLoading={isLoading}
            swap={swap}
            remove={remove}
            inputRef={inputRef}
            name="medias"
            control={control}
            fields={fields}
          />

          <div className={styles.confirmButtons}>
            <Button
              fullWidth
              theme="secondary"
              disabled={isLoading}
              onClick={onResetClick}
              type="reset"
            >
              Reset
            </Button>
            <Button
              fullWidth
              theme="secondary"
              disabled={isLoading}
              onClick={() => onSave(watchedfield)}
              type="submit"
            >
              Apply
            </Button>
          </div>
        </form>
      </BorderedContainer>
    </div>
  )
}

export const EditMainPage = () => {
  const [updateHead] = useUpdateHeadContentMutation()
  const { data, isLoading } = useGetHeadContentQuery()
  const [isProcessing, setIsProcessing] = useState(false)

  const updateHeadContentHandler = useCallback(
    async (data: MainPageHeaderContent<EditableMediaItem>) => {
      setIsProcessing(true)
      const medias = data.medias

      for (let item of medias) {
        if (item.file) {
          const format = item.file.type.split('/')[1] || ''
          item.mediaUrl = await uploadHeadContentFile(item.file)
          item.type = format === 'mp4' ? MediaType.VIDEO : MediaType.IMAGE
        }
      }

      await updateHead({
        title: data.title,
        description: data.description,
        medias: data.medias.map((item) => ({
          time: item.time,
          isExternal: item.isExternal,
          mediaUrl: item.mediaUrl,
          onClickUrl: item.onClickUrl,
          type: item.type,
        })),
      })

      setIsProcessing(false)
    },
    [updateHead],
  )

  return (
    <PageWrapper>
      <div
        className="w-100 h-100 center pv3 ph3 ph5-m ph5-l"
        style={{ maxWidth: '74.4rem' }}
      >
        <Loader
          active={isLoading}
          style={{
            top: '50%',
            left: 'calc(50% - 30px)',
          }}
          className="z-999"
        />
        <Conditional shouldRender={!isLoading}>
          <EditContent
            defaultValue={data}
            onSave={updateHeadContentHandler}
            isLoading={isProcessing}
          />
        </Conditional>
      </div>
    </PageWrapper>
  )
}
