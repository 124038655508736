import React, { FC } from 'react'
import { Select } from 'components/Inputs'
import styles from '../BuyProductForm.module.scss'

type ChildRecordSelectProps = {
  value: { childName: string; submissionId: any }
  onChange(childName: string, submissionId: any): void
  childrenApprovedRecords: {
    childName: string
    records: {
      id: string
      title: string
    }[]
  }[]
}

export const ChildRecordSelect: FC<ChildRecordSelectProps> = ({
  value,
  onChange,
  childrenApprovedRecords,
}) => {
  const childOptions = childrenApprovedRecords.map((child) => ({
    label: child.childName,
    value: child.childName,
  }))
  const selectedChildRecords =
    childrenApprovedRecords.find((item) => item.childName === value?.childName)
      ?.records || []

  const recordOptions = selectedChildRecords.map((record) => ({
    value: record.id,
    label: record.title,
  }))

  return (
    <div className={styles.select}>
      <Select
        name="childName"
        value={value?.childName}
        items={childOptions}
        onChange={(value) => onChange(value, '')}
        label="Child Profile"
      />
      <Select
        name="submissionId"
        items={recordOptions}
        value={value?.submissionId}
        onChange={(e) => onChange(value.childName, e)}
        label="Record Broken"
        disabled={!recordOptions.length}
      />
    </div>
  )
}
