import React, { useEffect, useState, useCallback } from 'react'
import axios, { AxiosProgressEvent } from 'axios'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import { formatDate } from 'utils/datetime'

import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'

import { CONFIG } from '../../config'
import { useLazyGetAmbassadorQuery } from '../../store/api'

import Categories, { Category } from 'data/Category'
import useToggle from 'hooks/useToggle'

import Button from 'components/Button'
import CheckBox from 'components/CheckBox'
import Form from 'components/Form'
import InputText from 'components/InputText'
import { DatePicker as NewDatePicker } from 'components/Inputs/DatePicker'
import People from 'components/People'
import { Person } from 'typings/RecordSubmissionFormData'
import Conditional from 'components/Conditional'
import { NewFileUpload } from 'components/MediaUpload'
import Select from 'components/Select'
import SubmitButton from 'components/SubmitButton'
import { showToaster } from 'store/app/actions'
import Locations from '../../data/Location'

import { ValidRecordDetailsFormData } from 'typings/RecordSubmissionFormData'

import './record-details.css'
import { isEmailValid } from 'utils/validators'
import { RecordDescription } from 'components/RecordDescription'
import { sortLinksImagesFirst } from 'utils/url'

import { Child } from '../../store/api/types/Child.types'
import { RecordFunFacts } from 'components/RecordFunFacts'
import { LocationAutocomplete } from '../../components/Inputs'
import { ErrorAgeGapModal } from './ErrorAgeGapModal'

interface RecordDetailsProps {
  childProfile: Child | null
  isRecordHolder: boolean
  onBack: () => void
  onSubmit: (data: ValidRecordDetailsFormData) => void
  record?: {
    title: string
    category: string
  }
  state: ValidRecordDetailsFormData | null
  ideaTitle?: string
}

const CATEGORIES = Categories.getCategoryOptions([
  'most',
  'smallest',
  'largest',
  'longest',
  'highest',
  'fastest',
  'endurance',
  'collections',
  'other',
])

interface RecordDetailsLocation {
  city: string
  state: string
  country: string
}

interface RecordDetailsEntity {
  category: Category | null
  title: string
  description: string
  brokenOn: Date
  location: RecordDetailsLocation | null
  picturedPersons: Person[]
  media: File[]
  funFacts: string
}

interface PersonEntityErrors {
  name?: string
  email?: string
}

interface RecordDetailsEntityErrors {
  isValid: boolean
  category?: string
  title?: string
  description?: string
  brokenOn?: string
  location?: string
  picturedPersons: Array<PersonEntityErrors>
  media?: string
  funFacts?: string
}

const validateMedia = (media: File[]): string | null => {
  if (!media?.length) return 'At least one image is required'

  const isImageExist =
    media.findIndex((file) => file.type.indexOf('image/') === 0) >= 0

  if (!isImageExist) return 'At least one image is required'

  return null
}

const validatePerson = (
  persons: Person[],
): { personErrors: Array<PersonEntityErrors>; isValid: boolean } => {
  if (!persons.length) return { isValid: true, personErrors: [] }
  let isValid = true

  const personErrors = persons.map((person) => {
    const personEntityErrors: PersonEntityErrors = {
      name: '',
      email: '',
    }

    if (!person.name) {
      personEntityErrors.name = 'Person Name is required'
      isValid = false
    }
    if (!person.email || !isEmailValid(person.email)) {
      personEntityErrors.email = 'Person Email should be valid'
      isValid = false
    }

    return personEntityErrors
  })

  return { personErrors, isValid }
}

const validateLocation = (
  location: RecordDetailsLocation | null,
): string | null => {
  if (!location || !location.country || !location.city)
    return "Country, city are required. If you're from a city that functions as a prefecture or special administrative region then please enter a more specific location within your city to continue."
  return null
}

const validate = (data: RecordDetailsEntity): RecordDetailsEntityErrors => {
  const errors: RecordDetailsEntityErrors = {
    picturedPersons: [],
    isValid: true,
  }

  const mediaValidateIssue = validateMedia(data.media)
  const personValidationIssue = validatePerson(data.picturedPersons)
  const locationValidationIssue = validateLocation(data.location)

  if (!data.category) {
    errors.category = 'Record Category is required'
    errors.isValid = false
  }
  if (!data.title) {
    errors.title = 'Record Name is required'
    errors.isValid = false
  }
  if (!data.description) {
    errors.description = 'Record Description is required'
    errors.isValid = false
  }
  if (!data.brokenOn) {
    errors.brokenOn = 'Date of record broken is required'
    errors.isValid = false
  }
  if (!data.funFacts) {
    errors.funFacts = 'Fun fact(s) is required'
    errors.isValid = false
  }
  if (mediaValidateIssue) {
    errors.media = mediaValidateIssue
    errors.isValid = false
  }
  if (!personValidationIssue.isValid) {
    errors.picturedPersons = personValidationIssue.personErrors
    errors.isValid = false
  }
  if (locationValidationIssue) {
    errors.location = locationValidationIssue
    errors.isValid = false
  }

  return errors
}

const generateUploadLink = async (format: string): Promise<string> => {
  const { data } = await axios.get<{ url: string }>(
    `/api/media/uploadUrl?format=${format}`,
  )
  return data.url
}

const uploadFile = async (
  linkToUpload: string,
  file: File,
  onUploadProgress?: (progress: AxiosProgressEvent) => void,
): Promise<void> => {
  await axios.put(`${linkToUpload}`, file, {
    headers: { 'Content-Type': file.type, 'content-disposition': 'attachment' },
    onUploadProgress,
  })
}

const FUN_FACTS_PLACEHOLDER = `We'd also love to get to know our Kids Word Record holders and highlight them even more. Please tell us 1-3 Fun Facts that may be included in the book, website or social media. For example how long they've been doing their record-breaking activity or sport? Whats's their favourite things to do? What they want to be when they grow up? Any dreams, aspirations or other records they would love to set.`

const RecordDetails: React.FC<RecordDetailsProps> = ({
  childProfile,
  isRecordHolder,
  onBack,
  onSubmit,
  record,
  state,
  ideaTitle,
}) => {
  const [showCodeInput, setShowCodeInput] = useState(false)
  const [code, setCode] = useState('')
  const [ambassadorError, setAmbassadorError] = useState('')
  const [showErrorAgeGap, setShowErrorAgeGap] = useState(false)
  const [getAmbassador, { data: ambassador, isFetching, isError }] =
    useLazyGetAmbassadorQuery()
  const [type, setType] = useState<'FREE' | 'TEMPLATE'>('TEMPLATE')
  const [personPictured, setPersonPictured] = useToggle(false)

  const [errors, setErrors] = useState<RecordDetailsEntityErrors>({
    picturedPersons: [],
    isValid: true,
  })
  const [uploadingText, setUploadingText] = useState('')
  const dispatch = useDispatch()
  // const location =
  //   (loggedProfile &&
  //     Profiles.isChildProfile(loggedProfile) &&
  //     loggedProfile.location) ||
  //     undefined

  const [recordDetails, setRecordDetails] = useState<RecordDetailsEntity>({
    category: (record?.category as Category) || state?.category || null,
    title: record?.title || state?.title || '',
    description: state?.description || '',
    brokenOn: state?.brokenOn || new Date(),
    location: state?.location || null,
    picturedPersons: state?.picturedPersons || [],
    media: state?.media || [],
    funFacts: state?.funFacts || '',
  })

  useEffect(() => {
    isError && code
      ? setAmbassadorError('There is no ambassador with such code')
      : setAmbassadorError('')
  }, [isError, ambassadorError, code])

  useEffect(() => {
    if (!showCodeInput) {
      setCode('')
    }
  }, [showCodeInput])

  const onSubmitForm = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault()

    const recordSize = e.target?.recordSizeField?.value || ''
    const recordDescriptionField = e.target?.recordDescriptionField?.value || ''

    const ambassadorId = ambassador?.id
    const funFacts = e.target?.funFacts?.value || ''
    const genderAndAge = `${childProfile?.gender}s' ${childProfile?.ageGap}`
    const city = recordDetails?.location?.city || childProfile?.location?.city
    const state = recordDetails?.location
      ? recordDetails?.location?.state || ''
      : childProfile?.location?.state
    const country =
      recordDetails?.location?.country || childProfile?.location?.country
    const formattedLocation = `${
      state ? `${city} - ${state}` : `${city}`
    }, ${country}`
    const formattedDate = new Date(e.target.brokenOn.value)

    if (childProfile) {
      const formattedDateOfBirth =
        new Date(childProfile.dateOfBirth).getTime() +
        childProfile.location.timezoneInMiliseconds
      const dateOfBirth = dayjs(formattedDateOfBirth)
      const dateRecord = dayjs(formattedDate.getTime())
      dayjs.extend(isLeapYear)
      const fullYears = dateRecord.diff(dateOfBirth, 'year')
      const days =
        (dateRecord.isLeapYear() ? 366 : 365) -
        dateRecord.diff(dateOfBirth.add(fullYears, 'year'), 'day')

      let ageGapBoundaries = childProfile?.ageGap.split('-')
      let leftAgeGap = parseInt(
        ageGapBoundaries ? ageGapBoundaries[0] : '4',
        10,
      )
      if (fullYears - leftAgeGap < 0 && days > 14) {
        return setShowErrorAgeGap(true)
      }
    }
    const description =
      type === 'FREE'
        ? e.target.description.value
        : `The ${recordDescriptionField} in the ${genderAndAge} year old Division is ${recordSize} and was completed by ${childProfile?.firstName} in ${formattedLocation} on ${formatDate(formattedDate, 'MMMM DD, YYYY')}.`

    const isDescription =
      type === 'FREE'
        ? e.target.description.value
        : recordSize && recordDescriptionField

    const isFunFacts = e.target.funFacts.value && funFacts

    const validationErrors = validate({
      ...recordDetails,
      description: isDescription ? description : '',
      funFacts: isFunFacts ? funFacts : '',
    })

    if (!validationErrors.isValid) {
      return setErrors(validationErrors)
    }

    if (!recordDetails.location) {
      return
    }

    if (ambassadorError) {
      return
    }

    setUploadingText('Uploading Media')

    const mediaFiles = recordDetails.media.sort((a, b) => {
      const aType = a.type.indexOf('image/')
      const bType = b.type.indexOf('image/')

      return aType - bType
    })

    const imageLinks: string[] = []

    try {
      for (let file of mediaFiles) {
        const uploadLink = await generateUploadLink(file.type.split('/')[1])
        await uploadFile(uploadLink, file, (uploadProgress) => {
          setUploadingText(
            `Uploading ${file.name} (${(
              (uploadProgress.loaded * 100) /
              (uploadProgress?.total || 1)
            ).toFixed(2)}%)`,
          )
        })

        imageLinks.push(uploadLink.split('?')[0])
      }
    } catch (e) {
      dispatch(
        showToaster({
          message: `Error uploading your file, please try again in a few moments.`,
          color: 'red',
        }),
      )
      setUploadingText('')
      return
    }

    onSubmit({
      ...recordDetails,
      description: description,
      imageLinks: sortLinksImagesFirst(imageLinks),
      media: mediaFiles,
      funFacts: funFacts,
      brokenOn: formattedDate,
      location: recordDetails.location || { city: '', state: '', country: '' },
      category: recordDetails.category as Category,
      ambassadorId: ambassadorId,
    })
    setUploadingText('')
  }

  const onChange = <T,>(name: string, value: T) => {
    setErrors({ ...errors, [name]: undefined })
    setRecordDetails({ ...recordDetails, [name]: value })
  }

  const setLocation = useCallback((value) => {
    const { timezoneInMiliseconds, ...rest } = value
    setErrors((errs) => ({ ...errs, location: undefined }))
    setRecordDetails((details) => ({ ...details, location: rest }))
  }, [])

  const onBlur = (code: string) => {
    if (code) {
      getAmbassador(code)
      setCode(code)
    } else {
      setCode('')
    }
  }

  return (
    <Form onSubmit={onSubmitForm}>
      <Select
        error={!!errors?.category}
        helperText={errors?.category}
        value={recordDetails.category}
        onChange={(e) => onChange('category', e)}
        items={CATEGORIES}
        label="Record Category"
        name="category"
        disabled={!!record}
      />
      <InputText
        error={!!errors?.title}
        helperText={errors?.title}
        label="Record Name"
        name="title"
        value={recordDetails.title}
        onChange={(value) => onChange('title', value)}
        disabled={!!record}
      />
      <RecordDescription
        type={type}
        errorMessage={errors.description}
        location={recordDetails.location}
        brokenDate={recordDetails.brokenOn}
        onSwitchType={(type) => setType(type)}
      />
      <RecordFunFacts
        errorMessage={errors?.funFacts}
        value={recordDetails.funFacts}
        onChange={(value) => onChange('funFacts', value)}
      >
        {FUN_FACTS_PLACEHOLDER}
      </RecordFunFacts>
      <NewDatePicker
        name="brokenOn"
        errorText={errors.brokenOn}
        defaultValue={new Date()}
        label="Date record was broken"
        onChange={(value) => onChange('brokenOn', value)}
      />
      <LocationAutocomplete
        name="location"
        label="Where was the record broken?"
        errorText={errors?.location}
        defaultValue={Locations.getFullAddress(state?.location)}
        onSelectLocation={setLocation}
      />
      <Conditional shouldRender={CONFIG.FEATURE_324}>
        <Conditional shouldRender={isRecordHolder}>
          <label className="red pl2 pb2">
            <b>Ambassador code not available to existing record holders</b>
          </label>
        </Conditional>
        <Conditional shouldRender={!isRecordHolder}>
          <Button
            className={'codeButton'}
            ghost
            theme="secondary"
            onClick={() => setShowCodeInput(!showCodeInput)}
          >
            I have an Ambassador Code
          </Button>
        </Conditional>
        <Conditional shouldRender={showCodeInput}>
          <div className="codeRaw">
            <InputText
              className="codeInput"
              error={isError && !!code}
              helperText={ambassadorError}
              label="Ambassador Code"
              name="code"
              endAdornment={
                <Conditional shouldRender={isFetching}>
                  <InputAdornment position="end">
                    <CircularProgress size={20} />
                  </InputAdornment>
                </Conditional>
              }
              onBlur={(e) => onBlur(e.target.value)}
              disabled={isFetching}
            />
            <label>
              <b>Entering an Ambassador code gets you expedited review.</b>
            </label>
          </div>
        </Conditional>
      </Conditional>
      <label className="pl2">
        <p className="submit-include">Submission must include:</p>
        <ul className="pointer_ul">
          <li key={'unedited_video'}>
            Unedited video(s) that verify the Kids World Record attempt.
          </li>
          <li key={'submissions_with'}>
            Submissions with edited video will be rejected.
          </li>
          <li key={' high_resolution '}>
            High resolution photo(s) on the participant(s) for the Kids World
            Record Book and Website.
          </li>
        </ul>
      </label>
      <div
        className="flex flex-row justify-center items-center mt5-l"
        style={{ marginTop: '2rem' }}
      >
        <NewFileUpload
          name="media"
          files={recordDetails.media}
          loadingText={uploadingText}
          setValue={onChange}
          error={errors?.media}
          maxFiles={5}
        />
      </div>
      <div className="flex flex-row pt4" style={{ alignItems: 'end' }}>
        <CheckBox
          onChange={() => {
            setPersonPictured()
            if (!personPictured) {
              onChange('picturedPersons', [])
            }
          }}
        />
        <div className="flex flex-column">
          <div className="openSans-regular dark-gray pt2 fw7">
            Is there anyone else pictured in the images or video you provided?
          </div>
          <div className="pt2 dark-gray" style={{ fontSize: '12px' }}>
            Please ensure you have permission to post pictures of everyone in
            the video and pictures that are submitted with the attempt. If
            anyone is pictured please also provide their name and e-mail.
          </div>
        </div>
      </div>
      <Conditional shouldRender={personPictured}>
        <div className="pt5">
          <People
            name="picturedPersons"
            error={''}
            setValue={onChange}
            errors={errors.picturedPersons}
          />
        </div>
      </Conditional>
      <div className="flex h-100 flex-column items-center justify-end justify-start-l mt4 mt5-l">
        <SubmitButton className="w-100 w-40-l" disabled={!!uploadingText}>
          {uploadingText ? 'Uploading Media' : 'Next'}
        </SubmitButton>
      </div>
      <div className="flex h-100 flex-column items-center justify-end justify-start-l mt4">
        <Button onClick={onBack} className="w-100 w-40-l outline ghost mt4">
          Back
        </Button>
      </div>
      <ErrorAgeGapModal
        showErrorAgeGap={showErrorAgeGap}
        onCancel={() => setShowErrorAgeGap(false)}
      />
    </Form>
  )
}

export default RecordDetails
