import React, { FC } from 'react'
import { ShieldLevel } from '../../components/ShieldLevel'
import { SelectedShield } from '../../components/SelectedShield'
import Conditional from '../../components/Conditional'
import styles from './AmbassadorProfile.module.scss'

const levels = [
  {
    level: 1,
    perk: 'Level badge on your record image and page',
    howToUnlock: 'Refer 1 successful record breaker',
  },
  {
    level: 2,
    perk: 'Updated badge and have your name added to the Ambassador page',
    howToUnlock: 'Refer 2 successful record breakers',
  },
  {
    level: 3,
    perk: 'Updated badge and get sent a physical Ambassador ID card in the mail',
    howToUnlock: 'Refer 5 successful record breakers',
  },
  {
    level: 4,
    perk: 'Updated badge and get sent a physical Ambassador badge in the mail',
    howToUnlock: 'Refer 10 successful record breakers',
  },
  {
    level: 5,
    perk: 'Updated badge and get sent a KWR polo shirt in the mail',
    howToUnlock: 'Refer 15 successful record breakers',
  },
]

type LevelTableProps = {
  currentLevel: number
}

export const LevelTable: FC<LevelTableProps> = ({ currentLevel }) => {
  return (
    <div className={styles.table}>
      <div className={styles.background_line}></div>
      {levels.map((level) => (
        <div className={styles.row}>
          <div className={styles.left_cell}>
            {level.level === currentLevel ? (
              <SelectedShield level={currentLevel} />
            ) : (
              <div className={styles.shield_container}>
                <img
                  className={styles.ellipse}
                  src="/assets/ellipse.svg"
                  alt="circle"
                />
                <ShieldLevel className={styles.shield} level={level.level} />
              </div>
            )}
          </div>
          <div className={styles.right_cell}>
            <Conditional shouldRender={level.level === currentLevel}>
              <div>
                <p className={styles.minor_title}>
                  Congratulations!
                  <br />
                  You’re a Level {currentLevel} Ambassador.
                </p>
                <p className={styles.text}>
                  Continue referring friends to break world records to level up!
                </p>
              </div>
            </Conditional>
            <p>
              <strong>Level {level.level} Perk:</strong> {level.perk}{' '}
              {level.level === 2 && (
                <span style={{ color: 'var(--pure-red)' }}>(Coming soon)</span>
              )}
            </p>
            <p>
              <strong>To Unlock:</strong> {level.howToUnlock}
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}
