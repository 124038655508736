import React from 'react'

import { MediaCarousel } from 'components/MediaCarousel'

import {
  MainPageHeaderContent,
  MediaItem,
} from 'store/api/types/MainPageHeaderContent'

import styles from './KwrMediaHeader.module.scss'

type KwrMediaHeaderProps = MainPageHeaderContent<MediaItem> & {}

export const KwrMediaHeader = ({
  title,
  description,
  medias,
}: KwrMediaHeaderProps) => {
  return (
    <div className={styles.main}>
      <div className={styles.text}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.description}>{description}</p>
      </div>
      <MediaCarousel className={styles.carousel} medias={medias} />
    </div>
  )
}
