import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

import styles from './UserAccordion.module.scss'

type User = {
  id: string
  firstName: string
  lastName: string
  email: string
  amountChildren: number
}
interface UserAccordionProps {
  user: User
}

export const UserAccordion: React.FC<UserAccordionProps> = ({ user }) => {
  return (
    <Accordion className={styles.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={user.id}
        id={user.id}
        className={styles.accordionSummary}
      >
        <div className={styles.accordionTitle}>
          {user.firstName} {user.lastName}
          <br />
          {user.email}
        </div>
      </AccordionSummary>
      <AccordionDetails></AccordionDetails>
    </Accordion>
  )
}
