import React, { useEffect, useRef, useState } from 'react'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { SponsorImage } from './SponsorImage'
import { SponsorProps } from '../../../store/api/types/Event.types'
import ShadowModal from '../../../components/ShadowModal'
import Button from '../../../components/Button'
import { Input } from '../../../components/Inputs'
import SubmitButton from '../../../components/SubmitButton'
import Form from '../../../components/Form'
import styles from './SponsorList.module.scss'

export type NewSponsorProps = Omit<SponsorProps, 'id' | 'imageUrl'> & {
  id?: string
  file: File
}

export type SelectedSponsorProps = Omit<SponsorProps, 'id'> & {
  id?: string
}

interface SponsorModalProps {
  sponsor: SelectedSponsorProps | null
  onClose: () => void
  isLoading: boolean
  onSave: (newSponsor: NewSponsorProps) => void
}

export const SponsorModal: React.FC<SponsorModalProps> = ({
  sponsor,
  onClose,
  isLoading,
  onSave,
}) => {
  const [currentImage, setCurrentImage] = useState<string | undefined>('')
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setCurrentImage(sponsor?.imageUrl)
  }, [sponsor])

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const nameInputValue = e.target.name.value
    const siteUrlInputValue = e.target.siteUrl.value
    const file = e.target.sponsorImage.files?.[0]

    if (nameInputValue && siteUrlInputValue && file) {
      onSave({
        id: sponsor?.id,
        name: nameInputValue,
        siteUrl: siteUrlInputValue,
        file: file,
      })
    }
  }

  const onChangeImage = () => {
    fileInputRef?.current?.click()
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0]
    if (selectedFile) {
      const fileUrl = URL.createObjectURL(selectedFile)
      setCurrentImage(fileUrl)
    }
  }

  const onCloseModal = () => {
    setCurrentImage('')
    onClose()
  }

  return (
    <ShadowModal isModalOpen={Boolean(sponsor)} onCancel={onCloseModal} noSize>
      <IconButton
        className={styles.closeBtn}
        aria-label="close"
        onClick={onClose}
      >
        <Close />
      </IconButton>
      <Form onSubmit={handleSubmit}>
        <div className={styles.input_fields}>
          <div className={styles.imageContainer}>
            <SponsorImage imageUrl={currentImage} onClick={onChangeImage} />
            {currentImage ? (
              <IconButton
                className={styles.deleteBtn}
                aria-label="delete"
                onClick={() => setCurrentImage('')}
              >
                <Close />
              </IconButton>
            ) : null}
          </div>
          <input
            type="file"
            name="sponsorImage"
            accept="image/*"
            style={{ display: 'none' }}
            ref={fileInputRef}
            disabled={isLoading}
            onChange={handleFileChange}
          />
          <Input
            disabled={isLoading}
            name="name"
            label="Sponsor Name"
            defaultValue={sponsor?.name}
          />
          <Input
            disabled={isLoading}
            name="siteUrl"
            label="Site URL"
            defaultValue={sponsor?.siteUrl}
          />
        </div>
        <div className={styles.buttons}>
          <Button disabled={isLoading} ghost onClick={onCloseModal}>
            Cancel
          </Button>
          <SubmitButton disabled={isLoading}>
            {sponsor?.id ? 'Update' : 'Save'}
          </SubmitButton>
        </div>
      </Form>
    </ShadowModal>
  )
}
