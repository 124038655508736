import React, { Fragment, ReactElement, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useUpdatePinMutation } from '../../store/api'
import EditPin, { Pin } from 'scenes/Pin/EditPin'
import ShadowModal from 'components/ShadowModal'
import SubmitButton from 'components/SubmitButton'

import styles from './EditPin.module.scss'
function ResetPin(): ReactElement | null {
  const url = new URLSearchParams(useLocation().search)
  const navigate = useNavigate()
  const token = url.get('resetToken')

  const [updatePin] = useUpdatePinMutation()
  const [isModal, setIsModal] = useState(false)

  const resetPin = async (number: Pin) => {
    if (token) {
      try {
        await updatePin({
          token: token,
          pin: `${number.pin[0]}${number.pin[1]}${number.pin[2]}${number.pin[3]}`,
        })
        setIsModal(true)
      } catch (e) {
        console.error(e)
      }
    }
  }

  return (
    <Fragment>
      <EditPin handleSubmit={resetPin} isMasked />
      <ShadowModal isModalOpen={isModal}>
        <div className={styles.modalBody}>
          <span>Your PIN successfully reset</span>
          <SubmitButton
            className={styles.modalBtn}
            onClick={() => {
              setIsModal(false)
              navigate('/')
            }}
          >
            Ok
          </SubmitButton>
        </div>
      </ShadowModal>
    </Fragment>
  )
}

export default ResetPin
