import Joi from 'joi'

export type CmsPageFormProps = {
  content: string
}
export const CMS_CONTENT_VALIDATOR = Joi.object({
  content: Joi.string().required().messages({
    'string.empty': `This field cannot be empty`,
    'any.required': `This is a required field`,
  }),
})
