import React, { FC, Fragment } from 'react'

import SubTitle from 'components/SubTitle'
import Button from 'components/Button'
import LinkButton from 'components/LinkButton'

interface RuleStepProps {
  idea?: string
  onSubmit(): void
}

export const RuleStep: FC<RuleStepProps> = ({ idea, onSubmit }) => {
  return (
    <Fragment>
      <SubTitle className="neo-red f3-m f3-l">{idea}</SubTitle>
      <div className="rules__header-wrapper nunito-regular dark-gray">
        Rules:
      </div>
      <ul className="rules__items-wrapper flex flex-column justify-between list dark-gray h5">
        <li>
          1. You broke a record?! Woohoo! Fill out the application for your
          record.
        </li>
        <li>
          2. Prove it! Submit the necessary evidence to prove you broke the
          record.
        </li>
        <li>
          3. Sit back, and relax! Wait for Kids World Records to review your
          application.
        </li>
        <li>
          4. You’ve got mail! If your record is approved, your certificate will
          be sent to the email address you provided.
        </li>
        <li>
          5. Visit our online store to buy the book, printed or framed
          certificates, and medals!
        </li>
      </ul>
      <div className="flex h-100 mv3 justify-center">
        <div
          className="flex flex-column justify-between w-100 mw5-m mw5-l"
          style={{ height: '100px' }}
        >
          <Button className="w-100" onClick={onSubmit}>
            I agree
          </Button>
          <LinkButton to="/" ghost className="w-100">
            I don't agree
          </LinkButton>
        </div>
      </div>
    </Fragment>
  )
}
