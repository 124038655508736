import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import RemoveIcon from '@mui/icons-material/Remove'

import BorderedContainer from 'components/BorderedContainer'
import { Page } from 'components/Page'
import SubmitButton from 'components/SubmitButton'
import { FormCountryAutocompleteShortName } from 'components/Form/FormCountryAutocompleteShortName'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { PAID_COUNTRIES_VALIDATOR } from './PaidCountriesPage.utils'
import { FormInput } from 'components/Form/FormInput'
import { Edit } from '@mui/icons-material'
import { Input } from 'components/Inputs'
import Button from 'components/Button'
import {
  useCreatePaymentForCountryMutation,
  useDeletePaymentForCountryMutation,
  useGetPaymentForAllCountriesQuery,
  useUpdatePaymentForCountryMutation,
} from 'store/api'

import styles from './PaidCountriesPage.module.scss'

interface ReviewFormData {
  countryName: string
  payment: number
}

export const PaidCountriesPage: React.FC = () => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ReviewFormData>({
    resolver: joiResolver(PAID_COUNTRIES_VALIDATOR),
    mode: 'all',
    defaultValues: { countryName: '', payment: 0 },
  })
  const {
    data: paymentForCountries,
    isLoading,
    isFetching,
  } = useGetPaymentForAllCountriesQuery()
  const [createPaymentForCountry] = useCreatePaymentForCountryMutation()
  const [updatePaymentForCountry] = useUpdatePaymentForCountryMutation()
  const [deletePaymentForCountry] = useDeletePaymentForCountryMutation()
  const [isEditing, setIsEditing] = useState(false)
  const [editingCountry, setEditingCountry] = useState<ReviewFormData>({
    countryName: '',
    payment: 0,
  })

  const submitHandler = async (data: ReviewFormData) => {
    createPaymentForCountry({
      countryName: data.countryName,
      payment: parseFloat(data.payment.toFixed(2)) * 100,
    })
    reset()
  }
  const removeExistedIdea = (name: string) => {
    deletePaymentForCountry(name)
  }
  const onSave = () => {
    updatePaymentForCountry({
      countryName: editingCountry.countryName,
      payment: Math.round(editingCountry.payment * 100),
    })
    setIsEditing(false)
  }
  const onCancel = () => {
    setIsEditing(false)
  }
  const editExistedIdea = (country: ReviewFormData) => {
    setIsEditing(true)
    setEditingCountry({
      countryName: country.countryName,
      payment: parseFloat((country.payment / 100).toFixed(2)),
    })
  }

  const onPaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPayment = parseFloat(event.target.value)
    setEditingCountry((prevValue) => ({
      ...prevValue,
      payment: newPayment,
    }))
  }
  return (
    <Page>
      <BorderedContainer>
        <div className={styles.ideas_container}>
          <div>
            <p className={styles.title}>Add New Country Form</p>
            <form onSubmit={handleSubmit(submitHandler)}>
              <FormCountryAutocompleteShortName
                control={control}
                name="countryName"
                label="Country"
              />
              <FormInput
                disabled={isEditing}
                type="number"
                control={control}
                name="payment"
                label="Payment"
                errorText={errors.payment?.message}
              />
              <SubmitButton disabled={isEditing} fullWidth>
                Add New Country
              </SubmitButton>
            </form>

            {isEditing && (
              <div>
                <p className={styles.title}>
                  Editing Country:{' '}
                  <span className={styles.spanMoney}>
                    {editingCountry.countryName}
                  </span>
                </p>
                <Input
                  type="number"
                  name="editPayment"
                  label="Edit Payment"
                  value={String(editingCountry.payment)}
                  onChange={onPaymentChange}
                />
                <div className={styles.btnDiv}>
                  <Button className={styles.btn} onClick={onSave}>
                    Save
                  </Button>
                  <Button className={styles.btn} onClick={onCancel}>
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className={styles.divider} />
          <div className={styles.existed_list}>
            <p className={styles.title}>List of Paid Countries</p>
            <div className={styles.ideasList}>
              {(isLoading || isFetching) && (
                <p className={styles.no_idea}>Loading payment for countries</p>
              )}
              {!!paymentForCountries && !paymentForCountries.length && (
                <p className={styles.no_idea}>
                  Currently no payment for country
                </p>
              )}
              <ul>
                {!isFetching &&
                  !!paymentForCountries &&
                  paymentForCountries.map((country) => (
                    <li
                      className={styles.row}
                      key={`array${country.countryName}`}
                    >
                      <p className={styles.title}>
                        {country.countryName}: ${' '}
                        <span className={styles.spanMoney}>
                          {(country.payment / 100).toFixed(2)}
                        </span>
                      </p>
                      <div>
                        <IconButton
                          disabled={isEditing}
                          color="primary"
                          component="span"
                          onClick={() => editExistedIdea(country)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          disabled={isEditing}
                          color="primary"
                          component="span"
                          onClick={() => removeExistedIdea(country.countryName)}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </BorderedContainer>
    </Page>
  )
}
