import React from 'react'

import Link from 'components/Link'

function AccountLinks(): JSX.Element {
  return (
    <div className="flex-l justify-end items-center dn">
      <Link className="ph3 nunito-bold dark-gray" to="/login">
        Login
      </Link>
      <Link
        style={{ whiteSpace: 'nowrap' }}
        className="ph3 nunito-bold dark-gray"
        to="/signup"
      >
        Sign Up
      </Link>
    </div>
  )
}

export default AccountLinks
