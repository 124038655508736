import React, { FC } from 'react'

import { Controller } from 'react-hook-form'

import { PreviousRecordInfo } from './PreviousRecordInfo'

interface FormRecordMediasProps {
  name: string
  control: any
  currentRecordGender: string
  currentRecordAge: string
  currentRecordCategory?: string
  disabled?: boolean
}

export const FormPreviousRecordInfo: FC<FormRecordMediasProps> = ({
  name,
  control,
  currentRecordGender,
  currentRecordAge,
  currentRecordCategory,
  disabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <PreviousRecordInfo
          id={field.value}
          currentRecordGender={currentRecordGender}
          currentRecordAge={currentRecordAge}
          currentRecordCategory={currentRecordCategory}
          changePreviousRecord={(id) =>
            field.onChange({ target: { value: id } })
          }
          disabled={disabled}
        />
      )}
    />
  )
}
