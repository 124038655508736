import { CONFIG } from 'config'
interface MenuLink {
  url: string
  text: string
}

export const HEADER: MenuLink[] = [
  { url: '/', text: 'Browse Records' },
  { url: '/how-to-break-a-record', text: 'Record Guide' },
  { url: '/break-a-record', text: 'Submit' },
  { url: '/quizzes', text: 'Quizzes' },
  { url: '/record-ideas', text: 'Record Ideas' },
  { url: '/events', text: 'Group Events' },
  { url: '/shop', text: 'Store' },
]

export const FOOTER: MenuLink[] = [
  { url: '/educators', text: 'Educators' },
  { url: '/contact-us', text: 'Contact Us' },
  { url: '/FAQ', text: 'FAQ' },
  { url: '/about-us', text: 'About Us' },
  { url: '/privacy-policy', text: 'Privacy Policy' },
  { url: '/terms-of-service', text: 'Terms Of Service' },
]

if (CONFIG.FEATURE_324) {
  FOOTER.push({ url: '/ambassador-program', text: 'Ambassador Program' })
}

if (CONFIG.FEATURE_513) {
  FOOTER.push({ url: '/world-map', text: 'World Map' })
}
