import React, {
  FC,
  useMemo,
  useState,
  useCallback,
  FormEvent,
  FocusEvent,
  memo,
} from 'react'

import { Category } from 'store/api/types/Record.types'
import { getItemsForSelect } from 'utils/records.utils'
import {
  Select,
  Input,
  DatePicker,
  LocationAutocomplete,
} from 'components/Inputs'
import { RecordDescription } from 'components/RecordDescription'
import SubmitButton from 'components/SubmitButton'
import Conditional from 'components/Conditional'
import { Location } from 'data/Location'

import { PicturedPersonCheckBox } from './PicturedPersonCheckBox'
import { RecordMedias } from './RecordMedias'
import { PicturedPersons } from './PicturedPersons'
import {
  buildSubmissionByForm,
  FormError,
  validateSubmission,
} from './BreakRecord.utils'

const ITEMS = getItemsForSelect(Category)

interface RecordDetailsStepProps {
  idea?: string
  category?: Category
  onNext(): void
}

const FUN_FACTS_PLACEHOLDER = `We'd also love to get to know our Kids Word Record holders and highlight them even more. Please tell us three Fun Facts that may be included in the book, website or social media. For example how long they've been doing their record-breaking activity or sport? Whats's their favourite things to do? What they want to be when they grow up? Any dreams, aspirations or other records they would love to set.`

const SubmissionRequirementsComponent = () => (
  <label>
    <p className="submit-include">Submission must include:</p>
    <ul className="pointer_ul">
      <li key={'unedited_video'}>
        Unedited video(s) that verify the Kids World Record attempt.
      </li>
      <li key={'submissions_with'}>
        Submissions with edited video will be rejected.
      </li>
      <li key={'high_resolution'}>
        High resolution photo(s) on the participant(s) for the Kids World Record
        Book and Website.
      </li>
    </ul>
  </label>
)

const SubmissionRequirements = memo(SubmissionRequirementsComponent)

export const RecordDetailsStep: FC<RecordDetailsStepProps> = ({
  idea,
  category,
  onNext,
}) => {
  const [type, setType] = useState<'FREE' | 'TEMPLATE'>('TEMPLATE')
  const [medias, setMedias] = useState<File[]>([])
  const [isPicturedPerson, setIsPicturedPerson] = useState(false)
  const [picturedPeopleIds, setPicturedPeopleIds] = useState<string[]>([])
  const [location, setLocation] = useState<Location | null>(null)
  const [errors, setErrors] = useState<FormError | null>()

  const onSubmitForm = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const submission = buildSubmissionByForm(
        e.target as any,
        type,
        medias,
        picturedPeopleIds,
        location,
      )
      const validationIssue = validateSubmission(submission)
      setErrors(validationIssue as any)
    },
    [location, medias, picturedPeopleIds, type],
  )

  const clearError = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setErrors({ ...errors, [e.target.name]: '' })
    },
    [errors],
  )

  const urls = useMemo(() => {
    return medias.map((item) => URL.createObjectURL(item))
  }, [medias])

  return (
    <form onSubmit={onSubmitForm}>
      <Select
        items={ITEMS}
        label="Record Category"
        onFocus={clearError}
        name="category"
        defaultValue={category}
        disabled={Boolean(category)}
      />
      <Input
        label="Record Name"
        name="idea"
        onFocus={clearError}
        errorText={errors?.idea}
        defaultValue={idea}
        disabled={Boolean(idea)}
      />
      <RecordDescription
        type={type}
        onSwitchType={(type) => setType(type)}
        errorMessage={errors?.description}
      />
      <Input
        name="funFacts"
        errorText={errors?.funFacts}
        onFocus={clearError}
        multiline={true}
        placeholder={FUN_FACTS_PLACEHOLDER}
        minRows={4}
      />
      <DatePicker
        label="Date Record was broken"
        name="brokenOn"
        onFocus={clearError}
        fullWidth
        defaultValue={new Date()}
      />
      <LocationAutocomplete
        label="Where was the record broken?"
        name="location"
        onFocus={clearError}
        defaultValue={'Methuen, MA, USA'}
        onSelectLocation={setLocation}
      />
      <SubmissionRequirements />
      <RecordMedias
        medias={urls}
        onAddNewMedia={(files) =>
          setMedias((curMedias) => [...curMedias, ...files])
        }
      />
      <PicturedPersonCheckBox
        value={isPicturedPerson}
        onClick={() => setIsPicturedPerson(!isPicturedPerson)}
      />
      <Conditional shouldRender={isPicturedPerson}>
        <PicturedPersons
          onPersonListChanged={(ids) => setPicturedPeopleIds(ids)}
        />
      </Conditional>
      <SubmitButton>Next</SubmitButton>
    </form>
  )
}
