import React, { FC, useState } from 'react'
import AccordionSummary from '@mui/material/AccordionSummary'
import DownloadIcon from '@mui/icons-material/Download'
import { Accordion } from '@mui/material'

import { EventParticipant } from 'store/api/types/Event.types'

import { EventParticipantCertificate } from './EventParticipantCertificate'
import styles from './EventCertificates.module.scss'

type EventCertificateItemProps = {
  participant: EventParticipant
  token: string
}

export const EventCertificateItem: FC<EventCertificateItemProps> = ({
  participant,
  token,
}) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Accordion
      className={styles.accordion}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        expandIcon={!expanded && <DownloadIcon />}
        id={participant.id}
      >
        {participant.kidsFirstName + ' ' + participant.kidsLastName}
      </AccordionSummary>
      {expanded && (
        <EventParticipantCertificate
          participantId={participant.id}
          token={token}
        />
      )}
    </Accordion>
  )
}
