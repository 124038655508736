import { UserProfile } from 'store/api/types/Auth.types'
import { RecordType } from 'store/api/types/Record.types'

export const buildRecordOwnerInfo = (record: any) => {
  const recordOwnerInfo =
    record.type === RecordType.SINGLE
      ? `${record?.gender?.replace('_', ' ')} - ${record?.ageGap} YEARS OLD`
      : `${record.category} GROUP CATEGORY`
  return `${recordOwnerInfo} - ${record?.location?.city}, ${
    record?.location?.state === 'N/A' ? '' : record?.location?.state
  } (${record?.location?.country})`
}

export const getIsRestricted = (record: any, profile?: UserProfile | null) => {
  const age =
    new Date(
      new Date().getTime() - new Date(profile?.dateOfBirth || 0).getTime(),
    ).getFullYear() - 1970
  const ageBracket = record.ageGap.split('-').map((i: string) => parseInt(i))
  return (
    age < ageBracket[0] ||
    age > ageBracket[1] ||
    profile?.gender !== record.gender.toLowerCase()
  )
}
