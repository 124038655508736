import Joi from 'joi'

import { Location } from 'data/Location'
import { buildValidator } from '../../../utils/validators'

export interface Person {
  id: string
  name: string
  email: string
}

export type FormTarget = {
  idea: { value: string }
  category: { value: string }
  funFacts: { value: string }
  brokenOn: { value: string }
  description: { value: string }
  recordDescriptionField: { value: string }
  recordSizeField: { value: string }
}

export type FormError = Partial<{
  idea: string
  category: string
  description: string
  funFacts: string
  brokenOn: string
  location: string
  medias: string
  picturedPerson: string[]
}>

const validationRule: Joi.ObjectSchema = Joi.object({
  idea: Joi.string().required().messages({
    'string.empty': 'is not allowed to be empty',
  }),
  category: Joi.string().required(),
  description: Joi.string(),
  templatedDescription: Joi.object({
    description: Joi.string().required(),
    size: Joi.string().required(),
  }),
  funFacts: Joi.string(),
  location: Joi.object({
    country: Joi.string().required(),
    state: Joi.string(),
    city: Joi.string().required(),
  }).required(),
  medias: Joi.array().min(1),
  picturedPeoples: Joi.array().items({
    id: Joi.string(),
    name: Joi.string().required(),
    email: Joi.string().required(),
  }),
})

export const buildPersonsFromForm = (
  ids: string[],
  target: Record<string, { value: string }>,
): Person[] => {
  return ids.map((id) => ({
    id,
    name: target[`name-${id}`].value,
    email: target[`email-${id}`].value,
  }))
}

export const buildSubmissionByForm = (
  data: FormTarget,
  descriptionType: 'FREE' | 'TEMPLATE',
  medias: File[],
  peopleFormIds: string[],
  location: Location | null,
): Partial<any> => {
  return {
    idea: data.idea.value,
    category: data.category.value,
    funFacts: data.funFacts.value,
    brokenOn: new Date(data.brokenOn.value),
    description:
      descriptionType === 'FREE' ? data.description.value : undefined,
    templatedDescription:
      descriptionType === 'TEMPLATE'
        ? {
            description: data.recordDescriptionField.value,
            size: data.recordSizeField.value,
          }
        : undefined,
    location: location || undefined,
    medias,
    picturedPeoples: buildPersonsFromForm(peopleFormIds, data as any),
  }
}

export const validateSubmission = buildValidator(validationRule)
