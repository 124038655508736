import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BorderedContainer from 'components/BorderedContainer'
import ShadowModal from 'components/ShadowModal'

import SubmitButton from 'components/SubmitButton'
import SubTitle from 'components/SubTitle'
import Title from 'components/Title'
import { Page } from 'components/Page'

import { useSendContactUsEmailMutation } from 'store/api'

import { joiResolver } from '@hookform/resolvers/joi'
import { CONTACT_VALIDATOR, ContactUsFormData } from './ContactUs.utils'
import { useForm } from 'react-hook-form'
import { FormInput } from 'components/Form/FormInput'

import styles from './ContactUs.module.scss'

const addContact = (text: string, name: string, email: string): string =>
  `${text} \nfrom ${name} - ${email}`

export const ContactUs: React.FC = () => {
  const [sendContactUsEmail, { isLoading }] = useSendContactUsEmailMutation()
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactUsFormData>({
    resolver: joiResolver(CONTACT_VALIDATOR),
    mode: 'onSubmit',
  })
  const submitHandler = async (data: ContactUsFormData) => {
    await sendContactUsEmail({
      body: addContact(data.text, data.name, data.email),
      from: data.email,
      subject: `[Contact Us] ${data.subject}`,
    })
    reset()
    setShowModal(true)
  }

  return (
    <Page>
      <BorderedContainer>
        <Title>Contact Us</Title>

        <SubTitle className={styles.subTitle}>
          We want to hear from you! We appreciate your thoughts and suggestions.
        </SubTitle>

        <div className={styles.formDiv}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className={styles.inputRow}>
              <div className={styles.name}>
                <FormInput
                  name="name"
                  label="Name"
                  control={control}
                  errorText={errors.name?.message}
                />
              </div>
              <div className={styles.email}>
                <FormInput
                  name="email"
                  label="E-mail"
                  control={control}
                  errorText={errors.email?.message}
                />
              </div>
            </div>
            <FormInput
              name="subject"
              label="Subject"
              control={control}
              errorText={errors.subject?.message}
            />
            <FormInput
              name="text"
              label="Enter Your Text Here"
              control={control}
              errorText={errors.text?.message}
              minRows={4}
              multiline
            />

            <div className={styles.submitButton}>
              <SubmitButton disabled={isLoading}>SEND E-MAIL</SubmitButton>
            </div>
          </form>
        </div>
        <ShadowModal isModalOpen={showModal}>
          <div className={styles.modal}>
            <h3 className={styles.textModal}>
              Your contact form successfully sent
            </h3>
            <SubmitButton onClick={() => navigate('/')}>OK</SubmitButton>
          </div>
        </ShadowModal>
      </BorderedContainer>
    </Page>
  )
}
