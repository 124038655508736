import React from 'react'
import cn from 'classnames'

interface TitleProps {
  className?: string
}

const Title: React.FC<TitleProps> = ({ children, className = 'pb4' }) => (
  <h1 className={cn('f2-m f2-l ma0 indigo-blue nunito-extra-bold', className)}>
    {children}
  </h1>
)

export default Title
