import React, { useRef } from 'react'

import Button from 'components/Button'
import Image from 'components/Image'
import SubTitle from 'components/SubTitle'
import Tooltip from 'components/Tooltip'
import Conditional from 'components/Conditional'

import { safeSplice } from 'utils/arrays'

import styles from './MediaUpload.module.scss'

const MediaUploadTooltip: React.FC = () => (
  <Tooltip right>
    <div className="record-details__media-tooltip">
      <SubTitle className="f5 pb3">
        Tips to upload the images and videos.
      </SubTitle>
      <ul className={styles.dash_ul}>
        <li key={'minimum_one'}>
          Minimum one (1) good resolution image is required.
        </li>
        <li key={'include_a_good'}>
          Include a good portrait photo of your child.
        </li>
        <li key={'and_a_picture'}>
          And a picture of your child attempting a record.
        </li>
        <li key={'the_file'}>The file size must be less than 10GB.</li>
      </ul>
    </div>
  </Tooltip>
)

export type MediaUploadProps = {
  buttonLabel?: string
  files: File[]
  loadingText: string
  error?: string
  name: string
  maxFiles?: number
  tooltip?: any
  setValue(name: string, media: File[]): void
}

const MediaUpload: React.FC<MediaUploadProps> = ({
  buttonLabel,
  files,
  error,
  name,
  loadingText,
  maxFiles = 3,
  tooltip,
  setValue,
}) => {
  const isAtMaxFiles = files.length === maxFiles

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const filesFromInput = event?.currentTarget?.files || []

    if (
      filesFromInput.length &&
      files.length + filesFromInput?.length <= maxFiles
    ) {
      setValue(name, [...files, ...Array.from(filesFromInput)])
    }
  }

  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const handleClick = () => {
    fileInputRef?.current?.click()
  }

  return (
    <div className="flex flex-column">
      <div className="flex flex-row justify-center items-center">
        <Button
          ghost
          disabled={isAtMaxFiles}
          theme={isAtMaxFiles ? 'tertiary' : 'primary'}
          onClick={handleClick}
          className="w-100 mw5"
          outline
        >
          {buttonLabel || 'Upload Videos & Pictures'}
        </Button>
        {error && (
          <p className="f7 neo-red" style={{ textAlign: 'center' }}>
            {error}
          </p>
        )}
        <input
          type="file"
          id="myFile"
          name={name}
          accept="image/*,video/*"
          onChange={handleFileInputChange}
          ref={fileInputRef}
          hidden
          multiple
        />
        <Conditional shouldRender={!!tooltip}>{tooltip}</Conditional>
        <Conditional shouldRender={!tooltip}>
          <MediaUploadTooltip />
        </Conditional>
      </div>
      <div>
        {!!files.length && (
          <div className={`w-100 ${styles.mediaUpload}`}>
            {files.map((media, i) => (
              <Image
                file={media}
                alt={media.name}
                isBlurred={!!loadingText}
                key={media.name + i}
                onRemove={() => {
                  setValue(name, safeSplice(files, i, 1))
                }}
              />
            ))}
            <Conditional shouldRender={!!loadingText}>
              <div className={styles.uploadProgress}>
                <div className={styles.background} />
                <p className={styles.text}>{loadingText}</p>
              </div>
            </Conditional>
          </div>
        )}
      </div>
    </div>
  )
}

export default MediaUpload
