import React, { useRef, ReactElement, useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'

import {
  useLazyGetIdeasLegacyQuery,
  useCreateIdeasLegacyMutation,
  useDeleteIdeaLegacyMutation,
} from 'store/api'

import BorderedContainer from 'components/BorderedContainer'
import { Page } from 'components/Page'
import Button from 'components/Button'
import InputText from 'components/InputText'

import './IdeaManager.css'

function IdeaManager(): ReactElement {
  const fileInput = useRef<HTMLInputElement>(null)
  const ideaInput = useRef<HTMLInputElement>(null)
  const [newIdeas, setNewIdea] = useState<Array<string>>([])
  const [existedIdeas, setExistedIdeas] = useState<Array<string>>([])
  const [deleting, setDeleting] = useState(false)
  const [trigger] = useLazyGetIdeasLegacyQuery()
  const [deleteIdea] = useDeleteIdeaLegacyMutation()
  const [createIdea] = useCreateIdeasLegacyMutation()

  useEffect(() => {
    trigger()
      .unwrap()
      .then((ideas) => setExistedIdeas(ideas.map((item: any) => item.name)))
  }, [trigger])

  const showFile = async () => {
    const reader = new FileReader()

    reader.onload = (e) => {
      const content = reader.result
      const ideasArray = `${content}`
        ?.split(/\r?\n/)
        .map((i) => i.trim())
        .filter((i) => i && i.length)

      setNewIdea(ideasArray)
    }

    if (fileInput?.current?.files)
      reader.readAsText(fileInput?.current?.files[0])
  }

  const addExtraIdea = () => {
    const newIdea = ideaInput?.current?.value

    if (newIdea && ideaInput?.current?.value) {
      setNewIdea([...newIdeas, newIdea])
      ideaInput.current.value = ''
    }
  }

  const removeIdea = (index: number) => {
    const copyIdea = [...newIdeas]
    copyIdea.splice(index, 1)

    setNewIdea(copyIdea)
  }

  const removeExistedIdea = (name: string) => {
    setDeleting(true)
    deleteIdea({ idea: name })
      .unwrap()
      .then(() => {
        trigger()
          .unwrap()
          .then((ideas) => setExistedIdeas(ideas.map((item: any) => item.name)))
      })
      .finally(() => setDeleting(false))
  }

  const uploadIdeas = () => {
    createIdea({ ideas: newIdeas })
      .unwrap()
      .then(() => {
        setExistedIdeas([...newIdeas, ...existedIdeas])
        setNewIdea([])
      })
  }

  return (
    <Page>
      <BorderedContainer>
        <div className="ideas-container">
          <div className="padding">
            <Button onClick={() => fileInput?.current?.click()}>
              Select file with ideas
              <input
                type="file"
                style={{ display: 'none' }}
                ref={fileInput}
                onChange={showFile}
              />
            </Button>
            <div className="ideasList">
              <div className="row">
                <InputText label="Add extra Idea" inputRef={ideaInput} />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  className="add-icon"
                  onClick={addExtraIdea}
                >
                  <AddIcon />
                </IconButton>
              </div>
              {!newIdeas.length && (
                <p className={'no-idea'}>Currently no idea</p>
              )}
              <ul>
                {newIdeas.map((idea, index) => (
                  <li className="row" key={`array${idea}${index}`}>
                    <p className="title">{idea}</p>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={() => removeIdea(index)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </li>
                ))}
              </ul>
              <Button
                className="upload-btn"
                onClick={uploadIdeas}
                disabled={deleting}
              >
                Upload
              </Button>
            </div>
          </div>
          <div className="divider" />
          <div className="existed-list padding">
            <p className="title">Existed ideas</p>
            <div className="ideasList">
              {!existedIdeas.length && (
                <p className="no-idea">Currently no idea</p>
              )}
              <ul>
                {existedIdeas.map((idea, index) => (
                  <li className="row" key={`array${idea}${index}`}>
                    <p className="title">{idea}</p>
                    <IconButton
                      disabled={deleting}
                      color="primary"
                      component="span"
                      onClick={() => removeExistedIdea(idea)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </BorderedContainer>
    </Page>
  )
}

export default IdeaManager
