import React, { useCallback, useMemo, useRef } from 'react'
import cn from 'classnames'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import CircularProgress from '@mui/material/CircularProgress'
import { Search } from '@mui/icons-material'

import Conditional from 'components/Conditional'

export interface SearchBarProps {
  loading?: boolean
  value?: string
  className?: string
  label?: string
  onChange?(value: string): void
  onStartSearch?(value: string): void
}

export const SearchBar = ({
  loading = false,
  value,
  className,
  label = 'Search',
  onChange,
  onStartSearch,
}: SearchBarProps) => {
  const inputRef = useRef<HTMLInputElement>()

  return (
    <TextField
      inputRef={inputRef}
      className={cn('input-text', className)}
      label={label}
      variant="filled"
      value={value}
      fullWidth
      onKeyDown={(e) => {
        if (e.key === 'Enter' && onStartSearch)
          onStartSearch(inputRef?.current?.value || '')
      }}
      onChange={useCallback(
        (e) => onChange && onChange(e.target.value || ''),
        [onChange],
      )}
      InputProps={useMemo(
        () => ({
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <Conditional shouldRender={!loading}>
                <Search
                  onClick={() =>
                    onStartSearch &&
                    onStartSearch(inputRef?.current?.value || '')
                  }
                  style={{ cursor: 'pointer' }}
                />
              </Conditional>
              <Conditional shouldRender={!!loading}>
                <CircularProgress size={20} />
              </Conditional>
            </InputAdornment>
          ),
        }),
        // eslint-disable-next-line
        [loading],
      )}
    />
  )
}
