import React, { FC } from 'react'
import { Record } from 'store/api/types/Record.types'
import ImageWithPlaceHolder from '../../components/ImageWithPlaceholder'
import Link from 'components/Link'
import { getGenderLabel } from '../../utils/format'
import styles from './AmbassadorProfile.module.scss'

type RecordBreakerProps = {
  record: Record
}

const formatInfoString = (record: Record) => {
  return `${getGenderLabel(record.gender).toUpperCase()} - ${
    record.ageGap
  } YEARS OLD ${record.location.city}, ${record?.location?.state || ''} (${
    record.location.country
  })`
}

export const RecordBreaker: FC<RecordBreakerProps> = ({ record }) => {
  const recordInfo = formatInfoString(record)

  return (
    <Link className=".pointer:hover" to={`/records/${record.id}`}>
      <div className={styles.container}>
        <ImageWithPlaceHolder
          src={record.previewImageUrl}
          alt="Image representing the record"
          className={styles.image}
        />
        <div className={styles.description}>
          <p className={styles.record_info}>{recordInfo}</p>
          <h2 className={styles.record_title}>{record.idea.title}</h2>
          <p className={styles.record_info}>{record.description}</p>
        </div>
      </div>
    </Link>
  )
}
