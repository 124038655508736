export enum AttemptType {
  SPEED = 'SPEED',
  AMOUNT = 'AMOUNT',
}

export enum AttemptStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export type CreateAttemptProps = {
  quizId: string
  type: AttemptType
}

export type QuizAttempt = {
  id: string
  quizId: string
  recordCandidateId?: string
  type: AttemptType
  status: AttemptStatus
  createdAt: Date
  questions: {
    id: string
    image: string
    answers: { id: string; text: string }[]
    correctAnswer: string
  }[]
}

export type Quiz = {
  id: string
  title: string
  shortDescription: string
  description: string
  question: {
    id: string
    imageUrl: string
    answers: string[]
  }
  sponsorLogo: string
  duration: number
  previewImageUrl: string
  themeColor: string
}

export type AnswerResult = {
  attemptStatus: AttemptStatus
  answerStatus: 'WRONG' | 'CORRECT'
}

export type AnswerBody = {
  attemptId: string
  questionId: string
  answer: string
}

export type QuizSuggestion = {
  email: string
  suggestionText: string
}

export type CompleteBody = {
  id: string
  questions: {
    id: string
    answer: string
    timestamp: Date
  }[]
}

export type RecordHolders = {
  mostRecordInfo: string
  speedRecordInfo: string
}

export type QuizRecordScore = {
  quizTitle: string
  themeColor: string
  records: {
    id: string
    recordHolderName: string
    division: string
    location: string
    score: string
  }[]
}

export type SortType = {
  name: string
  order: 'asc' | 'desc'
}

export type QuizRecord = {
  id: string
  quizId: string
  quizTitle: string
  recordHolderName: string
  division: string
  location: string
  score: string
  previewImageUrl: string
  type: AttemptType
}
