import React from 'react'

import styles from './Footer.module.scss'
import { CONFIG } from 'config'

const calcTransform = (
  e: MouseEvent,
  anchorX: number,
  anchorY: number,
  box: HTMLElement,
) => {
  let angle =
    Math.atan2(e.pageX - anchorX, -(e.pageY - anchorY)) * (180 / Math.PI)
  if (box && angle < -30 && angle > -130) {
    box.style.transform = `rotate(${65 + angle}deg)`
  }
}

export const MovingRacoonHead: React.FC = () => {
  const box = document.getElementById('moving')
  let rekt: DOMRect
  let anchorX = 0
  let anchorY = 0
  if (box) {
    rekt = box.getBoundingClientRect()
    anchorX = rekt.left + rekt.width / 2
    anchorY = rekt.top + rekt.height / 2
    document.addEventListener('mousemove', (e) =>
      calcTransform(e, anchorX, anchorY, box),
    )
  }
  window.addEventListener('resize', (event) => {
    if (box) {
      rekt = box.getBoundingClientRect()
      anchorX = rekt.left + rekt.width / 2
      anchorY = rekt.top + rekt.height / 2
    }
  })

  return (
    <div
      className={
        CONFIG.FEATURE_513
          ? styles.headContainer_FEATURE_513
          : styles.headContainer
      }
      id={'moving'}
    >
      <img
        className={styles.racoon}
        src={'/assets/racoon_head.png'}
        alt="Racoon"
      />
    </div>
  )
}
