import React from 'react'

import BorderedContainer from 'components/BorderedContainer'
import Title from 'components/Title'
import { Screen } from 'components/Screen'
import { PageWrapper } from 'components/PageWrapper'

import AmbassadorProgressTable from 'components/AmbassadorProgressTable'
import { useGetAmbassadorsProgressQuery } from 'store/api'

import styles from './AmbassadorsProgressTablePage.module.scss'

export const AmbassadorsProgressTablePage: React.FC = () => {
  const { data: ambassadors, isFetching } = useGetAmbassadorsProgressQuery()

  return (
    <PageWrapper>
      <Screen className={styles.screen}>
        <BorderedContainer>
          <Title>Ambassadors</Title>
          {ambassadors && (
            <AmbassadorProgressTable
              ambassadors={ambassadors}
              dataFetching={isFetching}
            />
          )}
        </BorderedContainer>
      </Screen>
    </PageWrapper>
  )
}
