import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'components/Button'
import IconButton from 'components/IconButton'
import ImageWithPlaceHolder from 'components/ImageWithPlaceholder'
import LinkButton from 'components/LinkButton'
import Loader from 'components/Loader'
import QuantityUpdate from 'components/QuantityUpdate'
import ShadowModal from 'components/ShadowModal'
import Title from 'components/Title'

import ProductCheckouts, { ProductCheckout } from 'data/Product'

import {
  // checkoutAsync,
  toggleShoppingCart,
  removeFromShoppingCart,
  updateShoppingCart,
} from 'store/shop/actions'
import {
  getShoppingCart,
  getShoppingCartIsOpen,
  isLoading,
} from 'store/shop/selectors'

import './shop-cart.css'
import api from 'api'

interface ShopCartLineItemProps {
  item: ProductCheckout
  onRemove: (product: ProductCheckout) => void
  onUpdate: (product: ProductCheckout) => void
}

const ShopCartLineItem: React.FC<ShopCartLineItemProps> = ({
  item,
  onRemove,
  onUpdate,
}) => {
  const handleQuantityChange = (newQuatity: number) => {
    onUpdate({
      ...item,
      quantity: newQuatity,
    })
  }

  const lineItemCssClass =
    'shop-cart__line-item flex relative f6 pv3 dark-gray bb border-bottom--dark-surface'

  const totalPrice = ProductCheckouts.getProductTotal(item)

  return (
    <div className={lineItemCssClass}>
      <ImageWithPlaceHolder
        className="shop-cart__line-item-image"
        src={item.product.imageLinks[0]}
        alt=""
      />
      <div className="flex flex-column flex-row-m justify-between-m flex-row-l justify-between-l">
        <div className="flex flex-column justify-center">
          <span className="nunito-bold dark-gray f6">{item.product.info}</span>
          <span className="nunito-bold indigo-blue f5">
            {item.product.title}
          </span>
        </div>
        <div className="flex justify-between items-center pt2">
          <QuantityUpdate
            defaultValue={item.quantity}
            onChange={handleQuantityChange}
          />
          <div className="shop-cart__line-item-price pl4-m pl4-l">
            <span className="nunito-bold dark-gray f5">{`$${totalPrice.toFixed(
              2,
            )}`}</span>
          </div>
        </div>
      </div>
      <IconButton
        className="shop-cart__line-item-remove"
        name="close"
        onClick={() => onRemove(item)}
      />
    </div>
  )
}

const ShopCartEmptyState: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <>
    <div className="flex flex-column justify-center items-center">
      <p className="openSans-regular dark-gray">Your cart is empty.</p>
      <img
        className="shop-cart__empty-cart-image"
        alt="Girl doing a handstand."
        src="/assets/girl-doing-handstand.png"
      />
    </div>
    <div className="flex justify-center pt3">
      <LinkButton to="/shop" onClick={onClick}>
        Shop Now
      </LinkButton>
    </div>
  </>
)

const ShopCart: React.FC = () => {
  const isOpen = useSelector(getShoppingCartIsOpen)
  const checkoutProducts = useSelector(getShoppingCart)
  const proceedingToCheckout = useSelector(isLoading)
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState('')
  const ref = useRef<HTMLAnchorElement | null>(null)

  const dispatch = useDispatch()

  useEffect(() => {
    setUrl('')
    api.shop.buyProducts(checkoutProducts).then((checkout: any) => {
      setUrl(checkout.webUrl)
      setLoading(false)
    })
  }, [checkoutProducts])

  const handleCancelClick = () => {
    dispatch(toggleShoppingCart())
  }

  const handleRemove = (product: ProductCheckout) => {
    dispatch(removeFromShoppingCart(product))
  }

  const handleUpdate = (product: ProductCheckout) => {
    dispatch(updateShoppingCart(product))
  }

  const subTotal = ProductCheckouts.getSubTotal(checkoutProducts)

  return (
    <ShadowModal noSize isModalOpen={isOpen} onCancel={handleCancelClick}>
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        ref={ref}
        style={{ display: 'none' }}
      >
        Hidden shop link
      </a>
      <div className="shop-cart">
        <Title className="tc f3-l f3-m">Kids World Records Cart</Title>
        {checkoutProducts.length === 0 && (
          <ShopCartEmptyState onClick={handleCancelClick} />
        )}
        {checkoutProducts.length > 0 && (
          <>
            {checkoutProducts.map((checkoutProduct) => (
              <ShopCartLineItem
                key={checkoutProduct.submissionTitle}
                item={checkoutProduct}
                onRemove={handleRemove}
                onUpdate={handleUpdate}
              />
            ))}
            <div
              className="flex justify-end pt3 pb4"
              style={{ marginRight: '9px' }}
            >
              <label className="dark-gray f5 b">Subtotal</label>
              <span className="dark-gray f5 pl3 b">{`$${subTotal.toFixed(
                2,
              )}`}</span>
            </div>
            {!proceedingToCheckout ? (
              <div className="flex flex-column-reverse flex-row-m flex-row-l justify-end pt3">
                <Button ghost onClick={handleCancelClick}>
                  Cancel
                </Button>
                <div className="flex justify-center ml3-m ml3-l">
                  <Button
                    endIcon
                    disabled={loading || !url}
                    onClick={() => ref.current?.click()}
                  >
                    Proceed to Checkout
                  </Button>
                </div>
              </div>
            ) : (
              <Loader active className="shop-cart__loader" />
            )}
          </>
        )}
      </div>
    </ShadowModal>
  )
}

export default ShopCart
