import { useCallback, useEffect, useState } from 'react'
import { QueryStatus } from '@reduxjs/toolkit/query'
import { useLazyGetAllUsersQuery } from 'store/api'
import { ParentResponse } from 'store/api/types/Parent.types'

const PAGE_SIZE = 12

export const useAllUsers = (search: string) => {
  const [skip, setSkip] = useState(0)
  const [hasNextPage, setHasNextPage] = useState<boolean>(true)
  const [filtersForQuery, setFiltersForQuery] = useState({
    search,
    skip,
    take: PAGE_SIZE,
  })
  const [users, setUsers] = useState<ParentResponse[]>([])

  const [trigger, { data, isLoading, error, status }] =
    useLazyGetAllUsersQuery()

  useEffect(() => {
    trigger(filtersForQuery)
  }, [filtersForQuery, trigger])

  useEffect(() => {
    setSkip(0)
    setUsers([])
    setFiltersForQuery({
      search,
      skip: 0,
      take: PAGE_SIZE,
    })
  }, [search])

  useEffect(() => {
    if (data) {
      setHasNextPage(data.length === PAGE_SIZE)
      setUsers((users) => [...users, ...data])
      setSkip((skip) => skip + PAGE_SIZE)
    }
  }, [data])

  const onNext = useCallback(() => {
    setSkip((skip) => {
      setFiltersForQuery((filters) => ({ ...filters, skip }))
      return skip
    })
  }, [])

  return {
    isLoading: isLoading || status === QueryStatus.pending || Boolean(error),
    users,
    onNext,
    hasNextPage,
  }
}
