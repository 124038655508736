import Joi from 'joi'
import { Recommendation } from 'store/api/types/Recommendation.types'

export const RECOMMENDATION_UPDATE_VALIDATOR = Joi.object({
  title: Joi.string().required(),
  description: Joi.string().required(),
  didYouKnow: Joi.string().required(),
  urlText: Joi.string().required(),
  link: Joi.string().required(),
  imageUrl: Joi.array().items(Joi.string()).length(1).required(),
})

export const buildDefaultValue = (recommendation: Recommendation): any => {
  if (!recommendation) {
    return undefined
  }

  return {
    title: recommendation.title,
    description: recommendation.description,
    didYouKnow: recommendation.didYouKnow,
    urlText: recommendation.urlText,
    link: recommendation.link,
    imageUrl: [recommendation.imageUrl],
  }
}
