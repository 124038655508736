import React from 'react'

import { Screen } from 'components/Screen'

import { BreakRecordBody } from './BreakRecordBody'

import styles from './BreakRecord.module.scss'
import { PageWrapper } from 'components/PageWrapper'

export const BreakRecord = () => {
  return (
    <PageWrapper>
      <Screen className={styles.breakRecord}>
        <BreakRecordBody />
      </Screen>
    </PageWrapper>
  )
}
