import React from 'react'

const RejectReasonSection: React.FC<{
  message: string | null
  className?: string
}> = ({ message }) => (
  <section className="f6 ph3 pv2 ba br3 mb3 border--dark-surface bg-surface dark-gray">
    <h3 className="neo-red">
      {!!message ? 'Reason for rejection' : 'Broken by someone'}
    </h3>
    <p>{message}</p>
  </section>
)

export default RejectReasonSection
