import React, { FC } from 'react'
import styles from '../Ambassador.module.scss'

type ItemProps = {
  index: number
  imageUrl: string
  levelPerk: string
  howToUnlock: string
}

export const AboutLevelItem: FC<ItemProps> = ({
  index,
  imageUrl,
  levelPerk,
  howToUnlock,
}) => {
  return (
    <div className={styles.level_item}>
      <h1>Level {index}</h1>
      <div className={styles.container}>
        <img src={imageUrl} alt={imageUrl} />
        <div>
          <h3 className={styles.title}>Level Perk</h3>
          <div className={styles.description}>{levelPerk}</div>
          <h3 className={styles.title}>How to Unlock Level</h3>
          <div className={styles.description}>{howToUnlock}</div>
        </div>
      </div>
    </div>
  )
}
