import React, { FC } from 'react'

import styles from './RecordMedias.module.scss'

interface MediaItemProps {
  src: string
  alt?: string
}

export const MediaItem: FC<MediaItemProps> = ({ src, alt }) => {
  return (
    <img
      alt={alt}
      src={src}
      className={styles.mediaItem}
      onError={(event) => {
        //@ts-ignore
        event.target.src = '/assets/video-thumb.png'
        // @ts-ignore
        event.onerror = null
      }}
    />
  )
}
