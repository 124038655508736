import React from 'react'
// import { useSelector } from 'react-redux'
// import { useParams } from 'react-router-dom'

import Button from 'components/Button'
import Field from 'components/Field'
import ImageWithPlaceHolder from 'components/ImageWithPlaceholder'
import LinkButton from 'components/LinkButton'
import Loader from 'components/Loader'
import Paragraph from 'components/Paragraph'
import ShareModal from 'components/ShareModal'
import Title from 'components/Title'

import Charities from 'data/Charity'
import Locations from 'data/Location'
import Profiles from 'data/Profile'

import useToggle from 'hooks/useToggle'

// import { isLoading } from 'store/charity/selectors'

import { getFormattedDateTime } from 'utils/datetime'

import './charity-details.css'
import { PageWrapper } from 'components/PageWrapper'

// type CharityDetailsParams = {
//   id: string
// }

const isLocalhost = (url: string) => url.indexOf('localhost') >= 0

const CharityDetails: React.FC = () => {
  // const params = useParams<CharityDetailsParams>()

  const charityEvent = undefined as any

  const loading = false //useSelector(isLoading)

  const [isShareModalOpen, toggleShareModal] = useToggle(false)

  const getTextToShare = (eventDate: string, charityInstitution: string) => {
    return `Hello! On ${eventDate}, the kid's world record will be broken and I would like to share this wonder with you. In addition to the event, we are raising funds for ${charityInstitution}, check if you're interested!`
  }

  const fundraiserCampaignUrl =
    charityEvent && Charities.getFundraiserCampaignUrl(charityEvent.fundraiser)

  return (
    <PageWrapper>
      <div
        className="w-100 h-100 center pv3 ph3 ph5-m ph5-l"
        style={{ maxWidth: '74.4rem' }}
      >
        {/*<MetaTags>*/}
        {/*  <meta property="og:title" content={charityEvent?.idea.title} />*/}
        {/*  <meta name="description" content={charityEvent?.description} />*/}
        {/*  <meta property="og:image" content={charityEvent?.imageLinks[0]} />*/}
        {/*  <link rel="canonical" href={window.location.pathname}></link>*/}
        {/*</MetaTags>*/}
        <Loader
          active={loading}
          style={{
            top: '50%',
            left: 'calc(50% - 30px)',
          }}
          className="z-999"
        />
        {!loading && charityEvent && (
          <>
            <ImageWithPlaceHolder
              src={
                (charityEvent.imageLinks && charityEvent.imageLinks[0]) || ''
              }
              alt={`Image representing the ${charityEvent.idea.title} charity event`}
              className="charity-details__media"
              ariaHidden={true}
            />
            <div className="flex flex-column flex-row-l justify-between">
              <div className="w-100">
                <div className="charity-details__text-content">
                  <p className="charity-details__info ttu pt3 ma0 dark-gray">
                    {`Help the ${charityEvent.fundraiser} to raise money through this event`}
                  </p>
                  <Title className="pb3">{charityEvent.idea.title}</Title>
                  <Paragraph variant="noMargin">
                    {charityEvent.description}
                  </Paragraph>
                </div>
                <div className="charity-details__grid w-100 pt4 pt5-l">
                  <Field
                    info={charityEvent.organizer.name}
                    label="Created by:"
                  />
                  <Field
                    info={Profiles.getFullName(charityEvent.childProfile)}
                    label="Record Breaker:"
                  />
                  <Field
                    info={getFormattedDateTime(charityEvent.breakAt)}
                    label="Date and Time:"
                  />
                  <Field
                    info={Locations.getFullAddress(charityEvent.location)}
                    label="Location:"
                  />
                </div>
              </div>
              <div className="charity-details__buttons flex flex-column justify-between pt4 pt3-l">
                {fundraiserCampaignUrl && (
                  <LinkButton
                    to={fundraiserCampaignUrl}
                    externalLink={true}
                    target="__blank"
                  >
                    Donate
                  </LinkButton>
                )}
                <Button outline ghost onClick={toggleShareModal}>
                  Share Event
                </Button>
              </div>
            </div>
          </>
        )}
        {charityEvent && (
          <ShareModal
            onCancel={toggleShareModal}
            isOpen={isShareModalOpen}
            shareOptions={{
              text: getTextToShare(
                getFormattedDateTime(charityEvent.breakAt),
                charityEvent.fundraiser,
              ),
              link: isLocalhost(window.location.href)
                ? 'https://staging.kidsworldrecords.us/'
                : `${window.location.href}`,
            }}
          />
        )}
      </div>
    </PageWrapper>
  )
}

export default CharityDetails
