import React, { Fragment, useEffect, useState } from 'react'
import { SponsorImage } from './SponsorImage'
import {
  NewSponsorProps,
  SelectedSponsorProps,
  SponsorModal,
} from './SponsorModal'
import { SponsorProps } from '../../../store/api/types/Event.types'
import styles from './SponsorList.module.scss'
import {
  useCreateSponsorMutation,
  useDeleteSponsorMutation,
  useGetSponsorsQuery,
  useUpdateSponsorMutation,
} from '../../../store/api'
import { DeleteSponsorModal } from './DeleteSponsorModal'

export const Sponsors = () => {
  const [selectedSponsor, setSelectedSponsor] =
    useState<SelectedSponsorProps | null>(null)
  const { data, isLoading } = useGetSponsorsQuery()
  const [sponsors, setSponsors] = useState<SponsorProps[]>(data || [])

  const [createSponsor, { isLoading: isCreating }] = useCreateSponsorMutation()
  const [updateSponsor, { isLoading: isUpdating }] = useUpdateSponsorMutation()
  const [deleteSponsor, { isLoading: isDeleting }] = useDeleteSponsorMutation()
  const [sponsorToDelete, setSponsorToDelete] = useState<string>('')

  useEffect(() => {
    if (!isLoading) {
      setSponsors(data || [])
    }
  }, [data, isLoading])

  const onSave = async (sponsor: NewSponsorProps) => {
    sponsor.id ? await updateSponsor(sponsor) : await createSponsor(sponsor)
    setSelectedSponsor(null)
  }

  const onDelete = async () => {
    try {
      if (sponsorToDelete) {
        await deleteSponsor(sponsorToDelete)
      }
      setSponsorToDelete('')
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Fragment>
      <DeleteSponsorModal
        isOpenModal={Boolean(sponsorToDelete)}
        onClose={() => setSponsorToDelete('')}
        isLoading={isDeleting}
        onDelete={onDelete}
      />
      <SponsorModal
        sponsor={selectedSponsor}
        onClose={() => {
          setSelectedSponsor(null)
        }}
        isLoading={isCreating || isUpdating}
        onSave={onSave}
      />
      <div className={styles.list_grid}>
        {sponsors.map((sponsor) => (
          <SponsorImage
            key={sponsor.id}
            imageUrl={sponsor.imageUrl}
            onClick={() => {
              setSelectedSponsor(sponsor)
            }}
            onDelete={() => setSponsorToDelete(sponsor.id)}
          />
        ))}
        <SponsorImage
          onClick={() =>
            setSelectedSponsor({ name: '', imageUrl: '', siteUrl: '' })
          }
        />
      </div>
    </Fragment>
  )
}
