import React, { FC } from 'react'

import { useController } from 'react-hook-form'

import { LocationAutocomplete } from 'components/Inputs/LocationAutocomplete'
import Locations from '../../data/Location'

export interface FormInputProps {
  name: string
  label?: string
  control: any
}

//todo: remove any
const getLocationError = (errors: any) => {
  console.log(errors)
  if (errors.location?.message) {
    return errors.location.message
  }

  const cityError = errors.location?.city?.message
  const stateError = errors.location?.state?.message
  const countryError = errors.location?.country?.message
  let error = ''

  if (cityError) {
    error += cityError
  }

  if (stateError) {
    error += stateError
  }

  if (countryError) {
    error += countryError
  }

  return error
}

export const FormLocationAutocomplete: FC<FormInputProps> = ({
  name,
  label,
  control,
}) => {
  const {
    field: { ref, onChange, value, ...rest },
    formState: { errors },
  } = useController({ control, name })

  return (
    <LocationAutocomplete
      {...rest}
      name={rest.name}
      defaultValue={Locations.getFullAddress(value)}
      label={label}
      onSelectLocation={onChange}
      errorText={getLocationError(errors)}
    />
  )
}
