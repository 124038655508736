import React, { useEffect, useRef } from 'react'
import { IconButton } from '@mui/material'
import { Close, ArrowForwardIos, ArrowBackIosNew } from '@mui/icons-material'

import ShadowModal from 'components/ShadowModal'
import Button from 'components/Button'
import ImageGallery from 'components/ImageGallery'
import {
  EventParticipant,
  EventParticipantStatus,
} from '../../../store/api/types/Event.types'

import styles from './ParticipantsList.module.scss'
import dayjs from 'dayjs'
import { Input } from '../../../components/Inputs'
import Conditional from '../../../components/Conditional'
import Loader from '../../../components/Loader'

interface ReviewModalProps {
  isLoading: boolean
  disabled?: boolean
  participant: EventParticipant | undefined
  error?: string
  onClose: () => void
  onNext?: () => void
  onPrev?: () => void
  onChangeStatus: (
    status: EventParticipantStatus,
    reviewComment?: string,
  ) => void
}

const defaultImage = [
  'https://storage.googleapis.com/staging.refreshing-park-265118.appspot.com/JJRU-P9V7-99ZY-YM3R-6howtobreakarecord.png',
]

export const ReviewModal: React.FC<ReviewModalProps> = ({
  isLoading,
  participant,
  onChangeStatus,
  onClose,
  onNext,
  onPrev,
  disabled,
  error,
}) => {
  const inputRef = useRef<HTMLInputElement>()
  const image =
    !!participant &&
    Array.isArray(participant.medias) &&
    participant.medias.length
      ? participant.medias
      : defaultImage

  const onReject = () => {
    onChangeStatus(EventParticipantStatus.REJECTED, inputRef.current?.value)
  }

  const onApprove = () => {
    onChangeStatus(EventParticipantStatus.APPROVED, inputRef.current?.value)
  }

  useEffect(() => {
    if (typeof inputRef.current?.value === 'string') {
      inputRef.current.value = participant?.reviewComment || ''
    }
  }, [participant])

  if (participant) {
    return (
      <ShadowModal isModalOpen={!!participant} onCancel={onClose} noSize>
        <Loader active={isLoading} className={styles.loader} />
        <Conditional shouldRender={!!onPrev}>
          <IconButton
            className={styles.arrowBack}
            aria-label="close"
            onClick={onPrev}
          >
            <ArrowBackIosNew />
          </IconButton>
        </Conditional>
        <Conditional shouldRender={!!onNext}>
          <IconButton
            className={styles.arrowFwd}
            aria-label="close"
            onClick={onNext}
          >
            <ArrowForwardIos />
          </IconButton>
        </Conditional>
        <IconButton
          className={styles.closeBtn}
          aria-label="close"
          onClick={onClose}
        >
          <Close />
        </IconButton>
        <div className={styles.reviewModalContainer}>
          <div className={styles.columnLeft}>
            <ImageGallery
              alt="Image representing the record"
              imageLinks={image}
              initialIndex={0}
            />
          </div>
          <div className={styles.columnRight}>
            <div className={styles.row}>
              <div className={styles.fieldName}>Parent First</div>
              <div className={styles.fieldData}>
                {participant.parentFirstName}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.fieldName}>Parent Last</div>
              <div className={styles.fieldData}>
                {participant.parentLastName}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.fieldName}>Parent Email</div>
              <div className={styles.fieldData}>{participant.email}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.fieldName}>Kid First</div>
              <div className={styles.fieldData}>
                {participant.kidsFirstName}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.fieldName}>Kid Last</div>
              <div className={styles.fieldData}>{participant.kidsLastName}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.fieldName}>Kid Birthdate</div>
              <div className={styles.fieldData}>
                {dayjs(participant.kidsDateOfBirth).format('MM-DD-YYYY')}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.fieldName}>Instagram</div>
              <div className={styles.fieldData}>
                <a
                  href={`https://www.instagram.com/${participant.instagramId}/`}
                  rel="noreferrer"
                  target={'_blank'}
                >
                  {participant.instagramId}
                </a>
              </div>
            </div>
            <Conditional shouldRender={!!disabled}>
              <div className={styles.row}>
                <div className={styles.fieldName}>Status</div>
                <div
                  className={
                    participant.status === 'APPROVED'
                      ? styles.approved
                      : styles.reject
                  }
                >
                  {participant.status}
                </div>
              </div>
            </Conditional>
            <Input
              errorText={error}
              multiline={true}
              disabled={disabled}
              className={styles.input}
              name="reviewComment"
              label="Review comment"
              defaultValue={participant?.reviewComment}
              inputRef={inputRef}
            />
          </div>
        </div>
        {!disabled && (
          <div className={styles.buttons}>
            <Button disabled={disabled} ghost onClick={onReject}>
              Reject
            </Button>
            <Button
              disabled={disabled}
              ghost
              className={styles.green}
              onClick={onApprove}
            >
              Approve
            </Button>
          </div>
        )}
      </ShadowModal>
    )
  } else {
    return null
  }
}
