import React, { memo, useCallback, useState, useEffect } from 'react'

import { ListViewSelector } from 'components/V2/ListViewSelector'
import { CategoryDropDown } from 'components/V2/CategoryDropDown'
import { RecordForMeFilter } from '../RecordForMeFilter'
import { SearchBar } from '../SearchBar'

import {
  ExtraCategory,
  PublicRecordFilters,
} from 'store/api/types/Record.types'
import styles from './ControlPanel.module.scss'
import { GenderV2 } from 'store/api/types/Gender.types'

export interface ControlPanelProps {
  view: 'list' | 'card'
  onChangeView(view: 'list' | 'card'): void
  onSearch(params: PublicRecordFilters): void
  defaultValue: ExtraCategory
  loading?: boolean
}

export interface RecordForMeFilterValue {
  genders: GenderV2[]
  ageGaps: string[]
}

export const ControlPanelComponent = ({
  view,
  onSearch,
  loading,
  defaultValue,
  onChangeView,
}: ControlPanelProps) => {
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState<ExtraCategory>(defaultValue)
  const [filters, setFilters] = useState<RecordForMeFilterValue>({
    genders: [],
    ageGaps: [],
  })

  useEffect(() => {
    onSearch({
      search,
      category,
      genders: filters.genders,
      ageGaps: filters.ageGaps,
    })
  }, [category, filters, onSearch, search])

  return (
    <div className={styles.main}>
      <div className={styles.controls}>
        <ListViewSelector
          view={view}
          onClick={useCallback(
            () => onChangeView(view === 'list' ? 'card' : 'list'),
            [onChangeView, view],
          )}
        />
        <div className={styles.right}>
          <RecordForMeFilter
            disabled={loading}
            value={filters}
            onApply={useCallback((value) => setFilters(value), [])}
          />
          <CategoryDropDown
            disabled={loading}
            value={category}
            onChange={useCallback(
              (value) => setCategory(value as ExtraCategory),
              [],
            )}
          />
        </div>
      </div>
      <div>
        <SearchBar loading={loading} onStartSearch={setSearch} />
      </div>
    </div>
  )
}

export const ControlPanel = memo(ControlPanelComponent)
