import { RootState } from 'store'

import { Toast } from 'typings/Toast'

export const isContentOverlay = (state: RootState): boolean =>
  state.app.contentOverlay
export const isMenuOpened = (state: RootState): boolean => state.app.menuOpened
export const isScrollEnabled = (state: RootState): boolean =>
  state.app.scrollEnabled
export const getToast = (state: RootState): Toast | null => state.app.toast
