import Profiles, { Profile } from 'data/Profile'

import * as localStorage from 'utils/localStorage'

const ADMIN_PROFILE = 'ADMIN_PROFILE'
const AUTH_TOKEN_KEY = 'AUTH_TOKEN'

const setAuthProfile = (profile: Profile): void => {
  const [authToken] = profile.tokens
  if (Profiles.isAdminProfile(profile)) {
    localStorage.set(ADMIN_PROFILE, profile)
  }
  localStorage.set(AUTH_TOKEN_KEY, authToken)
}

const getAuthUserToken = (): string | undefined =>
  localStorage.get(AUTH_TOKEN_KEY)

export { setAuthProfile, getAuthUserToken }
