import React from 'react'

import Button from 'components/Button'
import Icon from 'components/Icon'

import { ItemLayout } from 'typings'

interface ViewSelectorProps {
  onClick: () => void
  view: ItemLayout
}
const ViewSelector: React.FC<ViewSelectorProps> = ({ onClick, view }) => {
  const isLisView = view === 'list_view'
  const icon = (
    <Icon
      className="indigo-blue"
      name={isLisView ? 'view_module' : 'view_list'}
    />
  )
  return (
    <div className="flex items-center">
      <Button onClick={onClick} ghost theme="tertiary" startIcon={icon}>
        <span className="nunito-regular">
          {isLisView ? 'Card View' : 'List View'}
        </span>
      </Button>
    </div>
  )
}

export default ViewSelector
