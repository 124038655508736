import React from 'react'

import Link from 'components/Link'

import { FOOTER } from './links'

const Sitemap = () => (
  <ul className="flex flex-wrap ph0 ma0 justify-center list tc">
    {!!FOOTER.length &&
      FOOTER.map((item) => (
        <li className="mr4 pv1 mw3 mw5-ns" key={item.url}>
          <Link to={item.url}>{item.text}</Link>
        </li>
      ))}
  </ul>
)

export default Sitemap
