import React from 'react'

import MUIStepper from '@mui/material/Stepper'
import MUIStep from '@mui/material/Step'
import MUIStepLabel from '@mui/material/StepLabel'

import './stepper.css'

interface StepperProps {
  activeStep: number
  steps: string[]
}

const Stepper: React.FC<StepperProps> = ({ activeStep, steps }) => (
  <MUIStepper activeStep={activeStep} alternativeLabel className="stepper">
    {steps.map((item, index) => {
      return (
        <MUIStep key={item} completed={index < activeStep}>
          <MUIStepLabel>{item}</MUIStepLabel>
        </MUIStep>
      )
    })}
  </MUIStepper>
)

export default Stepper
