import React, { FC, Fragment } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import Button from 'components/Button'
import {
  MediaType,
  MainPageHeaderContent,
} from 'store/api/types/MainPageHeaderContent'
import { Controller, FieldArrayWithId, UseFormSetValue } from 'react-hook-form'
import { AccordionImage } from '../AccordionImage'
import { EditableMediaItem } from 'scenes/EditMainPage/EditMainPage'
import { FormCheckbox } from 'components/Form/FormCheckbox'
import { FormInput } from 'components/Form/FormInput'

import styles from '../../EditMainPage.module.scss'

type FormAccordionProps = {
  name: string
  className?: string
  control: any
  errorText?: string
  fields: FieldArrayWithId<
    MainPageHeaderContent<EditableMediaItem>,
    'medias',
    'id'
  >[]
  isLoading: boolean
  inputRef?: any
  swap(from: number, to: number): void
  remove(index?: number | number[]): void
  setValue: UseFormSetValue<MainPageHeaderContent<EditableMediaItem>>
}

export const FormAccordion: FC<FormAccordionProps> = ({
  control,
  errorText,
  className,
  fields,
  isLoading,
  inputRef,
  swap,
  remove,
  setValue,
}) => {
  const onImgDelete = (idx: number) => {
    const medias = {
      time: 10,
      type: fields[idx].type,
      onClickUrl: fields[idx].onClickUrl,
      isExternal: fields[idx].isExternal,
    }
    setValue(`medias.${idx}`, medias)
  }

  const onMoveItemClick = (index: number, toTop: boolean) => {
    const offset = toTop ? 1 : -1
    const indexToPlace = index + offset

    if (indexToPlace >= fields.length || indexToPlace < 0) {
    } else {
      swap(index, indexToPlace)
    }
  }
  const onImageSelectClick = (index: number) => {
    if (inputRef?.current) {
      inputRef.current.name = String(index)
      inputRef.current.click()
    }
  }
  return (
    <Fragment>
      {fields.map((field, idx) => (
        <Accordion disabled={isLoading} key={field.id}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={field.id}
            id={field.id}
          >
            <Controller
              control={control}
              name={`medias.${idx}.onClickUrl`}
              render={({ field }) => <Typography>{field.value}</Typography>}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              control={control}
              name={`medias.${idx}`}
              render={({ field }) => (
                <AccordionImage
                  src={field.value?.mediaUrl || field.value?.fileUrl}
                  type={field.value?.type || MediaType.IMAGE}
                  alt="img"
                  onDelete={() => onImgDelete(idx)}
                  onUp={() => onMoveItemClick(idx, false)}
                  onDown={() => onMoveItemClick(idx, true)}
                  onLink={() => {}}
                  onImageSelect={() => onImageSelectClick(idx)}
                />
              )}
            />
            <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormInput
                  label="Click URL"
                  name={`medias.${idx}.onClickUrl`}
                  control={control}
                />
                <FormInput
                  label="Seconds"
                  name={`medias.${idx}.time`}
                  control={control}
                  type="number"
                  className={styles.number}
                />
              </div>
              <FormCheckbox
                name={`medias.${idx}.isExternal`}
                control={control}
                label="Set URL as an external."
              />
              <div>
                <Button
                  fullWidth
                  onClick={() => {
                    remove(idx)
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </Fragment>
  )
}
