import { Category } from 'data/Category'

import recordDetailsSchema from 'validation/recordDetailsSchema'
import recordDetailsPersonPicturedSchema from 'validation/recordDetailsPersonPicturedSchema'
import recordWitnessSchema from 'validation/recordWitnessesSchema'

export interface Person {
  id: string
  name: string
  email: string
}

export interface WitnessParams {
  email: string
}

export interface RecordDetailsFormData {
  brokenOn: Date | null
  category: Category | null
  description: string | null
  imageLinks: string[]
  file?: File[]
  title: string | null
  location: null | {
    city: string
    state: string
    country: string
  }
  picturedPersons: Person[]
}

export interface ValidRecordDetailsFormData {
  brokenOn: Date
  category: Category
  description: string
  funFacts: string
  locationSearchString?: string
  imageLinks: string[]
  media: File[]
  title: string
  location: {
    city: string
    state: string
    country: string
  }
  picturedPersons: Person[]
  ambassadorId?: string
}

export interface RecordWitnessesFormData {
  witness1Email: string | null
  witness2Email: string | null
}

export interface ValidRecordWitnessesFormData {
  witness1Email: string
  witness2Email: string
}

export type ValidRecordSubmissionFormData = ValidRecordDetailsFormData &
  ValidRecordWitnessesFormData & {
    childProfileId: string
    ideaId?: string
    brokenRecordId?: string
    paypalTransactionId?: string
  }

const isValidRecordDetails = (
  recordDetails: RecordDetailsFormData,
): recordDetails is ValidRecordDetailsFormData =>
  recordDetailsSchema.isValidSync(recordDetails)

const isValidRecordDetailsPersonPictured = (
  recordDetails: RecordDetailsFormData,
): recordDetails is ValidRecordDetailsFormData =>
  recordDetailsPersonPicturedSchema.isValidSync(recordDetails)

const isValidWitnesses = (
  recordWitness: RecordWitnessesFormData,
): recordWitness is ValidRecordWitnessesFormData =>
  recordWitnessSchema.isValidSync(recordWitness)

const exp = {
  isValidRecordDetails,
  isValidRecordDetailsPersonPictured,
  isValidWitnesses,
}

export default exp
