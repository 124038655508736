import Joi from 'joi'
import Locations from 'data/Location'
import { Option } from '../../components/Select'
import { Gender } from '../../data/ChildProfile'

export const CHILD_UPDATE_VALIDATOR = Joi.object({
  dateOfBirth: Joi.date().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .allow(''),
  firstName: Joi.string().required(),
  gender: Joi.string().required(),
  lastName: Joi.string().required(),
  location: Joi.object({
    country: Joi.string().required().label('country'),
    city: Joi.string().required().label('city'),
    state: Joi.string(),
    timezoneInMiliseconds: Joi.number().required(),
  }).required(),
})

//todo: replace it when update child move to lambda
const Converter = {
  Boy: 'male',
  Girl: 'female',
  'Gender X': 'gender_x',
}

export const GENDER_OPTIONS: Option<Gender>[] = [
  {
    label: 'Girl',
    value: 'female',
  },
  {
    label: 'Boy',
    value: 'male',
  },
  {
    label: 'Gender X',
    value: 'gender_x',
  },
]

export const buildDefaultValue = (child: any): any => {
  if (!child) {
    return undefined
  }

  return {
    dateOfBirth: new Date(child.dateOfBirth.replace('Z', '')),
    email: child.email,
    firstName: child.firstName,
    lastName: child.lastName,
    gender: Converter[child.gender as 'Boy' | 'Girl' | 'Gender X'],
    location: child.location
      ? Locations.getLocationParamsFromLocation(child.location)
      : undefined,
  }
}
