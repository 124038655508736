import React, { useEffect, useMemo, useState } from 'react'
import styles from '../Quiz/Quiz.module.scss'

const SECOND = 1000
const MINUTE = SECOND * 60

interface TimerProps {
  seconds: number
  isPaused: boolean
  onZero: () => void
  shouldRestart?: boolean
}

export const Timer = ({
  seconds,
  onZero,
  isPaused,
  shouldRestart,
}: TimerProps) => {
  const initialTime = useMemo(() => seconds * SECOND, [seconds])
  const [time, setTime] = useState(initialTime)

  useEffect(() => {
    if (shouldRestart) {
      setTime(initialTime)
    }
  }, [shouldRestart, initialTime])

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        if (time <= 0) {
          onZero()
        } else {
          setTime((time) => time - SECOND)
        }
      }, SECOND)

      return () => clearInterval(interval)
    }
  }, [time, onZero, isPaused])

  const mins = `${Math.floor(Math.floor(time / MINUTE))}`.padStart(2, '0')
  const sec = `${(time / SECOND) % 60}`.padStart(2, '0')

  return (
    <div className={styles.timer}>
      <div>
        <div className={styles.box}>
          <p className={styles.number}>{mins}</p>
          <span className="text">mins</span>
        </div>
      </div>
      <div>
        <div className={styles.box}>
          <p className={styles.number}>{sec}</p>
          <span className="text">sec</span>
        </div>
      </div>
    </div>
  )
}
