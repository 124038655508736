import Joi from 'joi'
export type ExportRecordsFormProps = {
  startDate: Date
  endDate: Date
}

export const EXPORT_RECORDS_VALIDATOR = Joi.object({
  startDate: Joi.date().required().messages({
    'any.required': `This is a required field`,
    'date.base': `Enter valid date`,
  }),
  endDate: Joi.date().required().messages({
    'any.required': `This is a required field`,
    'date.base': `Enter valid date`,
  }),
})
