import React from 'react'
import { useNavigate } from 'react-router'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'
import {
  TableContainer,
  TableSortLabel,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
} from '@mui/material'

import dayjs from 'dayjs'

import {
  capitalizeFirstLetter,
  capitalizeAndRemoveUnderscode,
} from 'utils/strings'
import Loader from 'components/Loader'
import { InView } from 'components/InView'
import { Conditional } from 'components/Conditional'

import { RecordType } from 'store/api/types/Record.types'

import './RecordSubmissionTable.css'

const HEADER_TITLES = [
  'Submitted On',
  'Record Name',
  'Child',
  'Age',
  'Gender',
  'Location',
  'Category',
  'Paypal ID',
  '',
  'Status',
]

const statusColor = (status: any): string => {
  switch (status) {
    case 'IN_REVIEW':
      return 'dark-gray'
    case 'BROKEN':
      return 'pink'
    case 'APPROVED':
      return 'pure-green'
    case 'REJECTED':
      return 'pure-red'
  }
  return 'pure-red'
}

interface RecordSubmissionTableProps {
  sort?: {
    name: string
    order: 'asc' | 'desc'
  }
  getMore: () => void
  onSortChange: (title: string) => void
  hasNextPage: boolean
  loadingMore: boolean
  submissions: any[]
  refreshRows: () => void
}

const GENDERS = {
  MALE: 'Boy',
  FEMALE: 'Girl',
  GENDER_X: 'Gender X',
}

export type AvailableGenders = 'MALE' | 'FEMALE' | 'GENDER_X'

export const getGenderLabel = (gender: AvailableGenders): string => {
  return GENDERS[gender] || GENDERS.GENDER_X
}

const DISABLED_SORT = ['Age', 'Status']

function RecordSubmissionTable({
  sort,
  getMore,
  onSortChange,
  hasNextPage,
  loadingMore,
  submissions,
  refreshRows,
}: RecordSubmissionTableProps) {
  const navigate = useNavigate()

  return (
    <>
      <TableContainer>
        <Table className="table">
          <caption
            className={`no-more-content ${loadingMore ? 'mloader' : ''}`}
          >
            {loadingMore ? (
              <Loader active />
            ) : !hasNextPage ? (
              'There are no more record submissions to be loaded'
            ) : null}
          </caption>
          <TableHead>
            <TableRow>
              {HEADER_TITLES.map((headItem) => (
                <TableCell
                  key={headItem}
                  sortDirection={sort?.order}
                  style={{ padding: '0.5rem' }}
                >
                  <TableSortLabel
                    active={sort?.name === headItem}
                    direction={sort?.order}
                    onClick={() => onSortChange(headItem)}
                    className="table-label"
                    disabled={!!DISABLED_SORT.find((i) => i === headItem)}
                  >
                    {headItem}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {submissions.map((submission, index) => (
              <TableRow
                hover
                onClick={() => navigate(`/admin/submissions/${submission.id}`)}
                key={index + submission.id}
                className="table-row"
              >
                <TableCell>
                  {dayjs(submission.submittedOn).format('MM-DD-YYYY')}
                </TableCell>
                <TableCell>{submission.idea}</TableCell>
                <TableCell>
                  {submission.type === RecordType.GROUP
                    ? 'Group Record'
                    : `${submission.recordOwner?.firstName} ${submission.recordOwner?.lastName}`}
                </TableCell>
                <TableCell>{submission.ageGap}</TableCell>
                <TableCell>{getGenderLabel(submission.gender)}</TableCell>
                <TableCell>{submission.location.city}</TableCell>
                <TableCell>
                  {capitalizeFirstLetter(submission.category)}
                </TableCell>
                <TableCell>{submission.paypalTransactionId}</TableCell>
                <TableCell>
                  {submission.ambassadorId && (
                    <WorkspacePremiumIcon
                      style={{ marginTop: '2px', color: 'orange' }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <p
                    className={`nunito-bold ${statusColor(submission.status)}`}
                  >
                    {capitalizeAndRemoveUnderscode(submission.status)}
                  </p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Conditional shouldRender={!loadingMore && hasNextPage}>
        <InView onInView={getMore} />
      </Conditional>
    </>
  )
}

export default RecordSubmissionTable
