import Joi from 'joi'

export const CUSTOM_PRODUCT_VALIDATOR = Joi.object({
  childRecord: Joi.object({
    childName: Joi.string().required().label('child name'),
    submissionId: Joi.string().required().label('submission'),
  }).required(),
  quantity: Joi.number().min(1).required().label('quantity'),
})

export const GENERIC_PRODUCT_VALIDATOR = Joi.object({
  quantity: Joi.number().min(1).required().label('quantity'),
})

export const buildDefaultValue = (): any => {
  return {
    quantity: 1,
  }
}
