import React, { useEffect } from 'react'
import { Navigate, useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'

import SubmitButton from 'components/SubmitButton'
import BackButton from 'components/BackButton'
import BorderedContainer from 'components/BorderedContainer'
import { Page } from 'components/Page'
import { FormInput } from 'components/Form/FormInput'
import { FormRecordMedias } from 'components/Form/FormRecordMedias'
import { FormDatePicker } from 'components/Form/FormDatePicker'
import Title from 'components/Title'

import { TwoColumnsGrid } from '../RecordSubmissionDetailsEdit/components/TwoColumnsGrid'
import { FormPreviousRecordInfo } from '../RecordSubmissionDetails/components/PreviousRecordInfo/FormPreviousRecordInfo'

import {
  GroupCategory,
  Record,
  SubmissionCreateBody,
} from 'store/api/types/Record.types'

import {
  useCreateRecordSubmissionMutation,
  useLazyGetRecordByIdQuery,
} from 'store/api'

import { SUBMISSION_CREATE_VALIDATOR } from './CreateGroupRecordSubmission.utils'
import { FormCategorySelect } from './components/FormCategorySelect'

import styles from './CreateGroupRecordSubmission.module.scss'
import { FormLocationAutocomplete } from '../../components/Form/FormLocationAutocomplete'
import { useMyRole } from 'hooks/useMyRole'

export const CreateGroupRecordSubmission = () => {
  const navigate = useNavigate()
  const { recordId } = useParams<{ recordId: string }>()
  const [getRecord, { data: recordToBreak }] = useLazyGetRecordByIdQuery()
  const [createSubmission, { isLoading }] = useCreateRecordSubmissionMutation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<SubmissionCreateBody>({
    resolver: joiResolver(SUBMISSION_CREATE_VALIDATOR),
    mode: 'all',
  })

  const { isChild: isChildLogged, isAuthenticated } = useMyRole()
  const onSubmit = async (body: SubmissionCreateBody) => {
    const { timezoneInMiliseconds, ...rest } = body.location
    const res = (await createSubmission({
      ...body,
      location: rest,
    })) as unknown as {
      data: Record
    }
    navigate(`/break-a-record/record-submitted/${res.data.id}`)
  }
  useEffect(() => {
    if (recordId) getRecord(recordId)
  }, [getRecord, recordId])
  useEffect(() => {
    reset({
      idea: recordToBreak?.idea.title,
      recordCategory: {
        category: GroupCategory.SMALL,
        participantAmount: null,
      },
      previousRecordId: recordId,
      brokenOn: new Date(),
    })
  }, [reset, recordToBreak, recordId])

  if (!isAuthenticated || isChildLogged) {
    return <Navigate to="/" />
  }

  return (
    <Page>
      <BorderedContainer>
        <Title>Group Record submission</Title>
        <p className={styles.description}>
          For individual records for one child, click the Hippo icon in the top
          right corner to switch profiles.{' '}
          <strong>
            This form is for submitting records for groups of two or more kids.
          </strong>
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TwoColumnsGrid className={styles.top}>
            <BackButton backUrl={`/submissions`}>Back to Records</BackButton>
            <SubmitButton disabled={isLoading}>Submit</SubmitButton>
          </TwoColumnsGrid>
          <FormInput
            name="idea"
            label="Title"
            control={control}
            errorText={errors?.idea?.message as string}
            disabled={!!recordToBreak}
          />
          <FormCategorySelect
            name="recordCategory"
            control={control}
            error={errors?.recordCategory?.participantAmount?.message as string}
          />
          <FormPreviousRecordInfo
            name="previousRecordId"
            control={control}
            currentRecordCategory={watch('recordCategory.category') || ''}
            currentRecordGender={''}
            currentRecordAge={''}
          />
          <FormInput
            name="description"
            label="Record Description"
            control={control}
            multiline
            errorText={errors?.description?.message as string}
          />
          <FormInput
            name="notes"
            label="Record Notes"
            control={control}
            errorText={errors?.notes?.message as string}
            multiline
          />
          <TwoColumnsGrid className={styles.flexGrid}>
            <FormDatePicker
              name="brokenOn"
              label="Record Date"
              control={control}
            />
            <FormLocationAutocomplete
              control={control}
              name="location"
              label="Record Location"
            />
          </TwoColumnsGrid>
          <FormRecordMedias name="mediaLinks" control={control} />
        </form>
      </BorderedContainer>
    </Page>
  )
}
