import React, { FC } from 'react'
import { Event } from '../../../store/api/types/Event.types'
import { formatDate } from 'utils/datetime'
import { IconButton, TableCell, TableRow, Checkbox } from '@mui/material'
import classNames from 'classnames'
import styles from './EventList.module.scss'
import Conditional from '../../../components/Conditional'
import { DeleteForever, ForwardToInbox, Edit } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useResolvedPath } from 'react-router'

type EventRowProps = {
  event: Event
  sendCertificates: () => void
  onDeleteEvent: () => void
  onChangePrivacy: () => void
  onChangeVisibility: () => void
}

const EventRow: FC<EventRowProps> = ({
  event,
  sendCertificates,
  onDeleteEvent,
  onChangePrivacy,
  onChangeVisibility,
}) => {
  const { pathname: path } = useResolvedPath('')
  const formattedStartDate = formatDate(new Date(event.startDate), 'MM/DD/YYYY')
  const formattedEndDate = formatDate(new Date(event.endDate), 'MM/DD/YYYY')

  const isClosed = new Date().getTime() >= new Date(event.endDate).getTime()

  return (
    <TableRow
      className={classNames(
        styles.row,
        event.isParticipantDeleted && styles.red,
      )}
    >
      <TableCell className={styles.cell} component="th" scope="row">
        <Link
          target="_blank"
          rel="noreferrer"
          className={styles.createEditCampaignLink}
          to={
            event.isPrivate
              ? `/events/${event.slug}?token=${event.token}`
              : `/events/${event.slug}`
          }
          style={{ color: 'blue', textDecoration: 'underline' }}
        >
          {event.name}
        </Link>
      </TableCell>
      <TableCell className={styles.cell}>
        <Link
          target="_blank"
          rel="noreferrer"
          className={styles.createEditCampaignLink}
          to={`/events/${event.slug}?preview=true`}
          style={{ color: 'blue', textDecoration: 'underline' }}
        >
          {event.partnerName}
        </Link>
      </TableCell>
      <TableCell className={styles.cell}>{formattedStartDate}</TableCell>
      <TableCell className={styles.cell}>{formattedEndDate}</TableCell>
      <TableCell className={styles.cell}>
        {event.inReviewAmount + event.rejectedAmount + event.approvedAmount}
      </TableCell>
      <TableCell className={styles.cell}>{event.approvedAmount}</TableCell>
      <TableCell className={styles.cell}>{event.rejectedAmount}</TableCell>
      <TableCell className={styles.cell}>
        <Checkbox checked={event.isHidden} onChange={onChangeVisibility} />
      </TableCell>
      <TableCell className={styles.cell}>
        <Checkbox checked={event.isPrivate} onChange={onChangePrivacy} />
      </TableCell>
      <TableCell className={styles.cell} component="th" scope="row">
        <Link to={`${path}/edit/${event.id}`}>
          <IconButton
            className={styles.editButton}
            aria-label="Edit"
            component="span"
          >
            <Edit />
          </IconButton>
        </Link>
      </TableCell>
      <TableCell className={styles.cell}>
        {isClosed ? (
          <IconButton
            className={`${styles.emailButton} ${
              event.isCertificateSent ? styles.disableIcon : ''
            }`}
            aria-label="Sent Email"
            component="span"
            disabled={event.isCertificateSent}
            onClick={sendCertificates}
          >
            <ForwardToInbox />
          </IconButton>
        ) : null}
      </TableCell>
      <TableCell className={styles.cell}>
        <Conditional shouldRender={!event.isParticipantDeleted}>
          <IconButton
            className={styles.iconButton}
            aria-label="Delete Record"
            component="span"
            onClick={onDeleteEvent}
          >
            <DeleteForever />
          </IconButton>
        </Conditional>
      </TableCell>
    </TableRow>
  )
}

export default EventRow
