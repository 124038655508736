import Joi from 'joi'

export const PARENT_UPDATE_VALIDATOR = Joi.object({
  firstName: Joi.string().required().label('First name'),
  lastName: Joi.string().required().label('Last name'),
  dateOfBirth: Joi.date().required(),
})

export const buildDefaultValue = (parent: any): any => {
  if (!parent) {
    return undefined
  }

  return {
    firstName: parent.firstName,
    lastName: parent.lastName,
    dateOfBirth: new Date(parent.dateOfBirth),
  }
}
