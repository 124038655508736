import React from 'react'

import ShadowModal from 'components/ShadowModal'
import Button from 'components/Button'

interface ConfirmSubmissionApprovalProps {
  isOpen: boolean
  onCancel: () => void
  onConfirm: (id: string, description: string) => void
  submissionId: string
  submissionTitle: string
  submissionDescription: string
  disabled?: boolean
}

const ConfirmSubmissionApproval: React.FC<ConfirmSubmissionApprovalProps> = ({
  isOpen,
  onCancel,
  onConfirm,
  submissionId,
  submissionTitle,
  submissionDescription,
  disabled,
}) => {
  return (
    <ShadowModal isModalOpen={isOpen}>
      <div className="nunito-bold neo-red f4">Approve Record?</div>
      <div className="nunito-regular f5 pt3">{`Are you sure you want to approve ${submissionTitle}?`}</div>
      <div className="flex flex-row justify-end pt5">
        <Button ghost theme="secondary" onClick={onCancel} disabled={disabled}>
          Cancel
        </Button>
        <Button
          ghost
          theme="secondary"
          onClick={() => onConfirm(submissionId, submissionDescription)}
          disabled={disabled}
        >
          Confirm
        </Button>
      </div>
    </ShadowModal>
  )
}

export default ConfirmSubmissionApproval
