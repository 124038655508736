import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { CategorySelect } from './CategorySelect'

type FormCategorySelectProps = {
  name: string
  control: any
  error: string
}

export const FormCategorySelect: FC<FormCategorySelectProps> = ({
  name,
  control,
  error,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CategorySelect
          value={field.value}
          onChange={(category: string, participantAmount: number | null) =>
            field.onChange({
              target: { value: { category, participantAmount } },
            })
          }
          error={error}
        />
      )}
    />
  )
}
