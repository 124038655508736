import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './rootReducer'
import { kwrApi } from './api'

const middlewares = [kwrApi.middleware]

const store = configureStore({
  reducer: rootReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
