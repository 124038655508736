import React from 'react'

import Button from 'components/Button'
import SubmitButton from 'components/SubmitButton'

import RecordRejectFormDatas, {
  RecordSubmissionRejectFormData,
  ValidRecordSubmissionRejectFormData,
} from 'typings/RecordSubmissionRejectFormData'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { REJECTION_VALIDATOR } from './ConfirmSubmission.utils'
import { FormInput } from 'components/Form/FormInput'
import Conditional from 'components/Conditional'
import ShadowModal from 'components/ShadowModal'

import styles from './ConfirmSubmission.module.scss'

interface ConfirmSubmissionRejectProps {
  isOpen: boolean
  onCancel: () => void
  onConfirm: (data: ValidRecordSubmissionRejectFormData) => void
  submissionId: string
  submissionTitle: string
  disabled?: boolean
}

const ConfirmSubmissionReject: React.FC<ConfirmSubmissionRejectProps> = ({
  isOpen,
  onCancel,
  onConfirm,
  submissionId,
  submissionTitle,
  disabled,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RecordSubmissionRejectFormData>({
    resolver: joiResolver(REJECTION_VALIDATOR),
    mode: 'all',
  })

  const submitHandler = (data: RecordSubmissionRejectFormData) => {
    const rejection = { ...data, submissionId }
    if (RecordRejectFormDatas.isValid(rejection)) {
      onConfirm(rejection)
    }
  }

  return (
    <ShadowModal isModalOpen={isOpen} isLarge>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className={styles.submissionTitle}>Reject Record?</div>
        <div
          className={styles.submissionInfo}
        >{`Are you sure you want to reject ${submissionTitle}?`}</div>
        <div className={styles.submissionInfo}>
          <FormInput
            control={control}
            errorText={errors.reason?.message}
            label="Reason for rejection"
            multiline
            name="reason"
            minRows={3}
            disabled={disabled}
          />
        </div>
        <Conditional shouldRender={disabled ? disabled : false}>
          <div className={styles.submissionLoadingInfo}>{`Loading...`}</div>
        </Conditional>
        <div className={styles.buttonsDiv}>
          <Button
            ghost
            theme="secondary"
            onClick={onCancel}
            disabled={disabled}
          >
            Cancel
          </Button>
          <SubmitButton ghost theme="secondary" disabled={disabled}>
            Confirm
          </SubmitButton>
        </div>
      </form>
    </ShadowModal>
  )
}
export default ConfirmSubmissionReject
