import React, { useEffect } from 'react'
import { useParams } from 'react-router'

import BorderedContainer from 'components/BorderedContainer'
import LinkButton from 'components/LinkButton'
import Title from 'components/Title'
import { Page } from 'components/Page'
import Loader from 'components/Loader'
import Conditional from 'components/Conditional'

import { useGetChildRecordByIdQuery } from '../../store/api'
import { CONFIG } from 'config'

type RecordSubmittedParams = {
  id: string
}

const RecordSubmitted: React.FC = () => {
  const { id } = useParams<RecordSubmittedParams>()

  const { data: record, isLoading } = useGetChildRecordByIdQuery(id || '', {
    skip: !id,
  })

  useEffect(() => {
    if (CONFIG.APPLY_ANALYTICS === 'true')
      window?.fbq('trackCustom', 'NewRecord', { id })
  }, [id])

  return (
    <Page>
      <Conditional shouldRender={isLoading}>
        <Loader
          active
          className="z-999"
          style={{
            top: '50%',
            left: 'calc(50% - 30px)',
          }}
        />
      </Conditional>
      <Conditional shouldRender={!isLoading}>
        <BorderedContainer>
          <Title className="pb4">Your record was successfully submitted!</Title>
          <p className="nunito-regular f6 ma0 dark-gray">
            Now, we'll analyze the record "
            <span className="b">{`${record?.idea}`}</span>" and give you a
            feedback as soon as possible!
          </p>
          <div className="flex justify-center pv5">
            <img
              style={{ maxWidth: 300 }}
              src="/assets/high-5.png"
              alt="A high five celebrating the record submission."
            />
          </div>
          <div className="flex flex-column items-center w-100 mb4">
            <LinkButton theme="secondary" className="w-100 w5-ns w5-l" to="/">
              Browse Records
            </LinkButton>
          </div>
        </BorderedContainer>
      </Conditional>
    </Page>
  )
}

export default RecordSubmitted
