import React, { FC } from 'react'
import { Interweave } from 'interweave'
import cn from 'classnames'

import Title from '../../../components/Title'
import { Timer } from './Timer'
import { ImageOrVideo } from '../../../components/ImageOrVideo'
import styles from './ParticipantsInEvent.module.scss'

interface ComingSoonEventProps {
  image: string[]
  title: string
  description: string
  startDate: Date
  onZero: () => void
}

export const ComingSoonEvent: FC<ComingSoonEventProps> = ({
  startDate,
  image,
  title,
  description,
  onZero,
}: ComingSoonEventProps) => {
  return (
    <div className={styles.container}>
      <ImageOrVideo
        className={styles.imageOrVideo}
        alt="event image"
        src={image[0] || ''}
      />
      <div className={cn(styles.columnBox, styles.padding)}>
        <Title className={styles.title}>{title}</Title>
        <span className={styles.timerDescription}>
          This Kids World Record attempt starts in...
        </span>
        <div className={styles.timerBox}>
          <Timer key={'ComingSoonEvent'} deadline={startDate} onZero={onZero} />
        </div>
        <Interweave content={description} />
      </div>
    </div>
  )
}
