import React from 'react'

interface ParagraphProps {
  variant?: 'noMargin' | 'noMarginBottom'
}

const Paragraph: React.FC<ParagraphProps> = ({ children, variant }) => {
  const className = `${variant === 'noMargin' && 'mt0 mb0'} ${
    variant === 'noMarginBottom' && 'mt0 mb0'
  }`

  return (
    <p className={`${className} f4 nunito-regular dark-gray`}>{children}</p>
  )
}

export default Paragraph
