import React from 'react'
import cn from 'classnames'
import { Dialog, DialogContent } from '@mui/material'
import TimerRoundedIcon from '@mui/icons-material/TimerRounded'
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded'
import Title from 'components/Title'

import styles from '../Quiz.module.scss'

const PaperProps = { sx: { borderRadius: '1rem' } }

interface PickTimeModalProps {
  isModalOpen: boolean
  selectedType: 'amount' | 'speed'
  onTypeSelect: (type: 'amount' | 'speed') => void
  onClose: () => void
}

export const PickTypeModal = ({
  isModalOpen,
  onTypeSelect,
  selectedType,
  onClose,
}: PickTimeModalProps): React.ReactElement => {
  const onSelect = (type: 'amount' | 'speed') => {
    onTypeSelect(type)
    onClose()
  }

  return (
    <Dialog
      open={isModalOpen}
      fullWidth
      maxWidth={'md'}
      onClose={onClose}
      PaperProps={PaperProps}
    >
      <DialogContent className={styles.dialogContent}>
        <div className={styles.typeModal}>
          <div className={styles.pickTypeHeader}>
            <Title className={styles.title}>Pick a Quiz Type</Title>
            <p className={styles.description}>
              Choose between a Most or a Fastest Quiz
            </p>
          </div>

          <div className={styles.buttons}>
            <div
              className={cn(
                'card',
                styles.button,
                selectedType === 'amount' && styles.red,
              )}
              onClick={() => onSelect('amount')}
            >
              <DashboardCustomizeRoundedIcon className={styles.typeIcon} />
              <div className={styles.buttonTitle}>Most Quiz</div>
              Rewarding the most correct answers
            </div>

            <div
              className={cn(
                'card',
                styles.button,
                selectedType === 'speed' && styles.red,
              )}
              onClick={() => onSelect('speed')}
            >
              <TimerRoundedIcon className={styles.typeIcon} />
              <div className={styles.buttonTitle}>Speed Quiz</div>
              Rewarding the fastest record breaker
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
