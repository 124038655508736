import React, { FC, useCallback } from 'react'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'

import { Input, InputProps } from './Input'

interface SelectProps {
  label?: string
  name?: string
  value?: string
  disabled?: boolean
  defaultValue?: string
  onChange?(value: string): void
  onFocus?: InputProps['onFocus']
  errorText?: string
  items: { value: string | number; label: string }[]
  className?: string
}

const listItem = {
  fontFamily: `'Nunito', serif !important`,
}

export const Select: FC<SelectProps> = ({
  label,
  disabled,
  items,
  value,
  defaultValue = '',
  onFocus,
  onChange,
  errorText,
  name,
  className,
}) => {
  return (
    <Input
      className={className}
      key={value}
      label={label}
      name={name}
      value={value}
      disabled={disabled}
      onChange={useCallback(
        (event) => (onChange ? onChange(event.target.value) : undefined),
        [onChange],
      )}
      onFocus={onFocus}
      defaultValue={defaultValue}
      errorText={errorText}
      select
    >
      {items.map((item) => (
        <MenuItem key={item.value} value={item.value} sx={listItem}>
          <ListItemText>
            {item.value ? item.label : <em>{item.label}</em>}
          </ListItemText>
        </MenuItem>
      ))}
    </Input>
  )
}
