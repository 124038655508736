import Joi from 'joi'

export interface LoginFormData {
  email: string
}

export const LOGIN_VALIDATOR = Joi.object({
  email: Joi.string()
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      'string.empty': `This field cannot be empty`,
      'any.required': `This is a required field`,
      'string.email': `Enter valid email`,
    }),
})
