import * as Yup from 'yup'
import {
  // requiredDateOfBirth,
  requiredEmail,
  requiredFirstName,
  requiredLastName,
} from './requiredFields'

const Schema = Yup.object().shape({
  firstName: requiredFirstName,
  lastName: requiredLastName,
  email: requiredEmail,
  // dateOfBirth: requiredDateOfBirth,
  howDidYouFoundUs: Yup.string().required('Field is required'),
})

export default Schema
