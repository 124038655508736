import React, { FC } from 'react'
import { Box } from '@mui/material'

import Conditional from 'components/Conditional'
import { Checkbox } from 'components/Inputs/Checkbox'
import { Input } from 'components/Inputs'
import { WhiteList } from './types'

import styles from './CampaignForm.module.scss'

type WhitelistedEmailsInputProps = {
  whitelist: WhiteList
  disabled?: boolean
  onChange: (data: WhiteList) => void
  errorText?: string
}

export const WhitelistedEmails: FC<WhitelistedEmailsInputProps> = ({
  whitelist,
  disabled,
  onChange,
  errorText,
}) => {
  return (
    <Box className={styles.whitelistBox}>
      <Checkbox
        name="whitelistEnabled"
        label="Whitelist required"
        checked={whitelist.isEnabled}
        onClick={() =>
          onChange({ ...whitelist, isEnabled: !whitelist.isEnabled })
        }
      />
      <Conditional shouldRender={whitelist.isEnabled}>
        <Input
          name="whitelistedEmails"
          label="Emails"
          multiline
          defaultValue={whitelist?.emails?.join('\n')}
          disabled={disabled}
          errorText={errorText}
        />
      </Conditional>
    </Box>
  )
}
