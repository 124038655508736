import React from 'react'
import cn from 'classnames'

import { Product } from 'data/Product'

import Button from 'components/Button'
import InfoCard from 'components/InfoCard'

import styles from './BuyContainer.module.scss'

interface BuyContainerProps {
  product: Product
  disabled: boolean
}

export const BuyContainer: React.FC<BuyContainerProps> = ({
  product,
  disabled,
}) => {
  const price = Number(product?.price) || 0
  const formattedPrice = `$${price.toFixed(2)}`
  const infoCardId = 'info-card' + product.id

  const handleDisabledClick = (e: any) => {
    if (disabled) {
      e.preventDefault()

      const infoCardDom = document.getElementById(infoCardId)

      if (infoCardDom) {
        infoCardDom.style.visibility = 'visible'
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.constainerContent}>
        <div className={styles.rowDiv}>
          <div className={styles.btnDiv}>
            <Button
              className={cn(styles.btn, disabled ? styles.btnDisabled : '')}
              endIcon={
                <img
                  src="/assets/shopping-cart.svg"
                  alt="a trophy representing the record to be claimed"
                />
              }
              theme="primary"
              onClick={handleDisabledClick}
            >
              BUY NOW
            </Button>
          </div>
          <p
            className={cn(
              styles.price_tag,
              disabled ? styles.priceColorDis : styles.priceColor,
            )}
          >
            {formattedPrice}
          </p>
        </div>
        <InfoCard id={infoCardId} />
      </div>
    </div>
  )
}
