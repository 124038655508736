import { getVariantTitleInfo } from '../../data/Product'
import { Product as ShopifyProduct } from 'shopify-buy'
import { Product } from './types/Product.types'

export const transformProductData = (
  fetchedProduct: ShopifyProduct,
): Product => {
  const productImageLinks = fetchedProduct?.images
    ? fetchedProduct.images.map((image: any) => image.src)
    : []
  const productVariantIds = fetchedProduct?.variants
    ? fetchedProduct.variants.map((variant: any) => variant.id)
    : []
  const variantTitleInfo = fetchedProduct
    ? getVariantTitleInfo(fetchedProduct)
    : []
  const isCustomProduct = variantTitleInfo.length
    ? variantTitleInfo[1].trim() === 'custom'
    : false

  return {
    id: fetchedProduct.id,
    title: fetchedProduct.title,
    description: fetchedProduct.description,
    info: variantTitleInfo[0],
    price: fetchedProduct.variants[0].price.amount,
    imageLinks: productImageLinks,
    variantIds: productVariantIds,
    isCustom: isCustomProduct,
    previewImageUrl: productImageLinks[0],
  }
}
