import Joi from 'joi'

export const PIN_VALIDATOR = Joi.object({
  pin: Joi.array()
    .items(
      Joi.string()
        .regex(/^[0-9]*$/)
        .required()
        .messages({
          'string.empty': 'Pin should have four digits',
          'string.pattern.base': 'Should be a number',
          'any.required': 'Pin should have four digits',
        }),
    )
    .length(4)
    .required()
    .messages({
      'array.length': 'Pin should have four digits',
      'string.pattern.base': 'Should be a number',
      'array.includes': 'Pin should have four digits',
      'array.includesRequiredUnknowns': 'Pin should have four digits',
      'any.required': 'Pin should have four digits',
    }),
})
