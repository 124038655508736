import React, { FC, useEffect, useState } from 'react'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useGetQuizzesQuery } from 'store/api'

import styles from './QuizList.module.scss'

type QuizListProps = {
  onSelect: (id: string) => void
}

export const QuizList: FC<QuizListProps> = ({ onSelect }) => {
  const { data: quizzes = [] } = useGetQuizzesQuery()
  const [swiper, setSwiper] = useState<any>(null)

  useEffect(() => {
    quizzes.length && onSelect(quizzes[0].id)
  }, [quizzes, onSelect])

  const SwiperButtonNext = () => (
    <h2 className={styles.moreBtn} onClick={() => swiper.slideNext()}>
      More
      <ArrowForwardIos className={styles.arrow} />
    </h2>
  )

  const SwiperButtonPrev = () => (
    <h2 className={styles.moreBtn} onClick={() => swiper.slidePrev()}>
      <ArrowBackIos className={styles.arrow} />
      Back
    </h2>
  )

  return (
    <div className={styles.swiper}>
      <SwiperButtonPrev />
      <Swiper slidesPerView={'auto'} onSwiper={setSwiper}>
        {quizzes.map((quiz) => (
          <SwiperSlide
            key={quiz.id}
            style={{ backgroundColor: quiz.themeColor }}
            className={styles.swiperSlide}
            onClick={() => onSelect(quiz.id)}
          >
            {quiz.title}
          </SwiperSlide>
        ))}
      </Swiper>
      <SwiperButtonNext />
    </div>
  )
}
