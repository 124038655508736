import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { PinInput } from '../Inputs/PinInput'

interface FormPinInputProps {
  name: string
  control: any
  errorText?: string
  isMasked?: boolean
  isFocused?: boolean
}

export const FormPinInput: FC<FormPinInputProps> = ({
  name,
  errorText,
  control,
  isMasked,
  isFocused,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <PinInput
          value={field.value}
          onChange={field.onChange}
          errorText={errorText}
          isMasked={isMasked}
          isFocused={isFocused}
        />
      )}
    />
  )
}
