import React from 'react'

import BorderedContainer from '../../components/BorderedContainer'
import Paragraph from '../../components/Paragraph'
import SubTitle from '../../components/SubTitle'
import Title from '../../components/Title'
import { Page } from '../../components/Page'

const PrivacyPolicyV1: React.FC = () => {
  window.scrollTo(0, 0)

  return (
    <Page>
      <BorderedContainer>
        <Title>Privacy Policy</Title>
        <SubTitle className="pb2 pt4">Scope of this Policy</SubTitle>
        <Paragraph variant="noMarginBottom">
          This Privacy Policy sets forth the manner in which we handle the
          information that is provided or collected on the websites and
          applications where this Privacy Policy is posted, including our
          digital properties or applications that we make available on
          third-party sites or platforms. We adhere to this Privacy Policy in
          compliance with the relevant laws applicable in the jurisdictions
          where we conduct business. In some instances, we may issue additional
          data privacy notices that are specific to particular products,
          practices, or regions, and such terms must be read together with this
          Policy. Please note that when you furnish information to us on a
          third-party site or platform, such information may be independently
          collected by the third-party site or platform. While the information
          we collect is subject to this Privacy Policy, the information
          collected by the third-party site or platform is subject to the
          privacy practices of that site or platform. The privacy preferences
          you select on the third-party site or platform do not apply to our use
          of the information we have directly collected through our
          applications. It is further important to note that our sites and
          applications may include links to other sites that we do not own or
          control, and we disclaim responsibility for the privacy practices of
          such sites. We encourage you to exercise caution when exiting our
          sites or applications and to review the privacy policies of other
          sites that may collect your personal information.
        </Paragraph>
        <SubTitle className="pb2 pt4">Introduction</SubTitle>
        <Paragraph>
          Kids World Records ("KWR" or "we" or "us" or "our") acknowledges and
          respects the privacy rights of individuals and is dedicated to
          ensuring a secure, safe, and transparent environment to safeguard the
          personal data that we collect from you. This Privacy Policy governs
          our use of any personal data that we collect from you through the
          following means:
        </Paragraph>
        <Paragraph>
          • Kidsworldrecords.com, related sub-domains, counterpart sites in
          other languages, and any other KWR website that links to this Privacy
          Policy (the "Site");
        </Paragraph>
        <Paragraph>
          • Digital applications made available through third-party platforms
          (the "Apps");
        </Paragraph>
        <Paragraph>
          • Telephone and written correspondence including email, text, and
          letters; and
        </Paragraph>
        <Paragraph>
          • Directly from you in person, for example at a KWR event, challenge
          fair, or third-party event in which KWR participates (the "Events").
          (The Site, the Apps, and the Events are collectively known as the "KWR
          Products & Services").
        </Paragraph>
        <Paragraph>
          This Privacy Policy explains the following with regard to your
          personal data:
        </Paragraph>
        <Paragraph>
          • The type of information that KWR may collect about you;
        </Paragraph>
        <Paragraph>• How KWR may use that information;</Paragraph>
        <Paragraph>
          • When KWR may use your personal data to contact you;
        </Paragraph>
        <Paragraph>
          • Whether KWR will share your personal data with any third parties;
          and
        </Paragraph>
        <Paragraph>• Your legal rights.</Paragraph>
        <Paragraph>
          If you sign up for a user account on this Site, apply to attempt an
          official KWR record attempt or enter a competition or quiz organized
          by KWR, you may be subject to additional policies in addition to this
          Privacy Policy. We will explain these additional policies to you
          before your registration or entry to those events.
        </Paragraph>
        <SubTitle className="pb2 pt4">Introduction</SubTitle>
        <Paragraph>
          At KWR, we believe that every child has the potential to break
          records, not sometime in the distant future as adults, but at this
          very moment! Our platform provides children with a safe and supportive
          environment to pursue their passions with boundless enthusiasm and
          creativity, all while striving towards a tangible goal. Whether you
          have a groundbreaking idea for a new record or want to surpass an
          existing one, we welcome it all! Join us in the pursuit of fun and put
          your own unique spin on history - it's YOUR chance to shine!
        </Paragraph>
        <SubTitle className="pb2 pt4">
          What information will KWR collect about me?
        </SubTitle>
        <Paragraph>
          As the ultimate global authority on kids' record-breaking
          achievements, KWR maintains a large database of record-related
          information. This includes personal details such as name, address,
          telephone number, age, gender, and occupation of individuals or groups
          who hold those records (the "KWR Database"). By participating in,
          accessing, signing up for, or purchasing products from any of the KWR
          Products & Services, you may provide personal data to us, such as
          those detailed above. In providing the KWR Products & Services, we
          make use of cookies. These are small pieces of information stored by
          your browser on your device's hard drive. Please see our Cookie Policy
          for more information on cookies. We will only request the disclosure
          of personal data that we reasonably deem necessary to participate in
          the activity, fulfil your request, or achieve our legitimate business
          objectives. However, if personal data is required for participation in
          an activity, you cannot participate without disclosing such personal
          data. We will not ask you to disclose sensitive personal data, such as
          religious or political beliefs or your medical history. However, in
          the event that you have voluntarily sent us such information (for
          example, to support a record attempt application), we will contact you
          to clarify the terms of its use.
        </Paragraph>
        <SubTitle className="pb2 pt4">Children under 13 years of age</SubTitle>
        <Paragraph>
          As a matter of policy, we require parental or guardian involvement in
          their children's submission of personal data through our online
          experiences. It is recommended that parents discuss our Privacy Policy
          with their children to ensure they fully understand how to use KWR
          Products & Services and the types of information we may request from
          them. In all instances, including when the user is under the age of
          13, KWR Submissions, Products & Services will request personal data
          about the child. The Parent will be prompted to provide contact
          details as parent or legal guardian, and will be asked to provide
          consent for the child to provide personal data. If the parent or
          guardian does not consent, the child must not provide personal data.
          The child's email address can be entered by the Parent or legal
          guardian for login purposes only. For users under the age of 13, our
          collection of personal data is restricted to the following areas:
          Record attempt applications: Parents may submit record attempts by
          creating a Parental account and adding the child to the Parent
          account. This require the provision of an email address for account
          verification and the name and date of birth of the user. However, the
          name, and age of the child will also be required in order for us to
          determine the appropriate age group for the child, to display records
          and to produce products for KWR record holders. Following receipt of
          consent, personal data collected as part of this process will be used
          in accordance with the terms of services. Competitions: We
          periodically provide competitions, and the terms of each competition
          will dictate whether or not children under the age of 13 may
          participate. If under-13s are permitted to enter the competition, we
          will request an email address of the child's parent or legal guardian.
        </Paragraph>
        <SubTitle className="pb2 pt4">Third party advertisements</SubTitle>
        <Paragraph>
          At KWR, we prioritize the privacy of our users and strive to provide a
          safe online environment for all ages, particularly for children under
          the age of 13. As such, we want to be clear that we do not display any
          third-party advertisements on our Products & Services. While we may
          provide links to websites operated by third parties, these sites are
          not affiliated with KWR and we are not responsible for their content,
          policies or practices. It is important to note that such links or
          advertisements do not constitute sponsorship, endorsement or approval
          by us of the content, policies or practices of these websites. Please
          be aware that these third-party websites may collect information about
          your visits to our site and other KWR Products & Services, though this
          information will not include your personal contact details. This
          information may be used to provide advertisements for goods and
          services that may be of interest to you. We respect your right to
          privacy and provide information about these practices in our Cookie
          Policy. If you would like to know more about these practices and how
          you can opt out of having this information used, please see the
          details in our Cookie Policy. Furthermore, we want to assure parents
          and guardians that we do not permit interest-based advertising on any
          element of our Products & Services that is specifically targeted to
          children under the age of 13, nor do we knowingly collect personal
          information from children under the age of 13 without parental
          consent. We take the protection of children's privacy seriously and
          encourage parents and guardians to monitor their children's online
          activities.
        </Paragraph>
        <SubTitle className="pb2 pt4">
          How will KWR use the information it collects about me?
        </SubTitle>
        <Paragraph>
          KWR's use of your personal data is subject to the following purposes:
        </Paragraph>
        <Paragraph>
          • Ensuring the accuracy and currency of the KWR Database;
        </Paragraph>
        <Paragraph>
          • Personalizing and improving the KWR Products & Services, as well as
          your user experience;
        </Paragraph>
        <Paragraph>
          • Conducting internal marketing and demographic studies;
        </Paragraph>
        <Paragraph>
          • Complying with applicable laws or requests by law enforcement
          agencies;
        </Paragraph>
        <Paragraph>
          • Protecting the rights, property, or safety of the KWR Products &
          Services and their users.
        </Paragraph>
        <Paragraph>
          If KWR intends to use your personal data for any purpose not listed
          above, your consent will be obtained prior to such use. The KWR
          Database contains a unique record of children's achievements, and due
          to the archival nature of KWR's activities, personal data may be
          stored for a significant period of time. This is necessary to ensure
          that the most current record-holder can be identified and shared with
          audiences, for example in the event of a broken record.
        </Paragraph>
        <SubTitle className="pb2 pt4">
          Will KWR share my data with anyone else?
        </SubTitle>
        <Paragraph variant="noMargin">
          KWR assures you that your child's information will never be sold.
          However, there may be instances where your personal data will be
          shared between our offices. For instance, if your record attempt
          application requires expertise not available in our New York office,
          it may be processed by a KWR representative in Toronto. We may also
          share personal data collected from any KWR Products & Services with
          other KWR Products & Services. Generally, we will keep your personal
          data within KWR, except when disclosure is legally required or
          permitted, or when you have given your consent for sharing it with a
          third party. KWR shares data with trusted partners globally, such as
          book publishers and printing houses. By submitting your data, images,
          and record information, you consent to sharing your personal data with
          these companies to promote your achievement further. We require that
          all our partners and licensees adhere to appropriate levels of
          personal data security, including protection against unauthorized or
          unlawful processing, and accidental loss, destruction, or damage,
          using suitable technical or organizational measures. KWR does not sell
          or disclose personal data to third parties, except as stated in this
          section. In case of a merger, acquisition, reorganization, or similar
          event, your personal data may be transferred or shared with our
          successors.
        </Paragraph>
        <SubTitle className="pb2 pt4">
          Your legal rights to your personal data
        </SubTitle>
        <Paragraph variant="noMargin">
          As a user, you have the right to withdraw any consents you may have
          provided to KWR for the processing of your or your child's personal
          data, which can be done by contacting us at
          personaldata@kidsworldrecords.com. It is important to note that
          withdrawing your consent(s) will not affect the lawfulness of any
          processing KWR has already carried out prior to the withdrawal. If you
          have any questions or concerns regarding the handling of your personal
          data by KWR, you may also contact us at
          personaldata@kidsworldrecords.com. We are committed to protecting the
          privacy of children, and will delete any personal data provided by a
          child without parental consent. If you become aware that your child
          has provided personal data to us without your consent, please contact
          us and we will promptly delete any such data. Additionally, you may
          contact us at any time to inquire about whether we hold any personal
          data related to your child.
        </Paragraph>
        <Paragraph variant="noMargin">
          When you participate in, access, sign up to, or buy products from, any
          of the KWR Products & Services we may receive personal data from you
          such as those detailed above.
        </Paragraph>
        <SubTitle className="pb2 pt4">
          Agreement to this privacy policy and privacy policy modifications
        </SubTitle>
        <Paragraph variant="noMargin">
          By accessing and using KWR Products & Services, you are indicating
          your acceptance of this Privacy Policy. If you do not agree to any of
          the terms set forth in this policy, please refrain from using the KWR
          Products & Services. We reserve the right to modify, amend, or update
          this Privacy Policy at any time, without prior notice. Any substantial
          changes to this Privacy Policy will be communicated to you through a
          notice on this Site or by email sent to the primary email address
          provided by you. We encourage all users of the KWR Products & Services
          to periodically review this Privacy Policy to stay informed about our
          current privacy policies and practices.
        </Paragraph>
      </BorderedContainer>
    </Page>
  )
}

export default PrivacyPolicyV1
