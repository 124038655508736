import React from 'react'

import BorderedContainer from 'components/BorderedContainer'
import LinkButton from 'components/LinkButton'
import Title from 'components/Title'
import { Page } from 'components/Page'

import styles from './AboutUs.module.scss'
import { WorldMap } from 'scenes/WorldMap/WorldMap'

const activityList = [
  {
    name: 'Digital & Gaming',
    imageLink: '/assets/aboutUs/about_image_1.png',
    description:
      'Our digital platform includes digital events that allows kids to participate as record breakers from anywhere – for free.',
  },
  {
    name: 'Camps and School Events',
    imageLink: '/assets/aboutUs/about_image_2.png',
    description:
      'From local in-person events to virtual worldwide attempts, our team can help come up with the perfect record attempt, and we provide a digital platform to make the experience a breeze!',
  },
  {
    name: 'Publishing',
    imageLink: '/assets/aboutUs/about_image_3.png',
    description:
      'Kids World records has been published annually since 2021. The book includes all record holders approved since August the past year.',
  },
  {
    name: 'Content for Broadcast/ Digital',
    imageLink: '/assets/aboutUs/about_image_4.png',
    description:
      'We license our clips, stills and short-form content from our vast video and image library presented as supporting evidence for record claims. ',
  },
]

const valuesList = [
  {
    name: 'Safe',
    imageLink: '/assets/aboutUs/about_image_5.png',
    description:
      'The KWR platform strictly enforces parental consent and encourages parental support. Safety guidelines are strictly adhered to and any records deemed dangerous are rejected.',
  },
  {
    name: 'Educational',
    imageLink: '/assets/aboutUs/about_image_6.png',
    description:
      'Kids World Records provides a free curriculum that follows Ontario teaching standards. Topics covered include Grade 2, 3 Mathematics and Grade 5 Science and Mathematics.',
  },
  {
    name: 'Diverse and inclusive',
    imageLink: '/assets/aboutUs/about_image_7.png',
    description:
      'Kids World Records promotes global diversity and inclusivity by offering its services to all genders and ethnicities, ensuring representation and equal opportunity worldwide.',
  },
]

export const AboutUs: React.FC = () => (
  <Page>
    <BorderedContainer>
      <Title>About Kids World Records</Title>
      <h2 className={styles.subTitle}>Our Mission</h2>
      <div className={styles.imageDiv}>
        <img
          className={styles.imageLeft}
          src="/assets/aboutUs/about_image_8.jpeg"
          alt={'About Kids World Records'}
        />
        <p className={styles.paragraph}>
          We believe that every child possesses the capacity for greatness. Our
          mission is simple yet powerful: to offer children aged 4-16 the
          opportunity to make record breaking history competing on a fair
          playing field.
        </p>
      </div>
      <h2 className={styles.subTitle}>What we do</h2>
      <div className={styles.imageDiv}>
        <img
          className={styles.imageRigth}
          src="/assets/aboutUs/about_image_9.jpeg"
          alt={'What we do'}
        />
        <p className={styles.paragraph}>
          We measure and chronicle the extraordinary. Our platform highlights
          young record-breakers from all over the world and help share their
          amazing achievement and histories. Have an idea for a record that’s
          never been broken? Send it to us, we already love it! Want to break an
          existing record? We love that, too! Follow the fun and make it your
          own -<strong> history is waiting for you!</strong>{' '}
        </p>
        <p className={styles.paragraph}>
          Our free services support kids breaking records and we help schools,
          camps, and other organizations in creating engaging activities and
          captivating content, including show-stopping photo opportunities.
          Setting a world record creates unforgettable memories that kids love
          and share.
        </p>
      </div>
      <div className={styles.activityList}>
        {activityList.map((item) => (
          <div className={styles.cardInfo} key={item.name}>
            <p className={styles.cardName}>{item.name}</p>
            <img
              className={styles.cardImage}
              src={item.imageLink}
              alt={item.name}
            />
            <p className={styles.recordsFactsText}>{item.description}</p>
          </div>
        ))}
      </div>

      <h2 className={styles.subTitle}>Where to find us</h2>
      <p className={styles.paragraph}>
        Kids World Records is located in Toronto Canada, but you can find our
        world record holders all over the world. Check out the map and see if
        you could be the first to represent your country on Kids World Records.{' '}
      </p>
      <WorldMap isTableVisible={false} />
      <h2 className={styles.subTitle}>Our values</h2>
      <p className={styles.paragraph}>
        At Kids World Records, we celebrate the creativity, ingenuity, and
        persistence of young minds. Our mission is to provide a safe and
        supportive platform for children aged 4-16 to achieve remarkable feats,
        share their extraordinary stories, and inspire a sense of wonder. We
        believe in creating an exclusive space where kids can enjoy the thrill
        of setting records without competing against adults, ensuring a fair and
        fun environment for all. Committed to promoting global diversity and
        inclusivity, Kids World Records welcomes participants of all ages,
        genders, and ethnicities, providing equal opportunities and
        representation worldwide.
      </p>
      <p className={styles.paragraph}>
        Kids World Records acknowledges all Indigenous people in Canada and
        their traditional territories. We acknowledge all those nations that,
        across the Country, have called this land their home.
      </p>

      <div className={styles.valuesList}>
        {valuesList.map((item) => (
          <div className={styles.cardInfo} key={item.name}>
            <p className={styles.cardName}>{item.name}</p>
            <img
              className={styles.cardImage}
              src={item.imageLink}
              alt={item.name}
            />
            <p className={styles.recordsFactsText}>{item.description}</p>
          </div>
        ))}
      </div>
      <div className={styles.button_wrapper}>
        <LinkButton to="/" className={styles.button} theme="primary">
          Browse Records
        </LinkButton>
        <h3>OR</h3>
        <LinkButton
          to="/break-a-record"
          className={styles.button}
          theme="primary"
        >
          Submit a Record
        </LinkButton>
      </div>
    </BorderedContainer>
  </Page>
)
