import React from 'react'
import Badge from '@mui/material/Badge'
import cn from 'classnames'

import { IconName } from 'components/Icon/IconName'
import IconButton from 'components/IconButton'

import './badge-button.css'

interface BadgeButtonProps {
  badgeContent?: React.ReactNode
  className?: string
  iconName: IconName
  onClick?: () => void
}
const BadgeButton: React.FC<BadgeButtonProps> = ({
  badgeContent,
  className,
  iconName,
  onClick,
}) => (
  <Badge badgeContent={badgeContent} className={cn('badge-button', className)}>
    <IconButton name={iconName} onClick={onClick} />
  </Badge>
)

export default BadgeButton
