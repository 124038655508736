import React, { FC } from 'react'
import LinkButton from 'components/LinkButton'
import Title from 'components/Title'
import { Page } from 'components/Page'
import { SwiperWorldMap } from './SwiperWorldMap'
import { WorldMap } from './WorldMap'

import styles from './WorldMap.module.scss'

export const WorldMapPage: FC = () => {
  return (
    <Page>
      <Title className={styles.titlePage}>Kids World Records on the Map</Title>
      <SwiperWorldMap />
      <WorldMap isTableVisible={true} />
      <p className={styles.description}>
        Click here to get ideas for cool records to set
      </p>
      <div className={styles.linkButton}>
        <LinkButton to="/record-ideas">Record Ideas</LinkButton>
      </div>
    </Page>
  )
}
