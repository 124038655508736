import React from 'react'

interface VideoProps {
  src: string
  onEndHandler(): void
  onClick?(): void
}

export const Video = ({ src, onEndHandler, onClick }: VideoProps) => {
  return (
    <video
      autoPlay
      muted
      playsInline
      onClick={onClick}
      src={src}
      width="100%"
      onEnded={() => onEndHandler()}
    />
  )
}
