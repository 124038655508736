import React from 'react'

import Button, { ButtonProps } from 'components/Button'

interface SubmitButtonProps extends ButtonProps {}

const SubmitButton: React.FC<SubmitButtonProps> = ({ ...props }) => (
  <Button type="submit" {...props} />
)

export default SubmitButton
