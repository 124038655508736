import React, { useState } from 'react'
// import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'

import BorderedContainer from 'components/BorderedContainer'
import Stepper from 'components/Stepper'
import Title from 'components/Title'

import { CharityParams } from 'data/Charity'
import Profiles from 'data/Profile'

// import { AuthenticationContext } from 'providers/AuthenticationProvider'

// import { ValidEventDetailsFormData } from 'typings/EventDetailsFormData'
// import { ValidFundraiserFormData } from 'typings/FundraiserFormData'

import Details from './Details'
import Disclaimer from './Disclaimer'
import Fundraiser from './Fundraiser'
import RecordForCharityInfo from './RecordForCharityInfo'

const BreakRecord: React.FC = () => {
  const [step, setStep] = useState<'details' | 'disclaimer' | 'fundraiser'>(
    'disclaimer',
  )

  const [fundraiserValues, setFundraiserValues] = useState<any | null>(null)

  // const { isAuthenticated } = useContext(AuthenticationContext)
  const isAuthenticated = true

  const loggedProfile = undefined as any

  // const dispatch = useDispatch()

  const handleSubmitDetails = (data: any) => {
    const {
      category,
      breakDate,
      breakTime,
      childProfileId,
      description,
      imageLinks,
      location,
      title,
    } = data

    const time = dayjs(breakTime)
    const breakAt = dayjs(breakDate)
      .add(time.get('hours'), 'hours')
      .add(time.get('minutes'), 'minutes')
      .toDate()

    const params: CharityParams = {
      breakAt,
      childProfileId,
      description,
      imageLinks,
      location,
      idea: {
        category,
        title,
      },
      fundraiser: fundraiserValues?.charityInstitution!,
      organizer: {
        name:
          fundraiserValues?.kindOfOrganizer === 'team'
            ? fundraiserValues?.teamName!
            : Profiles.getFullName(loggedProfile)!,
        type: fundraiserValues?.kindOfOrganizer || 'solo',
      },
    }
    console.log(params)
    // dispatch(createCharityAsync.request(params))
  }

  const handleSubmitFundraiser = (data: any) => {
    setStep('details')
    setFundraiserValues(data)
  }

  const steps = ['Disclaimer', 'Fundraiser', 'Record Details']

  const getStepIndex = (): number => {
    switch (step) {
      case 'disclaimer':
        return 0
      case 'fundraiser':
        return 1
      case 'details':
        return 2
    }
  }
  return (
    <>
      <BorderedContainer>
        <Title>Records for Charity</Title>
        {isAuthenticated ? (
          <>
            <Stepper activeStep={getStepIndex()} steps={steps} />
            {step === 'disclaimer' && (
              <Disclaimer onSubmit={() => setStep('fundraiser')} />
            )}
            {step === 'fundraiser' && (
              <Fundraiser onSubmit={handleSubmitFundraiser} />
            )}
            {step === 'details' && (
              <Details
                loggedProfile={loggedProfile}
                onSubmit={handleSubmitDetails}
              />
            )}
          </>
        ) : (
          <RecordForCharityInfo />
        )}
      </BorderedContainer>
    </>
  )
}

export default BreakRecord
