import * as Yup from 'yup'

export const requiredDateOfBirth = Yup.date().required(
  'Date of birth is required',
)
export const email = Yup.string().email('Email should be valid')

export const requiredEmail = email.required('Email is required')

export const requiredFirstName = Yup.string().required('First name is required')

export const requiredLastName = Yup.string().required('Last name is required')

export const requiredLocation = Yup.object()
  .nullable()
  .shape({
    country: Yup.string().required('country is required'),
    city: Yup.string().required('city is required'),
    state: Yup.string().required('state is required'),
  })
  .required()
