import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router'

import { isScrollEnabled } from 'store/app/selectors'

import ShopCart from 'components/ShopCart'
import Splash from 'components/Splash'
import { NewsletterSignUp } from 'components/NewsletterSignUp'

import { handlePageScroll } from 'utils/dom'
import Toaster from 'components/Toaster'
import { useDispatch } from 'react-redux'
import { closeMenu } from 'store/app/actions'
import { isContentOverlay, getToast } from 'store/app/selectors'
import cn from 'classnames'

import { Footer } from './Footer'
import Header from './Header'
import { CookiePolicyPopUp } from 'components/CookiePolicyPopUp'

const ContentOverlay: React.FC<{ active: boolean; onClick: () => void }> = ({
  active,
  onClick,
}) => (
  <div
    className={cn(
      active ? 'db' : 'dn',
      'z-2 aspect-ratio--object bg-black-20 pointer',
    )}
    onClick={onClick}
  />
)
const Layout: React.FC = ({ children }) => {
  const scrollEnabled = useSelector(isScrollEnabled)
  handlePageScroll(scrollEnabled)

  const dispatch = useDispatch()

  const contentOverlay = useSelector(isContentOverlay)
  const toast = useSelector(getToast)
  return (
    <Splash>
      <Header />
      <Toaster toast={toast} />
      <ContentOverlay
        active={contentOverlay}
        onClick={() => dispatch(closeMenu())}
      />
      <Outlet />
      <CookiePolicyPopUp />
      <Footer />
      <ShopCart />
      <NewsletterSignUp />
    </Splash>
  )
}
export default Layout
