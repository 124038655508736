import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLazyGetRandomIdeaLegacyQuery } from 'store/api'

import BorderedContainer from 'components/BorderedContainer'
import Button from 'components/Button'
import Title from 'components/Title'
import { Page } from 'components/Page'
import LoadingTextRow from 'components/LoadingTextRow'

import './IdeaGenerator.css'

function IdeaGenerator() {
  const [ideas, setIdeas] = useState<Array<string>>([])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [trigger] = useLazyGetRandomIdeaLegacyQuery()
  useEffect(() => {
    trigger()
      .unwrap()
      .then((ideas: string[]) => setIdeas(ideas || []))
  }, [trigger])
  const showRecord = () => {
    setIsLoading(true)
    trigger()
      .unwrap()
      .then((idea: string[]) => {
        if (hasDuplicates(ideas, idea)) {
          showRecord()
        } else {
          setIdeas(idea || [])
          setIsLoading(false)
        }
      })
  }
  const hasDuplicates = (arr1: string[], arr2: string[]) => {
    const set = new Set(arr1)
    for (const item of arr2) {
      if (set.has(item)) {
        return true
      }
    }
    return false
  }

  return (
    <Page>
      <BorderedContainer>
        <Title className="pb3 pb4-l">Record Generator</Title>
        <h4 className="colored">
          We want to see what you can do! Try the Record Generator for cool
          records to set.
        </h4>
        <LoadingTextRow
          className="margin-inf-rw"
          loading={isLoading}
          title={ideas[0] || ''}
          onClick={() =>
            // @ts-ignore
            navigate('/break-a-record', { ideaTitle: ideas[0] })
          }
        />
        <LoadingTextRow
          className="margin-inf-rw"
          loading={isLoading}
          title={ideas[1] || ''}
          onClick={() =>
            // @ts-ignore
            navigate('/break-a-record', { ideaTitle: ideas[1] })
          }
        />
        <LoadingTextRow
          className="margin-inf-rw"
          loading={isLoading}
          title={ideas[2] || ''}
          onClick={() =>
            // @ts-ignore
            navigate('/break-a-record', { ideaTitle: ideas[2] })
          }
        />
        <div className={`buttons-idea-gen margin margin-inf-rw`}>
          <Button onClick={showRecord} disabled={isLoading}>
            Show me records
          </Button>
        </div>
      </BorderedContainer>
    </Page>
  )
}

export default IdeaGenerator
