import Joi from 'joi'
import { GroupCategory } from 'store/api/types/Record.types'
import { validateParticipantAmount } from '../CreateGroupRecordSubmission/CreateGroupRecordSubmission.utils'

export const SUBMISSION_EDIT_VALIDATOR = Joi.object({
  idea: Joi.string().required().label('Title'),
  previousRecordId: Joi.string().uuid().allow(null, ''),
  description: Joi.string().required().label('Description'),
  notes: Joi.string().allow(null, ''),
  mediaLinks: Joi.array().items(Joi.string()).min(1).required(),
  isFirstRecord: Joi.boolean().required(),
  requireParentalSupervision: Joi.boolean().required(),
  requireTimingDevice: Joi.boolean().required(),
  noNewRecord: Joi.boolean().required(),
  recordCategory: Joi.object({
    category: Joi.string()
      .valid(...Object.values(GroupCategory))
      .required(),
    participantAmount: Joi.number()
      .integer()
      .required()
      .custom((value, helpers) => {
        if (
          !validateParticipantAmount(helpers.state.ancestors[0].category, value)
        ) {
          return helpers.error('any.invalid')
        }
        return value
      }),
  }).required(),
})

export const buildDefaultValue = (submission: any): any => {
  if (!submission) {
    return undefined
  }

  return {
    idea: submission.idea,
    description: submission.description,
    notes: submission.notes,
    previousRecordId: submission.previousRecordId,
    mediaLinks: submission.mediaLinks,
    recordCategory: {
      category: submission.category,
      participantAmount: submission.participantAmount,
    },
    isFirstRecord: submission.isFirstRecord,
    requireParentalSupervision: submission.requireParentalSupervision,
    requireTimingDevice: submission.requireTimingDevice,
    noNewRecord: submission.noNewRecord,
  }
}
