import React from 'react'
import cn from 'classnames'

import './loader.css'

interface LoaderProps {
  active?: boolean
  className?: string
  style?: React.CSSProperties
}

const Loader: React.FC<LoaderProps> = ({
  active = false,
  className,
  style,
}) => (
  <div
    style={style}
    className={cn('loader', { 'loader--active': active }, className)}
  />
)

export default Loader
