import React from 'react'

import cn from 'classnames'

import Button from 'components/Button'
import Icon from 'components/Icon'
import { IconName } from 'components/Icon/IconName'

import './review-button.css'

interface Props {
  disabled: boolean
  hasTheme: boolean
  label: string
  name: IconName
  onClick: () => void
  theme: 'primary' | 'secondary' | 'tertiary'
}

const ReviewButton: React.FC<Props> = ({
  disabled,
  hasTheme,
  label,
  name,
  onClick,
  theme,
}) => (
  <Button
    className="review-button__button"
    theme={hasTheme ? theme : 'tertiary'}
    ghost={!hasTheme}
    outline={!hasTheme}
    size="medium"
    onClick={() => {
      !disabled && onClick()
    }}
  >
    <p className={cn('ma0', hasTheme ? 'surface' : 'gray')}>{label}</p>
    <Icon name={name} color={hasTheme ? 'white' : 'gray'} className="pl3" />
  </Button>
)

export default ReviewButton
