import { MutableRefObject, useEffect } from 'react'

type Callback = () => void

export const useVisibleCheck = (
  target: MutableRefObject<HTMLElement | null>,
  callback?: Callback,
) => {
  useEffect(() => {
    const targetToObserve = target.current
    if (targetToObserve && callback) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              callback()
            }
          })
        },
        {
          threshold: 1,
        },
      )
      if (targetToObserve) {
        observer.observe(targetToObserve)
      }

      return () => {
        if (targetToObserve) {
          observer.unobserve(targetToObserve)
        }
      }
    }
  }, [callback, target])
}
