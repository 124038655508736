import React, { Fragment, useEffect, useState } from 'react'
import SubTitle from 'components/SubTitle'
import Conditional from 'components/Conditional'
import styles from './RecordCard.module.scss'

export const RecordSetter = () => {
  const [showModal, setShowModal] = useState(false)

  const handleClickOutside = (event: any) => {
    if (showModal && !event.target.closest(`#recordSetter`)) {
      setShowModal(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <Fragment>
      <img
        id="recordSetter"
        className={styles.recordSetter}
        src={`/assets/record-setter.png`}
        alt="record setter icon"
        onClick={(e) => {
          e.preventDefault()
          setShowModal(!showModal)
        }}
      />
      <Conditional shouldRender={showModal}>
        <div className={styles.recordSetterModal}>
          <SubTitle className="pb3">Record Setter!</SubTitle>
          <p className="ma0 dark-gray">
            This record holder came up with a new Kids World Record.
          </p>
        </div>
      </Conditional>
    </Fragment>
  )
}
