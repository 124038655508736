import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { FormInput } from 'components/Form/FormInput'
import { FormRecordMedias } from 'components/Form/FormRecordMedias'
import SubmitButton from 'components/SubmitButton'

import { RecommendationUpdateBody } from 'store/api/types/Recommendation.types'
import {
  useCreateRecommendationMutation,
  useGetRecommendationByIdQuery,
  useUpdateRecommendationMutation,
} from 'store/api'

import {
  buildDefaultValue,
  RECOMMENDATION_UPDATE_VALIDATOR,
} from './RecommendationEdit.utils'

import styles from '../RecommendationArea.module.scss'

type RecommendationEditProps = {
  recordId: string
  onChange: () => void
}

export const RecommendationEdit: FC<RecommendationEditProps> = ({
  recordId,
  onChange,
}) => {
  const { data: recommendation } = useGetRecommendationByIdQuery(recordId)
  const [createRecommendation] = useCreateRecommendationMutation()
  const [updateRecommendation] = useUpdateRecommendationMutation()

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<RecommendationUpdateBody>({
    resolver: joiResolver(RECOMMENDATION_UPDATE_VALIDATOR),
    mode: 'all',
  })

  useEffect(() => {
    if (recommendation) {
      reset(buildDefaultValue(recommendation))
    }
  }, [reset, recommendation])

  const onSubmit = async (body: RecommendationUpdateBody) => {
    if (recommendation) {
      updateRecommendation({
        id: recommendation.id,
        ...body,
        imageUrl: body.imageUrl[0],
      })
    } else {
      createRecommendation({
        recordId,
        ...body,
        imageUrl: body.imageUrl[0],
      })
    }
    onChange()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormRecordMedias
        name="imageUrl"
        control={control}
        maxCount={1}
        accept="image/*"
      />
      <div className={styles.top}>
        <FormInput name="title" label="Title" control={control} />
        <FormInput
          name="description"
          label="Description"
          multiline
          control={control}
        />
        <FormInput
          name="didYouKnow"
          label="Did you know?"
          multiline
          control={control}
        />
        <FormInput name="urlText" label="URL Text" control={control} />
        <FormInput name="link" label="Link" control={control} />
      </div>
      <SubmitButton disabled={!isValid}>Save Changes</SubmitButton>
    </form>
  )
}
