import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded'
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded'

import SubmitButton from 'components/SubmitButton'
import BackButton from 'components/BackButton'
import BorderedContainer from 'components/BorderedContainer'
import { Page } from 'components/Page'
import { FormInput } from 'components/Form/FormInput'
import { FormRecordMedias } from 'components/Form/FormRecordMedias'
import { DatePicker, Input } from 'components/Inputs'
import { FormCheckbox } from 'components/Form/FormCheckbox'

import { TwoColumnsGrid } from '../RecordSubmissionDetailsEdit/components/TwoColumnsGrid'
import { FormPreviousRecordInfo } from '../RecordSubmissionDetails/components/PreviousRecordInfo/FormPreviousRecordInfo'
import { buildLocationValue } from '../RecordSubmissionDetails/RecordSubmissionDetails.utils'

import { FormCategorySelect } from '../CreateGroupRecordSubmission/components/FormCategorySelect'

import {
  GroupRecordUpdateBody,
  RecordStatus,
} from 'store/api/types/Record.types'
import {
  useGetSubmissionByIdQuery,
  useUpdateRecordSubmissionMutation,
} from 'store/api'

import {
  buildDefaultValue,
  SUBMISSION_EDIT_VALIDATOR,
} from './GroupRecordSubmissionEdit.utils'

import styles from './GroupRecordSubmissionEdit.module.scss'

type RecordSubmissionDetailParams = {
  id: string
}

export const GroupRecordSubmissionEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams<RecordSubmissionDetailParams>()
  const { data: submission, isLoading } = useGetSubmissionByIdQuery(id || '', {
    skip: !id,
  })
  const [updateSubmission, { isLoading: isUpdating }] =
    useUpdateRecordSubmissionMutation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<GroupRecordUpdateBody>({
    resolver: joiResolver(SUBMISSION_EDIT_VALIDATOR),
    mode: 'all',
  })

  const onSubmit = async (body: GroupRecordUpdateBody) => {
    if (id) {
      await updateSubmission({ id, ...body }).unwrap()
      navigate(-1)
    }
  }

  useEffect(() => {
    if (submission) {
      reset(buildDefaultValue(submission))
    }
  }, [reset, submission])

  return (
    <Page>
      <BorderedContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TwoColumnsGrid className={styles.top}>
            <BackButton backUrl={`/admin/submissions/${id}`}>
              Back to Records
            </BackButton>
            <SubmitButton disabled={isLoading || isUpdating}>
              Save Changes
            </SubmitButton>
          </TwoColumnsGrid>
          <FormInput
            name="idea"
            label="Title"
            control={control}
            errorText={errors?.idea?.message as string}
          />
          <FormCategorySelect
            name="recordCategory"
            control={control}
            error={errors?.recordCategory?.participantAmount?.message as string}
          />
          <FormPreviousRecordInfo
            name="previousRecordId"
            control={control}
            currentRecordCategory={watch('recordCategory.category') || ''}
            currentRecordGender={''}
            currentRecordAge={''}
            disabled={submission?.status !== RecordStatus.IN_REVIEW}
          />
          <FormInput
            name="description"
            label="Record Description"
            control={control}
            multiline
            errorText={errors?.description?.message as string}
          />
          <FormInput
            name="notes"
            label="Record Notes"
            control={control}
            errorText={errors?.notes?.message as string}
            multiline
          />
          <TwoColumnsGrid>
            <DatePicker
              label="Record Date"
              disabled
              value={new Date(submission?.brokenOn || 0)}
            />
            <Input
              name="location"
              label="Record Location"
              disabled
              value={buildLocationValue(submission?.location)}
            />
          </TwoColumnsGrid>
          <FormCheckbox
            name="isFirstRecord"
            control={control}
            icon={<WorkspacePremiumRoundedIcon className={styles.icon} />}
            label="This record is the first of its kind."
          />
          <FormCheckbox
            name="requireParentalSupervision"
            control={control}
            label="This record require proof of parental supervision."
            icon={<ReportProblemRoundedIcon className={styles.icon} />}
          />
          <FormCheckbox
            name="requireTimingDevice"
            control={control}
            label="A timing device must be visible in video submitted."
            icon={<ReportProblemRoundedIcon className={styles.icon} />}
          />
          <FormCheckbox
            name="noNewRecord"
            control={control}
            label="We are not accepting new submissions for this record."
            icon={<ReportProblemRoundedIcon className={styles.icon} />}
          />
          <FormRecordMedias name="mediaLinks" control={control} />
        </form>
      </BorderedContainer>
    </Page>
  )
}
