import React, { useEffect } from 'react'

import styles from './MediaCarousel.module.scss'

interface ImageProps {
  src: string
  secondsToShow?: number
  onEndHandler(): void
  onClick?(): void
}

export const Image = ({
  src,
  onEndHandler,
  onClick,
  secondsToShow = 10,
}: ImageProps) => {
  useEffect(() => {
    const timeID = setTimeout(() => onEndHandler(), secondsToShow * 1000)

    return () => clearTimeout(timeID)
  }, [src, onEndHandler, secondsToShow])

  return (
    <img
      alt={`display ${secondsToShow}`}
      className={styles.image}
      src={src}
      onClick={onClick}
    />
  )
}
