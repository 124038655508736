import React, { useState } from 'react'

import {
  ExtraCategory,
  PublicRecordFilters,
} from 'store/api/types/Record.types'
import { useGetHeadContentQuery } from 'store/api'

import { usePublicRecords } from 'hooks/useRecordApi.hook'
import { Page } from 'components/Page'
import Conditional from 'components/Conditional'
import EmptySection from 'components/EmptySection'
import { KwrMediaHeader } from 'components/KwrMediaHeader/KwrMediaHeader'
import { ControlPanel } from './components/ControlPanel'
import { RecordList } from './components/RecordList'

export const BrowseRecords = (): React.ReactElement => {
  const { data } = useGetHeadContentQuery()
  const [view, setView] = useState<'list' | 'card'>('card')
  const [filter, setFilter] = useState<PublicRecordFilters>({
    ageGaps: [],
    genders: [],
    search: '',
    category: ExtraCategory.NEWEST,
  })
  const { records, onNext, isLoading, hasNextPage } = usePublicRecords(filter)

  const isTopicCategory = filter.category === ExtraCategory.TOP_PICKS

  const EMPTY_SECTION_TEXT = isTopicCategory
    ? "We did not find any results for the record in the Top Picks category. It's important for to determine if an existing record exist. Please click here to search the entire site."
    : 'There are no records registered until now'

  return (
    <Page>
      <Conditional shouldRender={Boolean(data)}>
        <KwrMediaHeader
          title={data?.title || ''}
          description={data?.description || ''}
          medias={data?.medias || []}
        />
      </Conditional>
      <ControlPanel
        view={view}
        loading={isLoading}
        onChangeView={setView}
        onSearch={setFilter}
        defaultValue={ExtraCategory.NEWEST}
      />
      <Conditional shouldRender={records.length === 0 && !isLoading}>
        <EmptySection
          message={EMPTY_SECTION_TEXT}
          onSearchTheEntireSite={
            isTopicCategory
              ? () => setFilter({ ...filter, category: ExtraCategory.NEWEST })
              : undefined
          }
        />
      </Conditional>
      <RecordList
        isLoading={isLoading}
        records={records}
        layoutType={'card_view'}
        onGetMore={onNext}
        hasNextPage={hasNextPage}
      />
    </Page>
  )
}
