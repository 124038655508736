import React, { useEffect, useRef } from 'react'

import SubTitle from 'components/SubTitle'

import './info-card.css'

const InfoCard: React.FC<{ id: string }> = ({ id }) => {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>

  const handleOutsideClick = (e: any) => {
    if (ref.current.contains(e.target)) {
      return
    }
    const infoCardDom = document.getElementById(id)
    if (infoCardDom) {
      infoCardDom.style.visibility = 'hidden'
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  })

  return (
    <div id={id} ref={ref} className="info-card z-999 bg-white absolute pa3">
      <SubTitle className="pb3">You need to break a record!</SubTitle>
      <p className="ma0 dark-gray">
        Go to "How to break a record" and learn how to do it.
      </p>
      <p className="ma0 dark-gray">Good luck!</p>
    </div>
  )
}

export default InfoCard
