import React, { FC } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import AccordionDetails from '@mui/material/AccordionDetails'

import Conditional from 'components/Conditional'
import { useDownloadEventParticipantCertificateQuery } from 'store/api'
import styles from './EventCertificates.module.scss'

type EventParticipantCertificateProps = {
  participantId: string
  token: string
}

export const EventParticipantCertificate: FC<
  EventParticipantCertificateProps
> = ({ participantId, token }) => {
  const { data, isLoading } = useDownloadEventParticipantCertificateQuery({
    participantId,
    token,
  })

  return (
    <AccordionDetails className={styles.details}>
      <Conditional shouldRender={isLoading}>
        <CircularProgress size={30} />
      </Conditional>
      <Conditional shouldRender={!isLoading}>
        <iframe className={styles.ifr} src={data} title={`Certificate`} />
      </Conditional>
    </AccordionDetails>
  )
}
