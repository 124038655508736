import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useResolvedPath } from 'react-router'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { Download, ImageSearch } from '@mui/icons-material'
import dayjs from 'dayjs'
import classNames from 'classnames'

import Conditional from 'components/Conditional'

import {
  EventParticipant,
  EventParticipantStatus,
} from 'store/api/types/Event.types'
import { useLazyGetEventParticipantCertificateQuery } from 'store/api'

import styles from './ParticipantsList.module.scss'

export type ParticipantRowProps = {
  data: EventParticipant
  onSelectParticipant: (e: any) => void
}

const ParticipantRow: FC<ParticipantRowProps> = ({
  data,
  onSelectParticipant,
}) => {
  const { pathname } = useResolvedPath('')
  const formattedBirthDate = dayjs(data.kidsDateOfBirth).format('MM/DD/YYYY')
  const [getCertificate] = useLazyGetEventParticipantCertificateQuery()

  const handleDownload = async () => {
    try {
      const certificateLink = await getCertificate({
        participantId: data.id,
        eventId: data.eventId,
      }).unwrap()
      const anchorElement = document.createElement('a')
      anchorElement.href = certificateLink
      anchorElement.download = `Certificate.pdf`
      anchorElement.click()
    } catch (e) {}
  }

  return (
    <TableRow
      className={classNames(
        styles.row,
        data.status === EventParticipantStatus.REJECTED && styles.red,
        data.status === EventParticipantStatus.APPROVED && styles.green,
      )}
    >
      <TableCell className={styles.cell} component="th" scope="row">
        <Link
          className={styles.createEditCampaignLink}
          to={`${pathname}/edit/${data.id}`}
          style={{ color: 'blue', textDecoration: 'underline' }}
        >
          {data.parentFirstName}
        </Link>
      </TableCell>
      <TableCell className={styles.cell}>
        <a href={data.parentLastName}>{data.parentLastName}</a>
      </TableCell>
      <TableCell className={styles.cell}>
        <Link
          className={styles.createEditCampaignLink}
          to={`${pathname}/edit/${data.id}`}
          style={{ color: 'blue', textDecoration: 'underline' }}
        >
          {data.email}
        </Link>
      </TableCell>
      <TableCell className={styles.cell}>{data.kidsFirstName}</TableCell>
      <TableCell className={styles.cell}>{data.kidsLastName}</TableCell>
      <TableCell className={styles.cell}>{formattedBirthDate}</TableCell>
      <TableCell className={styles.cell}>
        <a
          href={`https://www.instagram.com/${data.instagramId}/`}
          rel="noreferrer"
          target={'_blank'}
        >
          {data.instagramId}
        </a>
      </TableCell>
      <TableCell className={styles.cell}>{data.status}</TableCell>
      <TableCell className={classNames(styles.cell, styles.center)}>
        <Conditional
          shouldRender={data.status === 'APPROVED' && !!data.certificate}
        >
          <IconButton
            className={styles.iconButton}
            aria-label="Download Certificate"
            component="span"
            onClick={handleDownload}
          >
            <Download />
          </IconButton>
        </Conditional>
      </TableCell>
      <TableCell className={styles.cell}>
        <IconButton
          className={styles.iconButton}
          aria-label="Review"
          component="span"
          onClick={onSelectParticipant}
        >
          <ImageSearch />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default ParticipantRow
