import React, { useEffect, useState } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useDispatch } from 'react-redux'

import Conditional from 'components/Conditional'
import ShadowModal from 'components/ShadowModal'
import { FormPinInput } from 'components/Form/FormPinInput'
import SubmitButton from 'components/SubmitButton'

import { useSendResetPinEmailMutation, useVerifyPinMutation } from 'store/api'
import { showToaster } from 'store/app/actions'

import { PIN_VALIDATOR } from './PinInput.utils'

import styles from './ParentPermission.module.scss'

const getErrorMessage = (errors: FieldErrors<{ pin: string[] }>) =>
  errors?.pin &&
  (errors.pin[0]?.message ||
    errors.pin[1]?.message ||
    errors.pin[2]?.message ||
    errors.pin[3]?.message)

interface ParentPermissionModalProps {
  isOpenModal: boolean
  onClose: () => void
  onPlay: () => void
  isLoading: boolean
}

export const ParentPermissionModal: React.FC<ParentPermissionModalProps> = ({
  isOpenModal,
  onClose,
  onPlay,
  isLoading,
}) => {
  const [sendResetPinEmail] = useSendResetPinEmailMutation()
  const [verifyPin, { isLoading: isLoadingVerifyPin }] = useVerifyPinMutation()

  const [isConfirmation, setIsConfirmation] = useState(false)

  const dispatch = useDispatch()

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{ pin: string[] }>({
    resolver: joiResolver(PIN_VALIDATOR),
    mode: 'all',
  })

  const onSubmit = async (body: { pin: string[] }) => {
    try {
      await verifyPin({ pin: body.pin.join('') }).unwrap()
      onPlay()
    } catch (e) {
      dispatch(
        showToaster({
          message: `Incorrect pin, please try again.`,
          color: 'red',
        }),
      )
    }
  }

  useEffect(() => {
    reset({ pin: [] })
  }, [reset, isOpenModal])

  return (
    <ShadowModal
      className={styles.modal}
      isModalOpen={isOpenModal}
      onCancel={onClose}
      withShadow
      noSize
    >
      <Conditional shouldRender={!isConfirmation}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.modalContainer}>
            <div className={styles.header}>We need a parents permission.</div>
            <div className={styles.body}>
              <img
                className={styles.webcam}
                src={`/assets/recording.png`}
                alt="recording"
              />
              <div className={styles.modalText}>
                We need to capture images to verify the quiz player.
              </div>
            </div>
            <div className={styles.modalText}>
              To start the quiz, please, enter your parent PIN.
            </div>
            <FormPinInput
              name="pin"
              control={control}
              isMasked={true}
              errorText={getErrorMessage(errors)}
            />
            <div className={styles.buttonBox}>
              <SubmitButton
                className={styles.startBtn}
                disabled={isLoading || isLoadingVerifyPin}
              >
                Start Quiz
              </SubmitButton>
              <button
                type="button"
                className={styles.linkButton}
                onClick={() => {
                  sendResetPinEmail()
                  setIsConfirmation(true)
                }}
                disabled={isLoading || isLoadingVerifyPin}
              >
                Send reset PIN email
              </button>
            </div>
          </div>
        </form>
      </Conditional>
      <Conditional shouldRender={isConfirmation}>
        <div className={styles.confirmationContainer}>
          <div className={styles.header}>Reset Instruction</div>
          <div className={styles.row}>
            <div className={styles.modalText}>
              We will send you instruction to your email
            </div>
            <SubmitButton onClick={() => setIsConfirmation(false)}>
              Ok
            </SubmitButton>
          </div>
        </div>
      </Conditional>
    </ShadowModal>
  )
}
