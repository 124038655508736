import React, { FC, useState } from 'react'
import BorderedContainer from 'components/BorderedContainer'
import Title from 'components/Title'
import { Page } from 'components/Page'
//import { SearchBar } from 'scenes/BrowseRecords/components/SearchBar'

import Divider from 'components/Divider'
import Button from 'components/Button'
import styles from './ListOfRecordsPage.module.scss'

const AVAILABLE_PAGE_TITLE = 'Available 2024 School & Camp Records'
const RESERVED_PAGE_TITLE = 'Reserved 2024 School & Camp Records'
const AVAILABLE_PAGE_CONTENT_BOLD = [
  'Records ideas below are currently available',
  ' Please note that you can come up with your own record.',
]
const RESERVED_PAGE_CONTENT_BOLD = [
  'Records ideas below are currently not available',
  ` Check out our available records, or come up with your own ideas! We're happy to help with ideas and guidelines.`,
]
const AVAILABLE_FOR_FUN = [
  'We want to hear from you, contact us with your idea',
  'Most Kids Blowing Soap Bubbles',
  'Largest Group Hug',
  'Most Kids Pouring Water on Themselves',
  'Longest Head Massage Chain',
  'Most Balloons Inflated',
  'Most Kids Howling',
  'Most Kids in a Circle Touching Feet',
]

const RESERVED_FOR_FUN = [
  'Longest Hopscotch',
  'Most Kids Eating Jell-o',
  'Largest Water Ballon Fight',
  'Most Kids Blowing Soap Bubbles',
]
const AVAILABLE_STEM = [
  'We want to hear from you, contact us with your idea',
  'Largest Telescope Made',
  'Tallest Milk Carton Structure',
  'Tallest Popscicle Stick Structure ',
  'Largest Trebuchet',
  'Longest Unassisted glider',
  'Largest Water gun',
  'Largest Cardboard Structure',
  'Largest Globe Made',
  'Heaviest Object Lifted by Balloons ',
  'Longest Marble Run ',
  'Longest Hovering Paper Helicopter',
]
const RESERVED_STEM = ['Longest DNA chain']

const AVAILABLE_PHYSICAL_ACTIVITIES = [
  'We want to hear from you, contact us with your idea',
  'Most Kids Doing Tug of War',
  'Most Kids Doing Push-Ups, Sit-Ups, etc.',
  'Most Kids Doing  Push-Ups, Sit-Ups, etc.',
  'Most Kids Hula Hooping (Must beat 73)',
  'Most Kids Doing a 1 Minute Plank',
  'Most Kids Skipping Rope for 1 Minute',
]

const RESERVED_PHYSICAL_ACTIVITIES = [
  'Longest Time Bouncing a Beach Ball',
  'Most Kids Completing a Scout Banana Relay',
  'Most Kids Playing Indiana Jones KIN-BALL®',
  'Longest Time holding a KIN-BALL® in the air',
]
const AVAILABLE_ART_CRAFT_DANCE = [
  'We want to hear from you, contact us with your idea',
  'Longest Street Drawing',
  'Largest Mural (must be measurable in square feet)',
  'Most Kids Singing YMCA (records available for other songs)',
  'Most Kids Dancing the Chicken Dance, etc.',
  'Longest Conga line',
  'Largest Dance Party',
  'Most Kids Playing a Bottle',
  'Most Kids Playing a Clapping Game (ex. Stella Ella Olla)',
]

const RESERVED_ART_CRAFT_DANCE = [
  'Most Kids Drumming on Paper Cups',
  'Most Kids Singing a Camp Song',
]
export const ListOfRecordsPageV1: FC = () => {
  const [isAvailable, setIsAvailable] = useState(true)

  // const [search, setSearch] = useState('')
  // const loading = false
  return (
    <Page>
      <div className={styles.buttonsDiv}>
        <Button
          className={isAvailable ? styles.btnActive : styles.btnNotActive}
          theme="secondary"
          onClick={() => setIsAvailable(true)}
        >
          2024 Records
        </Button>
        <Button
          className={isAvailable ? styles.btnNotActive : styles.btnActive}
          theme="secondary"
          onClick={() => setIsAvailable(false)}
        >
          Reserved Records
        </Button>
      </div>
      <BorderedContainer>
        <Title className="pb3 pb4-l mb2">
          {isAvailable ? AVAILABLE_PAGE_TITLE : RESERVED_PAGE_TITLE}
        </Title>
        <p className={styles.textInfo}>
          <span className={styles.textBold}>
            {isAvailable
              ? AVAILABLE_PAGE_CONTENT_BOLD[0]
              : RESERVED_PAGE_CONTENT_BOLD[0]}
          </span>{' '}
          for schools and camps.{' '}
          <span>
            {isAvailable
              ? AVAILABLE_PAGE_CONTENT_BOLD[1]
              : RESERVED_PAGE_CONTENT_BOLD[1]}
          </span>{' '}
          Any record for groups of kids between 4-16 that is{' '}
          <span className={styles.textBold}>adequately challenging, safe,</span>{' '}
          and <span className={styles.textBold}>measurable</span> by either
          quantity (most), size (smallest, largest, longest, highest) or time
          (fastest, endurance).
        </p>

        {/* <div>
          <SearchBar loading={loading} onStartSearch={setSearch} />
        </div> */}
        <Divider />
        <h4 className={styles.miniHeader}>JUST FOR FUN</h4>
        <ul>
          {isAvailable
            ? AVAILABLE_FOR_FUN.map((elem) => <li key={elem}>{elem}</li>)
            : RESERVED_FOR_FUN.map((elem) => <li key={elem}>{elem}</li>)}
        </ul>
        <Divider />
        <h4 className={styles.miniHeader}>STEM</h4>
        <ul>
          {isAvailable
            ? AVAILABLE_STEM.map((elem) => <li key={elem}>{elem}</li>)
            : RESERVED_STEM.map((elem) => <li key={elem}>{elem}</li>)}
        </ul>
        <Divider />
        <h4 className={styles.miniHeader}>Physical Abilities</h4>
        <ul>
          {isAvailable
            ? AVAILABLE_PHYSICAL_ACTIVITIES.map((elem) => (
                <li key={elem}>{elem}</li>
              ))
            : RESERVED_PHYSICAL_ACTIVITIES.map((elem) => (
                <li key={elem}>{elem}</li>
              ))}
        </ul>
        <Divider />
        <h4 className={styles.miniHeader}>Arts, Crafts and Dance</h4>
        <ul>
          {isAvailable
            ? AVAILABLE_ART_CRAFT_DANCE.map((elem) => (
                <li key={elem}>{elem}</li>
              ))
            : RESERVED_ART_CRAFT_DANCE.map((elem) => (
                <li key={elem}>{elem}</li>
              ))}
        </ul>
      </BorderedContainer>
    </Page>
  )
}
