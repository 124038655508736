import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import BorderedContainer from 'components/BorderedContainer'
import SubmitButton from 'components/SubmitButton'
import Title from 'components/Title'

import { getSignUpProfile } from 'store/signup/selectors'

import InputText from 'components/InputText'
import Tooltip from 'components/Tooltip'
import SubTitle from 'components/SubTitle'
import { NewFileUpload } from 'components/MediaUpload'
import axios, { AxiosProgressEvent } from 'axios'
import Pins from 'typings/Pin'
import { PageWrapper } from 'components/PageWrapper'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { REVIEW_VERIFY_VALIDATOR } from './VerifyAge.utils'
import { joiResolver } from '@hookform/resolvers/joi'
import { FormSelect } from 'components/Form/FormSelect'
import { FormCountryAutocomplete } from 'components/Form/FormCountryAutocomplete'

const DOCUMENTS = [
  {
    label: 'Driver license',
    value: 'DRIVER_LICENSE',
  },
  {
    label: 'Passport',
    value: 'PASSPORT',
  },
  {
    label: 'Permanent Resident Card',
    value: 'PERMANENT_RESIDENT_CARD',
  },
  {
    label: 'ID Card',
    value: 'ID_CARD',
  },
]

const MediaUploadTooltip: React.FC = () => (
  <Tooltip right>
    <div className="record-details__media-tooltip">
      <SubTitle className="f5 pb3">Acceptable identity documents</SubTitle>
      <p className="dark-gray f7 mv1">- Driver's license.</p>
      <p className="dark-gray f7 mv1">- Passport</p>
      <p className="dark-gray f7 mv1">- Permanent Resident Card</p>
      <p className="dark-gray f7 mv1">
        - ID card issued by federal, state or local government agency or
        entities, provided it contains a photograph, full name and date of birth
      </p>
      <b className="dark-gray f7 mv1">Documents must be UNEXPIRED</b>
    </div>
  </Tooltip>
)

const generateUploadLink = async (
  fileName: string,
  contentType: string,
): Promise<string> => {
  // eslint-disable-next-line
  const [_, format] = contentType.split('/')
  const resp = await axios.get(`/api/auth/upload/document?format=${format}`)

  return resp.data.urlToUpload
}

const uploadFile = async (
  linkToUpload: string,
  file: File,
  onUploadProgress?: (progress: AxiosProgressEvent) => void,
): Promise<void> => {
  await axios.put(`${linkToUpload}`, file, {
    headers: { 'Content-Type': file.type, 'content-disposition': 'attachment' },
    onUploadProgress,
  })
}

interface ReviewFormData {
  country?: string
  documentType?: string
}

export const VerifyParentByReview: React.FC = () => {
  const profile = useSelector(getSignUpProfile)
  const navigate = useNavigate()
  const [documentFile, setDocumentFile] = useState<File | null>(null)
  const [uploadingText, setUploadingText] = useState('')

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ReviewFormData>({
    resolver: joiResolver(REVIEW_VERIFY_VALIDATOR),
    mode: 'all',
  })
  const submitHandler = async (data: ReviewFormData) => {
    if (!documentFile || !profile.pin) return

    const uploadLink = await generateUploadLink(
      documentFile?.name.replace(/[^a-zA-Z\d.]/g, ''),
      documentFile?.type,
    )
    await uploadFile(uploadLink, documentFile, (uploadProgress) => {
      setUploadingText(
        `Uploading ${documentFile.name} (${(
          (uploadProgress.loaded * 100) /
          (uploadProgress?.total || 1)
        ).toFixed(2)}%)`,
      )
    })

    setUploadingText('Checking a few things')
    try {
      await axios.post(`/api/auth/draftAccount`, {
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email?.trim().toLowerCase(),
        dateOfBirth: profile?.dateOfBirth?.valueOf() || 0,
        pin: Pins.formatPin(profile.pin!),
        documentUrl: uploadLink.split('?')[0],
        documentType: data.documentType,
        country: data.country,
      })
      setUploadingText('')
      navigate('/submitted-on-review')
    } catch (e) {
      setUploadingText('')
      console.log(e)
    }
  }
  return (
    <PageWrapper>
      <BorderedContainer>
        <Title>Please verify that you are an adult</Title>
        <p>
          To get started, we need to verify that you are an adult. Buy
          completing registration you agree to our{' '}
          <Link
            to={`/terms-of-service`}
            target="_blank"
            rel="noreferrer"
            style={{ color: 'blue', textDecoration: 'underline' }}
          >
            Terms of Service
          </Link>
          {` `}
          and{' '}
          <Link
            to={`/privacy-policy`}
            target="_blank"
            rel="noreferrer"
            style={{ color: 'blue', textDecoration: 'underline' }}
          >
            Privacy policy
          </Link>
          .
        </p>
        <p>
          <b>Adult verification can be done by either:</b>
        </p>
        <ul style={{ listStyle: 'none', padding: 0 }}>
          <li> 1. Entering a valid credit card (you will not be charged)</li>
          <li>2. Answering skill-testing question</li>
          <li>
            3. Providing a copy of valid government-issued photo identification
          </li>
        </ul>
        <p>
          For options 1 and 2, please{' '}
          <Link
            to={`/verify-age`}
            style={{ color: 'blue', textDecoration: 'underline' }}
          >
            register here
          </Link>
          . For option 3, please complete the form below and provide a photo of
          valid government-issued photo identification.
        </p>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="flex flex-column">
            <div className="mw6">
              <InputText
                label="Parent's First Name"
                defaultValue={profile.firstName}
                disabled={true}
              />
              <InputText
                label="Parent's Last Name"
                defaultValue={profile.lastName}
                disabled={true}
              />
              <InputText
                label="Parent's Date of Birth"
                defaultValue={profile.dateOfBirth?.split('T')[0]}
                disabled={true}
              />
              <FormCountryAutocomplete
                control={control}
                name="country"
                label="Country"
              />
              <div style={{ width: '100%' }}>
                <FormSelect
                  control={control}
                  errorText={errors.documentType?.message}
                  label="Identification document"
                  items={DOCUMENTS}
                  name="documentType"
                />
              </div>
            </div>
          </div>
          <div
            className={'flex w100'}
            style={{ flexDirection: 'column', alignItems: 'center', gap: 10 }}
          >
            <div
              className={'flex'}
              style={{
                width: '400px',
                flexDirection: 'column',
                paddingLeft: '3rem',
              }}
            >
              <NewFileUpload
                files={documentFile ? [documentFile] : []}
                buttonLabel={'Upload Identification'}
                name={'document'}
                loadingText={uploadingText}
                tooltip={<MediaUploadTooltip />}
                setValue={(_, files) => setDocumentFile(files[0])}
              />
            </div>
            <SubmitButton
              className="w5"
              disabled={!documentFile || !!uploadingText}
            >
              Verify
            </SubmitButton>
          </div>
        </form>
      </BorderedContainer>
    </PageWrapper>
  )
}
