import { ProductCheckout } from 'data/Product'

import { RootState } from 'store'

export const getShoppingCart = (state: RootState): ProductCheckout[] =>
  state.shop.shoppingCart

export const getShoppingCartIsOpen = (state: RootState): boolean =>
  state.shop.isOpen

export const isLoading = (state: RootState): boolean => state.shop.isLoading
