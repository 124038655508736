import React, { FC } from 'react'
import cn from 'classnames'
import AddIcon from '@mui/icons-material/Add'
import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'

import styles from './SponsorList.module.scss'

type SponsorImageProps = {
  imageUrl?: string
  onClick?: () => void
  onDelete?: () => void
  className?: string
}

export const SponsorImage: FC<SponsorImageProps> = ({
  imageUrl,
  onClick,
  onDelete,
  className,
}) => {
  return (
    <div className={cn(styles.list_item, className)}>
      {imageUrl ? (
        <div className={styles.container}>
          <img src={imageUrl} alt={imageUrl} onClick={onClick} />
          {onDelete ? (
            <IconButton
              className={styles.deleteBtn}
              aria-label="delete sponsor"
              onClick={onDelete}
            >
              <Close />
            </IconButton>
          ) : null}
        </div>
      ) : (
        <div className={styles.plus_frame} onClick={onClick}>
          <AddIcon className={styles.plus_icon} />
        </div>
      )}
    </div>
  )
}
