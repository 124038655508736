import { combineReducers } from '@reduxjs/toolkit'

import app from './app'
import shop from './shop'
import signup from './signup'
import { kwrApi } from './api'

const rootReducer = () =>
  combineReducers({
    app,
    [kwrApi.reducerPath]: kwrApi.reducer,
    shop,
    signup,
  })

export default rootReducer
