export const capitalizeFirstLetter = (text: string) =>
  `${text.charAt(0).toUpperCase()}${text.substring(1).toLowerCase()}`

export const capitalizeAndRemoveUnderscode = (text: string) => {
  const spaced = text.replace(/_/g, ' ')
  const spacedCapitalized = spaced.replace(/(?:^|\s)\S/g, (c) =>
    c.toUpperCase(),
  )
  return spacedCapitalized
}

export const splitOnUpperCase = (text: string): string[] =>
  text.split(/(?=[A-Z])/)

export const normalizeFieldName = (fieldName: string): string => {
  const [first, ...rest] = splitOnUpperCase(fieldName)
  return rest.length > 0
    ? `${capitalizeFirstLetter(first)} ${rest.join(' ')}`
    : capitalizeFirstLetter(first)
}
