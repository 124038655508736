import React, { FC } from 'react'
import { Checkbox as MuiCheckbox } from '@mui/material'
import cn from 'classnames'

import Conditional from 'components/Conditional'

import styles from './Input.module.scss'

interface LabeledCheckBoxProps {
  label?: string
  name?: string
  icon?: React.ReactNode
  checked?: boolean
  disabled?: boolean
  defaultValue?: boolean
  onClick?(): void
}

export const Checkbox: FC<LabeledCheckBoxProps> = ({
  label,
  name,
  icon,
  onClick,
  checked,
  defaultValue,
  disabled = false,
}) => {
  console.log('DEPRECATED CHECKBOX use V2')
  return (
    <div className={styles.checkbox}>
      <MuiCheckbox
        name={name}
        checked={checked}
        disabled={disabled}
        defaultChecked={defaultValue}
        className={styles.check}
        onClick={onClick}
      />
      <Conditional shouldRender={Boolean(label)}>
        {icon}
        <h1>{label}</h1>
      </Conditional>
    </div>
  )
}

interface LabeledCheckBoxPropsV2 {
  label?: string
  name?: string
  icon?: React.ReactNode
  value?: boolean
  checked?: boolean
  disabled?: boolean
  defaultValue?: boolean
  className?: string
  onChange?(): void
}

const STYLES = {
  '&.Mui-checked:not(.Mui-disabled)': {
    color: 'var(--neo-red)',
  },
}

export const CheckboxV2: FC<LabeledCheckBoxPropsV2> = ({
  label,
  name,
  icon,
  onChange,
  value,
  checked,
  defaultValue,
  disabled = false,
  className,
}) => {
  return (
    <div className={cn(styles.checkbox, className)}>
      <MuiCheckbox
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        defaultChecked={defaultValue}
        className={styles.check}
        sx={STYLES}
        onChange={onChange}
      />
      <Conditional shouldRender={Boolean(label)}>
        {icon}
        <h1>{label}</h1>
      </Conditional>
    </div>
  )
}
