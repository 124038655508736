import React from 'react'
import ReactDOM from 'react-dom'
import { Navigate, Outlet, Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { APIProvider } from '@vis.gl/react-google-maps'

import { CONFIG } from './config'

import store from 'store'

import AuthenticationProvider from 'providers/AuthenticationProvider'

import 'assets/tachyons.css'

import DefaultRedirect from 'components/DefaultRedirect'
import Layout from 'components/Layout'
import { AboutUs } from 'scenes/AboutUs'
import Educators from 'scenes/Educators'
import AddChild from 'scenes/AddChild'
import { BreakRecord } from 'scenes/BreakRecord'
import IdeaManager from 'scenes/IdeaManager'
import BrowseProducts from 'scenes/BrowseProducts'
import { BrowseRecords } from 'scenes/BrowseRecords'
import CharityDetails from 'scenes/CharityDetails'
import ChildProfile from 'scenes/ChildProfile'
import { ChooseAPin } from 'scenes/ChooseAPin'
import { ContactUs } from 'scenes/ContactUs'
import ResetPin from 'scenes/Pin/ResetPin'
import EditPin from 'scenes/Profile/EditPin'
import EditProfile from 'scenes/Profile/Edit'
import LogInToUser from 'scenes/LoginToUser'
import EditChildProfile from 'scenes/ChildProfile/Edit'
import GroupEvents from 'scenes/GroupEvents'
import { LogIn } from 'scenes/LogIn/Login'
import MagicLinkRequested from 'scenes/LogIn/MagicLinkRequested'
import MyRecords from 'scenes/MyRecords'
import MyRecordsDetails from 'scenes/MyRecords/Details'
import { PrivacyPolicy } from 'scenes/PrivacyPolicy'
import PrivacyPolicyV1 from 'scenes/PrivacyPolicy/PrivacyPolicyV1'
import Profile from 'scenes/Profile'
import RecordDetails from 'scenes/RecordDetails'
import RecordForCharity from 'scenes/RecordForCharity'
import IdeaGenerator from 'scenes/IdeaGenerator'
import { RecordSubmissions } from 'scenes/RecordSubmissions'
import { RecordSubmissionDetails } from 'scenes/RecordSubmissionDetails'
import { RecordSubmissionDetailsEdit } from 'scenes/RecordSubmissionDetailsEdit'
import RecordSubmitted from 'scenes/RecordSubmitted'
import RequestSubmitted from 'scenes/RequestSubmitted'
import { SignUp } from 'scenes/SignUp'
import TermsOfServiceV1 from 'scenes/TermsOfService/TermsOfServiceV1'
import VerifyAge from 'scenes/VerifyAge'
import VerifyAgeByQuestions from 'scenes/VerifyAge/QuestionsOptions'
import VerifyAgeByCreditCard from 'scenes/VerifyAge/CreditCardOption'
import { VerifyParentByReview } from 'scenes/VerifyAge/VerifyParentByReview'
import { SubmittedOnReviewParent } from 'scenes/SubmittedOnReviewParent'
import { EditMainPage } from './scenes/EditMainPage'
import { Events, ParticipateInEvent } from './scenes/Events'
import { AmbassadorProgram } from './scenes/Ambassador'
import { AmbassadorProfile } from './scenes/AmbassadorProfile'
import { Quizzes } from 'scenes/Quizz'
import { Quiz as QuizGame } from 'scenes/Quizz/Quiz'

import '@fontsource/nunito'
import '@fontsource/nunito/500.css'
import '@fontsource/nunito/600.css'
import '@fontsource/nunito/700.css'
import '@fontsource/nunito/800.css'
import '@fontsource/nunito/900.css'

import 'stylesheets/animations.css'
import 'stylesheets/borders.css'
import 'stylesheets/colors.css'
import 'stylesheets/fonts.css'
import 'stylesheets/generics.css'
import 'stylesheets/variables.css'
import './index.css'
import { EventList } from './scenes/Events/EventList'
import { Participants } from './scenes/Events/Participants'
import { EditEvent } from './scenes/Events/EditEvent'
import { CreateEvent } from './scenes/Events/CreateEvent'
import { Sponsors } from './scenes/Events/Sponsors'
import { CreateGroupRecordSubmission } from './scenes/CreateGroupRecordSubmission/CreateGroupRecordSubmission'
import { GroupRecordSubmissionEdit } from './scenes/GroupRecordSubmissionEdit'
import { EventCertificates } from 'scenes/Events/EventCertificates'
import { QuizScoreBoard } from 'scenes/QuizScoreBoard'
import { ProductDetailsPage } from './scenes/ProductDetails'
import { ListOfRecordsPage } from 'scenes/ListOfRecordsPage'
import { WorldMapPage } from 'scenes/WorldMap'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ListOfRecordsPageV1 } from 'scenes/ListOfRecordsPage/ListOfRecordsPageV1'
import { TermsOfService } from 'scenes/TermsOfService'
import { FAQV1 } from 'scenes/FAQ/FAQV1'
import { FAQ } from 'scenes/FAQ'
import { HowTo, HowToV1 } from 'scenes/HowTo'
import { AmbassadorsProgressTablePage } from 'scenes/AmbassadorsProgressTable'
import { PaidCountriesPage } from 'scenes/PaidCountriesPage'
import { UsersManagmentPage } from 'scenes/UsersManagmentPage'
import { useMyRole } from 'hooks/useMyRole'

const PAYPAL_TOKEN =
  process.env.REACT_APP_PAYPAL_TOKEN ||
  'AQjH_aI32cK_a6KZ3pK1JkHke04oYGXjiUldm_qgxcCldZIRMxmdF-jlpYi5ZPV5ASQ9u_T6cwhDI7Uf'

const GOOGLE_MAP_API_TOKEN = 'AIzaSyDwPmieypyO2Fc5SVhuwhDXI0Zr8XQC0xw'

const PAYPAL_CLIENT_ID = { clientId: PAYPAL_TOKEN }

const AdminLayout = () => {
  const { isAdmin: isAdminLogged, isSuccess } = useMyRole()

  if (isSuccess) {
    if (!isAdminLogged) {
      return <Navigate to={'/login'} />
    }
  }

  return <Outlet />
}

ReactDOM.render(
  <PayPalScriptProvider options={PAYPAL_CLIENT_ID}>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AuthenticationProvider>
          <APIProvider apiKey={GOOGLE_MAP_API_TOKEN} language="en">
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<BrowseRecords />} />
                  <Route path="/records" element={<Navigate to="/" />} />
                  <Route path="/login" element={<LogIn />} />
                  <Route path="/reset-pin" element={<ResetPin />} />
                  <Route
                    path="/login-requested/:email"
                    element={<MagicLinkRequested />}
                  />
                  <Route path="/profile" element={<Profile />} />
                  {CONFIG.FEATURE_931 ? (
                    <Route path="/FAQ" element={<FAQ />} />
                  ) : (
                    <Route path="/FAQ" element={<FAQV1 />} />
                  )}
                  {CONFIG.FEATURE_513 && (
                    <Route path="/world-map" element={<WorldMapPage />} />
                  )}
                  <Route path="/events" element={<GroupEvents />} />
                  <Route path="/Educators" element={<Educators />} />
                  <Route path="/edit-pin" element={<EditPin />} />
                  <Route path="/edit-profile" element={<EditProfile />} />
                  <Route path="/events/:id" element={<ParticipateInEvent />} />
                  <Route path="/records/:id" element={<RecordDetails />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/choose-a-pin" element={<ChooseAPin />} />
                  <Route path="/add-child" element={<AddChild />} />
                  <Route
                    path="/submitted-on-review"
                    element={<SubmittedOnReviewParent />}
                  />
                  <Route path="/child-profile/:id" element={<ChildProfile />} />
                  <Route
                    path="/child-profile/edit/:id"
                    element={<EditChildProfile />}
                  />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/verify-age" element={<VerifyAge />} />
                  <Route
                    path="/verify-age/questions"
                    element={<VerifyAgeByQuestions />}
                  />
                  <Route
                    path="/verify-age/review"
                    element={<VerifyParentByReview />}
                  />
                  <Route
                    path="/verify-age/credit-card"
                    element={<VerifyAgeByCreditCard />}
                  />
                  <Route
                    path="/verify-age/request-submitted"
                    element={<RequestSubmitted />}
                  />
                  <Route
                    path="/break-a-record/:recordId"
                    element={<BreakRecord />}
                  />
                  <Route path="/break-a-record" element={<BreakRecord />} />
                  <Route
                    path="/break-a-record/record-submitted/:id"
                    element={<RecordSubmitted />}
                  />
                  <Route
                    path="/break-a-record/:id/:currentRecordId"
                    element={<BreakRecord />}
                  />
                  <Route path="/about-us" element={<AboutUs />} />
                  {CONFIG.FEATURE_931 ? (
                    <Route
                      path="/available-and-unavailable-group-records"
                      element={<ListOfRecordsPage />}
                    />
                  ) : (
                    <Route
                      path="/available-and-unavailable-group-records"
                      element={<ListOfRecordsPageV1 />}
                    />
                  )}
                  {CONFIG.FEATURE_931 ? (
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  ) : (
                    <Route
                      path="/privacy-policy"
                      element={<PrivacyPolicyV1 />}
                    />
                  )}
                  {CONFIG.FEATURE_931 ? (
                    <Route
                      path="/terms-of-service"
                      element={<TermsOfService />}
                    />
                  ) : (
                    <Route
                      path="/terms-of-service"
                      element={<TermsOfServiceV1 />}
                    />
                  )}
                  <Route path="/my-records" element={<MyRecords />} />
                  <Route
                    path="/my-records/:id"
                    element={<MyRecordsDetails />}
                  />
                  {CONFIG.FEATURE_324 && (
                    <Route
                      path="/ambassador-program"
                      element={<AmbassadorProgram />}
                    />
                  )}
                  {CONFIG.FEATURE_324 && (
                    <Route path="/ambassador" element={<AmbassadorProfile />} />
                  )}
                  <Route path="/quizzes" element={<Quizzes />} />
                  <Route path="/quiz/:id" element={<QuizGame />} />
                  {CONFIG.FEATURE_362 && (
                    <Route
                      path="/quiz-score-board"
                      element={<QuizScoreBoard />}
                    />
                  )}
                  <Route path="/shop" element={<BrowseProducts />} />
                  <Route path="/shop/:id" element={<ProductDetailsPage />} />
                  <Route
                    path="/record-for-charity"
                    element={<RecordForCharity />}
                  />
                  <Route path="/record-ideas" element={<IdeaGenerator />} />
                  <Route path="/charities/:id" element={<CharityDetails />} />

                  {CONFIG.FEATURE_931 ? (
                    <Route path="/how-to-break-a-record" element={<HowTo />} />
                  ) : (
                    <Route
                      path="/how-to-break-a-record"
                      element={<HowToV1 />}
                    />
                  )}

                  {CONFIG.FEATURE_784 && (
                    <Route
                      path="/group-submission/:recordId"
                      element={<CreateGroupRecordSubmission />}
                    />
                  )}
                  {CONFIG.FEATURE_784 && (
                    <Route
                      path="/group-submission"
                      element={<CreateGroupRecordSubmission />}
                    />
                  )}
                  <Route
                    path="/event-certificate-view"
                    element={<EventCertificates />}
                  />
                  <Route path="admin" element={<AdminLayout />}>
                    <Route path="submissions" element={<RecordSubmissions />} />
                    {CONFIG.FEATURE_328 && (
                      <Route
                        path="ambassador-table"
                        element={<AmbassadorsProgressTablePage />}
                      />
                    )}
                    <Route path="events" element={<Events />}>
                      <Route index element={<EventList />} />
                      <Route path="participants" element={<Participants />} />
                      <Route path="edit/:id" element={<EditEvent />} />
                      <Route path="create" element={<CreateEvent />} />
                      <Route path="sponsors" element={<Sponsors />} />
                    </Route>
                    <Route path="login-to-user" element={<LogInToUser />} />
                    <Route path="idea-manager" element={<IdeaManager />} />
                    <Route
                      path="paid-countries"
                      element={<PaidCountriesPage />}
                    />
                    <Route
                      path="submissions/:id"
                      element={<RecordSubmissionDetails />}
                    />
                    <Route
                      path="submissions/:id/edit"
                      element={<RecordSubmissionDetailsEdit />}
                    />
                    <Route
                      path="create-submission"
                      element={<CreateGroupRecordSubmission />}
                    />
                    <Route path="editMainPage" element={<EditMainPage />} />
                    <Route
                      path="group-submission/:id/edit"
                      element={<GroupRecordSubmissionEdit />}
                    />
                    {CONFIG.FEATURE_1054 && (
                      <Route
                        path="users-managment"
                        element={<UsersManagmentPage />}
                      />
                    )}
                  </Route>
                  <Route path="*" element={<DefaultRedirect />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </APIProvider>
        </AuthenticationProvider>
      </LocalizationProvider>
    </Provider>
  </PayPalScriptProvider>,
  document.getElementById('root'),
)
