import creditCardSchema from 'validation/creditCardSchema'

export type KindOfOrganizer = 'solo' | 'team'

export interface CreditCardFormData {
  holderName?: string
  number?: string
  validThru?: string
}

export interface ValidCreditCardFormData {
  holderName: string
  number: string
  validThru: string
}

const isValid = (
  creditCard: CreditCardFormData,
): creditCard is ValidCreditCardFormData =>
  creditCardSchema.isValidSync(creditCard)

const exp = { isValid }

export default exp
