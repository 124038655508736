import Client, { Checkout } from 'shopify-buy'

import { CONFIG } from 'config'

import { ProductCheckout } from 'data/Product'

export const shopifyClient = Client.buildClient({
  domain: CONFIG.SHOPIFY_DOMAIN,
  storefrontAccessToken: CONFIG.SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  apiVersion: '2023-04',
})

const createCheckout = async (): Promise<Checkout> =>
  await shopifyClient.checkout.create()

const addLineItems = (lineItems: any, checkoutId: string): Promise<any> =>
  shopifyClient.checkout
    .addLineItems(checkoutId, lineItems)
    .then((checkout: any) => checkout)
    .catch((error: any) => error.message)

const buyProducts = (products: ProductCheckout[]) => {
  const lineItemsToAdd = products.map((productCheckout) => ({
    variantId: productCheckout.product.variantIds[0],
    quantity: productCheckout.quantity,
    customAttributes: [
      { key: 'child_name', value: `${productCheckout.childName}` },
      { key: 'record_name', value: `${productCheckout.submissionTitle}` },
      { key: 'record_id', value: `${productCheckout.submissionId}` },
      { key: 'record_url', value: `${productCheckout.submissionUrl}` },
    ],
  }))

  return createCheckout()
    .then((checkout: Checkout) => addLineItems(lineItemsToAdd, checkout.id))
    .catch((error: any) => error.message)
}

export { buyProducts }
