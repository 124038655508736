import { Child } from './Child.types'
import { GenderV2 } from './Gender.types'

export enum AccountRole {
  ADMIN = 'ADMIN',
  PARENT = 'PARENT',
  CHILD = 'CHILD',
}

export type AuthProfile = {
  id: string
  firstName: string
  lastName: string
  email?: string
  role: AccountRole
}

export interface UserProfile {
  role: AccountRole
  dateOfBirth: string
  email: string
  firstName: string
  id: string
  lastName: string
  gender: GenderV2
}

export type UserAccount = {
  id: string
  role: AccountRole
}

export type MyProfile = {
  type: Omit<AccountRole, 'ADMIN'>
  profile: Child
}

export type ResetPinBody = {
  token: string
  pin: string
}
