import { Profile } from 'data/Profile'
import { Location, LocationParams } from 'data/Location'

import { Option } from 'components/Select'

export type Gender = 'female' | 'male' | 'gender_x'
type ParsedGender = 'Girl' | 'Boy' | 'Gender X'

export interface ChildProfile extends Profile {
  __typename: 'ChildProfile'
  ageBracket: string
  gender: Gender
  location: Location | null
  parentProfileId: string
  parentProfile?: {
    firstName: string
    lastName: string
    email: string
  }
}

export type ChildProfileParams = Omit<
  ChildProfile,
  'ageBracket' | 'id' | 'location' | 'parentProfileId' | 'tokens' | '__typename'
> & {
  location: LocationParams | null
}

const getFullName = (profile: ChildProfile): string =>
  `${profile.firstName} ${profile.lastName}`

// const getAgeBracket = (date: Date) => {
//   const now = moment(new Date())
//   const birthDate = moment(date)
//   const duration = moment.duration(now.diff(birthDate))
//   const age = Math.round(duration.asYears())
//
//   const brackets = [
//     [4, 6],
//     [7, 9],
//     [10, 12],
//     [13, 16],
//   ]
//
//   for (let i = 0; i < brackets.length; i++) {
//     if (age >= brackets[i][0] && age <= brackets[i][1]) {
//       return `${brackets[i][0]} - ${brackets[i][1]}`
//     }
//   }
//
//   return ''
// }

const getGender = (gender: Gender): ParsedGender => {
  switch (gender) {
    case 'male':
      return 'Boy'
    case 'female':
      return 'Girl'
    case 'gender_x':
      return 'Gender X'
  }
}

const getChildProfileOptions = (childProfiles: ChildProfile[]) => {
  const childProfileOptions: Option<string>[] = []

  childProfiles.forEach((childProfile: ChildProfile) => {
    const item = {
      label: `${childProfile.firstName} ${childProfile.lastName}`,
      value: childProfile.id,
    }
    childProfileOptions.push(item)
  })

  return childProfileOptions
}

const exp = {
  getChildProfileOptions,
  getGender,
  getFullName,
}

export default exp
