import React, { FC, useEffect, memo, useRef, useState } from 'react'
import { useMapsLibrary } from '@vis.gl/react-google-maps'

import { Input, InputProps } from './Input'
import { getCompleteAddress } from 'utils/location.util'
import { LocationParams } from 'data/Location'

export interface LocationAutocompleteProps {
  label?: string
  name: string
  defaultValue?: string
  onFocus?: InputProps['onFocus']
  errorText?: string
  onSelectLocation?(location: LocationParams | null): void
}

const options = {
  fields: [
    'address_components',
    'geometry',
    'icon',
    'name',
    'utc_offset_minutes',
  ],
  language: 'en',
  types: ['geocode'],
}

const LocationAutocompleteCmp: FC<LocationAutocompleteProps> = ({
  label,
  name,
  onFocus,
  defaultValue,
  onSelectLocation,
  errorText,
}) => {
  const inputRef = useRef<HTMLInputElement>()
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null)
  const places = useMapsLibrary('places')

  useEffect(() => {
    if (!places || !inputRef.current) return

    //todo: check how to use it properly
    // places.AutocompleteSessionToken

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options))
  }, [places])

  useEffect(() => {
    if (!placeAutocomplete || !onSelectLocation) return

    placeAutocomplete.addListener('place_changed', () => {
      onSelectLocation(getCompleteAddress(placeAutocomplete.getPlace()))
    })
  }, [onSelectLocation, placeAutocomplete])

  return (
    <Input
      key={defaultValue}
      name={name}
      label={label}
      onFocus={onFocus}
      defaultValue={defaultValue}
      inputRef={inputRef}
      errorText={errorText}
    />
  )
}

export const LocationAutocomplete = memo(LocationAutocompleteCmp)
