import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { Select } from '../Inputs'

interface FormSelectProps {
  name: string
  label?: string
  items: { value: string | number; label: string }[]
  control: any
  errorText?: string
  className?: string
  disabled?: boolean
}

export const FormSelect: FC<FormSelectProps> = ({
  name,
  label,
  items,
  control,
  errorText,
  className,
  disabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          className={className}
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          items={items}
          label={label}
          errorText={errorText}
          disabled={disabled}
        />
      )}
    />
  )
}
