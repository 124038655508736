import * as Yup from 'yup'

import { requiredEmail } from './requiredFields'

import { recordDetails } from './recordDetailsSchema'

const picturedPersons = Yup.array()
  .of(
    Yup.object().shape({
      name: Yup.string().required(),
      email: requiredEmail,
    }),
  )
  .required()

const Schema = Yup.object().shape({
  ...recordDetails,
  picturedPersons,
})

export default Schema
