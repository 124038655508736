import React, { FC } from 'react'
import { Radio } from '@mui/material'

import { GroupCategory } from 'store/api/types/Record.types'
import { CATEGORY_OPTIONS } from '../CreateGroupRecordSubmission.utils'

import styles from '../CreateGroupRecordSubmission.module.scss'

const Input = ({ error, ...props }: any) => (
  <span className={styles.optionInput}>
    <input className={styles.amountInput} {...props} />
    {error && <span className={styles.error}>*</span>}
  </span>
)

type CategorySelectProps = {
  value: { category: GroupCategory; participantAmount: number }
  onChange(category: GroupCategory, participantAmount: number | null): void
  error: string
}

export const CategorySelect: FC<CategorySelectProps> = ({
  value,
  onChange,
  error,
}) => {
  return (
    <div className={styles.radioRow}>
      {CATEGORY_OPTIONS.map((option) => (
        <div className={styles.radioOption} key={option.value}>
          <Radio
            id={option.value}
            className={styles.radio}
            checked={value?.category === option.value}
            onChange={() => onChange(option.value, null)}
          />
          {`${option.label}`}
          <Input
            id={option.value}
            type="number"
            disabled={value?.category !== option.value}
            value={value?.category === option.value && value?.participantAmount}
            error={error}
            onChange={(e: any) => onChange(option.value, e.target.value)}
          />
        </div>
      ))}
    </div>
  )
}
