import React, { FC } from 'react'
import { Box } from '@mui/material'

import { PublicRecord } from 'hooks/useRecordApi.hook'
import Loader from 'components/Loader'
import { InView } from 'components/InView/InView'
import { Conditional } from 'components/Conditional'
import { ItemLayout } from 'typings'

import { RecordCard } from './RecordCard'

import styles from './RecordList.module.scss'

interface RecordListProps {
  isLoading: boolean
  records: PublicRecord[]
  layoutType: ItemLayout
  hasNextPage: boolean
  onGetMore(): void
}

export const RecordList: FC<RecordListProps> = ({
  isLoading,
  records,
  layoutType,
  hasNextPage,
  onGetMore,
}) => {
  return (
    <Box className={styles.recordList}>
      {records.map((record, index) => (
        <RecordCard key={record.id} record={record} />
      ))}
      <Conditional
        shouldRender={Boolean(records.length) && !isLoading && hasNextPage}
      >
        <InView onInView={onGetMore} />
      </Conditional>
      <Loader active={isLoading} className={styles.loader} />
    </Box>
  )
}
