import { CategoryOption, Category } from 'data/Category'
import { Location } from 'data/Location'
import { RecordIdea } from './RecordIdea'
import ChildProfileData, { Gender, ChildProfile } from './ChildProfile'
import { Page } from 'typings/Page'

export interface Record {
  ageBracket: string
  brokenOn: string
  category: Category
  newRecordId?: string
  childProfile?: ChildProfile
  description: string
  priority?: number
  gender: Gender
  id: string
  idea: RecordIdea
  imageLinks: string[]
  isLiked: boolean
  likes: number
  location: Location
  title: string
  deletedAt?: any
  requireParentalSupervision?: boolean
  requireTimingDevice?: boolean
  noNewRecord?: boolean
  notes?: string
}

export interface Connection<T> {
  edges: [
    {
      cursor: string
      node: T
    },
  ]
  pageInfo: {
    endCursor?: string
    hasNextPage: boolean
    hasPreviousPage: boolean
    startCursor?: string
  }
  totalCount: number
}

export interface RecordConnection extends Connection<Record> {}

export interface RecordsByCategory {
  category: CategoryOption
  recordPage: Page<Record>
}

export interface RecordsByTitle {
  title: string
  recordPage: Page<Record>
}

const getInfoDescription = (record: Record) => {
  const gender = ChildProfileData.getGender(record.gender)
  const address = getAddressForRecordCard(record.location)

  return `${gender} - ${record.ageBracket} YEARS OLD - ${address}`.toUpperCase()
}

const getAddressForRecordCard = (location: Location) =>
  `${location.city}, ${location.state === 'N/A' ? '' : location.state} (${
    location.country
  })`

const exp = { getAddressForRecordCard, getInfoDescription }

export default exp
