import React from 'react'
import ShadowModal from 'components/ShadowModal'
import Button from 'components/Button'
import styles from './DeleteChildModal.module.scss'

interface ModalProps {
  isOpen: boolean
  onConfirm: () => void
  onClose: () => void
}

export const WarningModal: React.FC<ModalProps> = ({
  isOpen,
  onConfirm,
  onClose,
}) => {
  return (
    <ShadowModal
      isModalOpen={isOpen}
      className={styles.modal}
      onCancel={onClose}
      withShadow
      noSize
    >
      <div className={styles.modalContainer}>
        <div className={styles.header}>Warning!</div>
        <div className={styles.modalText}>
          Deleting your child's profile will remove all information related to
          the child from our record database. This includes all your child's
          records if it has any.{' '}
          <strong>
            This process is irreversible. Once the data is deleted it cannot be
            restored.
          </strong>
        </div>
        <Button onClick={onConfirm}>I understand</Button>
      </div>
    </ShadowModal>
  )
}
