import React, { useEffect, useMemo, useState } from 'react'
import styles from './ParticipantsInEvent.module.scss'

const SECOND = 1000
const MINUTE = SECOND * 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24

interface TimerProps {
  deadline: Date | undefined
  onZero: () => void
}

export const Timer = ({ deadline = new Date(), onZero }: TimerProps) => {
  const parsedDeadline = useMemo(
    () => Date.parse(deadline.toString()),
    [deadline],
  )
  const [time, setTime] = useState(parsedDeadline - Date.now())

  useEffect(() => {
    const interval = setInterval(() => {
      if (parsedDeadline - Date.now() <= 0) {
        onZero()
      }
      setTime(parsedDeadline - Date.now())
    }, 1000)

    return () => clearInterval(interval)
  }, [parsedDeadline, onZero])

  const formatTime = (value: number, label: string) => {
    return (
      <div key={label}>
        <div className={styles.box}>
          <p className={styles.number}>
            {`${Math.floor(value)}`.padStart(2, '0')}
          </p>
          <span className="text">{label}</span>
        </div>
      </div>
    )
  }

  return time > 0 ? (
    <div className={styles.timer}>
      {Object.entries({
        days: time / DAY,
        hours: (time / HOUR) % 24,
        mins: (time / MINUTE) % 60,
        sec: (time / SECOND) % 60,
      }).map(([label, value]) => formatTime(value, label))}
    </div>
  ) : (
    <div className={styles.timer}>
      {['days', 'hours', 'mins', 'sec'].map((label) => formatTime(0, label))}
    </div>
  )
}
