import { RootState } from 'store'

import { SignUpProfile } from 'typings/SignUpProfile'

export const getSignUpProfile = (state: RootState): SignUpProfile => {
  return {
    dateOfBirth: state.signup.profile.dateOfBirth,
    firstName: state.signup.profile.firstName,
    lastName: state.signup.profile.lastName,
    email: state.signup.profile.email,
    pin: state.signup.profile.pin,
    howDidYouFoundUs: state.signup.profile.howDidYouFoundUs,
    isTeacher: state.signup.profile.isTeacher,
  }
}

export const isLoading = (state: RootState): boolean => state.signup.isLoading
