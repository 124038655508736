import React, { useState } from 'react'
import {
  useGetProfileQuery,
  useJoinAmbassadorProgramMutation,
} from '../../../store/api'
import { AccountRole } from '../../../store/api/types/Auth.types'
import Button from '../../../components/Button'
import Title from 'components/Title'
import BorderedContainer from 'components/BorderedContainer'
import { AboutLevelItem } from './AboutLevelItem'
import { JoinTheProgramModal } from './JoinTheProgramModal'
import styles from '../Ambassador.module.scss'

const steps = [
  {
    image: '/assets/ambassador-circle-1.png',
    description: (
      <>
        First <strong>become an Ambassador</strong>, then{' '}
        <strong>tell your friends about Kids World Records</strong> and give
        them your Ambassador code.
      </>
    ),
  },
  {
    image: '/assets/ambassador-circle-2.png',
    description: (
      <>
        Your friends <strong>use the code</strong> when they submit a Kids World
        Record attempt.
      </>
    ),
  },
  {
    image: '/assets/ambassador-circle-3.png',
    description: (
      <>
        If your friend's attempt is approved, you advance in the program and{' '}
        <strong>get digital and physical stuff.</strong>
      </>
    ),
  },
]

const levels = [
  {
    level: 1,
    imageUrl: '/assets/ambassador-level-1.png',
    levelPerk:
      'You’re officially an Ambassador and you get a Level badge on your record image.',
    howToUnlock:
      'Tell a friend about Kids World Records! When a friend gets 1 new record approved you’re a Level 1. You can not refer yourself or someone who already has a record.',
  },
  {
    level: 2,
    imageUrl: '/assets/ambassador-level-2.png',
    levelPerk:
      'You get an updated Level badge and have your name added to the official Ambassador list.',
    howToUnlock:
      'You’re doing great! Refer one more successful record breaker.',
  },
  {
    level: 3,
    imageUrl: '/assets/ambassador-level-3.png',
    levelPerk:
      'Updated Level badge and you get a physical Ambassador ID card in the mail!',
    howToUnlock:
      'Refer 5 successful record breakers to get your official KWR ID card. Mailing address required',
  },
  {
    level: 4,
    imageUrl: '/assets/ambassador-level-4.png',
    levelPerk:
      'Updated Level digital badge and you get a physical Ambassador badge in the mail! And you get a shout out on social media.',
    howToUnlock:
      'Refer 10 successful record breakers to get a physical Ambassador badge in the mail. Mailing address required.',
  },
  {
    level: 5,
    imageUrl: '/assets/ambassador-level-5.png',
    levelPerk:
      'Get the Level digital badge and get sent a KWR shirt in the mail. Your record is featured on our YouTube channel and you will get a shout out on social media.',
    howToUnlock:
      'Refer 15 successful record breakers to reach to top of the program! Mailing address required.',
  },
]

export const AboutProgram = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isJoined, setIsJoined] = useState(false)
  const [joinAmbassadorProgram, { isLoading: isJoining }] =
    useJoinAmbassadorProgramMutation()
  const { data, isLoading } = useGetProfileQuery()

  const isChild = data?.type === AccountRole.CHILD

  const handleSubmit = async () => {
    try {
      if (isChild) {
        await joinAmbassadorProgram(data.profile.id).unwrap()
        setIsJoined(true)
      }
    } catch (e) {
      setIsJoined(false)
      console.error(e)
    } finally {
      setIsModalOpen(true)
    }
  }

  return (
    <BorderedContainer style={{ marginBottom: '4rem' }}>
      <JoinTheProgramModal
        isOpen={isModalOpen}
        isJoined={isJoined}
        isChild={isChild}
        onClose={() => setIsModalOpen(false)}
      />
      <Title className={styles.main_title}>About the Ambassador Program</Title>
      <p className={styles.description}>
        Spread the word, Get Cool Stuff. Most of our new record holders are
        referred by a friend. The Ambassador Program is our way of saying thanks
        to any record holder who refers new record breakers. Please note that
        you MUST be a Kids World Record holder to participate in the Ambassador
        Program.
      </p>
      <div className={styles.program_info}>
        {steps.map((step) => (
          <div className={styles.container} key={step.image}>
            <img className={styles.image} src={step.image} alt={'stepImage'} />
            <div className={styles.description}>{step.description}</div>
          </div>
        ))}
      </div>
      {levels.map((level) => (
        <AboutLevelItem
          key={level.level}
          index={level.level}
          imageUrl={level.imageUrl}
          levelPerk={level.levelPerk}
          howToUnlock={level.howToUnlock}
        />
      ))}
      <div className={styles.buttonBox}>
        <Button
          className={styles.button}
          disabled={isJoining || isLoading || data?.profile.isAmbassador}
          onClick={handleSubmit}
        >
          JOIN THE AMBASSADOR PROGRAM
        </Button>
      </div>
    </BorderedContainer>
  )
}
