import React, { createContext } from 'react'
import * as localStorage from 'utils/localStorage'
import { useMyRole } from 'hooks/useMyRole'

interface AuthenticationContextData {
  isAuthenticated: boolean
}

export const AuthenticationContext = createContext<AuthenticationContextData>({
  isAuthenticated: false,
})

const AuthenticationProvider = ({
  children,
}: {
  children: any
}): JSX.Element => {
  const { isLoading, isAuthenticated } = useMyRole()
  if (!isLoading && !isAuthenticated) {
    localStorage.remove('AUTH_TOKEN')
  }

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationProvider
