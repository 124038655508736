import React from 'react'
import ShadowModal from '../../../components/ShadowModal'
import styles from './EventList.module.scss'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import Button from '../../../components/Button'

interface DeleteEventModalProps {
  isOpenModal: boolean
  onClose: () => void
  isLoading: boolean
  onDelete: () => void
}

export const DeleteEventModal: React.FC<DeleteEventModalProps> = ({
  isOpenModal,
  onClose,
  isLoading,
  onDelete,
}) => {
  return (
    <ShadowModal isModalOpen={isOpenModal} onCancel={onClose} noSize>
      <IconButton
        className={styles.closeBtn}
        aria-label="close"
        onClick={onClose}
      >
        <Close />
      </IconButton>
      <div className={styles.deleteEventModalContainer}>
        <div className={styles.header}>Delete Event</div>
        <div>Are you sure you want to delete this campaign?</div>
      </div>
      <div className={styles.buttons}>
        <Button disabled={isLoading} ghost onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={onDelete}>
          Delete
        </Button>
      </div>
    </ShadowModal>
  )
}
