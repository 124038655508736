import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { isLoading } from 'store/signup/selectors'
import {
  useSendResetPinEmailMutation,
  useSwitchProfileMutation,
  useVerifyPinMutation,
} from '../../store/api'

import Loader from 'components/Loader'
import ShadowModal from 'components/ShadowModal'
import Title from 'components/Title'
import Button from 'components/Button'
import SubmitButton from 'components/SubmitButton'
import { Profile } from 'data/Profile'
import { SplashContext } from 'components/Splash'

import { showToaster } from 'store/app/actions'
import { FormPinInput } from 'components/Form/FormPinInput'
import { joiResolver } from '@hookform/resolvers/joi'

import { PIN_VALIDATOR } from '../../scenes/Quizz/components/ParentPermissionModal/PinInput.utils'
import { useForm } from 'react-hook-form'

import styles from './EnterYourPin.module.scss'
import Conditional from 'components/Conditional'

export const EnterYourPin: React.FC<{
  open: boolean
  selectedProfile?: Profile | null
  onCancel(): void
}> = ({ open, selectedProfile, onCancel }) => {
  const [sendResetPinEmail] = useSendResetPinEmailMutation()
  const [verifyPin, { isLoading: isLoadingVerifyPin }] = useVerifyPinMutation()
  const [switchProfile, { isSuccess, isLoading: isLoadingSwitchProfile }] =
    useSwitchProfileMutation()
  const loading = useSelector(isLoading)
  const [isConfirmation, setIsConfirmation] = useState(false)

  const dispatch = useDispatch()

  const { showSplash } = useContext(SplashContext)
  const onSubmit = async (body: { pin: string[] }) => {
    try {
      await verifyPin({ pin: body.pin.join('') }).unwrap()
      if (selectedProfile?.id) {
        await switchProfile(selectedProfile.id).unwrap()
        onCancel()
        showSplash && showSplash(selectedProfile)
      }
    } catch (e) {
      dispatch(
        showToaster({
          message: `Incorrect pin, please try again.`,
          color: 'red',
        }),
      )
    }
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{ pin: string[] }>({
    resolver: joiResolver(PIN_VALIDATOR),
    mode: 'onSubmit',
  })
  useEffect(() => {
    reset({ pin: [] })
  }, [reset, open])

  return (
    <Conditional shouldRender={open}>
      <Fragment>
        <Loader active={loading} className={styles.enter_your_pin__loader} />
        <ShadowModal isModalOpen={!isSuccess} noSize onCancel={onCancel}>
          <Conditional shouldRender={!isConfirmation}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.enter_your_pin}>
                <Title>Enter your PIN</Title>
                <span className={styles.yourPinSpan}>
                  To log in this session, please, enter your PIN.
                </span>
                <div className={styles.pinInput}>
                  <FormPinInput
                    name="pin"
                    control={control}
                    isMasked={true}
                    errorText={errors.pin?.message}
                    isFocused={true}
                  />
                </div>
                <div className={styles.send_reset}>
                  <button
                    type="button"
                    className={styles.send_reset_link}
                    onClick={() => {
                      sendResetPinEmail()
                      setIsConfirmation(true)
                    }}
                    disabled={isLoadingSwitchProfile || isLoadingVerifyPin}
                  >
                    Send reset PIN email
                  </button>
                </div>
                <div className={styles.buttonsDiv}>
                  <SubmitButton
                    disabled={isLoadingSwitchProfile || isLoadingVerifyPin}
                    className={styles.buttonChoice}
                  >
                    Confirm
                  </SubmitButton>
                  <Button
                    ghost
                    outline
                    disabled={isLoadingSwitchProfile || isLoadingVerifyPin}
                    onClick={onCancel}
                    className={styles.buttonChoice}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </Conditional>
          <Conditional shouldRender={isConfirmation}>
            <div className={styles.enter_your_pin}>
              <Title>Reset Instruction</Title>
              <div className={styles.row}>
                <span className={styles.message}>
                  We will send you instruction to your email
                </span>
                <SubmitButton onClick={() => setIsConfirmation(false)}>
                  Ok
                </SubmitButton>
              </div>
            </div>
          </Conditional>
        </ShadowModal>
      </Fragment>
    </Conditional>
  )
}
