import { useCallback, useEffect, useState } from 'react'
import { QueryStatus } from '@reduxjs/toolkit/query'
import { useLazyGetSubmissionsQuery } from 'store/api'
import { SortType, Submission } from 'store/api/types/Record.types'

const PAGE_SIZE = 12

export const useSubmissions = (filters: {
  search: string
  status: string
  sort: SortType
}) => {
  const [skip, setSkip] = useState(0)
  const [hasNextPage, setHasNextPage] = useState<boolean>(true)
  const [filtersForQuery, setFiltersForQuery] = useState({
    ...filters,
    skip,
    take: PAGE_SIZE,
  })

  const [counts, setCounts] = useState({
    totalCount: 0,
    inReview: 0,
    approved: 0,
    rejected: 0,
  })
  const [submissions, setSubmissions] = useState<Submission[]>([])

  const [trigger, { data, isLoading, error, status }] =
    useLazyGetSubmissionsQuery()

  useEffect(() => {
    trigger(filtersForQuery)
  }, [filtersForQuery, trigger])

  useEffect(() => {
    setSkip(0)
    setSubmissions([])
    setFiltersForQuery({
      ...filters,
      skip: 0,
      take: PAGE_SIZE,
    })
  }, [filters])

  useEffect(() => {
    if (data) {
      setHasNextPage(data.submissions?.length === PAGE_SIZE)
      setSubmissions((submissions) => [...submissions, ...data.submissions])
      setCounts(data.counts)
      setSkip((skip) => skip + PAGE_SIZE)
    }
  }, [data])

  const onNext = useCallback(() => {
    setSkip((skip) => {
      setFiltersForQuery((filters) => ({ ...filters, skip }))
      return skip
    })
  }, [])

  return {
    isLoading: isLoading || status === QueryStatus.pending || Boolean(error),
    submissions,
    counts,
    onNext,
    hasNextPage,
  }
}
