import React, { FC } from 'react'
import { Popper } from '@mui/material'

import styles from './PopperModal.module.scss'

interface PopperModalProps {
  isPopUpVisible: boolean
}
export const PopperModal: FC<PopperModalProps> = ({
  isPopUpVisible,
  children,
}) => {
  const rootEl = document.getElementById('root')
  return (
    <Popper
      className={styles.popper}
      open={isPopUpVisible}
      placement="bottom-start"
      disablePortal={false}
      anchorEl={rootEl}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'viewport',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'viewport',
            padding: 8,
          },
        },
      ]}
    >
      {children}
    </Popper>
  )
}
