import React, { FC, useEffect, memo, useRef, useState } from 'react'
import { useMapsLibrary } from '@vis.gl/react-google-maps'

import { Input, InputProps } from './Input'
import { getCountry } from 'utils/location.util'

export interface LocationAutocompleteProps {
  label?: string
  name: string
  defaultValue: string
  onFocus?: InputProps['onFocus']
  errorText?: string
  onSelectLocation?(country: string | null): void
}

const options = {
  fields: ['address_components', 'geometry', 'icon', 'name'],
  language: 'en',
  types: ['geocode'],
}

const CountryAutocompleteCmp: FC<LocationAutocompleteProps> = ({
  label,
  name,
  onFocus,
  defaultValue,
  onSelectLocation,
  errorText,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null)
  const places = useMapsLibrary('places')

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = defaultValue
  }, [defaultValue])

  useEffect(() => {
    if (!places || !inputRef.current) return

    //todo: check how to use it properly
    // places.AutocompleteSessionToken

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options))
  }, [places])

  useEffect(() => {
    if (!placeAutocomplete || !onSelectLocation) return

    placeAutocomplete.addListener('place_changed', () => {
      onSelectLocation(getCountry(placeAutocomplete.getPlace()))
    })
  }, [onSelectLocation, placeAutocomplete])

  return (
    <Input
      type="text"
      name={name}
      label={label}
      onFocus={onFocus}
      inputRef={inputRef}
      errorText={errorText}
    />
  )
}

export const CountryAutocomplete = memo(CountryAutocompleteCmp)
