import React, { FC } from 'react'
import Title from 'components/Title'

import { Box } from '@mui/material'
import Conditional from 'components/Conditional'
import { InView } from 'components/InView'
import Loader from 'components/Loader'
import { useAllUsers } from 'hooks/useAllUsersApi.hook'
import { UserAccordion } from 'components/UserAccordion'
import { PageWrapper } from 'components/PageWrapper'
import { Screen } from 'components/Screen'

import styles from './UsersManagmentPage.module.scss'

export const UsersManagmentPage: FC = () => {
  //const [filter, setFilter] = useState('')
  const filter = ''

  const { users, onNext, isLoading, hasNextPage } = useAllUsers(filter)
  return (
    <PageWrapper>
      <Screen className={styles.screen}>
        <Title className={styles.titlePage}>Users Managment</Title>
        <Box className={styles.recordList}>
          {users.map((user) => (
            <UserAccordion user={user} key={user.id} />
          ))}
          <Conditional
            shouldRender={Boolean(users.length) && !isLoading && hasNextPage}
          >
            <InView onInView={onNext} />
          </Conditional>
          <Loader active={isLoading} className={styles.loader} />
        </Box>
      </Screen>
    </PageWrapper>
  )
}
