import Conditional from 'components/Conditional'
import React, { Fragment } from 'react'
import { Chart } from 'react-google-charts'
import { useGetWorldMapDataQuery } from 'store/api'

import Loader from 'components/Loader'
import styles from './WorldMap.module.scss'

const recordsFacts = [
  {
    fieldName: 'currentRecordHoldersAmount',
    name: 'Current Kids World Records Holders',
  },
  {
    fieldName: 'totalRecordHoldersAmount',
    name: 'Total Number of Kids World Records Holders',
  },
  {
    fieldName: 'ambassadorsRecordHoldersAmount',
    name: 'Total Kids World Records Ambassadors',
  },
]

interface WorldMapProps {
  isTableVisible: boolean
}
let dataWorldMap = [['Country', 'Records', { type: 'string', role: 'tooltip' }]]
export const WorldMap: React.FC<WorldMapProps> = ({ isTableVisible }) => {
  const { data, isSuccess, isLoading, isError } = useGetWorldMapDataQuery()
  if (data) {
    data.countryAmount.map((item) =>
      dataWorldMap.push([item.country, item.count, 'Has records']),
    )
  }

  return (
    <Fragment>
      <Conditional shouldRender={isLoading}>
        <Loader
          active
          className="z-999"
          style={{
            top: '50%',
            left: 'calc(50% - 30px)',
          }}
        />
      </Conditional>
      <Conditional shouldRender={isSuccess}>
        <div className={styles.totalInfo}>
          {data &&
            isTableVisible &&
            recordsFacts.map((item, index) => (
              <div className={styles.cardInfo} key={index}>
                {/* <p className={styles.recordsFactsNumber}>{data.holders[item.fieldName as keyof typeof data.holders]}</p> */}
                <p className={styles.recordsFactsNumber}>Hidden</p>
                <p className={styles.recordsFactsText}>{item.name}</p>
              </div>
            ))}
        </div>
        <Chart
          chartType="GeoChart"
          width="100%"
          height="400px"
          data={dataWorldMap}
          options={{
            colors: ['#4758AB', '#4758AB'],
            legend: 'none',
          }}
        />
      </Conditional>
      <Conditional shouldRender={isError}>
        <p className={styles.errorMessageDisplay}>Can't load World Map</p>
      </Conditional>
    </Fragment>
  )
}
