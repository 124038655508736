import { useGetMyAccountQuery } from 'store/api'
import { AccountRole } from 'store/api/types/Auth.types'

type UsersRole = {
  isChild: boolean
  isParent: boolean
  isAdmin: boolean
  isAuthenticated: boolean
  isLoading: boolean
  isSuccess: boolean
}

export const useMyRole = (): UsersRole => {
  const { data: account, isFetching, isSuccess } = useGetMyAccountQuery()
  const isChild = account?.role === AccountRole.CHILD
  const isParent = account?.role === AccountRole.PARENT
  const isAdmin = account?.role === AccountRole.ADMIN
  const isAuthenticated = Boolean(account)

  return {
    isChild: isChild,
    isParent: isParent,
    isAdmin: isAdmin,
    isAuthenticated: isAuthenticated,
    isLoading: isFetching,
    isSuccess: isSuccess,
  }
}
