import { ContentInput } from 'components/Inputs/ContentInput'
import React, { FC } from 'react'

import { Controller } from 'react-hook-form'

interface FormContentInputProps {
  name: string
  control: any
  errorText?: string
  initialValue: string
}

export const FormContentInput: FC<FormContentInputProps> = ({
  name,
  errorText,
  control,
  initialValue,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <ContentInput
          onChange={field.onChange}
          initialValue={initialValue}
          errorText={errorText}
        />
      )}
    />
  )
}
