import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import IconButton from 'components/IconButton'

import './tooltip.css'

const Tooltip: React.FC<{ right?: boolean; className?: string }> = ({
  children,
  className,
  right = false,
}) => {
  const [visible, setVisible] = useState<boolean>(false)

  const ref = useRef<HTMLDivElement>(null)

  const handleOutsideClick = (e: any) => {
    if (ref.current?.contains(e.target)) {
      return
    }

    setVisible(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  })

  const tooltipClass = cn(
    'tooltip__content z-999 bg-white absolute pa3',
    visible && 'visible',
    right && 'right-desktop',
  )

  return (
    <div className={cn('tooltip', className)} ref={ref}>
      <IconButton onClick={() => setVisible((state) => !state)} name="help" />
      <div className={tooltipClass}>{children}</div>
    </div>
  )
}

export default Tooltip
