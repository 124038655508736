import React, { FC } from 'react'
import cn from 'classnames'
import Link from '../../components/Link'
import ImageWithPlaceHolder from '../../components/ImageWithPlaceholder'
import Conditional from '../../components/Conditional'
import styles from './Quiz.module.scss'

interface QuizCardProps {
  id: string
  title: string
  description: string
  image: string
  sponsorLogo?: string
}

export const QuizCard: FC<QuizCardProps> = ({
  id,
  title,
  description,
  image,
  sponsorLogo,
}) => {
  return (
    <Link className=".pointer:hover" to={`/quiz/${id}`}>
      <div className={cn('card', styles.card)}>
        <div className={cn('card-image', styles.image_container)}>
          <ImageWithPlaceHolder
            src={image}
            alt="Quiz"
            className={cn('card__media', styles.image)}
            ariaHidden={true}
          />
          <Conditional shouldRender={Boolean(sponsorLogo)}>
            <div className={styles.sponsor_logo}>
              <img src={sponsorLogo} alt="Sponsor" />
            </div>
          </Conditional>
        </div>
        <div className={styles.cardDescription}>
          <p className={styles.title}>{title}</p>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
    </Link>
  )
}
