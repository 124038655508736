import React from 'react'
import { Navigate } from 'react-router'
import { useSelector } from 'react-redux'

import { getSignUpProfile } from 'store/signup/selectors'

import BorderedContainer from 'components/BorderedContainer'
import LinkButton from 'components/LinkButton'
import Title from 'components/Title'
import { PageWrapper } from 'components/PageWrapper'

const VerifyAgeOptions: React.FC = () => {
  const user = useSelector(getSignUpProfile)

  const filledSignUpForm = user.firstName && user.lastName && user.email

  return (
    <PageWrapper>
      <div
        className="w-100 h-100 center pv3 ph3 ph5-m ph5-l"
        style={{ maxWidth: '74.4rem' }}
      >
        {!filledSignUpForm && <Navigate to="/signup" />}
        {filledSignUpForm && (
          <BorderedContainer>
            <Title>Please verify that you are an adult</Title>
            <div>
              Parental permission is required to gather basic information needed
              to award Kids World Records. <b>No credit charges apply.</b>
            </div>
            <div
              className="flex flex-column justify-between items-center items-start-ns mt5"
              style={{ height: '180px' }}
            >
              <LinkButton
                to="/verify-age/credit-card"
                theme="quaternary"
                ghost
                outline
                className="w-100 w-90-ns"
              >
                PROVIDE A VALID CREDIT CARD&nbsp;
                <p style={{ color: 'black' }}> - NO CHARGES APPLY</p>
              </LinkButton>
              <LinkButton
                to="/verify-age/questions"
                theme="quaternary"
                ghost
                outline
                className="w-100 w-90-ns"
              >
                ANSWER THREE SKILL-TESTING QUESTIONS
              </LinkButton>
              <LinkButton
                to="/verify-age/review"
                theme="quaternary"
                ghost
                outline
                className="w-100 w-90-ns"
              >
                REGISTER WITH GOVERNMENT-ISSUED ID
              </LinkButton>
            </div>
            <div className="flex flex-row justify-center w-100 pt6">
              <LinkButton className="w-100 mw5" to="/choose-a-pin" ghost>
                BACK
              </LinkButton>
            </div>
          </BorderedContainer>
        )}
      </div>
    </PageWrapper>
  )
}

export default VerifyAgeOptions
