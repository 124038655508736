import React, { FC } from 'react'
import cn from 'classnames'

import styles from './TwoColumnsGrid.module.scss'

interface TwoColumnsGridProps {
  className?: string
}

export const TwoColumnsGrid: FC<TwoColumnsGridProps> = ({
  children,
  className,
}) => {
  return <div className={cn(styles.twoColumnsGrid, className)}>{children}</div>
}
