import React from 'react'

import { Radio } from '@mui/material'
import cn from 'classnames'

import Tooltip from 'components/Tooltip'
import { Input as InputText } from 'components/Inputs'
import { OutlinedContainer } from 'components/OutlineContainer'

import styles from './RecordDescription.module.scss'
import { useGetProfileQuery } from '../../store/api'
import { formatDate } from 'utils/datetime'

export const TemplatedPart = ({ value }: any) => {
  return <span className={styles.templatedPart}>{value}</span>
}

const Input = (props: any) => (
  <span className={styles.input}>
    <input {...props} />
    <Tooltip className={styles.toolTip}>
      <div style={{ width: '260px' }}>{props.tooltip}</div>
    </Tooltip>
  </span>
)

const RECORD_DESCRIPTION_PLACEHOLDER = (
  <span>
    Describe your record. For example:{' '}
    <h4 style={{ margin: 0 }}>Largest foot</h4>
  </span>
)
const RECORD_SIZE_PLACEHOLDER = (
  <span>
    How big, log, tall fast was the record. For example:{' '}
    <h4 style={{ margin: 0 }}>5 minutes</h4>
  </span>
)

export const TemplateDescription = ({
  location,
  brokenDate,
  disabled,
  defaultValue,
  recordSizeFieldDefaultValue,
  recordDescriptionFieldDefaultValue,
}: any) => {
  const { data: loggedProfile } = useGetProfileQuery()
  const genderAndAge = `${loggedProfile?.profile.gender}s' ${loggedProfile?.profile.ageGap}`
  const city = location?.city || loggedProfile?.profile.location?.city
  const state = location
    ? location.state || ''
    : loggedProfile?.profile.location?.state
  const country = location?.country || loggedProfile?.profile.location?.country
  const formattedLocation = `${
    state ? `${city} - ${state}` : `${city}`
  }, ${country}`

  return (
    <span
      className={cn(
        styles.templateDescription,
        disabled ? styles.disabled : '',
      )}
    >
      {'The '}
      <Input
        disabled={disabled}
        placeholder="Describe your record."
        name="recordDescriptionField"
        defaultValue={recordDescriptionFieldDefaultValue}
        tooltip={RECORD_DESCRIPTION_PLACEHOLDER}
      />
      {' in the '}
      <TemplatedPart value={genderAndAge} />
      {' year old Division is '}
      <Input
        disabled={disabled}
        placeholder="Record Measurement"
        name="recordSizeField"
        defaultValue={recordSizeFieldDefaultValue}
        tooltip={RECORD_SIZE_PLACEHOLDER}
      />
      {' and was completed by '}
      <TemplatedPart value={loggedProfile?.profile.firstName} /> in{' '}
      <TemplatedPart value={formattedLocation} /> {' on '}
      <TemplatedPart value={brokenDate} />.
    </span>
  )
}

export interface RecordDescriptionProps {
  type: 'FREE' | 'TEMPLATE'
  location?: any
  brokenDate?: Date
  defaultValue?: string
  recordSizeFieldDefaultValue?: string
  recordDescriptionFieldDefaultValue?: string
  errorMessage?: string
  onSwitchType(type: 'FREE' | 'TEMPLATE'): void
}

export const RecordDescription = ({
  type,
  location,
  brokenDate = new Date(),
  defaultValue,
  recordSizeFieldDefaultValue,
  recordDescriptionFieldDefaultValue,
  onSwitchType,
  errorMessage,
}: RecordDescriptionProps) => {
  const formattedDate = formatDate(brokenDate, 'MMMM DD, YYYY')

  return (
    <OutlinedContainer
      label="Record Description"
      errorMessage={errorMessage}
      className={styles.outlined}
    >
      <div className={styles.radioRow}>
        <Radio
          className={styles.radio}
          checked={type === 'TEMPLATE'}
          onChange={() => onSwitchType('TEMPLATE')}
        />
        <TemplateDescription
          location={location}
          brokenDate={formattedDate}
          recordSizeFieldDefaultValue={recordSizeFieldDefaultValue}
          recordDescriptionFieldDefaultValue={
            recordDescriptionFieldDefaultValue
          }
          disabled={type !== 'TEMPLATE'}
        />
      </div>
      <div className={styles.radioRow}>
        <Radio
          className={styles.radio}
          checked={type === 'FREE'}
          onChange={() => onSwitchType('FREE')}
        />
        <InputText
          label="Describe your Record in FREE form"
          name="description"
          disabled={type !== 'FREE'}
          defaultValue={defaultValue}
          // onChange={(value) => onChange('description', value)}
        />
      </div>
    </OutlinedContainer>
  )
}
