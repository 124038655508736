import React from 'react'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { useUpdateCmsTableRecordMutation } from 'store/api'
import { CmsDataToUpdate } from 'store/api/types/Record.types'
import SubmitButton from 'components/SubmitButton'
import { FormContentInput } from 'components/Form/FormContentInput'

import { CMS_CONTENT_VALIDATOR, CmsPageFormProps } from './AdminCKEditor.utils'

import styles from '../Inputs/ContentInput/ContentInput.module.scss'

interface CmsEditFormProps {
  contentData: CmsDataToUpdate
}

export const CmsEditForm: React.FC<CmsEditFormProps> = ({ contentData }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CmsPageFormProps>({
    resolver: joiResolver(CMS_CONTENT_VALIDATOR),
    defaultValues: { content: contentData.content },
    mode: 'all',
  })
  const [updateCmsTableRecord, { isLoading: isLoadingUpdate }] =
    useUpdateCmsTableRecordMutation()
  const onSubmitHandler = (body: CmsPageFormProps) => {
    updateCmsTableRecord({
      contentName: contentData.contentName,
      content: body.content,
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormContentInput
        errorText={errors.content?.message}
        control={control}
        name="content"
        initialValue={contentData.content}
      />

      <div className={styles.buttonsDiv}>
        <SubmitButton theme="secondary" disabled={isLoadingUpdate}>
          Apply
        </SubmitButton>
      </div>
    </form>
  )
}
