import React from 'react'

import BorderedContainer from 'components/BorderedContainer'
import Paragraph from 'components/Paragraph'
import SubTitle from 'components/SubTitle'
import Title from 'components/Title'
import { Page } from 'components/Page'

const TermsOfServiceV1: React.FC = () => {
  window.scrollTo(0, 0)

  return (
    <Page>
      <BorderedContainer>
        <Title>Terms of Service</Title>
        <SubTitle className="pb2 pt4">Introduction</SubTitle>
        <Paragraph>Last Modified: 04/02/2023</Paragraph>
        <Paragraph variant="noMargin">
          The website, www.kidsworldrecords.com and related sub-domains and
          sites (collectively referred to as the "Site"), are provided by Kids
          World Records, and are subject to specific terms and conditions. These
          terms and conditions, along with the privacy policy and cookie policy,
          govern your access to and use of the Site. By accessing and using the
          Site, you acknowledge that you have read, understood, and agree to
          abide by these terms and conditions, as well as the privacy policy and
          cookie policy. Failure to accept these terms and conditions, privacy
          policy, and cookie policy will result in your inability to access and
          contribute to the Site. The terms and conditions, privacy policy, and
          cookie policy are subject to change at any time, and any such changes
          will become effective upon their posting to the Site. If you do not
          wish to consent to the use of cookies by Kids World Records or third
          parties in connection with the Site, please refer to the Cookie Policy
          for information on how to disable or remove such cookies. If you sign
          up for a user account on the Site, or apply to attempt an official
          Kids World Records record attempt, you will be subject to additional
          policies in addition to these terms and conditions, privacy policy,
          and cookie policy. These additional policies will be explained to you
          before you register for those services.
        </Paragraph>
        <SubTitle className="pb2 pt4">Use of the Website</SubTitle>
        <Paragraph variant="noMargin">
          This website, provided by Kids World Records (“KWR” or “us” or “we” or
          “our”), and in partnership with Service Providers, is an interactive
          and informational resource containing material such as software, text,
          graphics, images, video, audio and other material (collectively
          referred to as the “Content”). The Content is protected by copyright
          under Canada, United States and international laws, and unauthorized
          use of the Content may violate copyright, trademark, and other laws.
          By accessing the Site, you acknowledge that you have no rights in or
          to the Content and may only use it as permitted under the Terms of
          Use. You may not sell, transfer, assign, license, sublicense, or
          modify the Content or use it in any way for any public or commercial
          purpose without prior written consent from us or the owner of the
          Content. The use or posting of the Content on any other website or in
          a networked computer environment for any purpose is expressly
          prohibited. Violation of any part of the Terms of Use will result in
          immediate termination of your permission to access and/or use the
          Content and Site, and you must destroy any copies you have made of the
          Content. The Site may contain trademarks or service marks owned by
          others (the “Third Party Trademarks”). Nothing on this Site grants, by
          implication, estoppel, or otherwise, any license or right to use any
          Third-Party Trademark. The Third-Party Trademarks may not be used to
          disparage any applicable third-party, any of their products or
          services, or in any manner that may damage any goodwill in the
          Third-Party Trademarks. The Site may contain links to third party
          websites (“External Sites”) provided as a convenience to you and not
          as an endorsement by us of the content on such External Sites. The
          content of such External Sites is developed and provided by others,
          and any concerns regarding such links or content located on External
          Sites should be directed to the site administrator or webmaster. The
          Site is for personal use only and may not be used in connection with
          any commercial endeavors except those that are specifically approved
          by us. Prohibited behavior includes collecting usernames and/or email
          addresses of other users for the purpose of sending unsolicited email
          or other communications, degrading the reliability, speed, or
          operation of the Site, and any use of the Site that is unlawful or in
          violation of the Terms of Use. By using the Site, you agree to use it
          for lawful purposes and in a way that does not infringe the rights of,
          restrict or inhibit anyone else's use and enjoyment of the Site.
          Prohibited behavior includes harassing or causing distress or
          inconvenience to any person, transmitting obscene or offensive
          content, or disrupting the normal flow of dialogue within the Site.
          All copyright, trademarks, design rights, patents, and other
          intellectual property rights (registered and unregistered) in and on
          the Site and all content (including all applications) located on the
          Site shall remain vested in KWR or its licensors (which includes other
          users). You may not copy, reproduce, republish, disassemble,
          decompile, reverse engineer, download, post, broadcast, transmit, make
          available to the public, or otherwise use the Site’s content in any
          way except for your own personal, non-commercial use. Any other use of
          the Site’s content requires the prior written permission of KWR.
        </Paragraph>
        <SubTitle className="pb2 pt4">
          Contributions to the Site: Terms and Conditions for User-Generated
          Content
        </SubTitle>
        <Paragraph variant="noMargin">
          The following section pertains to the submission and publication of
          user-generated Content on the Site. By submitting any user-generated
          Content, hereafter referred to as "User Content," you agree to be
          bound by these Terms of Use. Confidentiality of User Content The Site
          permits the submission of User Content at the direction of users of
          the Site and the hosting, sharing, and/ or publishing of such User
          Content. You understand that whether or not such User Content is
          published, we do not guarantee any confidentiality with respect to any
          submissions.
        </Paragraph>
        <SubTitle className="pb2 pt4">Responsibility for User Content</SubTitle>
        <Paragraph variant="noMargin">
          You shall be solely responsible for User Content you submit
          (including, but not limited to text, photographs, graphics, video, or
          audio) and the consequences of our posting or publishing such User
          Content. In connection with any User Content you submit, you affirm,
          represent, and/ or warrant that: (i) you own or have the necessary
          licenses, rights, consents, and permissions to use and authorize us to
          use all patent, trademark, trade secret, copyright or other
          proprietary rights in and to any and all User Content to enable
          inclusion and use of the User Content in the manner contemplated by
          the Site and these Terms of Use; and (ii) you have the written
          consent, release, and/ or permission of each and every identifiable
          individual person in the User Content (including, where relevant
          consent of the parent/ guardian of anyone under 18 years of age) to
          use the name or likeness of each and every such identifiable
          individual person to enable inclusion and use of the User Content in
          the manner contemplated by the Site and these Terms of Use. We may
          maintain copies of any User Content for purposes of backup, security,
          or maintenance, or as required by law.
        </Paragraph>
        <SubTitle className="pb2 pt4">License to User Content</SubTitle>
        <Paragraph variant="noMargin">
          By submitting User Content to us to be published on the Site, you
          hereby grant us a perpetual, worldwide, non-exclusive, royalty-free,
          sub-licensable, and transferable license to use, reproduce,
          distribute, prepare derivative works of, modify, display, and perform
          all or any portion of the User Content in connection with the Site and
          all of our (and our successors’) business, including without
          limitation for promoting and redistributing part or all of the Site
          (and derivative works thereof) in any media formats and through any
          media channels whatsoever. You also hereby grant each user of the Site
          a non-exclusive license to access your User Content through the Site,
          and to use, reproduce, distribute, prepare derivative works of,
          display, and perform such User Content as permitted through the
          functionality of the Site and under these Terms of Use. We may
          maintain copies of any User Content for purposes of backup, security,
          or maintenance, or as required by law. In addition to the foregoing
          licenses, you hereby grant us a perpetual, worldwide, non-exclusive,
          royalty-free, sub-licensable and transferable license to use,
          reproduce, distribute, prepare derivative works of, display, and
          perform the User Content in connection with other websites that make
          use of services provided by any Service Provider.
        </Paragraph>
        <SubTitle className="pb2 pt4">Prohibited User Content</SubTitle>
        <Paragraph variant="noMargin">
          In connection with User Content, you further agree that you will not:
          (i) submit material that is copyrighted, protected by trade secret or
          otherwise subject to third-party proprietary rights, including privacy
          and publicity rights, unless you are the owner of such rights or have
          permission from their rightful owner to post the material and to grant
          us all of the license rights granted herein; (ii) publish falsehoods
          or misrepresentations that could damage us or any third party; (iii)
          submit material that is unlawful, obscene, defamatory, libelous,
          threatening, pornographic, harassing, hateful, racially or ethnically
          offensive, or encourages conduct that would be considered a criminal
          offense, give rise to civil liability, violate any law, or is
          otherwise inappropriate; (iv) post advertisements or solicitations of
          business; (v) impersonate another person. We do not endorse any User
          Content or any opinion, recommendation, or advice expressed therein,
          and we expressly disclaim any and all liability in connection with any
          User Content. We do not permit copyright infringing activities and
          infringement of intellectual property rights on this Site, and we will
          remove all Content and User Content if properly notified that such
          Content or User Content infringes on another's intellectual property
          rights. We may remove any Content and User Content without prior
          notice. We may remove such User Content and/ or terminate your access
          for uploading such material in violation of these Terms of Use at any
          time, without prior notice and at its sole discretion. If you do not
          want to grant us the permission set out above on these terms, please
          do not submit or share your contribution to the Site. We may also
          terminate your access to the Site, if you are determined to be a
          repeat infringer. A repeat infringer is anyone who has been notified
          of infringing activity more than once and/ or has had any User Content
          removed from the Site more than twice. We also reserve the right to
          decide whether Content or User Content is appropriate and complies
          with these Terms of Use for violations other than copyright
          infringement and violations of intellectual property law, such as, but
          not limited to, pornography, obscene or defamatory material, or
          excessive length. Moreover, you shall not use any automated systems or
          software to extract data from the Site for commercial purposes.
          Additionally, you shall not interfere with, disrupt, or attempt to
          gain unauthorized access to other accounts, computer systems, or
          networks connected to the Site. We reserve the right to take
          appropriate legal action, including but not limited to referral to law
          enforcement, for any illegal or unauthorized use of the Site.
          Furthermore, you acknowledge that we have the right, but not the
          obligation, to monitor User Content and to remove any such content
          that violates these Terms of Use or is otherwise objectionable, in our
          sole discretion.
        </Paragraph>
        <SubTitle className="pb2 pt4">Your Safety</SubTitle>
        <Paragraph variant="noMargin">
          To ensure your safety and the safety of others, it is imperative that
          you exercise caution when creating content that you may subsequently
          share with KWR. Please refrain from engaging in any behavior that may
          put yourself or others at risk or may constitute a violation of any
          applicable laws. It is recommended that you never disclose any
          personal information about yourself or others, such as telephone
          numbers, postal addresses, home addresses, email addresses, or any
          other details that could be used to personally identify you, in any
          User Content. Additionally, it is important to note that by submitting
          User Content, you are agreeing that you will not infringe on any
          third-party proprietary rights, including copyrights, trade secrets,
          privacy, and publicity rights, unless you have obtained permission
          from the rightful owner of such rights or are the owner yourself. You
          should also avoid publishing any falsehoods or misrepresentations that
          could potentially harm KWR or any third party, and refrain from
          submitting any content that is unlawful, obscene, defamatory,
          libelous, threatening, pornographic, harassing, hateful, racially or
          ethnically offensive, or that promotes conduct that would be
          considered a criminal offense or that could give rise to civil
          liability. In summary, we strongly encourage you to exercise good
          judgment and discretion when creating User Content and to always
          prioritize the safety and security of yourself and others.
        </Paragraph>
        <SubTitle className="pb2 pt4">Copyright Infringement</SubTitle>
        <Paragraph variant="noMargin">
          As a copyright owner or an agent thereof, if you have reason to
          believe that any User Content or other Content infringes upon your
          copyright, you may submit a notification to our designated Copyright
          Agent, as defined below, in writing. To be effective, your
          notification must include the following information: (i) a physical or
          electronic signature of a person authorized to act on behalf of the
          owner of an exclusive right that is allegedly infringed; (ii)
          identification of the copyrighted work claimed to have been infringed,
          or, if multiple copyrighted works at a single online site are covered
          by a single notification, a representative list of such works at that
          site; (iii) identification of the material that is claimed to be
          infringing or to be the subject of infringing activity and that is to
          be removed or access to which is to be disabled, and information
          reasonably sufficient to permit the Copyright Agent to locate the
          material; (iv) information reasonably sufficient to permit the
          Copyright Agent to contact you, such as an address, telephone number,
          and, if available, an electronic mail; (v) a statement that you have a
          good faith belief that use of the material in the manner complained of
          is not authorized by the copyright owner, its agent, or the law; and
          (vi) a statement that the information in the notification is accurate,
          and under penalty of perjury, that you are authorized to act on behalf
          of the owner of an exclusive right that is allegedly infringed.
          Notifications of claimed infringement and other notices relating to
          User Content and/or violation of these Terms of Use (e.g., violations
          of criminal laws) should be sent to our Copyright Agent at the
          following address: Kids World Records, 3﻿5﻿8 Da﻿nfo﻿rth A﻿v﻿e P﻿O B﻿ox
          6﻿501﻿1, T﻿or﻿onto, O﻿N, M﻿4﻿K 1﻿M0, Canada, or via email at
          tos@kidsworldrecords.com. We take intellectual property rights
          seriously and will respond to any valid claims of copyright
          infringement in accordance with applicable laws and regulations.
        </Paragraph>
        <SubTitle className="pb2 pt4">Your Kids World Records Account</SubTitle>
        <Paragraph variant="noMargin">
          As a user of Kids World Records, you must comply with the Terms and
          Conditions, which prohibit disruptive and inappropriate behavior. In
          particular, you may not use multiple logins to disrupt the Service or
          annoy other users, and all accounts must be registered with a valid
          personal email address that you access regularly. Any accounts
          registered with someone else's email address or with temporary email
          addresses may be closed without notice, and we may require users to
          re-validate their account if we believe they have been using an
          invalid email address. Please note that KWR reserves the right to
          close accounts if any user is seen to be using proxy IPs to attempt to
          hide the use of multiple KWR accounts or disrupt any of our services.
          Furthermore, you may not submit or share any defamatory or illegal
          material in the Site's communities, including text, graphics, video,
          programs or audio. You must only contribute your own original work,
          and must not violate or infringe the rights of KWR or third parties.
          If you fail to abide by these Community Rules, you may receive an
          email informing you why your contribution has been refused or edited,
          along with a warning that continuing to break the rules may result in
          action being taken against your account or accounts. Such action may
          include any User Content posted by you being checked before allowed to
          go on the Site or a temporary or permanent suspension of your ability
          to participate in any or all of the Site's community areas. If you
          submit or share offensive or inappropriate content or engage in any
          disruptive behavior on the Site, KWR may use whatever information is
          available to it to stop any further such infringements, including
          informing relevant third parties such as your employer, school, or
          email provider. KWR reserves the right to delete any contribution or
          take action against any KWR account, at any time, for any reason.
        </Paragraph>
        <SubTitle className="pb2 pt4">Indemnity</SubTitle>
        <Paragraph variant="noMargin">
          Under these Terms of Use, you are responsible for any breach of the
          terms and conditions contained herein, as well as any claims, actions,
          or demands that arise from your use or misuse of the Content or Site.
          You agree to defend, indemnify, and hold us harmless, as well as our
          affiliates, officers, directors, employees, agents, and third-party
          service providers, against any such claims, actions, or demands,
          including reasonable legal and accounting fees. If such a claim, suit,
          or proceeding arises, we will provide notice to you and assist you, at
          your expense, in defending against it. However, we reserve the right
          to assume exclusive defense and control of any such matter that is
          subject to indemnification under this section. In that event, you
          agree to cooperate with us in any reasonable requests we make to
          assist in our defense of the matter.
        </Paragraph>
        <SubTitle className="pb2 pt4">
          Disclaimer of Warranty and Limitation of Liability
        </SubTitle>
        <Paragraph variant="noMargin">
          OUR COMPANY, ITS AFFILIATES, AND RESPECTIVE OFFICERS, DIRECTORS,
          EMPLOYEES, AGENTS, SUPPLIERS, OR LICENSORS DO NOT MAKE ANY WARRANTIES
          OR REPRESENTATIONS ABOUT THE CONTENT (INCLUDING THE USER CONTENT)
          AVAILABLE ON THIS SITE. THIS INCLUDES BUT IS NOT LIMITED TO THE
          ACCURACY, RELIABILITY, COMPLETENESS, TIMELINESS, OR RELIABILITY OF THE
          INFORMATION. WE ARE NOT LIABLE FOR ANY ERRORS, MISTAKES, OR OMISSIONS
          IN THE CONTENT OR FOR ANY DELAYS OR INTERRUPTIONS OF THE DATA OR
          INFORMATION STREAM FROM WHATEVER CAUSE. YOU AGREE THAT YOUR USE OF THE
          SITE AND THE CONTENT IS AT YOUR OWN RISK. THE USER CONTENT IS CREATED
          BY MEMBERS OF THE PUBLIC, AND ANY VIEWS EXPRESSED ARE THEIRS ALONE AND
          NOT THOSE OF OUR COMPANY. WE ARE NOT RESPONSIBLE FOR ANY CONTENT
          POSTED BY MEMBERS OF THE PUBLIC ON THE SITE OR FOR THE AVAILABILITY OR
          CONTENT OF ANY THIRD-PARTY SITES THAT ARE ACCESSIBLE THROUGH THE SITE.
          ANY LINKS TO THIRD-PARTY WEBSITES DO NOT CONSTITUTE AN ENDORSEMENT OF
          THAT SITE, AND ANY USE OF THAT SITE BY YOU IS ENTIRELY AT YOUR OWN
          RISK. WE DO NOT WARRANT THAT THE SITE WILL OPERATE ERROR-FREE OR THAT
          THE SITE, ITS SERVER, OR THE CONTENT IS FREE OF COMPUTER VIRUSES OR
          SIMILAR CONTAMINATION OR DESTRUCTIVE FEATURES. IF YOUR USE OF THE SITE
          OR THE CONTENT RESULTS IN THE NEED FOR SERVICING OR REPLACING
          EQUIPMENT OR DATA, WE SHALL NOT BE RESPONSIBLE FOR THOSE COSTS. THE
          SITE AND CONTENT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS
          WITHOUT ANY WARRANTIES OF ANY KIND, AND WE HEREBY DISCLAIM ALL
          WARRANTIES, INCLUDING BUT NOT LIMITED TO THE WARRANTY OF TITLE,
          MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES’ RIGHTS, AND
          FITNESS FOR PARTICULAR PURPOSE. IN NO EVENT SHALL WE BE LIABLE FOR ANY
          DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION, INCIDENTAL AND
          CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST
          DATA OR BUSINESS INTERRUPTION, RESULTING FROM THE USE OR INABILITY TO
          USE THE SITE AND THE CONTENT, WHETHER BASED ON WARRANTY, CONTRACT,
          TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF WE
          HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME U.S. STATES
          DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR THE LIMITATION OF
          LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
          LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH STATES, OUR
          LIABILITY SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
        </Paragraph>
        <SubTitle className="pb2 pt4">General</SubTitle>
        <Paragraph variant="noMargin">
          You acknowledge and agree that these Terms of Use, and any rights and
          licenses granted hereunder, are personal to you and may not be
          transferred or assigned by you without our prior written consent.
          However, we may assign these Terms of Use, and any rights and licenses
          granted hereunder, without restriction. These terms shall be governed
          by and interpreted in accordance with the laws of England and Wales,
          and any dispute arising out of or in connection with these terms,
          including any question regarding its existence, validity or
          termination, shall be referred to and finally resolved by the courts
          of England and Wales. We may translate these Terms of Use, Privacy
          Policy, and Cookie Policy into multiple languages for convenience
          purposes only. In the event of any conflict between the English
          version and any other language version, the English version shall
          prevail. If any of these terms are determined to be illegal, invalid
          or otherwise unenforceable by any court of competent jurisdiction,
          such provision shall be deemed to be severable and shall not affect
          the validity and enforceability of the remaining provisions of these
          Terms of Use. The failure or delay by us to enforce any right or
          provision of these Terms of Use shall not be deemed a waiver of such
          right or provision. These Terms of Use constitute the entire agreement
          between you and us with respect to the subject matter, and supersede
          all previous or contemporaneous agreements, whether written or oral,
          between the parties with respect to the subject matter. The section
          headings are provided for convenience only and shall not affect the
          interpretation of these Terms of Use. These Terms of Use shall be
          binding upon and inure to the benefit of our successors, assigns,
          licensees, and sub-licensees.
        </Paragraph>
      </BorderedContainer>
    </Page>
  )
}

export default TermsOfServiceV1
