import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CampaignForm } from 'scenes/Events/CampaignForm'
import { useGetDraftQuery, useCreateEventMutation } from '../../../store/api'
import { getErrorsCreate } from './createEvent.validator'
import { FormError, UpdateEvent } from '../CampaignForm/types'
import { uploadEventImages } from '../Events.utils'
import { DraftType } from '../../../store/api/types/Event.types'

export const CreateEvent = () => {
  const navigate = useNavigate()

  const { data: draft, isLoading } = useGetDraftQuery(DraftType.EVENT)
  const [createEvent, { isLoading: isCreating }] = useCreateEventMutation()
  const [uploading, setUploading] = useState(false)
  const [errors, setErrors] = useState<FormError | undefined>({})

  const submitCreateEvent = async (event: UpdateEvent) => {
    setUploading(true)

    const rawErrors = getErrorsCreate(event)
    setErrors(rawErrors)
    if (!rawErrors && draft?.id && !isLoading) {
      try {
        const { medias, finishedPageMedias, comingSoonPageMedias } =
          await uploadEventImages(
            draft?.id,
            event.medias,
            event.comingSoonPageMedias,
            event?.finishedPageMedias,
          )

        const updatedMedias = medias.length ? medias : event?.medias || []
        const updatedComingSoonPageMedias = comingSoonPageMedias.length
          ? comingSoonPageMedias
          : event?.comingSoonPageMedias || []
        const updatedFinishedPageMedias = finishedPageMedias.length
          ? finishedPageMedias
          : event?.finishedPageMedias || []

        await createEvent({
          ...event,
          id: draft.id,
          medias: updatedMedias,
          comingSoonPageMedias: updatedComingSoonPageMedias,
          finishedPageMedias: updatedFinishedPageMedias,
        })
        navigate('/admin/events')
      } catch (e) {
        console.log(e)
      }
    }
    setUploading(false)
  }

  return (
    <CampaignForm
      isLoading={isCreating || isLoading || uploading}
      onSubmit={submitCreateEvent}
      errors={errors}
    />
  )
}
