import React, { FC } from 'react'
import { Record } from 'store/api/types/Record.types'
import { RecordBreaker } from './RecordBreaker'
import styles from './AmbassadorProfile.module.scss'

type RecordListProps = {
  records?: Record[]
}

export const RecordBreakerList: FC<RecordListProps> = ({ records }) => {
  return (
    <div className={styles.record_list}>
      <p className={styles.minor_title}>REFERRED RECORD BREAKERS</p>
      {records?.map((record) => (
        <RecordBreaker key={record.id} record={record} />
      ))}
    </div>
  )
}
