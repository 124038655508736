import React from 'react'
import cn from 'classnames'

interface SubTitleProps {
  className?: string
}

const SubTitle: React.FC<SubTitleProps> = ({ children, className }) => (
  <h2 className={cn('f4 ma0 indigo-blue nunito-bold', className)}>
    {children}
  </h2>
)

export default SubTitle
