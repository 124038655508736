import React from 'react'

import ShadowModal from 'components/ShadowModal'
import Button from 'components/Button'

import styles from './ErrorAgeGapModal.module.scss'

export const ErrorAgeGapModal: React.FC<{
  showErrorAgeGap: boolean
  onCancel(): void
}> = ({ showErrorAgeGap, onCancel }) => {
  return (
    <ShadowModal isModalOpen={showErrorAgeGap} noSize onCancel={onCancel}>
      <h2 className={styles.title}>Oops! Age Group Mismatch!</h2>
      <span className={styles.text}>
        It looks like your child's birthday has moved it to a new age group
        since the attempt was made. You can't submit attempts that were made
        when you child was younger. Please check the attempt date.
      </span>

      <Button onClick={onCancel}>Ok</Button>
    </ShadowModal>
  )
}
