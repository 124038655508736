import React, { FC } from 'react'
import EmptySection from 'components/EmptySection'

import styles from './BreakRecord.module.scss'

export const RestrictComponent: FC = () => (
  <div className={styles.restrictComponent}>
    <EmptySection message="You must be logged in with a child profile to submit a record." />
    <EmptySection message="Click the animal icon in the top-right corner and switch to your child's profile to submit a Kids World Record attempt." />
    <EmptySection message="If you haven't yet registered your child, click the animal icon and to go to your profile and click ADD A CHILD." />
  </div>
)
