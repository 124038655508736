import React from 'react'

// import Form from 'components/Form'
// import InputTextWithValidation from 'components/InputTextWithValidation'
// import SelectWithValidation from 'components/SelectWithValidation'
import SubmitButton from 'components/SubmitButton'

// import { fundraiserCampaigns } from 'data/Charity'

// import useForm from 'hooks/useForm'

// import fundraiserSchema from 'validation/fundraiserSchema'

interface FundraiserProps {
  onSubmit: (data: any) => void
}

// const charityInstitutions = fundraiserCampaigns
//   .sort((campaignA, campaignB) => (campaignA.name <= campaignB.name ? -1 : 1))
//   .map(({ name }) => ({
//     label: name,
//     value: name,
//   }))

// const kindOfOrganizerOptions: RadioOption<KindOfOrganizer>[] = [
//   {
//     value: 'solo',
//     label: 'I am the sole organizer',
//   },
//   {
//     value: 'team',
//     label: 'We are a team of organizers',
//   },
// ]

const Fundraiser: React.FC<FundraiserProps> = ({ onSubmit }) => {
  // const [kindOfOrganizer, setKindOfOrganizer] =
  //   useState<KindOfOrganizer>('solo')

  // const {
  //   // control,
  //   // errors,
  //   handleSubmit,
  //   // setValue
  // } = useForm({
  //   submitHandler: (data: FundraiserFormData) => {
  //     if (FundraiserFormDatas.isValidFundraiser(data)) {
  //       onSubmit(data)
  //     }
  //   },
  //   validationSchema: fundraiserSchema,
  // })

  return (
    <form onSubmit={() => {}}>
      {/*<SelectWithValidation*/}
      {/*  control={control}*/}
      {/*  error={errors.charityInstitution?.message}*/}
      {/*  items={charityInstitutions}*/}
      {/*  label="Choose a Charity"*/}
      {/*  name="charityInstitution"*/}
      {/*/>*/}

      {/*<RadioButtonGroup*/}
      {/*  control={control}*/}
      {/*  initialValue={kindOfOrganizer}*/}
      {/*  name="kindOfOrganizer"*/}
      {/*  onChange={setKindOfOrganizer}*/}
      {/*  options={kindOfOrganizerOptions}*/}
      {/*  setValue={setValue}*/}
      {/*/>*/}
      {/*{kindOfOrganizer === 'team' && (*/}
      {/*  <InputTextWithValidation*/}
      {/*    control={control}*/}
      {/*    error={errors.teamName?.message}*/}
      {/*    label="Your Team Name"*/}
      {/*    name="teamName"*/}
      {/*  />*/}
      {/*)}*/}
      <div className="flex h-100 flex-column items-center justify-end justify-start-l mt4 mt5-l">
        <SubmitButton className="w-100 w-40-l">Next</SubmitButton>
      </div>
    </form>
  )
}

export default Fundraiser
