import React from 'react'

// import AddressAutoCompleteWithValidation from 'components/AddressAutoCompleteWithValidation'
import { DatePicker } from 'components/Inputs/DatePicker'
// import { TimePicker } from 'components/Inputs/TimePicker'
// import Form from 'components/Form'
// import MediaUploadWithValidation from 'components/MediaUploadWithValidation'
// import InputTextWithValidation from 'components/InputTextWithValidation'
// import SelectWithValidation from 'components/SelectWithValidation'
import SubmitButton from 'components/SubmitButton'
import SubTitle from 'components/SubTitle'

// import Categories from 'data/Category'
import ChildProfiles from 'data/ChildProfile'
// import Locations from 'data/Location'
import Profiles, { Profile } from 'data/Profile'

// import useForm from 'hooks/useForm'

// import eventDetailsSchema, {
//   eventDetailsWithChildSchema,
// } from 'validation/eventDetailsSchema'
import { NewFileUpload } from '../../components/MediaUpload'

interface DetailsProps {
  loggedProfile: Profile | null
  onSubmit: (data: any) => void
}

const Details: React.FC<DetailsProps> = ({ loggedProfile, onSubmit }) => {
  // const { handleSubmit } = useForm({
  //   submitHandler: (data: EventDetailsFormData) => {
  //     if (loggedProfile && Profiles.isChildProfile(loggedProfile))
  //       data.childProfileId = loggedProfile.id
  //
  //     if (EventFormDatas.isValidEventDetails(data)) {
  //       onSubmit(data)
  //     }
  //   },
  //   validationSchema:
  //     loggedProfile && Profiles.isParentProfile(loggedProfile)
  //       ? eventDetailsWithChildSchema
  //       : eventDetailsSchema,
  // })

  const allChildProfiles = [] as any

  const childOptions =
    loggedProfile &&
    Profiles.isParentProfile(loggedProfile) &&
    ChildProfiles.getChildProfileOptions(allChildProfiles)

  // const location =
  //   (loggedProfile &&
  //     Profiles.isChildProfile(loggedProfile) &&
  //     loggedProfile.location) ||
  //   undefined

  return (
    <form onSubmit={() => {}}>
      {childOptions && (
        <></>
        // <SelectWithValidation
        //   name="childProfileId"
        //   items={childOptions}
        //   label="Child Profile"
        //   control={control}
        //   error={errors.childProfileId?.message}
        // />
      )}
      {/*<SelectWithValidation*/}
      {/*  control={control}*/}
      {/*  error={errors.category?.message}*/}
      {/*  items={Categories.getCategoryOptions()}*/}
      {/*  label="Record Category"*/}
      {/*  name="category"*/}
      {/*/>*/}
      {/*<InputTextWithValidation*/}
      {/*  control={control}*/}
      {/*  error={errors.title?.message}*/}
      {/*  label="Give your record a name"*/}
      {/*  name="title"*/}
      {/*/>*/}
      {/*<InputTextWithValidation*/}
      {/*  control={control}*/}
      {/*  error={errors.description?.message}*/}
      {/*  label="Describe the record you will attempt to set or break"*/}
      {/*  multiline*/}
      {/*  name="description"*/}
      {/*  rows={4}*/}
      {/*/>*/}
      <SubTitle className="pt3 pb2">
        Where and when will the record be broken?
      </SubTitle>
      <DatePicker name="breakDate" />
      {/*<TimePicker label="Time of the record attempt" name="breakTime" />*/}
      {/*<AddressAutoCompleteWithValidation*/}
      {/*  name="location"*/}
      {/*  label="Location of the record attempt"*/}
      {/*  control={control}*/}
      {/*  error={errors.location && 'Enter with a valid address'}*/}
      {/*  setValue={setValue}*/}
      {/*  defaultValue={Locations.getLocationParamsFromLocation(location)}*/}
      {/*/>*/}
      <div className="flex h-100 flex-column items-center justify-end mt3 items-start-l justify-start-l">
        {/*<MediaUploadWithValidation*/}
        {/*  alt="Event Picture"*/}
        {/*  buttonLabel="Add a Picture for event"*/}
        {/*  control={control}*/}
        {/*  error={errors?.imageLinks && 'An image is required'}*/}
        {/*  label="eventPic"*/}
        {/*  maxFiles={1}*/}
        {/*  name="imageLinks"*/}
        {/*  setValue={setValue}*/}
        {/*/>*/}
        <NewFileUpload
          files={[]}
          loadingText={'lo'}
          name={'Event Picture'}
          setValue={() => {}}
        />
      </div>
      <div className="flex h-100 flex-column items-center justify-end justify-start-l mt4 mt5-l">
        <SubmitButton className="w-100 w-40-l">Next</SubmitButton>
      </div>
    </form>
  )
}

export default Details
