import Joi from 'joi'

export const CONTACT_US_VALIDATOR = Joi.object({
  name: Joi.string().required(),
  subject: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  text: Joi.string().required(),
})
