import React, { Fragment } from 'react'
import { ShieldLevel } from '../ShieldLevel'
import cn from 'classnames'
import styles from './AmbassadorBadge.module.scss'

interface BadgeProps {
  level?: number | null
  className?: string
}

export const AmbassadorBadge: React.FC<BadgeProps> = ({
  level = 1,
  className,
}) => {
  if (!level) {
    return <Fragment />
  }

  return (
    <div className={cn(styles.badge, className)}>
      <img src={`/assets/badge.svg`} alt="shield level" />
      <div className={styles.container}>
        <ShieldLevel level={level} className={styles.shield_level} />
        <div className={styles.text}>Ambassador</div>
      </div>
    </div>
  )
}
