import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import Conditional from 'components/Conditional'
import { MediaItem, MediaType } from 'store/api/types/MainPageHeaderContent'
import { Image } from './Image'

import styles from './MediaCarousel.module.scss'
import { Video } from './Video'

interface MediaCarouselProps {
  medias: MediaItem[]
  className?: string
}

export const MediaCarousel = ({ medias, className }: MediaCarouselProps) => {
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0)

  useEffect(() => {
    if (medias.length) {
      setCurrentMediaIndex(0)
    }
  }, [medias])

  const onChangeIndex = () => {
    if (medias.length > 1) {
      const nextIndex =
        currentMediaIndex + 1 > medias.length - 1 ? 0 : currentMediaIndex + 1
      setCurrentMediaIndex(nextIndex)
    }
  }

  if (!medias[currentMediaIndex]) return <></>

  const isVideo = medias[currentMediaIndex]?.type === MediaType.VIDEO
  const mediaUrl = medias[currentMediaIndex].mediaUrl
  const displayDuration = medias[currentMediaIndex].time
  const onClickUrl = medias[currentMediaIndex].onClickUrl.trim()
  const isExternal = medias[currentMediaIndex].isExternal
  return (
    <div className={cn(styles.container, className)}>
      <Conditional shouldRender={isVideo}>
        {onClickUrl ? (
          <a
            href={onClickUrl}
            target={isExternal ? '_blank' : ''}
            rel="noreferrer"
          >
            <Video src={mediaUrl || ''} onEndHandler={onChangeIndex} />
          </a>
        ) : (
          <Video src={mediaUrl || ''} onEndHandler={onChangeIndex} />
        )}
      </Conditional>
      <Conditional shouldRender={!isVideo}>
        {onClickUrl ? (
          <a
            href={onClickUrl}
            target={isExternal ? '_blank' : ''}
            rel="noreferrer"
          >
            <Image
              src={mediaUrl as unknown as string}
              secondsToShow={displayDuration}
              onEndHandler={onChangeIndex}
            />
          </a>
        ) : (
          <Image
            src={mediaUrl as unknown as string}
            secondsToShow={displayDuration}
            onEndHandler={onChangeIndex}
          />
        )}
      </Conditional>
    </div>
  )
}
