import React from 'react'

import Button from 'components/Button'
import LinkButton from 'components/LinkButton'

interface DisclaimerProps {
  onSubmit: () => void
}

const Disclaimer: React.FC<DisclaimerProps> = ({ onSubmit }) => {
  return (
    <>
      <div className="f3 nunito dark-gray pt3">Why:</div>
      <div className="f5 openSans-regular pt3 pb3">
        We want to help some charity institutions and we need you to create an
        event to break a record and share it with your friends. When you share
        with your friend we'll include an easy way for your friends or other
        people to donate to the charity.
      </div>
      <div className="f3 nunito neo-red pt3">Warning:</div>
      <div className="f5 openSans-regular pt3 pb3">
        1. In the next screens, you will create an event for charity, where you
        will be able to choose a charity institution, raise money for it and be
        able to break a record.
      </div>
      <div className="f5 openSans-regular pt3 pb5">
        2. After the event of breaking a record, you must go to "break a record"
        page and submit your record.
      </div>
      <div className="flex h-100 mv3 justify-center">
        <div
          className="flex flex-column justify-between w-100 mw5-m mw5-l"
          style={{ height: '100px' }}
        >
          <Button className="w-100" onClick={onSubmit}>
            I agree
          </Button>
          <LinkButton to="/" ghost className="w-100">
            I don't agree
          </LinkButton>
        </div>
      </div>
    </>
  )
}

export default Disclaimer
