import React, { ReactNode } from 'react'

export interface ConditionalProps {
  shouldRender: boolean
  children?: ReactNode
}

const Conditional = ({
  shouldRender,
  children,
}: ConditionalProps): JSX.Element => (
  <>{shouldRender && children ? children : null}</>
)

export default Conditional
