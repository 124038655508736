import React from 'react'

interface FormProps {
  onSubmit: (e: React.BaseSyntheticEvent) => Promise<void>
  children: any
  fullScreen?: boolean
  fullHeight?: boolean
}

const Form: React.FC<FormProps> = ({
  children,
  fullScreen = true,
  fullHeight = true,
  onSubmit,
}) => {
  return (
    <form
      className={
        fullScreen
          ? `relative flex flex-column w-100 mw-8 ${fullHeight ? 'h-100' : ''}`
          : ''
      }
      onSubmit={onSubmit}
    >
      {children}
    </form>
  )
}

export default Form
