import React, { ReactElement } from 'react'

import BorderedContainer from 'components/BorderedContainer'
import Loader from 'components/Loader'
import Title from 'components/Title'
import { Page } from 'components/Page'

import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { PIN_VALIDATOR } from 'scenes/Quizz/components/ParentPermissionModal/PinInput.utils'
import { FormPinInput } from 'components/Form/FormPinInput'

import styles from './EditPin.module.scss'
import SubmitButton from 'components/SubmitButton'

export type Pin = {
  pin: string[]
}

interface EditPinProps {
  handleSubmit?(pin: Pin): void
  isMasked?: boolean
}

function EditPin({
  handleSubmit = (pin) => console.log(pin),
  isMasked = false,
}: EditPinProps): ReactElement {
  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors },
  } = useForm<{ pin: string[] }>({
    resolver: joiResolver(PIN_VALIDATOR),
    mode: 'onSubmit',
  })

  return (
    <Page>
      <BorderedContainer>
        <Loader active={false} className={styles.loader} />
        <Title>Choose a new PIN</Title>
        <form onSubmit={handleSubmitForm(handleSubmit)}>
          <div className={styles.enter_your_pin}>
            <div className={styles.pinInput}>
              <FormPinInput
                name="pin"
                control={control}
                errorText={errors.pin?.message}
                isFocused={true}
                isMasked={isMasked}
              />
            </div>
            <div className={styles.buttonsDiv}>
              <SubmitButton className={styles.buttonChoice}>
                Confirm
              </SubmitButton>
            </div>
          </div>
        </form>
      </BorderedContainer>
    </Page>
  )
}

export default EditPin
