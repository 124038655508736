import React, { useEffect, useState, Fragment } from 'react'
import { useResolvedPath } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  InputAdornment,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import {
  useDeleteEventMutation,
  useGetEventsQuery,
  useSentCertificatesMutation,
  useUpdateEventPrivacyMutation,
  useUpdateEventVisibilityMutation,
} from 'store/api'
import { Event } from 'store/api/types/Event.types'
import { sortBy } from 'utils/arrays'

import { Input } from 'components/Inputs'
import Button from 'components/Button'

import EventRow from './EventRow'
import EnhancedTableHead, { HeadCell } from './EventTableHead'
import { DeleteEventModal } from './DeleteEventModal'

import styles from './EventList.module.scss'

export type Order = 'asc' | 'desc'

const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Campaign name',
  },
  {
    id: 'partnerName',
    numeric: false,
    disablePadding: true,
    label: 'Partner name',
  },
  {
    id: 'startDate',
    numeric: false,
    disablePadding: true,
    label: 'Campaign Start',
  },
  {
    id: 'endDate',
    numeric: false,
    disablePadding: true,
    label: 'Campaign End',
  },
  {
    id: 'submitted',
    numeric: false,
    disablePadding: true,
    label: 'Submitted',
  },
  {
    id: 'approvedAmount',
    numeric: false,
    disablePadding: true,
    label: 'Approved',
  },
  {
    id: 'rejectedAmount',
    numeric: false,
    disablePadding: true,
    label: 'Rejected',
  },
  {
    id: 'isHidden',
    numeric: false,
    disablePadding: true,
    label: 'Hidden',
  },
  {
    id: 'isPrivate',
    numeric: false,
    disablePadding: true,
    label: 'Private',
  },
  {
    id: 'edit',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'sendToApprove',
    numeric: false,
    disablePadding: true,
    label: 'Send Certificates',
  },
  {
    id: 'isParticipantDeleted',
    numeric: false,
    disablePadding: true,
    label: '',
  },
]

const prepareData = (
  data: Event[],
  search: string,
  skip: number,
  take: number,
  orderBy: string,
  order: Order,
): Event[] => {
  const sortedFiltered = sortBy(
    data.filter(
      (item) =>
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.partnerName.toLowerCase().includes(search.toLowerCase()),
    ) as any,
    orderBy,
    order,
  )

  return sortedFiltered.slice(skip, take + skip) as any
}

export const EventList = () => {
  const { pathname: path } = useResolvedPath('')
  const [take, setTake] = useState(7)
  const [pageNum, setPageNum] = useState(0)
  const { data, isLoading } = useGetEventsQuery()
  const [preparedData, setPreparedData] = useState<Event[]>(data || [])
  const [searchStr, setSearchStr] = useState('')
  const [order, setOrder] = React.useState<Order>('desc')
  const [orderBy, setOrderBy] = React.useState<string>('')
  const [sendCertificates, { isLoading: isSending }] =
    useSentCertificatesMutation()
  const [selectedEvent, setSelectedEvent] = useState('')
  const [deleteEvent, { isLoading: isDeleting }] = useDeleteEventMutation()
  const [updateEventPrivacy, { isLoading: isUpdating }] =
    useUpdateEventPrivacyMutation()
  const [updateEventVisibility] = useUpdateEventVisibilityMutation()

  useEffect(() => {
    if (!isLoading) {
      setPreparedData(
        prepareData(
          data || [],
          searchStr,
          pageNum * take,
          take,
          orderBy,
          order,
        ),
      )
    }
  }, [
    order,
    orderBy,
    take,
    data,
    isLoading,
    pageNum,
    searchStr,
    isSending,
    isUpdating,
  ])

  useEffect(() => {
    setPageNum(0)
  }, [take])

  const changePage = (e: any, page: number) => {
    setPageNum(page)
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const onDeleteEvent = async () => {
    try {
      await deleteEvent(selectedEvent)
      setSelectedEvent('')
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Fragment>
      <DeleteEventModal
        isOpenModal={Boolean(selectedEvent)}
        onClose={() => setSelectedEvent('')}
        isLoading={isDeleting}
        onDelete={onDeleteEvent}
      />
      <Box className={styles.topBar}>
        <Input
          label="Search"
          endAdornment={
            <InputAdornment position={'end'}>
              <SearchIcon />
            </InputAdornment>
          }
          type="search"
          value={searchStr}
          onChange={(e) => setSearchStr(e?.target?.value || '')}
        />
        <Link className={styles.createEditCampaignLink} to={`${path}/create`}>
          <Button>Create Campaign</Button>
        </Link>
      </Box>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {preparedData.map((event) => (
              <EventRow
                key={event.id}
                event={event}
                sendCertificates={() => sendCertificates(event.id)}
                onDeleteEvent={() => setSelectedEvent(event.id)}
                onChangePrivacy={() => updateEventPrivacy(event.id)}
                onChangeVisibility={() => updateEventVisibility(event.id)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[7, 10]}
        component="div"
        count={data?.length || 0}
        rowsPerPage={take}
        page={pageNum}
        onPageChange={changePage}
        onRowsPerPageChange={(e) =>
          setTake(e?.target?.value as unknown as number)
        }
      />
    </Fragment>
  )
}
