import React from 'react'
import cn from 'classnames'

import ChildProfiles, { ChildProfile } from 'data/ChildProfile'

import LinkButton from 'components/LinkButton'

const ChildProfileCard: React.FC<{
  profile: ChildProfile
  className?: string
}> = ({ profile, className }) => (
  <section
    className={cn(
      'flex justify-between items-center f6 ph3 pv3 ba br3 border--dark-surface bg-surface dark-gray',
      className,
    )}
  >
    <span className="nunito-bold indigo-blue f4">
      {ChildProfiles.getFullName(profile)}
    </span>
    <LinkButton ghost theme="primary" to={`/child-profile/${profile.id}`}>
      View
    </LinkButton>
  </section>
)

export default ChildProfileCard
