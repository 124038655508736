import React, { FC, Fragment, useState } from 'react'
import { useNavigate } from 'react-router'
import { EmojiEvents } from '@mui/icons-material'
import cn from 'classnames'

import { PublicRecord } from 'hooks/useRecordApi.hook'
import Button from 'components/Button'
import BreakRecordRestriction from 'components/BreakRecordRestriction'
import Conditional from 'components/Conditional'
import { useMyRole } from 'hooks/useMyRole'
import styles from './RecordCard.module.scss'

interface BreakRecordButtonProps {
  record: PublicRecord
  quizId?: string
  shouldRestrict?: boolean
  disabled?: boolean
}

export const BreakRecordButton: FC<BreakRecordButtonProps> = ({
  record,
  quizId,
  shouldRestrict,
  disabled,
}) => {
  const [isRestricted, setIsRestricted] = useState(false)
  const { isAuthenticated: isAuthorized, isAdmin, isParent } = useMyRole()
  const navigate = useNavigate()

  return (
    <Fragment>
      <Button
        disabled={disabled || (isAuthorized && isAdmin)}
        className={cn(
          styles.btn,
          quizId ? styles.beatQuizButton : styles.breakRecordButton,
        )}
        endIcon={<EmojiEvents />}
        onClick={(e) => {
          e.preventDefault()
          if (!shouldRestrict) {
            navigate(
              quizId ? `/quiz/${quizId}` : `/break-a-record/${record.id}`,
            )
          } else if (isParent && record.type === 'GROUP') {
            navigate(`/group-submission/${record.id}`)
          } else {
            setIsRestricted(true)
          }
        }}
      >
        <Conditional shouldRender={Boolean(quizId)}>Beat this quiz</Conditional>
        <Conditional shouldRender={!quizId}>Break this record</Conditional>
      </Button>
      <BreakRecordRestriction
        isOpen={isRestricted}
        onClose={() => setIsRestricted(false)}
        record={record}
      />
    </Fragment>
  )
}
