import React from 'react'

import Button from 'components/Button'

import ShadowModal from '.'

import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import {
  EXPORT_RECORDS_VALIDATOR,
  ExportRecordsFormProps,
} from './ExportRecordsModal.utils'
import dayjs from 'dayjs'

import { FormDatePicker } from 'components/Form/FormDatePicker'
import SubmitButton from 'components/SubmitButton'
import { useLazyGetFilteredRecordsQuery } from 'store/api'
import { formatDate } from 'utils/datetime'
import { showToaster } from 'store/app/actions'
import { useDispatch } from 'react-redux'

import styles from './ExportRecordsModal.module.scss'

interface LogoutModalProps {
  isOpen: boolean
  onCancel: () => void
}

export const ExportRecordsModal: React.FC<LogoutModalProps> = ({
  isOpen,
  onCancel,
}) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ExportRecordsFormProps>({
    resolver: joiResolver(EXPORT_RECORDS_VALIDATOR),
    mode: 'onSubmit',
    defaultValues: {
      startDate: dayjs().add(-1, 'months').toDate(),
      endDate: new Date(),
    },
  })
  const [trigger, { isLoading }] = useLazyGetFilteredRecordsQuery()
  const dispatch = useDispatch()
  const onSubmit = async (data: ExportRecordsFormProps) => {
    try {
      trigger(data)
        .unwrap()
        .then(
          (fileData) => {
            let blob = new Blob([fileData])
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `KWR_filtered_records_${formatDate(new Date())}.xlsx`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
          },
          (e) => {
            dispatch(
              showToaster({
                message: `There are no records that matches your filters`,
                color: 'red',
              }),
            )
          },
        )
    } catch (e) {
      console.log(e)
    }
  }
  const onCancelHandler = () => {
    reset()
    onCancel()
  }

  return (
    <ShadowModal isModalOpen={isOpen} isLarge>
      <div className={styles.submissionTitle}>Choose filters:</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormDatePicker
          name="startDate"
          label="Start of interval"
          control={control}
          errorText={errors.startDate?.message}
          disableFuture
        />
        <FormDatePicker
          name="endDate"
          label="End of interval"
          control={control}
          errorText={errors.endDate?.message}
          disableFuture
        />
        <div className={styles.buttonsDiv}>
          <Button
            ghost
            theme="secondary"
            onClick={onCancelHandler}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <SubmitButton theme="primary" disabled={isLoading}>
            Find Records
          </SubmitButton>
        </div>
      </form>
    </ShadowModal>
  )
}
