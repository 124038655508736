import React from 'react'
import cn from 'classnames'

import Link, { Page } from 'components/Link'

const headerLinks: React.FC<{
  isOnCurrentPage?: boolean
  onClick?: () => void
  url: Page
  className?: string
}> = ({ isOnCurrentPage, children, onClick, url, className }) => (
  <li className={cn('flex items-center', className)}>
    <Link
      className={cn(isOnCurrentPage && 'b', 'db ttc mh3 pa1 tc-l')}
      to={url}
      onClick={onClick}
    >
      {children}
    </Link>
  </li>
)

export default headerLinks
