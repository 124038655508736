// @ts-nocheck
import React, { useState } from 'react'
import { Dialog } from '@mui/material'
import { CheckCircleOutlined } from '@mui/icons-material'
import { PayPalButtons } from '@paypal/react-paypal-js'
import cn from 'classnames'
import Conditional from '../Conditional'
import SubmitButton from '../SubmitButton'
import styles from './PayPalModal.module.scss'

interface PayPalModalProps {
  open?: boolean
  onClose(): void
  onCompetePayment(transactionID: string): void
  isPaidCountry?: boolean
  paymentForPaidCountry: number
}

const PAYMENT_COST = 10
const Text = `We love the enthusiasm! Everyone’s first Kids World Record attempt submission is FREE and additional submissions are $${PAYMENT_COST} each. Please note that this fee helps defray the cost of the review process and does not guarantee approval.`

const PayPalModal = ({
  open = false,
  onClose,
  onCompetePayment,
  isPaidCountry = false,
  paymentForPaidCountry,
}: PayPalModalProps) => {
  const [isSuccessTransaction, setIsSuccessTransaction] = useState(false)
  const [transactionID, setTransactionID] = useState('')

  const _onClose = () => {
    setIsSuccessTransaction(false)
    if (process.env.NODE_ENV === 'development') {
      onCompetePayment('dev')
    }
    onClose()
  }

  return (
    <Dialog open={open} fullWidth onClose={_onClose}>
      <Conditional shouldRender={!isSuccessTransaction}>
        <h2 className={styles.header}>Record Payment</h2>
        <div className={styles.container}>
          <Conditional shouldRender={!isPaidCountry}>
            <div className={styles.text}>{Text}</div>
          </Conditional>
          <Conditional shouldRender={isPaidCountry}>
            <div className={cn(styles.text, styles.width31)}>
              We love the enthusiasm!
              <br />
              <br />
              We charge a small amount to help offset the cost of reviewing
              records.{' '}
              <strong>
                This payment is not a guarantee of approval and we do not offer
                returns if your record attempt is not approved.{' '}
              </strong>{' '}
              By proceeding you acknowledge you understand these terms.
            </div>
          </Conditional>
          <PayPalButtons
            className="ppl-cont"
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: `${isPaidCountry ? paymentForPaidCountry : PAYMENT_COST}`,
                    },
                  },
                ],
                application_context: {
                  shipping_preference: 'NO_SHIPPING',
                },
              })
            }}
            onApprove={(data, actions) => {
              return actions.order
                .capture()
                .then(function (details) {
                  setTransactionID(details.id)
                  setIsSuccessTransaction(true)
                })
                .catch((e) => console.log('error', e))
            }}
            onError={() => setIsSuccessTransaction(false)}
          />
        </div>
      </Conditional>
      <Conditional shouldRender={isSuccessTransaction}>
        <div className={styles.paymentComplete}>
          <h2 className={styles.header}>Payment Complete</h2>
          <CheckCircleOutlined className={styles.checkCircle} />
          <SubmitButton onClick={() => onCompetePayment(transactionID)}>
            OK
          </SubmitButton>
        </div>
      </Conditional>
    </Dialog>
  )
}

export default PayPalModal
