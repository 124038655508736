import { LocationParams } from 'data/Location'

interface AddressComponent {
  long_name: string
  types: string[]
  short_name: string
}
interface GoogleMapsAddress {
  name?: string
  utc_offset_minutes?: number
  address_components?: AddressComponent[]
}

const findLocalByType = (
  addresses: AddressComponent[],
  listOsValidTypes: string[],
) =>
  addresses.filter((address: AddressComponent) =>
    address.types.find((addressType) =>
      listOsValidTypes
        .map((type) => type === addressType)
        .reduce((previous, current) => previous || current),
    ),
  )

export const getCompleteAddress = (
  googleAddress: GoogleMapsAddress,
): (LocationParams & { timezoneInMiliseconds?: number }) | null => {
  if (googleAddress.address_components) {
    const addresses = googleAddress.address_components

    const [country] = findLocalByType(addresses, ['country'])

    const [city] = findLocalByType(addresses, [
      'locality',
      'postal_town',
      'administrative_area_level_2',
    ])

    const [state] = findLocalByType(addresses, ['administrative_area_level_1'])

    return {
      country: country?.short_name,
      city: city?.long_name,
      state: state?.short_name,
      timezoneInMiliseconds: googleAddress.utc_offset_minutes
        ? googleAddress.utc_offset_minutes * 60 * 1000
        : 0,
    }
  }

  return null
}

export const getCountry = (googleAddress: GoogleMapsAddress): string | null => {
  if (googleAddress.address_components) {
    const addresses = googleAddress.address_components
    const [country] = findLocalByType(addresses, ['country'])
    return country?.long_name
  }

  return null
}

export const getCountryShortName = (
  googleAddress: GoogleMapsAddress,
): string | null => {
  if (googleAddress.address_components) {
    const addresses = googleAddress.address_components
    const [country] = findLocalByType(addresses, ['country'])
    return country?.short_name
  }

  return null
}
