import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { formatDate } from 'utils/datetime'

import LocationParser from 'data/Location'

import BorderedContainer from 'components/BorderedContainer'
import ContextMenu, { ContextMenuOption } from 'components/ContextMenu'
import ImageDecoration, {
  ImageDecorationType,
} from 'components/ImageDecoration'
import LinkButton from 'components/LinkButton'
import Title from 'components/Title'
import { Page } from 'components/Page'
import { useDeleteChildMutation, useGetChildQuery } from 'store/api'
import { SendMagicLink } from './components/SendMagicLink'
import { WarningModal, DeleteChildModal } from './components/DeleteChildModal'
import { useMyRole } from 'hooks/useMyRole'

type ChildProfileParams = {
  id: string
}

const ChildProfile: FC = () => {
  const { id = '' } = useParams<ChildProfileParams>()
  const { data: childProfile } = useGetChildQuery(id, { skip: !id })
  const { isParent, isAuthenticated } = useMyRole()
  const [deleteChild, { isLoading }] = useDeleteChildMutation()
  const navigate = useNavigate()
  useEffect(() => {
    if (isAuthenticated && !isParent) {
      navigate('/')
    }
  }, [navigate, isAuthenticated, isParent])

  const locationText = LocationParser.getFullAddress(childProfile?.location)

  const [isDeleteChildModalOpen, setIsDeleteChildModalOpen] = useState(false)
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)

  const menuOptions: ContextMenuOption[] = [
    {
      label: 'Edit Child',
      onClick: () =>
        childProfile && navigate(`/child-profile/edit/${childProfile.id}`),
      disabled: childProfile?.ageGap === '17-130',
    },
    {
      label: 'Remove Child',
      onClick: () => setIsWarningModalOpen(true),
    },
  ]

  const handleRemoveChild = async () => {
    try {
      childProfile && (await deleteChild(childProfile.id).unwrap())
      navigate(-1)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Page>
      {isParent && childProfile && (
        <>
          <BorderedContainer>
            <div className="w-100 flex items-center justify-start">
              <LinkButton
                className="pr3"
                ghost
                size={'small'}
                theme={'tertiary'}
                to="/profile"
              >
                Back to My Profile
              </LinkButton>
            </div>
            <div className="flex justify-between pb4">
              <Title className="pa0">
                {`${childProfile.firstName} ${childProfile.lastName}`}
              </Title>
              <ContextMenu options={menuOptions} />
            </div>
            <div className="child-profile__content flex flex-column">
              <div className="pb4 flex flex-row justify-between">
                <span className="dark-gray">Location</span>
                <span className="indigo-blue">{locationText}</span>
              </div>
              <div className="pb4 flex flex-row justify-between">
                <span className="dark-gray">Gender</span>
                <span className="indigo-blue">{childProfile.gender}</span>
              </div>
              <div className="pb4 flex flex-row justify-between">
                <span className="dark-gray">Date of Birth</span>
                <span className="indigo-blue">
                  {formatDate(
                    dayjs(
                      (childProfile?.dateOfBirth as unknown as string).replace(
                        'Z',
                        '',
                      ),
                    ).toDate(),
                    'll',
                  )}
                </span>
              </div>
              <div className="pb4 flex flex-row justify-between">
                <span className="dark-gray">Age Bracket</span>
                <span className="indigo-blue">
                  {childProfile.ageGap === '17-130'
                    ? dayjs(new Date()).diff(childProfile.dateOfBirth, 'year')
                    : childProfile.ageGap}
                </span>
              </div>
            </div>
            {childProfile.email && <SendMagicLink email={childProfile.email} />}
          </BorderedContainer>
          <ImageDecoration
            type={ImageDecorationType.BOY_JUMPING_ROPE}
          ></ImageDecoration>
          <WarningModal
            isOpen={isWarningModalOpen}
            onConfirm={() => {
              setIsWarningModalOpen(false)
              setIsDeleteChildModalOpen(true)
            }}
            onClose={() => setIsWarningModalOpen(false)}
          />
          <DeleteChildModal
            isOpen={isDeleteChildModalOpen}
            onDelete={handleRemoveChild}
            isLoading={isLoading}
            onClose={() => setIsDeleteChildModalOpen(false)}
          />
        </>
      )}
    </Page>
  )
}

export default ChildProfile
