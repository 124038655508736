import React from 'react'

import ShadowModal from 'components/ShadowModal'
import Button from 'components/Button'

import styles from './ErrorAgeModal.module.scss'

export const ErrorAgeModal: React.FC<{
  showErrorAgeModal: boolean
  onCancel(): void
}> = ({ showErrorAgeModal, onCancel }) => {
  return (
    <ShadowModal isModalOpen={showErrorAgeModal} noSize onCancel={onCancel}>
      <h2 className={styles.title}>Child Age Restriction!</h2>
      <span className={styles.text}>
        According to the time zone of the location you entered and your child's
        birthdate, your child has not yet turned 4 years old. This may be due to
        a time difference between your current location and the location where
        your child was born.
        <br />
        <br />
        Please wait until the date aligns with your child's birth location to
        reflect their correct age.
      </span>

      <Button onClick={onCancel}>Ok</Button>
    </ShadowModal>
  )
}
