import React from 'react'
import { Alert } from '@mui/material'
import dayjs from 'dayjs'

import useToggle from 'hooks/useToggle'

import { Option } from 'components/Select'
import SubmitButton from 'components/SubmitButton'
import SubTitle from 'components/SubTitle'
import Switch from 'components/Switch'
import Title from 'components/Title'
import Tooltip from 'components/Tooltip'

import { FormInput } from '../Form/FormInput'
import { FormSelect } from '../Form/FormSelect'
import { FormDatePicker } from '../Form/FormDatePicker'

import './child-profile-form.css'
import { useLegacyChildProfilesQuery } from 'store/api'
import { FormLocationAutocomplete } from '../Form/FormLocationAutocomplete'

const items: Option<any>[] = [
  {
    label: 'Girl',
    value: 'FEMALE',
  },
  {
    label: 'Boy',
    value: 'MALE',
  },
  {
    label: 'Gender X',
    value: 'GENDER_X',
  },
]

const GenderXTooltip: React.FC = () => (
  <Tooltip>
    <div className="child_form__gender-tooltip">
      <SubTitle className="f5 pb3">“Gender X” - A Note for Parents</SubTitle>
      <p className="dark-gray f7 mv1">
        At Kids World Records, we understand that not every kid fits into a box.
        The exploration of gender identity is a deeply personal experience, and
        our global conversation about it has shifted in recent years. We want to
        respect the experience of each child who interacts with our company. In
        an effort to make Kids World Records accessible to everyone, we have
        chosen to offer the third gender identity “Gender X,” instead of
        “non-binary”.
      </p>
      <p className="dark-gray f7 mv1">
        “Gender X” is a term implemented by multiple US State governments, used
        to encompass a gender that is not exclusively male or female, including,
        but not limited to, intersex, agender, amalgagender, androgynous,
        bigender, demigender, female-to-male, genderfluid, genderqueer,
        male-to-female, neutrois, nonbinary, pangender, third sex, transgender,
        transsexual, Two Spirit, and unspecified. Our mission is to foster a
        space where kids under the age of 16 can explore their interests with
        unbridled enthusiasm and creativity. We feel that if we force kids to
        make a decision about their gender identity before they are ready, or if
        we don’t offer a term that encompasses their true identity, we will not
        be living up to our own values and mission.
      </p>
      <p className="dark-gray f7 mv1">
        This cultural conversation is still developing, and we want to let you
        know we understand the tension that can arise between having to make a
        decision while still respecting one another. We are excited to work with
        you to address any concerns surrounding the gender identity and
        expression of your child that may arise while breaking records. We want
        to learn and grow together in order to more fully support our future
        generation of record-breaking humans.
      </p>
    </div>
  </Tooltip>
)

interface ChildFormProps {
  handleSubmit: (e: React.BaseSyntheticEvent<object, any, any>) => Promise<void>
  control: any
  errors: any
  setValue: any
  shouldGetEmail?: boolean
  title: string
}

const ChildForm: React.FC<ChildFormProps> = ({
  handleSubmit,
  control,
  errors,
  shouldGetEmail = true,
  title,
}) => {
  const [shown, setShown] = useToggle(!shouldGetEmail)

  const { data: allChildProfiles = [] } = useLegacyChildProfilesQuery()

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Title>{title}</Title>
        {allChildProfiles.length > 0 && (
          <Alert severity="warning" sx={{ margin: '0 0 1rem 0' }}>
            Do not create more than one profile per child. If you do the
            submission will be rejected, and your account may be banned.
          </Alert>
        )}
        <div className="flex flex-row">
          <div className="mr2 flex-auto">
            <FormInput
              name="firstName"
              label="First Name"
              control={control}
              errorText={errors?.firstName?.message as string}
            />
          </div>
          <div className="ml2 flex-auto">
            <FormInput
              name="lastName"
              label="Last Name"
              control={control}
              errorText={errors?.lastName?.message as string}
            />
          </div>
        </div>
        <div className="flex flex-row">
          <FormSelect
            name="gender"
            label="Gender"
            items={items}
            control={control}
            errorText={errors?.gender?.message as string}
          />
          <GenderXTooltip />
        </div>
        <FormDatePicker
          name="dateOfBirth"
          label="Date of Birth"
          control={control}
          maxDate={dayjs().add(-4, 'years').toDate()}
        />
        <div className="flex flex-column">
          {shouldGetEmail && (
            <div className="flex flex-row items-center">
              <Switch onClick={setShown} theme={'tertiary'} />
              <div className="pl3">Send easy login to my child's profile</div>
            </div>
          )}
          {shown && (
            <FormInput
              name="email"
              label="Email"
              control={control}
              errorText={errors?.email?.message as string}
            />
          )}
        </div>
        <FormLocationAutocomplete
          control={control}
          name="location"
          label="City"
        />
        <div className="flex h-100 mv3 justify-end-l items-end items-start-l">
          <SubmitButton className="w-100 w4-l">Confirm</SubmitButton>
        </div>
      </form>
    </>
  )
}

export default ChildForm
