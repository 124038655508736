import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { QuantitySelect } from './QuantitySelect'

type FormQuantitySelectProps = {
  name: string
  control: any
}

export const FormQuantitySelect: FC<FormQuantitySelectProps> = ({
  name,
  control,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <QuantitySelect value={field.value} onChange={field.onChange} />
      )}
    />
  )
}
