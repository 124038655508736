import React from 'react'
import { useLocation } from 'react-router-dom'

import { Page } from 'components/Page'
import Title from 'components/Title'
import Conditional from 'components/Conditional'
import Loader from 'components/Loader'

import { useGetEventParticipantInfoQuery } from 'store/api'

import { EventCertificateItem } from './EventCertificateItem'
import styles from './EventCertificates.module.scss'

export const EventCertificates = () => {
  const url = new URLSearchParams(useLocation().search)
  const token = url.get('token') || ''
  const { data: participants = [], isLoading } =
    useGetEventParticipantInfoQuery(token || '')

  return (
    <Page className={styles.page}>
      <Conditional shouldRender={isLoading}>
        <Loader active className={styles.loader} />
      </Conditional>
      <Conditional shouldRender={!isLoading}>
        <Title>Certificates</Title>
        {participants.map((participant) => (
          <EventCertificateItem
            key={participant.id}
            participant={participant}
            token={token}
          />
        ))}
      </Conditional>
    </Page>
  )
}
