import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Toast } from 'typings/Toast'

interface AppState {
  menuOpened: boolean
  scrollEnabled: boolean
  contentOverlay: boolean
  toast: Toast | null
}

const initialState: AppState = {
  menuOpened: false,
  scrollEnabled: true,
  contentOverlay: false,
  toast: null,
}

const appSlice = createSlice({
  name: 'signup',
  initialState: initialState as AppState,
  reducers: {
    toggleMenu(state: AppState) {
      const menuOpened = !state.menuOpened
      state.menuOpened = menuOpened
      state.scrollEnabled = !menuOpened
      state.contentOverlay = menuOpened
    },
    closeMenu(state: AppState) {
      state.menuOpened = false
      state.scrollEnabled = true
      state.contentOverlay = false
    },
    setScroll(state: AppState, action: PayloadAction<boolean>) {
      state.scrollEnabled = action.payload
    },
    showToaster(
      state: AppState,
      action: PayloadAction<{ color: 'green' | 'red'; message: string }>,
    ) {
      state.toast = {
        ...action.payload,
        active: true,
      }
    },
    hideToaster(
      state: AppState,
      action: PayloadAction<{ color: 'green' | 'red'; message: string }>,
    ) {
      if (state.toast?.message !== action.payload.message) return state

      state.toast = {
        ...action.payload,
        active: false,
      }
    },
  },
})

export const { toggleMenu, closeMenu, setScroll, showToaster, hideToaster } =
  appSlice.actions
export default appSlice.reducer
