import React, { useCallback, useState } from 'react'

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { ArrowDropDown } from '@mui/icons-material'

import styles from './CategoryDropDown.module.scss'

const CATEGORIES = {
  TOP_PICKS: 'Top Picks',
  NEWEST: 'Newest',
  MOST_POPULAR: 'Most Popular',
  MOST: 'Most',
  SMALLEST: 'Smallest',
  LARGEST: 'Largest',
  LONGEST: 'Longest',
  HIGHEST: 'Highest',
  FASTEST: 'Fastest',
  ENDURANCE: 'Endurance',
  COLLECTIONS: 'Collections',
  BROKEN: 'Broken',
  OTHER: 'Other',
}

export type DropDownValue =
  | 'TOP_PICKS'
  | 'NEWEST'
  | 'MOST_POPULAR'
  | 'MOST'
  | 'SMALLEST'
  | 'LARGEST'
  | 'LONGEST'
  | 'HIGHEST'
  | 'FASTEST'
  | 'ENDURANCE'
  | 'COLLECTIONS'
  | 'BROKEN'
  | 'OTHER'

const CATEGORY_ARRAY = Object.keys(CATEGORIES) as DropDownValue[]

export interface CategoryDropDownProps {
  value: DropDownValue
  onChange(value: DropDownValue): void
  disabled?: boolean
}

const ITEM_STYLE = {
  fontFamily: "'Nunito', serif",
  color: 'var(--indigo-blue)',
}

export const CategoryDropDown = ({
  value,
  disabled,
  onChange,
}: CategoryDropDownProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = useCallback(
    (e: any) => onChange(e.target.value),
    [onChange],
  )

  return (
    <div className={styles.main}>
      <div className={styles.button} onClick={() => setIsOpen(true)}>
        <h3 className={styles.label}>{CATEGORIES[value]}</h3>
        <ArrowDropDown />
      </div>
      <Select
        className={styles.select}
        fullWidth
        disabled={disabled}
        open={isOpen}
        value={value}
        onChange={handleChange}
        onClose={() => setIsOpen(false)}
      >
        {CATEGORY_ARRAY.map((category) => (
          <MenuItem
            disabled={disabled}
            key={category}
            className={styles.item}
            style={ITEM_STYLE}
            value={category}
          >
            {CATEGORIES[category]}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}
