import React, { useState } from 'react'

import InputText from 'components/InputText'
import Button from 'components/Button'

import { Person } from 'typings/RecordSubmissionFormData'

import './people.css'

export type PeopleProps = {
  error: string
  errors: Array<{ name?: string; email?: string }>
  name: string
  setValue: any
}

const People = ({ error, setValue, name, errors }: PeopleProps) => {
  const [people, setPeople] = useState<Person[]>([
    {
      id: '0',
      name: '',
      email: '',
    },
  ])

  const handleAddPerson = () => {
    const newPerson = {
      id: (people.length + 1).toString(),
      name: '',
      email: '',
    }
    setPeople([...people, newPerson])
  }

  const onPersonChange = (
    personId: string,
    fieldName: keyof Person,
    newVal: string,
  ) => {
    const oldPerson = people.find((person: Person) => person.id === personId)

    if (oldPerson) {
      oldPerson[fieldName] = newVal
    }

    const updatedPeople = people.filter(
      (person: Person) => person.id !== personId,
    )

    oldPerson && setPeople([...updatedPeople, oldPerson])
    setValue(name, people)
  }

  return (
    <>
      {people.map((person: Person, index) => {
        return (
          <div
            className="flex flex-column flex-row-l justify-between"
            key={person.id}
          >
            <div className="people__name">
              <InputText
                value={person.name}
                onChange={(e) => onPersonChange(person.id, 'name', e || '')}
                error={!!errors?.[index]?.name}
                helperText={errors?.[index]?.name || ''}
                label="Name"
              />
            </div>
            <div className="people__email">
              <InputText
                value={person.email}
                onChange={(e) => onPersonChange(person.id, 'email', e || '')}
                error={!!errors?.[index]?.email}
                helperText={errors?.[index]?.email || ''}
                label="Email"
              />
            </div>
          </div>
        )
      })}
      <Button ghost className="mw5-l" onClick={handleAddPerson}>
        Add another person
      </Button>
      {error && (
        <p
          style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}
          className="f7 neo-red"
        >
          {error}
        </p>
      )}
    </>
  )
}

export default People
