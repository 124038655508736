import React from 'react'
import Title from 'components/Title'
import BorderedContainer from 'components/BorderedContainer'
import LinkButton from 'components/LinkButton'
import { Page } from 'components/Page'

export const SubmittedOnReviewParent = () => {
  return (
    <Page>
      <BorderedContainer style={{ padding: '2rem' }}>
        <Title>Thank you for submitting proof of age for verification</Title>
        <b>What Happens Next</b>
        <p>
          If we are able to confirm your ID submission, your account will be
          created. You must then login to your parent account and add your child
          or children to submit Kids World Record attempts.
        </p>
        <p>Verification typically takes 1-2 hours.</p>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '3rem',
          }}
        >
          <div className="w5">
            <LinkButton to={'/'} ghost outline>
              Back to Browsing
            </LinkButton>
          </div>
        </div>
      </BorderedContainer>
    </Page>
  )
}
