export enum MediaType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

export type MediaItem = {
  mediaUrl?: string
  time: number
  type: MediaType
  onClickUrl: string
  isExternal: boolean
}

export type MainPageHeaderContent<T> = {
  title: string
  description: string
  medias: T[]
}
