import React, { Fragment, useState, useEffect } from 'react'
import Menu from '@mui/material/Menu'
import { ArrowDropDown } from '@mui/icons-material'

import styles from './RecordForMeFilter.module.scss'
import { LabeledCheckBox } from 'components/CheckBox/LabeledCheckBox'
import Button from 'components/Button'
import { GenderV2 } from 'store/api/types/Gender.types'

const AGE_GAPS = ['4-6', '7-9', '10-12', '13-16']
const GenderLabel = {
  [GenderV2.MALE]: 'Boy',
  [GenderV2.FEMALE]: 'Girl',
  [GenderV2.GENDER_X]: 'Gender X',
}

const GENDERS = Object.values(GenderV2)

export interface RecordForMeFilterValue {
  genders: any[]
  ageGaps: any[]
}

interface RecordForMeFilterProps {
  value: RecordForMeFilterValue
  onApply(value: RecordForMeFilterValue): void
  disabled?: boolean
}

export const RecordForMeFilter = ({
  value,
  disabled,
  onApply,
}: RecordForMeFilterProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [filters, setFilters] = useState<RecordForMeFilterValue>({
    genders: [],
    ageGaps: [],
  })

  useEffect(() => {
    setFilters(value)
  }, [value])

  const selectOption = (groupName: 'genders' | 'ageGaps', newValue: string) => {
    const indexOfFoundValue = filters[groupName].indexOf(newValue)

    const groupValue = [...filters[groupName]]

    if (indexOfFoundValue !== -1) {
      groupValue.splice(indexOfFoundValue, 1)
    } else {
      groupValue.push(newValue)
    }

    setFilters({ ...filters, [groupName]: groupValue })
  }

  return (
    <Fragment>
      <div
        className={styles.main}
        onClick={(event) => {
          setIsOpen(true)
          setAnchorEl(event.currentTarget)
        }}
      >
        <h3>Records for me</h3>
        <ArrowDropDown />
      </div>
      <Menu
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={styles.body}>
          <div className={styles.columns}>
            <div>
              {AGE_GAPS.map((gap) => (
                <LabeledCheckBox
                  key={gap}
                  label={gap}
                  disabled={disabled}
                  checked={filters.ageGaps.indexOf(gap) !== -1}
                  onClick={() => selectOption('ageGaps', gap)}
                />
              ))}
            </div>
            <div>
              {GENDERS.map((gender) => (
                <LabeledCheckBox
                  key={gender}
                  disabled={disabled}
                  label={GenderLabel[gender]}
                  checked={filters.genders.indexOf(gender) !== -1}
                  onClick={() => selectOption('genders', gender)}
                />
              ))}
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              theme="secondary"
              fullWidth
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button>
            <Button
              fullWidth
              onClick={() => {
                onApply(filters)
                setIsOpen(false)
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </Menu>
    </Fragment>
  )
}
