import React, { FormEvent, useState, Fragment, FC } from 'react'
import { Interweave } from 'interweave'
import cn from 'classnames'
import Checkbox from '@mui/material/Checkbox'

import { Sponsor } from 'store/api/types/Event.types'
import { SponsorImage } from 'scenes/Events/Sponsors/SponsorImage'
import Conditional from '../../../components/Conditional'
import { DataProps } from './ParticipateInEvent'
import { DatePicker, FileInput, Input } from '../../../components/Inputs'
import Button from '../../../components/Button'
import Title from '../../../components/Title'
import { Timer } from './Timer'
import { ImageOrVideo } from '../../../components/ImageOrVideo'
import { QRCodeModal } from './QRCodeModal'
import { IconButton } from '@mui/material'
import { QrCode2 } from '@mui/icons-material'

import styles from './ParticipantsInEvent.module.scss'

interface UserLinksFormatterProps {
  links: { name: string; url: string }[]
}

export const UserLinksFormatter: React.FC<UserLinksFormatterProps> = ({
  links,
}) => {
  return links.length ? (
    <>
      {links.map(({ name, url }, index: number) => {
        return (
          <span key={name + index}>
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className={styles.blueLink}
            >
              {name}
            </a>
            {links.length > index + 1 ? ' and ' : ' and the '}
          </span>
        )
      })}
    </>
  ) : null
}

type SponsorsSectionProps = {
  sponsors: Sponsor[]
}

export const SponsorsSection: FC<SponsorsSectionProps> = ({ sponsors }) => {
  if (!sponsors.length) {
    return <Fragment />
  }

  return (
    <div className={styles.sponsorTitle}>
      This Kids World Record event is sponsored by:
      <div className={styles.sponsors}>
        {sponsors.map((sponsor) => (
          <SponsorImage
            key={sponsor.id}
            onClick={() => window.open(sponsor.siteUrl)}
            imageUrl={sponsor.imageUrl}
          />
        ))}
      </div>
    </div>
  )
}

export const OnGoingEvent: FC<DataProps> = ({
  event,
  onSubmit,
  errors,
  disabled,
  onZero,
}) => {
  const [ageChecked, setAgeChecked] = useState(false)
  const [termsChecked, setTermsChecked] = useState(false)
  const [isQRVisible, setIsQRVisible] = useState(false)
  const { endDate, image, title, description } = event
  const [kidsBirthDate, setKidsBirthDate] = useState<Date>(new Date())

  const minDate = new Date()
  minDate.setFullYear(minDate.getFullYear() - 16)

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const target = e.currentTarget

    onSubmit({
      parentFirstName: target.parentFirstName.value,
      parentLastName: target.parentLastName.value,
      email: target.parentEmail.value,
      instagramId: target.instagram.value || '',
      kidsFirstName: target.kidsFirstName.value,
      kidsLastName: target.kidsLastName.value,
      kidsDateOfBirth: kidsBirthDate,
      medias: target.mediaFile?.files || [],
    })
  }
  const currentUrl = window.location.href

  return (
    <div className={styles.container}>
      <QRCodeModal
        showQRCode={isQRVisible}
        onCancel={() => setIsQRVisible(false)}
        currentUrl={currentUrl}
      />
      <div className={styles.wrapper}>
        <ImageOrVideo
          className={styles.imageOrVideo}
          alt="event image"
          src={image[0] || ''}
          autoPlay
        />
        <div className={styles.buttons}>
          <IconButton
            className={styles.button}
            onClick={() => setIsQRVisible(true)}
          >
            <QrCode2 className={styles.customIcon} />
          </IconButton>
        </div>
      </div>
      <div className={cn(styles.columnBox, styles.padding)}>
        <Title className={styles.title}>{title}</Title>
        <span className={styles.timerDescription}>
          Registration for free personalized certificates of participation for
          this Kids World Record attempt ends in...
        </span>
        <div className={styles.timerBox}>
          <Timer key={'OnGoingEvent'} deadline={endDate} onZero={onZero} />
        </div>
        {event.target ? `Kids World Record Target: ${event.target}` : null}
      </div>
      <div className={styles.columnBox}>
        <Interweave content={description} />
        <SponsorsSection sponsors={event.sponsors} />
      </div>
      <span className={styles.submissionText}>Record Submission</span>
      <form onSubmit={onFormSubmit}>
        <div className={styles.columnBox}>
          <div className={styles.rowBox}>
            <Input
              disabled={disabled}
              name="parentFirstName"
              label="Parent First Name *"
              errorText={errors?.parentFirstName}
            />
            <Input
              disabled={disabled}
              name="parentLastName"
              label="Parent Last Name *"
              errorText={errors?.parentLastName}
            />
          </div>
          <Input
            disabled={disabled}
            name="parentEmail"
            label="Parent E-mail *"
            errorText={errors?.email}
          />
          <Input
            disabled={disabled}
            name="instagram"
            label="Instagram (Optional)"
          />
          <div className={styles.rowBox}>
            <Input
              disabled={disabled}
              name="kidsFirstName"
              label="Kids First Name *"
              errorText={errors?.kidsFirstName}
            />
            <Input
              disabled={disabled}
              name="kidsLastName"
              label="Kids Last Name *"
              errorText={errors?.kidsLastName}
            />
            <div className={styles.picker}>
              <DatePicker
                disabled={disabled}
                label={'Kids Birth Date *'}
                minDate={minDate}
                maxDate={new Date()}
                defaultValue={kidsBirthDate}
                onChange={(date) => date && setKidsBirthDate(date)}
                errorText={errors?.kidsDateOfBirth}
              />
            </div>
          </div>
        </div>
        <Conditional shouldRender={!!event.isMediaUploadRequired}>
          <FileInput
            disabled={disabled}
            name="mediaFile"
            label="Upload Photo/Video"
            errorText={errors?.medias}
          />
        </Conditional>
        <div className={styles.checkbox}>
          <div className={styles.checkmark}>
            <Checkbox
              onChange={() => setAgeChecked(!ageChecked)}
              checked={ageChecked}
            />
          </div>
          <p className={styles.checkboxText}>
            I am the parent or legal guardian of the child depicted in the
            submitted photo.
          </p>
        </div>
        <div className={styles.checkbox}>
          <div className={styles.checkmark}>
            <Checkbox
              onChange={() => setTermsChecked(!termsChecked)}
              checked={termsChecked}
            />
          </div>
          <p className={styles.checkboxText}>
            {'I have read and agree to the '}
            {event?.links && <UserLinksFormatter links={event.links} />}
            {'Kids World Records '}
            <a
              href={'/terms-of-service'}
              target="_blank"
              rel="noreferrer"
              className={styles.redLink}
            >
              Terms Of Service
            </a>
            {' and '}
            <a
              href={`/privacy-policy`}
              target="_blank"
              rel="noreferrer"
              className={styles.redLink}
            >
              Privacy Policy
            </a>
          </p>
        </div>
        <div className={styles.buttonBox}>
          <Button
            type="submit"
            disabled={!termsChecked || !ageChecked || disabled}
          >
            Submit Record
          </Button>
        </div>
      </form>
    </div>
  )
}
