import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { MagicLink } from 'typings/MagicLink'

import SubmitButton from 'components/SubmitButton'
import { FormInput } from 'components/Form/FormInput'

import { useSendMagicLinkMutation } from 'store/api'
import { MAGIC_LINK_VALIDATOR } from './SendMagicLink.utils'

import styles from './SendMagicLink.module.scss'

export const SendMagicLink: React.FC<{ email: string }> = ({ email }) => {
  const [sendMagicLink] = useSendMagicLinkMutation()

  const { control, handleSubmit, setValue } = useForm<MagicLink>({
    resolver: joiResolver(MAGIC_LINK_VALIDATOR),
    mode: 'all',
  })

  useEffect(() => {
    setValue('email', email)
  }, [email, setValue])

  const onSubmit = (data: MagicLink) => {
    sendMagicLink(data.email)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.title}>
        Send easy login link to my child's email
      </div>
      <FormInput
        name="email"
        label="Email"
        control={control}
        disabled
        endAdornment={
          <SubmitButton theme="primary" ghost>
            Send
          </SubmitButton>
        }
      />
    </form>
  )
}
