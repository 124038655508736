import React from 'react'

import ShadowModal from 'components/ShadowModal'
import Button from 'components/Button'
import QRCode from 'qrcode.react'

import styles from './QRCodeModal.module.scss'

export const QRCodeModal: React.FC<{
  showQRCode: boolean
  onCancel(): void
  currentUrl: string
}> = ({ showQRCode, onCancel, currentUrl }) => {
  return (
    <ShadowModal isModalOpen={showQRCode} noSize onCancel={onCancel}>
      <h2 className={styles.title}>Scan this QR-Code</h2>
      <div className={styles.qr_code_apple_pass}>
        <QRCode
          value={currentUrl}
          size={256}
          bgColor="#FFFFFF"
          fgColor="#000000"
        />
      </div>
      <Button onClick={onCancel}>Close</Button>
    </ShadowModal>
  )
}
