import axios from 'axios'

import { getAuthUserToken } from '../../services/authentication'

type UploadedMediasNewUrl = {
  medias: string[]
  finishedPageMedias: string[]
  comingSoonPageMedias: string[]
}

const fileUpload = async (
  route: string,
  mediaFile: FileList,
): Promise<string[] | undefined> => {
  if (mediaFile.item(0)) {
    const file = mediaFile.item(0)
    const token = getAuthUserToken()

    if (file) {
      const urlsToUpload = (
        await axios.get(`/api${route}?format=${file.type.split('/')[1]}`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
      ).data.uploadUrl
      await axios.put(urlsToUpload, file, {
        headers: { 'Content-Type': file.type },
      })
      return [urlsToUpload.split('?')[0] || '']
    }
  }
}

export const uploadEventImages = async (
  eventId: string,
  onGoingMedia: FileList,
  comingSoonPageMedias: FileList,
  finishedPageMedias: FileList,
): Promise<UploadedMediasNewUrl> => {
  const [medias, comingSoonMedias, finishedMedias] = await Promise.all([
    fileUpload(
      `/admin/events/${eventId}/generateMediaUploadLink`,
      onGoingMedia,
    ),
    fileUpload(
      `/admin/events/${eventId}/generateMediaUploadLink`,
      comingSoonPageMedias,
    ),
    fileUpload(
      `/admin/events/${eventId}/generateMediaUploadLink`,
      finishedPageMedias,
    ),
  ])

  return {
    medias: medias || [],
    comingSoonPageMedias: comingSoonMedias || [],
    finishedPageMedias: finishedMedias || [],
  }
}

export const uploadApplyEventImages = async (
  id: string,
  eventId: string,
  mediaFile: FileList,
): Promise<{ medias: string[] }> => {
  const response: { medias: string[] } = {
    medias: [],
  }
  response.medias =
    (await fileUpload(
      `/events/${eventId}/participants/${id}/generateMediaUploadLink`,
      mediaFile,
    )) || []

  return response
}
