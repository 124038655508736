export const getUrlParams = <T extends {}>(search: string): Partial<T> => {
  let hashes = search.slice(search.indexOf('?') + 1).split('&')
  return hashes.reduce((params, hash) => {
    let [key, val] = hash.split('=')
    return Object.assign(params, { [key]: decodeURIComponent(val) })
  }, {} as T)
}

export const imageFileFormats = [
  '.jpg',
  '.jpeg',
  '.tiff',
  '.png',
  '.gif',
  '.webp',
]

export const isImageFile = (fileUrl: string): boolean => {
  const fileUrlWithoutQueryParams = fileUrl.split('?')[0] || ''
  return new RegExp(imageFileFormats.join('|')).test(
    fileUrlWithoutQueryParams.toLowerCase(),
  )
}

export const sortLinksImagesFirst = (linksToSort: string[]) => {
  const links = [...linksToSort]
  links.sort((link: string) => (isImageFile(link.toLowerCase()) ? -1 : 1))
  return links
}
