import React, { FC, ReactNode } from 'react'

import { Controller } from 'react-hook-form'

import { Input } from 'components/Inputs'

export interface FormInputProps {
  name: string
  label?: string
  multiline?: boolean
  control: any
  errorText?: string
  className?: string
  type?: string
  disabled?: boolean
  endAdornment?: ReactNode
  minRows?: number
  onFocus?: () => void
  placeholder?: string
}

export const FormInput: FC<FormInputProps> = ({
  name,
  label,
  multiline,
  errorText,
  className,
  control,
  type,
  disabled,
  endAdornment,
  minRows,
  onFocus,
  placeholder,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Input
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          label={label}
          className={className}
          multiline={multiline}
          errorText={errorText}
          type={type}
          disabled={disabled}
          endAdornment={endAdornment}
          minRows={minRows}
          onFocus={onFocus}
          placeholder={placeholder}
          {...props}
        />
      )}
    />
  )
}
