import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import IconButton from 'components/IconButton'

import './image.css'

interface ImageProps {
  alt: string
  isBlurred?: boolean
  src?: string
  file?: File
  onRemove: () => void
  onEdit?: () => void
}

const Image: React.FC<ImageProps> = ({
  alt,
  isBlurred,
  onRemove,
  onEdit,
  src,
  file,
}) => {
  const [displayedSrc, setDisplayedSrc] = useState(src)
  useEffect(() => {
    if (file) {
      setDisplayedSrc(
        file.type.indexOf('image/') === 0
          ? URL.createObjectURL(file)
          : '/assets/video-thumb.png',
      )
    } else {
      setDisplayedSrc(src)
    }
  }, [file, src])
  return (
    <div className="image">
      <img
        alt={alt}
        className={cn(
          'image__actual-image',
          isBlurred && 'image__actual-image--blurred',
        )}
        src={displayedSrc}
        onError={() => setDisplayedSrc('/assets/video-thumb.png')}
      />
      <div className="image__cancel-button">
        {!isBlurred && onEdit && (
          <IconButton onClick={() => onEdit()} name="edit" />
        )}
        {!isBlurred && <IconButton onClick={() => onRemove()} name="cancel" />}
      </div>
    </div>
  )
}

export default Image
