import Joi from 'joi'

export const PAID_COUNTRIES_VALIDATOR = Joi.object({
  countryName: Joi.string().required().messages({
    'string.empty': `This field cannot be empty`,
    'any.required': `This is a required field`,
  }),
  payment: Joi.number().greater(0).required().messages({
    'number.base': 'The payment must be a number',
    'number.empty': `This field cannot be empty`,
    'number.greater': 'The payment must be greater than 0',
    'any.required': `This is a required field`,
  }),
})
