import React, { FC, Fragment } from 'react'
import classNames from 'classnames'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { Event } from '../../../store/api/types/Event.types'
import { Interweave } from 'interweave'
import Link from 'components/Link'
import 'swiper/css'
import 'swiper/css/pagination'
import styles from './EventsCarousel.module.scss'
import { ImageOrVideo } from 'components/ImageOrVideo'

const image =
  'https://storage.googleapis.com/staging.refreshing-park-265118.appspot.com/ZP37-GPEP-Z3GE-MKPH-3howtobreakarecord.png'

interface EventCardProps {
  className?: string
  title: string
  description: string
  image: string
  slug: string
  isPrivate: boolean
}

const EventCard: FC<EventCardProps> = ({
  className,
  title,
  description,
  image,
  slug,
  isPrivate,
}) => {
  return (
    <Link
      className=".pointer:hover"
      to={isPrivate ? `/events/${slug}?preview=true` : `/events/${slug}`}
    >
      <div className={classNames(className, styles.card)}>
        <div className={styles.imageDiv}>
          <ImageOrVideo
            className={styles.imageOrVideo}
            alt="event image or video"
            src={image || ''}
            autoPlay
            loop
          />
        </div>
        <div className={styles.cardDescription}>
          <p className={styles.title}>{title}</p>
          <Interweave content={description} />
        </div>
      </div>
    </Link>
  )
}

interface EventCarouselProps {
  events: Event[]
  name: string
}

const EventsCarousel = ({ events, name }: EventCarouselProps) => {
  const SwiperButtonPrev = () => {
    const swiper = useSwiper()
    return (
      <ArrowBackIos
        className={styles.arrow}
        onClick={() => swiper.slidePrev()}
      />
    )
  }

  const SwiperButtonNext = () => {
    const swiper = useSwiper()
    return (
      <ArrowForwardIos
        className={styles.arrow}
        onClick={() => swiper.slideNext()}
      />
    )
  }

  if (!events.length) {
    return <Fragment />
  }

  return (
    <div className={styles.component}>
      <Swiper slidesPerView={'auto'} className={styles.swiper}>
        <h1 className={styles.header}>
          <SwiperButtonPrev />
          {name}
          <SwiperButtonNext />
        </h1>
        {events.map((event: Event) => {
          const media = event.medias.length ? event.medias[0] : image
          return (
            <SwiperSlide className={styles.swiperSlide} key={event.id}>
              <EventCard
                description={event.description}
                image={media}
                title={event.name}
                slug={event.slug}
                isPrivate={event.isPrivate}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default EventsCarousel
