import React, { FC } from 'react'
import { useController } from 'react-hook-form'
import { CountryAutocomplete } from '../Inputs'

export interface FormInputProps {
  name: string
  label?: string
  control: any
}

export const FormCountryAutocomplete: FC<FormInputProps> = ({
  name,
  label,
  control,
}) => {
  const {
    field: { ref, onChange, value, ...rest },
    formState: { errors },
  } = useController({ control, name })

  return (
    <CountryAutocomplete
      {...rest}
      name={rest.name}
      defaultValue={''}
      label={label}
      onSelectLocation={onChange}
      errorText={errors.country?.message as string}
    />
  )
}
