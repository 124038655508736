import React from 'react'

import Button, { ButtonProps } from 'components/Button'
import Link, { Page } from 'components/Link'

interface LinkButtonProps extends ButtonProps {
  target?: string
  to: Page
  externalLink?: boolean
}

const LinkButton: React.FC<LinkButtonProps> = ({
  children,
  className,
  target,
  to,
  externalLink = false,
  ...rest
}) => {
  const button = (
    <Button className="w-100" {...rest}>
      {children}
    </Button>
  )
  if (externalLink) {
    return (
      <a className={className} href={to} target={target}>
        {button}
      </a>
    )
  }

  return (
    <Link className={className} to={to} target={target}>
      {button}
    </Link>
  )
}

export default LinkButton
