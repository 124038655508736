import React, { FC } from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Input } from '../../../components/Inputs'
import Button from '../../../components/Button'
import { useSuggestQuizMutation } from '../../../store/api'

import styles from '../Quiz.module.scss'

const ACCORDION_STYLE = {
  boxShadow: 'unset',
  border: 'unset',
  marginTop: '1rem',
  position: 'unset',
}

export const SuggestQuiz: FC = () => {
  const [suggestQuiz, { isLoading: isSending }] = useSuggestQuizMutation()
  const [expanded, setExpanded] = React.useState(false)

  const onSend = async (e: any) => {
    e.preventDefault()
    await suggestQuiz({
      email: e.target.email.value,
      suggestionText: e.target.suggestionText.value,
    })
  }

  return (
    <Accordion
      sx={ACCORDION_STYLE}
      className={styles.suggestQuiz}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        sx={{ padding: 0 }}
        expandIcon={<ArrowDropDownIcon className={styles.icon} />}
        id="panel1bh-header"
        className={styles.header}
      >
        {'Suggest a Quiz'}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <form className={styles.suggestionForm} onSubmit={onSend}>
          <Input
            className={styles.inputEmail}
            type="email"
            name="email"
            label="Parent Email"
            required
            disabled={isSending}
          />
          <Input
            multiline
            minRows={3}
            className={styles.inputSuggestion}
            type="text"
            name="suggestionText"
            label="Type your suggestion here."
            disabled={isSending}
          />
          <div></div>
          <Button
            className={styles.button}
            type={'submit'}
            disabled={isSending}
          >
            SEND
          </Button>
        </form>
      </AccordionDetails>
    </Accordion>
  )
}
