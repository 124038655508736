import React from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { closeMenu } from 'store/app/actions'

import Divider from 'components/Divider'
import HeaderLink from './headerLink'

import { HEADER, FOOTER } from '../links'
import './navigation.css'

import styles from './HeaderLink.module.scss'

interface NavigationProps {
  collapsed: boolean
}

const Navigation = ({ collapsed }: NavigationProps) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(closeMenu())
  }

  const { pathname } = useLocation()

  return (
    <nav
      className={cn(
        'navigation db fixed w5 h-100 top-0 left-0 bg-white',
        'black overflow-y-auto overflow-x-hidden z-4 flex-l relative-l w-100-l bg-none-l',
        collapsed && 'navigation--collapsed',
      )}
    >
      <ul className="flex flex-column justify-center align-start bg-white ph1 list flex-row-l bg-none-l pr5-l">
        {HEADER.map((item) => (
          <HeaderLink
            isOnCurrentPage={pathname === item.url}
            key={item.text}
            onClick={handleClick}
            url={item.url}
            className={
              item.text === 'Record Ideas' ? styles.headerLink : undefined
            }
          >
            {item.text}
          </HeaderLink>
        ))}
      </ul>

      <div className="dn-l mh2">
        <Divider />
      </div>
      {!!FOOTER.length && (
        <ul className="dn-l flex flex-column justify-center align-start ph1 list f6">
          {FOOTER.map((item) => (
            <HeaderLink
              isOnCurrentPage={pathname === item.url}
              key={item.text}
              onClick={handleClick}
              url={item.url}
            >
              {item.text}
            </HeaderLink>
          ))}
        </ul>
      )}
    </nav>
  )
}

export default Navigation
