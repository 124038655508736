import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ProductCheckout } from 'data/Product'

import { findAndReplace } from 'utils/arrays'

interface ShopState {
  shoppingCart: ProductCheckout[]
  isLoading: boolean
  isOpen: boolean
}

const initialState = {
  shoppingCart: [] as ProductCheckout[],
  isLoading: false,
} as ShopState

const shopSlice = createSlice({
  name: 'shop',
  initialState: initialState as ShopState,
  reducers: {
    checkoutAsync(state: ShopState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    toggleShoppingCart(state: ShopState) {
      state.isOpen = !state.isOpen
    },
    addToShoppingCart(
      state: ShopState,
      action: PayloadAction<ProductCheckout>,
    ) {
      if (!findProduct(state.shoppingCart, action.payload)) {
        state.shoppingCart = [...state.shoppingCart, action.payload]
      }

      state.shoppingCart = findAndReplace(
        state.shoppingCart,
        (checkoutProduct) => isSameProduct(checkoutProduct, action.payload),
        action.payload,
      )
    },
    updateShoppingCart(
      state: ShopState,
      action: PayloadAction<ProductCheckout>,
    ) {
      if (!findProduct(state.shoppingCart, action.payload)) {
        state.shoppingCart = [...state.shoppingCart, action.payload]
      }

      state.shoppingCart = findAndReplace(
        state.shoppingCart,
        (checkoutProduct) => isSameProduct(checkoutProduct, action.payload),
        action.payload,
      )
    },
    removeFromShoppingCart(
      state: ShopState,
      action: PayloadAction<ProductCheckout>,
    ) {
      state.shoppingCart = state.shoppingCart.filter(
        (product) => !isSameProduct(action.payload, product),
      )
    },
  },
})

const isSameProduct = (
  checkoutProduct1: ProductCheckout,
  checkoutProduct2: ProductCheckout,
): boolean =>
  checkoutProduct1.product.id === checkoutProduct2.product.id &&
  checkoutProduct1.childName === checkoutProduct2.childName &&
  checkoutProduct1.submissionTitle === checkoutProduct2.submissionTitle

const findProduct = (
  productCheckouts: ProductCheckout[],
  productCheckout: ProductCheckout,
): ProductCheckout | undefined => {
  return productCheckouts.find((productCheckout2) =>
    isSameProduct(productCheckout2, productCheckout),
  )
}

export const {
  addToShoppingCart,
  checkoutAsync,
  removeFromShoppingCart,
  toggleShoppingCart,
  updateShoppingCart,
} = shopSlice.actions
export default shopSlice.reducer
