import React from 'react'

import Sitemap from '../Sitemap'
import Conditional from '../../Conditional'
import { SocialLinks } from './SocialLinks'

import { MovingRacoonHead } from './MovingRacconHead'
import { CONFIG } from 'config'
import { useMyRole } from 'hooks/useMyRole'

import styles from './Footer.module.scss'

const style = {
  width: '100%',
  bottom: 0,
}

export const Footer: React.FC = () => {
  const { isAdmin: isAdminLogged } = useMyRole()

  return (
    <footer
      id="footer"
      style={style}
      className="dn flex-l pv4 white bg-almost-black nunito-regular relative"
    >
      <div className={styles.footer}>
        <Sitemap />
        <SocialLinks />
        <span>© Kids World Records {new Date().getFullYear()}</span>
      </div>
      <Conditional shouldRender={!isAdminLogged}>
        <MovingRacoonHead />
        <div
          className={
            CONFIG.FEATURE_513
              ? styles.imageContainer_FEATURE_513
              : styles.imageContainer
          }
        >
          <img
            className={styles.racoon}
            src={'/assets/racoon_body.png'}
            alt="Racoon"
          />
        </div>
      </Conditional>
    </footer>
  )
}
