//todo: THIS IS DEPRECATED, DO NOT USE IT
export enum Gender {
  Boy = 'Boy',
  Girl = 'Girl',
  'Gender X' = 'Gender X',
}

export enum GenderV2 {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  GENDER_X = 'GENDER_X',
}
