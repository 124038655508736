export const replaceByIndex = <T extends any>(
  list: T[],
  itemIndex: number,
  newItem: T,
): T[] => list.map((item, index) => (index !== itemIndex ? item : newItem))

export const findAndReplace = <T extends any>(
  list: T[],
  predicate: (value: T) => boolean,
  newItem: T,
): T[] => {
  const index = list.findIndex(predicate)
  return index < 0 ? [...list, newItem] : replaceByIndex(list, index, newItem)
}

export const safeSplice = <T>(array: T[], index: number, end = 1): T[] => {
  const aux = [...array]
  aux.splice(index, end)
  return aux
}

export const moveItem = <T>(
  array: T[],
  indexToMove: number,
  toForward = true,
) => {
  const offset = toForward ? 1 : -1
  const indexToPlace = indexToMove + offset

  if (indexToPlace >= array.length || indexToPlace < 0) return array

  const newArray = [...array]
  const item: T = array[indexToMove]

  newArray[indexToMove] = newArray[indexToPlace]
  newArray[indexToPlace] = item
  return newArray
}

export const removeDuplicatesById = <T>(array: any[]): T[] => {
  return array.reduce((prev, curr) => {
    return prev.find((i: any) => i.id === curr.id) ? prev : [...prev, curr]
  }, [])
}

type AnyObject = {
  [key: string]: string | number | Date
}

export const sortBy = (
  array: AnyObject[],
  orderBy: string,
  order: 'asc' | 'desc',
) => {
  const sorted = array.sort((a, b) => {
    const isValueString = typeof a[orderBy] === 'string'
    const isDate = a[orderBy] instanceof Date
    let value1 = isValueString
      ? (a[orderBy] as string).toLowerCase()
      : a[orderBy]
    let value2 = isValueString
      ? (b[orderBy] as string).toLowerCase()
      : b[orderBy]

    value1 = isDate ? (a[orderBy] as Date).getTime() : value1
    value2 = isDate ? (b[orderBy] as Date).getTime() : value2

    return isValueString
      ? (value1 as string).localeCompare(value2 as string)
      : (value1 as number) - (value2 as number)
  })

  return order === 'desc' ? sorted : sorted.reverse()
}
