import React from 'react'
import useToggle from '../../hooks/useToggle'
import SubTitle from 'components/SubTitle'
import Button from 'components/Button'

const Disclaimer: React.FC = () => {
  const [shown, setShown] = useToggle(false)

  return (
    <div>
      <SubTitle className="pb3">
        We require parental permission, so tell us a bit about yourself.
      </SubTitle>
      {shown && (
        <div className="dark-gray">
          <p>
            We want to make sure you’re 100% comfortable with how your
            information is used. Here are the highlights:
          </p>
          <ul className="dark-gray" style={{ paddingLeft: '20px' }}>
            <li className="nunito-regular">
              You and your child can browse the website, but to submit a Kids
              World Record attempt you must provide basic data for verification.
            </li>
            <li className="nunito-bold">
              Your data is never shared or sold to third parties.
            </li>
          </ul>
        </div>
      )}

      <div className="pb3">
        <Button ghost onClick={setShown} size="small" theme="tertiary">
          Show {shown ? 'less' : 'more'}
        </Button>
      </div>
    </div>
  )
}

export default Disclaimer
