import React from 'react'
import BorderedContainer from 'components/BorderedContainer'
import Title from 'components/Title'
import { Page } from 'components/Page'

export const HowToV1: React.FC = () => {
  return (
    <Page>
      <BorderedContainer>
        <Title className="pb3 pb4-l">How to Break a Record</Title>
        <h4>
          Want to set a new Kids World Record or break an existing one? The
          challenge is on!
        </h4>
        <h3>
          <b>The Ground Rules:</b>
        </h3>
        <h4>The KID(S) must:</h4>
        <ul>
          <li>Be 4 to 16 years old and have parental/guardian consent</li>
          <li>
            Get <b>video</b> and <b>photo</b> evidence of the record attempt
          </li>
          <li>Measure the results</li>
          <li>Have adult witnesses</li>
        </ul>
        <h4>The RECORD ATTEMPT must be:</h4>
        <ul>
          <li>Adequately challenging</li>
          <li>Measurable (most, smallest, longest, fastest, etc)</li>
          <li>Safe, ethical, moral and legal</li>
        </ul>
      </BorderedContainer>
    </Page>
  )
}
