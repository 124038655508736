import React from 'react'

import MuiMenu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'

import IconButton from 'components/IconButton'

import './context-menu.css'
import { IconName } from 'components/Icon/IconName'

export interface ContextMenuOption {
  label: string
  onClick: () => void
  disabled?: boolean
}

interface ContextMenuProps {
  className?: string
  icon?: IconName
  iconClassName?: string
  options: ContextMenuOption[]
  onClose?: () => void
  onOpen?: () => void
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  className,
  icon = 'more',
  iconClassName,
  options,
  onClose,
  onOpen,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    onOpen && onOpen()
  }

  const handleClose = () => {
    setAnchorEl(null)
    onClose && onClose()
  }

  return (
    <>
      <IconButton
        name={icon}
        className={className}
        iconClassName={iconClassName}
        onClick={handleClick}
        label="Toggle Context Menu"
      />
      <MuiMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className="context-menu"
        elevation={0}
        // getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        onClick={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option) => (
          <MuiMenuItem
            className="context-menu__item"
            onClick={option.onClick}
            key={option.label}
            disabled={option.disabled}
          >
            {option.label}
          </MuiMenuItem>
        ))}
      </MuiMenu>
    </>
  )
}

export default ContextMenu
