import React, { FC } from 'react'
import IconButton from '@mui/material/IconButton'
import { ArrowDownward } from '@mui/icons-material'

export interface PriorityChangeProps {
  priority: number
  onChangePriority(e: any): void
}
export const PriorityChange: FC<PriorityChangeProps> = ({
  priority,
  onChangePriority,
}) => {
  return (
    <IconButton aria-label="priority-change" onClick={onChangePriority}>
      <ArrowDownward
        style={{
          color: priority === 1 ? 'red' : 'green',
          transform: priority === 1 ? 'none' : 'rotate(180deg)',
        }}
      />
    </IconButton>
  )
}
