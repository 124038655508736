import React, { FC } from 'react'

import { Input } from '../Input'

export type FileInputProps = {
  label: string
  name?: string
  multiple?: boolean
  disabled?: boolean
  errorText?: string
}

export const FileInput: FC<FileInputProps> = ({
  label,
  name,
  multiple,
  disabled,
  errorText,
}) => {
  return (
    <Input
      label={label}
      type="file"
      multiple={multiple}
      shrink
      name={name}
      disabled={disabled}
      errorText={errorText}
    />
  )
}
