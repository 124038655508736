import React from 'react'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import Icon from 'components/Icon'
import { FormInput } from 'components/Form/FormInput'
import Conditional from 'components/Conditional'

import { SUBMISSION_SEARCH_VALIDATOR } from 'scenes/RecordSubmissions/RecordSubmissions.utils'

import styles from './SearchBar.module.scss'

interface SearchBarParams {
  isSearching: boolean
  onSearchSubmit: (title: string) => void
  setIsSearching: (value: boolean) => void
  showDisplayingSearchLabel?: boolean
}

export const SearchBar: React.FC<SearchBarParams> = ({
  isSearching,
  onSearchSubmit,
  setIsSearching,
  showDisplayingSearchLabel = true,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ title: string }>({
    resolver: joiResolver(SUBMISSION_SEARCH_VALIDATOR),
    mode: 'all',
  })

  const onSubmit = (body: { title: string }) => {
    setIsSearching(!!body)
    onSearchSubmit(body.title)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        label="Search"
        name="title"
        control={control}
        errorText={errors.title?.message}
        endAdornment={<Icon name="search" />}
      />
      <Conditional shouldRender={isSearching && showDisplayingSearchLabel}>
        <div className={styles.searchText}>Displaying search results</div>
      </Conditional>
    </form>
  )
}
