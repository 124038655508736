import React from 'react'
import MuiIconButton from '@mui/material/IconButton'
import cn from 'classnames'

import { IconName } from 'components/Icon/IconName'
import Icon from 'components/Icon'

interface Props {
  className?: string
  disabled?: boolean
  iconClassName?: string
  label?: string
  name: IconName
  onClick?: React.MouseEventHandler<HTMLElement>
  type?: 'button' | 'submit' | 'reset'
}

const IconButton: React.FC<Props> = ({
  className,
  disabled = false,
  iconClassName,
  label,
  name,
  onClick,
  type = 'button',
}) => (
  <MuiIconButton
    className={cn('icon-button', className)}
    onClick={onClick}
    type={type}
    aria-label={label}
    disabled={disabled}
  >
    <Icon name={name} className={iconClassName} />
  </MuiIconButton>
)

export default IconButton
