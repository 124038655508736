import Joi from 'joi'
import { GroupCategory } from 'store/api/types/Record.types'

export const CATEGORY_OPTIONS = [
  {
    label: 'Small (2-5)',
    value: GroupCategory.SMALL,
  },
  {
    label: 'Medium (6-15)',
    value: GroupCategory.MEDIUM,
  },
  {
    label: 'Large (16-30)',
    value: GroupCategory.LARGE,
  },
  {
    label: 'Gigantic (31+)',
    value: GroupCategory.GIGANTIC,
  },
]

export const SUBMISSION_CREATE_VALIDATOR = Joi.object({
  idea: Joi.string().required().label('Title'),
  previousRecordId: Joi.string().uuid().allow(null, ''),
  description: Joi.string().required().label('Description'),
  notes: Joi.string().allow(null, ''),
  brokenOn: Joi.date().required(),
  location: Joi.object({
    country: Joi.string().required().label('country'),
    city: Joi.string().required().label('city'),
    state: Joi.string().allow(null, ''),
    timezoneInMiliseconds: Joi.number().required(),
  })
    .required()
    .messages({
      'any.required':
        "Country, city are required. If you're from a city that functions as a prefecture or special administrative region then please enter a more specific location within your city to continue.",
    }),
  mediaLinks: Joi.array().items(Joi.string()).min(1).required(),
  recordCategory: Joi.object({
    category: Joi.string()
      .valid(...Object.values(GroupCategory))
      .required(),
    participantAmount: Joi.number()
      .integer()
      .required()
      .custom((value, helpers) => {
        if (
          !validateParticipantAmount(helpers.state.ancestors[0].category, value)
        ) {
          return helpers.error('any.invalid')
        }
        return value
      }),
  }).required(),
})

export const validateParticipantAmount = (
  category: GroupCategory,
  amount: number,
) => {
  switch (category) {
    case GroupCategory.SMALL:
      return amount >= 2 && amount <= 5
    case GroupCategory.MEDIUM:
      return amount >= 6 && amount <= 15
    case GroupCategory.LARGE:
      return amount >= 16 && amount <= 30
    case GroupCategory.GIGANTIC:
      return amount >= 31
  }
}
