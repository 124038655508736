import Joi from 'joi'

export const CARD_VERIFY_VALIDATOR = Joi.object({
  holderName: Joi.string().required().messages({
    'string.empty': `This field cannot be empty`,
    'any.required': `This is a required field`,
  }),
  number: Joi.string().required().messages({
    'string.empty': `This field cannot be empty`,
    'any.required': `This is a required field`,
  }),
  validThru: Joi.string().required().messages({
    'string.empty': `This field cannot be empty`,
    'any.required': `This is a required field`,
  }),
})

export const REVIEW_VERIFY_VALIDATOR = Joi.object({
  country: Joi.string().required().messages({
    'string.empty': `This field cannot be empty`,
    'any.required': `This is a required field`,
  }),
  documentType: Joi.string().required().messages({
    'string.empty': `This field cannot be empty`,
    'any.required': `This is a required field`,
  }),
})
