import React, { FC, useState } from 'react'

import { Page } from 'components/Page'
import { QuizList } from './components/QuizList'
import { Scoreboard } from './components/Scoreboard'
import { Header } from './components/Header'
import { QuizRecord } from './components/QuizRecord'

import styles from './QuizScoreBoard.module.scss'

export const QuizScoreBoard: FC = () => {
  const [selectedQuiz, setSelectedQuiz] = useState<string>('')

  return (
    <Page className={styles.page}>
      <Header />
      <div className={styles.content}>
        <QuizRecord />
      </div>
      <QuizList onSelect={setSelectedQuiz} />
      <div className={styles.content}>
        {selectedQuiz && <Scoreboard quizId={selectedQuiz} />}
      </div>
    </Page>
  )
}
