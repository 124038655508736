import Joi from 'joi'

export const WITNESSES_VALIDATOR = Joi.object({
  witness1Email: Joi.string()
    .email({ tlds: { allow: false } })
    .allow(''),
  witness2Email: Joi.string()
    .email({ tlds: { allow: false } })
    .allow(''),
})
