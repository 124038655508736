import Joi from 'joi'
import { FormError, SubmitParticipant } from './type'

const validationRule: Joi.ObjectSchema = Joi.object({
  isMediaRequired: Joi.boolean(),
  parentFirstName: Joi.string(),
  parentLastName: Joi.string(),
  kidsFirstName: Joi.string(),
  kidsLastName: Joi.string(),
  kidsDateOfBirth: Joi.date(),
  instagramId: Joi.allow(''),
  email: Joi.string().email({ tlds: { allow: false } }),
  medias: Joi.alternatives().conditional('isMediaRequired', [
    {
      is: true,
      then: Joi.array()
        .items(Joi.object())
        .messages({ 'array.base': 'Photo/Video is required' }),
    },
    { is: false, then: Joi.allow(0) },
  ]),
})

const parseErrors = (validationResult: Joi.ValidationResult<any>) => {
  if (validationResult.error?.details) {
    const validationErrors = validationResult.error.details
    if (validationErrors) {
      return validationErrors.reduce((acc, item) => {
        const label = item.context?.label
        return label ? { ...acc, [label]: item.message } : acc
      }, {})
    }
  }
}

export const getErrorsParticipate = (
  event: SubmitParticipant,
): FormError | undefined => {
  const validationResult = validationRule.validate(
    {
      ...event,
      medias: event.medias.length && Array.from(event.medias),
    },
    {
      abortEarly: false,
    },
  )
  return parseErrors(validationResult)
}
