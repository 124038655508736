import React from 'react'
import cn from 'classnames'

import MuiDivider from '@mui/material/Divider'

import './divider.css'

interface DividerProps {
  className?: string
}

const Divider: React.FC<DividerProps> = ({ className }) => (
  <MuiDivider className={cn(className, 'divider')} />
)

export default Divider
