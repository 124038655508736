import React from 'react'
import { ViewList, ViewModule } from '@mui/icons-material'

import styles from './ListViewSelector.module.scss'
import Conditional from 'components/Conditional'

const LABELS = {
  card: 'List View',
  list: 'Card View',
}

export interface ListViewSelectorProps {
  view: 'list' | 'card'
  onClick(): void
}

export const ListViewSelector = ({ view, onClick }: ListViewSelectorProps) => {
  return (
    <div className={styles.main} onClick={onClick}>
      <Conditional shouldRender={view === 'card'}>
        <ViewList className={styles.icon} />
      </Conditional>
      <Conditional shouldRender={view === 'list'}>
        <ViewModule className={styles.icon} />
      </Conditional>
      <h1 className={styles.label}>{LABELS[view]}</h1>
    </div>
  )
}
