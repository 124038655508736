import React, { FC } from 'react'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import Title from 'components/Title'
import styles from './Header.module.scss'

export const Header: FC = () => (
  <div className={styles.header}>
    <div className={styles.yellow}>
      <div className={styles.center}>
        <div>
          <Title className={styles.title}>Quiz Score Board</Title>
          <p>
            Check out the player quiz high scores from quizzes on Flags,
            Science, General Trivia, Nature, Disney World Capitals/Money/dialing
            codes and more. If you play our quizzes and get the most correct
            answers, or if you're the fastest with most correct answers, you
            become the next Kids World Record Holder!
          </p>
        </div>
        <div className={styles.stars}>
          <StarRoundedIcon className={styles.star1} />
          <StarRoundedIcon className={styles.star2} />
        </div>
      </div>
      <div className={styles.congratulations}>Congratulations!</div>
    </div>
  </div>
)
