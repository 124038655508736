import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useParams, useNavigate } from 'react-router-dom'
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded'
import TimerRoundedIcon from '@mui/icons-material/TimerRounded'
import BlockRoundedIcon from '@mui/icons-material/BlockRounded'
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded'

import {
  useGetSubmissionByIdQuery,
  useUpdateSubmissionMutation,
} from 'store/api'
import { GenderV2 } from 'store/api/types/Gender.types'
import {
  RecordStatus,
  SubmissionUpdateBody,
} from 'store/api/types/Record.types'
import SubmitButton from 'components/SubmitButton'
import BackButton from 'components/BackButton'
import BorderedContainer from 'components/BorderedContainer'
import { Page } from 'components/Page'
import { FormInput } from 'components/Form/FormInput'
import { FormSelect } from 'components/Form/FormSelect'
import { FormCheckbox } from 'components/Form/FormCheckbox'
import { FormRecordMedias } from 'components/Form/FormRecordMedias'
import { DatePicker, Input } from 'components/Inputs'

import { TwoColumnsGrid } from './components/TwoColumnsGrid'
import {
  buildDefaultValue,
  CATEGORY_OPTIONS,
  GENDER_LABEL,
  SUBMISSION_UPDATE_VALIDATOR,
} from './RecordSubmissionDetailsEdit.utils'

import { FormPreviousRecordInfo } from '../RecordSubmissionDetails/components/PreviousRecordInfo/FormPreviousRecordInfo'

import { buildLocationValue } from '../RecordSubmissionDetails/RecordSubmissionDetails.utils'

import styles from './RecordSubmissionDetailsEdit.module.scss'

type RecordSubmissionDetailParams = {
  id: string
}

export const RecordSubmissionDetailsEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams<RecordSubmissionDetailParams>()
  const { data: submission, isLoading } = useGetSubmissionByIdQuery(id || '', {
    skip: !id,
  })
  const [updateSubmission, { isLoading: isUpdating }] =
    useUpdateSubmissionMutation()

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    reset,
  } = useForm<SubmissionUpdateBody>({
    resolver: joiResolver(SUBMISSION_UPDATE_VALIDATOR),
    mode: 'all',
  })

  useEffect(() => {
    if (submission) {
      reset(buildDefaultValue(submission))
    }
  }, [reset, submission])

  const onSubmit = (body: SubmissionUpdateBody) => {
    if (id) {
      updateSubmission({ id, ...body })
        .unwrap()
        .then(() => navigate(-1))
    }
  }

  return (
    <Page>
      <BorderedContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TwoColumnsGrid className={styles.top}>
            <BackButton backUrl={`/admin/submissions/${id}`}>
              Back to Records
            </BackButton>
            <SubmitButton disabled={!isValid || isLoading || isUpdating}>
              Save Changes
            </SubmitButton>
          </TwoColumnsGrid>
          <FormInput
            name="idea"
            label="Title"
            control={control}
            errorText={errors?.idea?.message as string}
          />
          <FormSelect
            name="category"
            label="Select Category"
            items={CATEGORY_OPTIONS}
            control={control}
          />
          <TwoColumnsGrid>
            <Input
              label="Kid's First Name"
              disabled
              value={submission?.recordOwner.firstName || ''}
            />
            <Input
              label="Kid's Last Name"
              disabled
              value={submission?.recordOwner.lastName || ''}
            />
          </TwoColumnsGrid>
          <TwoColumnsGrid>
            <Input
              label="Submission Gender"
              disabled
              value={
                GENDER_LABEL[submission?.gender || GenderV2.GENDER_X] || ''
              }
            />
            <Input
              label="Child Profile Gender"
              disabled
              value={
                GENDER_LABEL[
                  submission?.recordOwner.gender || GenderV2.GENDER_X
                ] || ''
              }
            />
          </TwoColumnsGrid>
          <FormPreviousRecordInfo
            name="previousRecordId"
            control={control}
            currentRecordGender={submission?.gender || ''}
            currentRecordAge={submission?.ageGap || ''}
            disabled={submission?.status !== RecordStatus.IN_REVIEW}
          />
          <TwoColumnsGrid>
            <DatePicker
              label="Child birth date"
              disabled
              value={
                new Date(submission?.recordOwner?.dateOfBirth.toString() || 0)
              }
            />
            <Input
              name="ageGap"
              label="Age Range"
              disabled
              value={submission?.ageGap || ''}
            />
          </TwoColumnsGrid>
          <FormInput
            name="description"
            label="Record Description"
            control={control}
            multiline
            errorText={errors?.description?.message as string}
          />
          <FormInput
            name="notes"
            label="Record Notes"
            control={control}
            errorText={errors?.notes?.message as string}
            multiline
          />
          <TwoColumnsGrid>
            <DatePicker
              label="Record Date"
              disabled
              value={new Date(submission?.brokenOn || 0)}
            />
            <Input
              name="location"
              label="Record Location"
              disabled
              value={buildLocationValue(submission?.location)}
            />
          </TwoColumnsGrid>
          <FormCheckbox
            name="isFirstRecord"
            control={control}
            icon={<WorkspacePremiumRoundedIcon className={styles.icon} />}
            label="This record is the first of its kind."
          />
          <FormCheckbox
            name="requireParentalSupervision"
            control={control}
            icon={<ReportProblemRoundedIcon className={styles.icon} />}
            label="This record require proof of parental supervision."
          />
          <FormCheckbox
            name="requireTimingDevice"
            control={control}
            icon={<TimerRoundedIcon className={styles.icon} />}
            label="A timing device must be visible in video submitted."
          />
          <FormCheckbox
            name="noNewRecord"
            control={control}
            icon={<BlockRoundedIcon className={styles.icon} />}
            label="We are not accepting new submissions for this record."
          />
          <FormRecordMedias name="mediaLinks" control={control} />
        </form>
      </BorderedContainer>
    </Page>
  )
}
