import React, { FC, Fragment } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css'

import { useGetCmsRecordByContentNameQuery } from 'store/api'
import Conditional from 'components/Conditional'
import { AdminCKEditorPencil, CmsEditForm } from 'components/AdminCKEditor'
import { CmsDataToUpdate } from '../../store/api/types/Record.types'
import useToggle from 'hooks/useToggle'

import { CONFIG } from 'config'
import { useMyRole } from 'hooks/useMyRole'
import styles from './WorldMap.module.scss'

const funFactsData = ['First fun fact', 'Second fun fact']

const CMS_PAGE_NAME = 'world_map_fun_facts'

const QuizRecordSlide: FC<{ description: string }> = ({ description }) => {
  return (
    <div className={styles.slide}>
      <div className={styles.description_swiper}>{description}</div>
    </div>
  )
}

export const SwiperWorldMap: FC = () => {
  const { isAdmin: isAdminLogged } = useMyRole()
  const [isEditing, ClickEditToggle] = useToggle()
  const {
    data: contentData,
    isLoading,
    isUninitialized,
  } = useGetCmsRecordByContentNameQuery(CMS_PAGE_NAME)
  const shouldDisplayContent = !isEditing && !isLoading && !isUninitialized
  let funFacts = [] as string[]
  if (contentData) {
    funFacts = contentData.content
      .replace('<ul><li>', '')
      .replace('</li></ul>', '')
      .replaceAll('<li>', '^^')
      .replaceAll('</li>', '^^')
      .split('^^^^')
  }
  const isEditMode =
    CONFIG.FEATURE_931 && Boolean(contentData) && isAdminLogged && isEditing

  return (
    <Fragment>
      <div className={styles.swiperWrapper}>
        <h1 className={styles.title}>
          Fun Facts <AdminCKEditorPencil onClick={ClickEditToggle} />
        </h1>
        <Conditional shouldRender={shouldDisplayContent}>
          <Swiper
            slidesPerView={1}
            autoplay={{ delay: 10000 }}
            className={styles.recordsSwiper}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
            style={{
              '--swiper-pagination-color': '#FF5252',
              '--swiper-pagination-bullet-inactive-color': '#FFF',
              '--swiper-pagination-bullet-inactive-opacity': '1',
            }}
          >
            {CONFIG.FEATURE_931
              ? funFacts.map((dataElem) => {
                  return (
                    <SwiperSlide key={dataElem}>
                      <QuizRecordSlide description={dataElem} />
                    </SwiperSlide>
                  )
                })
              : funFactsData.map((dataElem) => {
                  return (
                    <SwiperSlide key={dataElem}>
                      <QuizRecordSlide description={dataElem} />
                    </SwiperSlide>
                  )
                })}
          </Swiper>
        </Conditional>
      </div>
      <Conditional shouldRender={isEditMode}>
        <p className={styles.editMessage}> Please, use only unordered list.</p>
        <CmsEditForm contentData={contentData as CmsDataToUpdate} />
      </Conditional>
    </Fragment>
  )
}
