const version = process.env.APP_VERSION || 0
export const PREFIX = `KWR_v${version}::`

export const set = <T = object>(key: string, value: T): void => {
  try {
    const serializedValue = JSON.stringify(value)
    localStorage?.setItem(`${PREFIX}${key}`, serializedValue)
  } catch (error) {
    throw new Error('store serialization failed')
  }
}

export const remove = (key: string): void => {
  try {
    localStorage?.removeItem(`${PREFIX}${key}`)
  } catch (error) {
    throw new Error('key deletion failed')
  }
}

export const get = <T = object>(key: string): T | undefined => {
  try {
    const serializedValue = localStorage?.getItem(`${PREFIX}${key}`)
    return serializedValue && JSON.parse(serializedValue)
  } catch (error) {
    throw new Error('store deserialization failed')
  }
}
