import React from 'react'

import Button from 'components/Button'

import ShadowModal from '.'

interface LogoutModalProps {
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
}

const LogoutModal: React.FC<LogoutModalProps> = ({
  isOpen,
  onCancel,
  onConfirm,
}) => (
  <ShadowModal isModalOpen={isOpen}>
    <div className="nunito-bold neo-red f4">Log Out?</div>
    <div className="nunito-regular f5 pt3">
      Are you sure you want to log out from your account?
    </div>
    <div className="flex flex-row justify-end pt5">
      <Button ghost theme="secondary" onClick={onCancel}>
        Cancel
      </Button>
      <Button ghost theme="secondary" onClick={onConfirm}>
        Log Out
      </Button>
    </div>
  </ShadowModal>
)

export default LogoutModal
