import axios from 'axios'

import { getAuthUserToken } from '../services/authentication'

export const uploadHeadContentFile = async (file: File): Promise<string> => {
  const format = file.type.split('/')[1] || ''
  const token = getAuthUserToken()
  const { data } = await axios.get(
    `/api/admin/header/generateMediaUploadLink?format=${format}`,
    { headers: { authorization: `Bearer ${token}` } },
  )

  await axios.put(`${data.uploadUrl}`, file, {
    headers: { 'Content-Type': file.type, 'content-disposition': 'attachment' },
  })

  return data.uploadUrl.split('?')[0] || ''
}
