import React, { useEffect, useState } from 'react'
import { CONFIG } from 'config'
import LinkButton from 'components/LinkButton'
import Conditional from 'components/Conditional'
import { SearchBar } from '../../BrowseRecords/components/SearchBar'
import { DropDownValue } from './CategorySelect'
import styles from '../Quiz.module.scss'

interface SearchParams {
  searchString: string
  category: DropDownValue
}

interface ControlPanelProps {
  onSearch(params: SearchParams): void
  defaultValue?: DropDownValue
  loading?: boolean
}

export const ControlPanel = ({
  onSearch,
  loading,
  defaultValue,
}: ControlPanelProps) => {
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState<DropDownValue>('ALL_QUIZZES')

  useEffect(() => {
    if (defaultValue) {
      setCategory(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    onSearch({
      searchString: search,
      category,
    })
  }, [onSearch, category, search])

  return (
    <div className={styles.control_panel}>
      <SearchBar
        className={styles.search}
        loading={loading}
        label={'Search for a quiz'}
        onStartSearch={setSearch}
      />
      <Conditional shouldRender={CONFIG.FEATURE_362}>
        <LinkButton to={'/quiz-score-board'}>
          Check out the quiz high score
        </LinkButton>
      </Conditional>
      {/*<CategorySelect*/}
      {/*  disabled={loading}*/}
      {/*  value={category}*/}
      {/*  onChange={(value) => setCategory(value)}*/}
      {/*/>*/}
    </div>
  )
}
