import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import Button from 'components/Button'
import { Input } from 'components/Inputs'
import Conditional from 'components/Conditional'
import { OutlinedContainer } from 'components/OutlineContainer'
import { getGenderLabel } from 'utils/format'
import { capitalizeFirstLetter } from 'utils/strings'

import { useLazyGetRecordByIdQuery } from 'store/api'
import { RecordType } from 'store/api/types/Record.types'

import styles from './PreviousRecordInfo.module.scss'

const TwoColumnsGrid: React.FC<{
  className?: string
  oneTemplate?: boolean
}> = ({ children, className, oneTemplate }) => (
  <div
    className={className}
    style={{
      display: 'grid',
      gridTemplateColumns: oneTemplate ? '1fr' : '1fr 1fr',
      columnGap: '32px',
      width: '100%',
    }}
  >
    {children}
  </div>
)

export interface PreviousRecordInfoProps {
  id?: string
  currentRecordGender: string
  currentRecordAge: string
  currentRecordCategory?: string
  changePreviousRecord?(id?: string): void
  onValidChange?(isValid: boolean): void
  disabled?: boolean
}

export const PreviousRecordInfo = ({
  id,
  changePreviousRecord,
  currentRecordGender,
  currentRecordAge,
  currentRecordCategory,
  onValidChange,
  disabled,
}: PreviousRecordInfoProps) => {
  const [getRecordById, { data, isLoading: loading }] =
    useLazyGetRecordByIdQuery()
  const [previousRecordId, setPreviousRecordId] = useState(id)
  const [hiddenContent, setHiddenContent] = useState(true)

  useEffect(() => {
    if (id) {
      setPreviousRecordId(id)
      getRecordById(id)
    }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if (data && onValidChange) {
      const isBroken = data?.deletedAt || data?.newRecordId
      const isGenderEqual = data?.gender === currentRecordGender
      const isAgeEqual = data?.ageGap === currentRecordAge
      const isCategoryEqual = data?.category === currentRecordCategory

      onValidChange(
        !isBroken &&
          (currentRecordCategory
            ? isCategoryEqual
            : isGenderEqual && isAgeEqual),
      )
    }
    // eslint-disable-next-line
  }, [data])

  const isBroken = Boolean(data?.deletedAt || data?.newRecordId)
  const isGenderEqual =
    data?.gender.toUpperCase() === currentRecordGender.toUpperCase()
  const isAgeEqual = data?.ageGap === currentRecordAge
  const isCategoryEqual = data?.category.toUpperCase() === currentRecordCategory

  return (
    <OutlinedContainer
      label="Previous Record Information"
      className={styles.outlined}
    >
      <div className={styles.main}>
        <div className={styles.inputRow}>
          <TwoColumnsGrid oneTemplate={changePreviousRecord && hiddenContent}>
            <Input
              className={styles.recordIdInput}
              label="This Record is breaking record #"
              disabled={!Boolean(changePreviousRecord) || disabled}
              onFocus={() => {
                setHiddenContent(false)
              }}
              onChange={(event) => setPreviousRecordId(event.target.value)}
              value={previousRecordId}
              defaultValue={id}
            />
            <Conditional
              shouldRender={Boolean(changePreviousRecord && !hiddenContent)}
            >
              <Button
                theme="secondary"
                onClick={() =>
                  previousRecordId && getRecordById(previousRecordId)
                }
                disabled={!previousRecordId || loading}
              >
                Find
              </Button>
            </Conditional>
            <Conditional shouldRender={!Boolean(changePreviousRecord)}>
              <Button
                theme="secondary"
                onClick={() => window.open(`/records/${id}`)}
                disabled={!id || loading}
              >
                To Record
              </Button>
            </Conditional>
          </TwoColumnsGrid>
        </div>
        <div
          className={cn(styles.content, !hiddenContent ? styles.visible : '')}
        >
          <Conditional shouldRender={Boolean(data)}>
            <div className={styles.record}>
              <img alt="Record img" src={data?.previewImageUrl} />
              <div className={styles.info}>
                <div className={styles.title}>{data?.idea.title}</div>
                <div className={styles.description}>{data?.description}</div>
                <div className={styles.date}>Broken on: {data?.brokenOn}</div>
                <div className={styles.date}>
                  Type: {capitalizeFirstLetter(data?.type || '')}
                </div>
                <div className={cn(styles.status, isBroken && styles.broken)}>
                  Record status: {isBroken ? 'Broken' : 'Active'}
                </div>
                <Conditional shouldRender={data?.type !== RecordType.GROUP}>
                  <div
                    className={cn(
                      styles.status,
                      !isGenderEqual && styles.broken,
                    )}
                  >
                    Gender: {getGenderLabel(data?.gender || 'gender_x')}
                  </div>
                  <div
                    className={cn(styles.status, !isAgeEqual && styles.broken)}
                  >
                    Age: {data?.ageGap}
                  </div>
                </Conditional>
                <Conditional shouldRender={data?.type === RecordType.GROUP}>
                  <div
                    className={cn(
                      styles.status,
                      !isCategoryEqual && styles.broken,
                    )}
                  >
                    Category: {capitalizeFirstLetter(data?.category || '')}
                  </div>
                </Conditional>
              </div>
            </div>
          </Conditional>
          <Conditional shouldRender={!!changePreviousRecord}>
            <div className="flex flex-row justify-end pt5">
              <Button
                ghost
                theme="secondary"
                onClick={() => {
                  setHiddenContent(true)
                  setPreviousRecordId(id)
                  id && getRecordById(id)
                }}
              >
                Cancel
              </Button>
              <Button
                theme="secondary"
                onClick={() => {
                  changePreviousRecord &&
                    data &&
                    changePreviousRecord(previousRecordId)
                  setHiddenContent(true)
                }}
                disabled={
                  !data ||
                  loading ||
                  id === previousRecordId ||
                  (!!previousRecordId &&
                    (isBroken ||
                      (currentRecordCategory
                        ? !isCategoryEqual
                        : !isGenderEqual || !isAgeEqual)))
                }
              >
                Apply
              </Button>
            </div>
          </Conditional>
        </div>
      </div>
    </OutlinedContainer>
  )
}
