import React, { useState, useEffect } from 'react'

import IconButton from 'components/IconButton'

import './quantity-update.css'

interface QuantityUpdateProps {
  defaultValue?: number
  disabled?: boolean
  name?: string
  onChange?: (value: number) => void
}

const QuantityUpdate: React.FC<QuantityUpdateProps> = ({
  defaultValue = 1,
  disabled = false,
  name,
  onChange,
}) => {
  const [quantity, setQuantity] = useState<number>(defaultValue)

  useEffect(() => {
    setQuantity(defaultValue)
  }, [defaultValue])

  return (
    <div className="flex flex-column justify-end items-start">
      <div className="quantity-update__button flex justify-between items-center border-box">
        <IconButton
          name="remove"
          className="quantity-update__icon-remove"
          disabled={disabled || quantity === 1}
          onClick={() => {
            onChange && onChange(quantity - 1)
            setQuantity((quantity: number) => quantity - 1)
          }}
        />
        <input
          name={name}
          className="quantity-update__count nunito-bold dark-gray"
          value={quantity}
          disabled={true}
        />
        <IconButton
          name="add"
          disabled={disabled}
          className="quantity-update__icon-add"
          onClick={() => {
            onChange && onChange(quantity + 1)
            setQuantity((quantity: number) => quantity + 1)
          }}
        />
      </div>
    </div>
  )
}

export default QuantityUpdate
