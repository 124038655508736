import React, { ReactNode } from 'react'
import cn from 'classnames'

import styles from './OutlinedContainer.module.scss'

export interface OutlinedContainerProps {
  label: string
  className?: string
  children: ReactNode
  errorMessage?: string
}

export const OutlinedContainer = ({
  label,
  className,
  children,
  errorMessage,
}: OutlinedContainerProps) => (
  <div
    className={cn(
      styles.outlinedContainer,
      className,
      errorMessage ? styles.error : '',
    )}
  >
    <label className={cn(styles.label, errorMessage ? styles.error : '')}>
      {label}
    </label>
    {children}
  </div>
)
