import Joi from 'joi'
import { FormError, UpdateEvent } from '../CampaignForm/types'

enum EventTemplate {
  TEMPLATE_1 = 'TEMPLATE_1',
}

export const validationSchema: Joi.ObjectSchema = Joi.object({
  template: Joi.string().valid(...Object.values(EventTemplate)),
  name: Joi.string(),
  description: Joi.string(),
  medias: Joi.array().items(Joi.object()),
  partnerName: Joi.string(),
  target: Joi.number(),
  isMediaUploadRequired: Joi.boolean(),
  links: Joi.array().items(
    Joi.object({
      name: Joi.string().required(),
      url: Joi.string().required(),
    }),
  ),
  sponsors: Joi.array().items(Joi.string().uuid()),
  startDate: Joi.date(),
  endDate: Joi.date().greater(Joi.ref('startDate')),
  comingSoonSplashPageTitle: Joi.string(),
  comingSoonSplashPageDescription: Joi.string(),
  comingSoonPageMedias: Joi.array().items(Joi.object()),
  finishedSplashPageTitle: Joi.string(),
  finishedSplashPageDescription: Joi.string(),
  finishedPageMedias: Joi.array().items(Joi.object()),
  certificateTitle: Joi.string(),
  certificateDescription: Joi.string(),
  whitelistEnabled: Joi.boolean(),
  whitelistedEmails: Joi.array()
    .items(
      Joi.string()
        .email({ tlds: { allow: false } })
        .label('whitelistedEmails'),
    )
    .custom((value, helpers) => {
      if (helpers.state.ancestors[0].whitelistEnabled && !Boolean(value[0])) {
        return helpers.error('any.invalid')
      }
      return value
    }),
})

export const updateValidationSchema: Joi.ObjectSchema = Joi.object({
  template: Joi.string().valid(...Object.values(EventTemplate)),
  name: Joi.string(),
  description: Joi.string(),
  medias: Joi.any(),
  partnerName: Joi.string(),
  target: Joi.number(),
  isMediaUploadRequired: Joi.boolean(),
  links: Joi.array().items(
    Joi.object({
      name: Joi.string().required(),
      url: Joi.string().required(),
    }),
  ),
  startDate: Joi.date(),
  endDate: Joi.date().greater(Joi.ref('startDate')),
  comingSoonSplashPageTitle: Joi.string(),
  comingSoonSplashPageDescription: Joi.string(),
  comingSoonPageMedias: Joi.any(),
  finishedSplashPageTitle: Joi.string(),
  finishedSplashPageDescription: Joi.string(),
  finishedPageMedias: Joi.any(),
  certificateTitle: Joi.string(),
  certificateDescription: Joi.string(),
  sponsors: Joi.array().items(Joi.string().uuid()),
  whitelistEnabled: Joi.boolean(),
  whitelistedEmails: Joi.array()
    .items(
      Joi.string()
        .email({ tlds: { allow: false } })
        .label('whitelistedEmails'),
    )
    .custom((value, helpers) => {
      if (helpers.state.ancestors[0].whitelistEnabled && !Boolean(value[0])) {
        return helpers.error('any.invalid')
      }
      return value
    }),
})

const parseErrors = (validationResult: Joi.ValidationResult<any>) => {
  if (validationResult.error?.details) {
    const validationErrors = validationResult.error.details
    if (validationErrors) {
      return validationErrors.reduce((acc, item) => {
        const label = item.context?.label
        return label ? { ...acc, [label]: item.message } : acc
      }, {})
    }
  }
}

export const getErrorsCreate = (event: UpdateEvent): FormError | undefined => {
  const validationResult = validationSchema.validate(
    {
      ...event,
      medias: event.medias.length && Array.from(event.medias),
      comingSoonPageMedias:
        event.comingSoonPageMedias.length &&
        Array.from(event.comingSoonPageMedias),
      finishedPageMedias:
        event.finishedPageMedias.length && Array.from(event.finishedPageMedias),
    },
    {
      abortEarly: false,
    },
  )
  return parseErrors(validationResult)
}

export const getErrorsUpdate = (event: UpdateEvent): FormError | undefined => {
  const validationResult = updateValidationSchema.validate(event, {
    abortEarly: false,
  })
  return parseErrors(validationResult)
}
