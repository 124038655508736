import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router'

import {
  useLazyGetEventForParticipationQuery,
  useApplyToEventMutation,
  useGetDraftQuery,
} from 'store/api'
import { DraftType, Sponsor } from 'store/api/types/Event.types'
import { showToaster } from 'store/app/actions'

import Loader from 'components/Loader'
import Button from 'components/Button'
import ShadowModal from 'components/ShadowModal'

import { ClosedEvent } from './ClosedEvent'
import { OnGoingEvent } from './OnGoingEvent'
import { ComingSoonEvent } from './ComingSoonEvent'
import { getErrorsParticipate } from './participateInEvent.validator'
import { SubmitParticipant, FormError } from './type'
import { uploadApplyEventImages } from '../Events.utils'

import styles from './ParticipantsInEvent.module.scss'
import { PageWrapper } from 'components/PageWrapper'

type ParticipateInEventParams = {
  id: string
}

export interface EventProps {
  links: {
    name: string
    url: string
  }[]
  image: string[]
  title: string
  description: string
  target: number
  startDate: Date
  endDate: Date
  sponsors: Sponsor[]
  isMediaUploadRequired?: boolean
}

export interface DataProps {
  disabled: boolean
  event: EventProps
  errors?: FormError
  onSubmit: (e: SubmitParticipant) => void
  onZero: () => void
}

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const ParticipateInEvent: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  let query = useQuery()
  const params = useParams<ParticipateInEventParams>()
  const preview = query.get('preview') === 'true'
  const token = query.get('token') || undefined

  const searchProps = useMemo(
    () => ({ id: params.id, preview, token }),
    [params.id, preview, token],
  )

  const [trigger, { data: event, isLoading: isLoadingEvent, error }] =
    useLazyGetEventForParticipationQuery()

  const { data: draft, isLoading: isLoadingDraft } = useGetDraftQuery(
    DraftType.EVENT_PARTICIPANT,
  )
  const [applyEvent, { isLoading: isApplying }] = useApplyToEventMutation()
  const [errors, setErrors] = useState<FormError | undefined>({})
  const [isModalOpen, setIsOpenModal] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)

  const onCancelModal = () => {
    setIsOpenModal(false)
    navigate('/')
  }

  const participateInEvent = async (applyData: SubmitParticipant) => {
    if (event && !preview) {
      const rawErrors = getErrorsParticipate({
        ...applyData,
        isMediaRequired: event.isMediaUploadRequired,
      })
      setErrors(rawErrors)
      if (!isLoading && !rawErrors && draft) {
        setDisabled(true)
        let medias: string[] = []
        if (event.isMediaUploadRequired) {
          const image = await uploadApplyEventImages(
            draft.id,
            event.id,
            applyData.medias,
          )
          medias = image.medias
        }
        try {
          await applyEvent({
            ...applyData,
            id: draft.id,
            medias: medias,
            eventId: event.id,
          }).unwrap()
          setIsOpenModal(true)
        } catch (e) {
          dispatch(
            showToaster({
              message:
                'Please contact the event organizer to have your email added to the event.',
              color: 'red',
            }),
          )
        }
        setDisabled(false)
      }
    }
  }

  const isLoading = isLoadingDraft || isLoadingEvent || isApplying
  let Page = null

  const onTrigger = () => {
    // @ts-ignore
    trigger(searchProps)
  }

  useEffect(() => {
    // @ts-ignore
    trigger(searchProps)
  }, [trigger, searchProps])

  if (error) {
    return <Navigate to="/events" />
  }

  if (!isLoading && event) {
    const isComingSoon =
      new Date().getTime() < new Date(event.eventStartDate).getTime()
    const isClosed =
      new Date().getTime() >= new Date(event.eventEndDate).getTime()
    const isOnGoing = !isComingSoon && !isClosed
    if (isOnGoing || preview) {
      Page = (
        <OnGoingEvent
          disabled={disabled || preview}
          event={{
            links: event.links,
            startDate: event.eventStartDate,
            endDate: event.eventEndDate,
            image: event.mediaList,
            description: event.description,
            title: event.title,
            target: event.target,
            isMediaUploadRequired: event.isMediaUploadRequired,
            sponsors: event.sponsors,
          }}
          errors={errors}
          onSubmit={participateInEvent}
          onZero={onTrigger}
        />
      )
    }
    if (isComingSoon && !preview) {
      Page = (
        <ComingSoonEvent
          startDate={event.eventStartDate}
          image={event.mediaList}
          description={event.description}
          title={event.title}
          onZero={onTrigger}
        />
      )
    }
    if (isClosed && !preview) {
      Page = (
        <ClosedEvent
          image={event.mediaList}
          description={event.description}
          title={event.title}
        />
      )
    }

    return (
      <PageWrapper>
        <div className={styles.page}>
          <Loader active={isLoading} className={styles.loader} />
          <ShadowModal
            isModalOpen={isModalOpen}
            className={styles.modal}
            withShadow
            noSize
          >
            <div className={styles.modalContainer}>
              <div className={styles.header}>Thank you for participating!</div>
              <div className={styles.modalText}>
                You will receive a digital certificate of participation if the
                record and your submission is approved.
                <br />
                <br />
                Don’t forget to{' '}
                <a
                  href={`/signup`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  register for an account
                </a>{' '}
                and add your child in order to submit your own record attempts
                on Kids World Records.
              </div>
              <Button className={styles.closeBtn} onClick={onCancelModal}>
                Close
              </Button>
              <div className={styles.bottomText}>
                We do not sell or share your information for marketing, ever.
                You may opt out at any time.{' '}
                <a
                  href={`/terms-of-service`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline' }}
                >
                  Terms of Service
                </a>
                {` `}
                and{' '}
                <a
                  href={`/privacy-policy`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline' }}
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </ShadowModal>
          {Page}
        </div>
      </PageWrapper>
    )
  }
  return <PageWrapper />
}
